<template>
  <div class="">
    <el-dialog :title="rejectInfo.title" width="740px" v-model="disagreeDialog" @close="closeDialog" >
      <el-form ref="editForm" :model="form" size="small" :rules="rules" style="margin:auto;">
        <el-form-item :label="rejectInfo.label ? `${rejectInfo.label}：` : '拒绝原因：'" prop="remark">
          <el-input v-model="form.remark" :placeholder="`请输入${rejectInfo.label || '拒绝原因'}`" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" v-on:click="onSubmit" :disabled="iscanEdit">提交</el-button>
            <el-button v-on:click="closeDialog">关闭</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default {
  name: "rejectPopup",
  props: {
    iscanEdit: {
      type: Boolean,
      default: false
    },
    rejectInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      disagreeDialog:true,
      form:{},
      rules: {
        remark: [{ required: true, message: "拒绝原因不能为空", trigger: "blur" }]
      },
    };
  },
  mounted() {
    if(this.rejectInfo.label){
      this.rules.remark[0].message = this.rejectInfo.label + '不能为空'
    }
  },
  methods: {
    closeDialog(){
      this.$emit("closedialog");
    },
    onSubmit() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          let postData=cloneObject(this.form);
          if(this.rejectInfo.value!='remark'){
            postData[this.rejectInfo.value]=postData.remark
            delete postData.remark;
          }
          this.$emit("submitform", postData);
        } else {
          return false
        }
      });
    }
  }

};
</script>

 <template>
  <div class="content_box">
    <div class="search_bar">
      <el-button-group>
        <el-button type="success"  size="small" @click="addItem()">添加</el-button>
        <el-button type="warning" size="small" @click="BatchUpdateGroupState(1)">批量审核</el-button>
        <el-button type="danger" size="small" @click="BatchUpdateGroupState(2)">批量作废</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.groupState==1||actionBtn.data.groupState==2?'info':'primary'"  plain size="small"  @click="submitItem(actionBtn.data,'edit')" :disabled="canEdit || actionBtn.data.groupState==1||actionBtn.data.groupState==2">编辑</el-button>
          <el-button type="success"  plain size="small" @click="submitItem(actionBtn.data,'ck')" :disabled="canEdit">查看</el-button>
          <el-button :type="actionBtn.data.groupState==1||actionBtn.data.groupState==2?'info':'warning'"  plain size="small" @click="submitItem(actionBtn.data,'audit')" :disabled="canEdit || actionBtn.data.groupState==1||actionBtn.data.groupState==2">审核</el-button>
          <el-button :type="actionBtn.data.ifDisabled?'info':'danger'"  plain size="small" @click="submitItem(actionBtn.data,'abolish')" :disabled="canEdit || actionBtn.data.ifDisabled">作废</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showDetailPage" title="爆款详情" width="70%" style="padding-bottom:20px;">
      <div style="margin:20px 0">
        驿站: <span class="textprimary textspan mr30">{{curItem.stageName}}</span>
        日期: <span class="textdanger textspan mr30">{{$formatDate(curItem.date)}}</span>
        起止时间: <span class="textdanger textspan mr30">{{curItem.startTime}}--{{curItem.endTime}}</span>
      </div>
      <mainTable :mainOptions="detailData" ref="imgTable"></mainTable>
    </el-dialog>
    <el-dialog v-model="showEditDialog" title="设置今日爆款" style="width:85%;max-width:1080px;">
      <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true,labelWidth:70,formRules:dynamicRules}" @changeform="changeform"  ></Vform>
      <div class="search_bar" style="background: rgba(21,162,231,0.2);padding:4px 10px 0;margin:0 0">
        <span class="textprimary textspan">限购特价商品</span>
        <baseSearch :formItemList="searchDetailItem" @search="searchDetail" :showRestbtn="false"></baseSearch>
      </div>
      <mainTable :mainOptions="channelData">
        <template v-slot:operation="actionBtn">
          <el-button :type="showSelect(actionBtn.data)?'info':'warning'"  plain size="small"  @click="selectItem(actionBtn.data,'edit')" :disabled="showSelect(actionBtn.data)">选中</el-button>
        </template>
      </mainTable>
      <div class="search_bar" style="background: rgba(21,162,231,0.2);padding:6px 10px;margin:0 0">
        <span class="textprimary textspan">爆品商品</span>
      </div>
      <mainTable :mainOptions="hotData" >
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.index==0?'info':'success'"  plain size="small" :disabled="actionBtn.index==0" @click="orderClick(actionBtn.index, actionBtn.data, true)">上移</el-button>
          <el-button :type="actionBtn.index==hotData.DataList.length-1?'info':'warning'"  :disabled="actionBtn.index==hotData.DataList.length-1" plain size="small" @click="orderClick(actionBtn.index, actionBtn.data, false)" >下移</el-button>
          <el-button type="danger"  plain size="small" @click="deleteclick(actionBtn.index,actionBtn.data)">删除</el-button>
        </template>
      </mainTable>
      <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" v-on:click="submitForm(true)" :disabled="canEdit">提交</el-button>
              <el-button v-on:click="showEditDialog = false">关闭</el-button>
            </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    var col=[
      { label: "商品名称", minWidth: 190, prop: "ProductNamePromotion" },
      { label: "运营商", width: 190, prop: "OperatorName" }];
    return {
      baseApiUrl: '/product',
      dateBegin:this.$formatDate(0, -15),
      dateEnd:this.$formatDate(0, 0),
      searchDetailItem: [
         {type: "input", value: "kw",placeholder: '支持查询商品'}
      ],
      searchData: {},
      mainData: {
        DataList: [],
        isMultiple: true,
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"260", fixed:'left'},
          { label: "日期", width: 100, prop: "date", callBack:(item)=>{ return this.$formatDate(item.date)}},
          { label: "开始时间", width: 100, prop: "startTime" },
          { label: "结束时间", width: 100, prop: "endTime" },
          { label: "明细数量", width: 100, prop: "count" },
          { label: "驿站", width: 150, prop: "stageName" },
          { label: "状态", width: 100, prop: "groupStateName", callBack:(item)=>{ return item.groupStateName},getColor:(item)=>{return item.groupState=='1'?'textsuccess':'textdanger'}},
          { label: "创建人", minWidth: 100, prop: "createdBy" }
        ]
      },
      channelData: {
        DataList: [],
        tableHeight:250,
        labelList: [
          ...col,
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'right'},
          { label: "限购数量", width: 70,callBack:(item)=>{ return item.LimitQty},getColor:()=>{return 'textdanger'}},
          { label: "单价", width: 70,callBack:(item)=>{ return item?.UnitPrice.toFixed(3)}},
          { label: "运费", width: 50,callBack:(item)=>{ return item?.FreightPrice.toFixed(3)}},
          { label: "单位", width: 50, prop: "FreightUnit" }
        ]
      },
      hotData: {
        DataList: [],
        tableHeight:210,
        labelList: [
          ...col,
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"190", fixed:'right'},
          { label: "限购数量", width: 70,callBack:(item)=>{ return item.LimitQty},getColor:()=>{return 'textdanger'}},
          { label: "单价", width: 70,callBack:(item)=>{ return item?.UnitPrice.toFixed(3)}},
          { label: "运费", width: 50,callBack:(item)=>{ return item?.FreightPrice.toFixed(3)}},
          { label: "单位", width: 50, prop: "FreightUnit" }
        ]
      },
      detailData: {
        DataList: [],
        tableHeight: 300,
        labelList: [
          { label: "商品名称",  minWidth: 100, prop: "ProductNamePromotion" },
          { label: "运营商", width: 200, prop: "OperatorName" },
          { label: "限购数量", width: 70, prop: "LimitQty" },
          { label: "单价", width: 70,prop:'UnitPrice'},
          { label: "运费", width: 80, prop: "FreightPrice" },
          { label: "单位", width: 60, prop: "FreightUnit" }
        ]
      },
      FormItems: [
        {label:"起止时间",type: "datetime", value: "timelist", span:3,startMinTime:this.$formatDate(0,'','hh:MM'), defaultValue:[this.$formatDate(0,'','hh:MM'),"20:00"]},
        {label:"商圈",type: "select", value: "merchantCode",span:3,placeholder: '请选择商圈',keyValue:['Code','Name'],multiple:false},
        {label:"驿站",type: "select", value: "stageCode",span:3,placeholder: '请选择驿站',keyValue:['Code','Name'],multiple:true}
      ],
      StageInfo:'',
      showDetailPage:false,
      showEditDialog:false,
      visibleStage:false,
      selectData:{},
      disabledList:{},
      modeType:'',
      curItem:{},
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      rules: {
        timelist:[{ required: true, message: "请选择起止时间", trigger: "manual" }],
        merchantCode: [{ required: true, message: "请选择商圈", trigger: "manual" }],
        stageCode: [{ required: true, message: "请选择驿站", trigger: "manual" }],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "日期", type: "daterange", value: "createtime",defaultValue:[this.dateBegin,this.dateEnd]},
        {label: "驿站", type: "select", value: "stageCode", selectOptions:this.StageInfo, multiple:false, keyValue:['Code','Name']},
      ];
    },
    formItemList() {
      return this.FormItems.map(item => ({
        ...item,
        visible:item.value=='stageCode'?this.visibleStage:true,
        selectOptions:this.selectData[item.value]||item.selectOptions||'',
        isDisabled:this.disabledList[item.value]||item.isDisabled||false,
        defaultValue: item.multiple==true&&typeof this.curItem[item.value]=='string'?this.curItem[item.value]&&this.curItem[item.value].split(','):(this.curItem[item.value]||item.defaultValue)
      }));
    },
    dynamicRules() {
      let rules = {};
      for (let item of this.formItemList) {
        if (item.visible!=false) {
          rules[item.value] = this.rules[item.value];
        }
      }
      return rules;
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.MerchantInfo,query:{...this.CacheKeys.MerchantInfo.query,state:-1}}).then((res) => {
        this.selectData.merchantCode=res;
      });
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.StageInfo,query:{...this.CacheKeys.StageInfo.query,state:-1}}).then((res) => {
        this.StageInfo=res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/GetRecommendGroup', {...this.searchData, ...this.pageinfo}).then((res)=>{
        let now = new Date().getTime()
        res.data.rows.forEach(item => {
          if(now>new Date(this.$formatDate(item.date) + ` ${item.endTime}`).getTime()||item.groupState==2){
            item.ifDisabled = true
          }else{
            item.ifDisabled = false
          }
        });
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    showSelect(d){
      let index=this.hotData.DataList.findIndex(item=>  d.ItemSerialCode==item.ItemSerialCode)
      return index !=-1;
    },
    selectItem(d){
      if(this.hotData.DataList.length>4){
        this.showTipMessage('爆款商品不可超过5个','error');
        return true;
      }
      this.hotData.DataList=[...this.hotData.DataList,d]
    },
    searchDetail(postData){
      let data=this.$refs.vform.formatData();
      let startTime= data.timelist[0].replace(":", "");
      let endTime= data.timelist[1].replace(":", "");
      if(!data.merchantCode){
        this.canEdit=false;this.showTipMessage('请先选择商圈后选择驿站再搜索','error');
        return;
      }else if(!data.stageCode.length){
        this.canEdit=false;this.showTipMessage('请先选择驿站再搜索','error');
        return;
      }else if(startTime>endTime){
        this.canEdit=false;this.showTipMessage('开始时间不能大于结束时间，请重新选择后再搜索','error');
        return;
      }
      this.$http.post(this.baseApiUrl + '/GetLimitGoods',{startTime:data.timelist[0],endTime:data.timelist[1],stageCode:this.modeType=='edit'?this.curItem.Code:data.stageCode.join(','),...postData}).then((res)=>{
        this.channelData.DataList=res.data;
      },(res)=>{this.showTipMessage(res.msg||'未知错误','error');});
    },
    orderClick(index,data,direction){
      let pre = direction ? index - 1 : index + 1
      if(pre < 0 || pre >= this.hotData.DataList.length){
        var type=direction ? 'up' : 'dwon';
        if(type=='up'){
          this.showTipMessage("已经是第一个了，不能上移","warning");
        }else if(type=='dwon'){
          this.showTipMessage("已经是最后一个了，不能下移","warning");
        }
        return
      }
      this.hotData.DataList.splice(index,1)
      this.hotData.DataList.splice(pre,0,data)
    },
    deleteclick(data,index){
      this.hotData.DataList.splice(index,1);
    },
    addItem(){
      this.showEditDialog=true;
      this.modeType='add';
      this.curItem={},
      this.channelData.DataList=[];
      this.visibleStage=false,
      this.hotData.DataList=[];
      this.disabledList={},
      this.FormItems[0].defaultValue=[this.$formatDate(0,'','hh:MM'),'20:00']
    },
    submitItem(d,type){
      switch (type){
        case 'abolish':
          this.showConfirm('确定要作废吗',()=>{
            this.canEdit=true;
            this.$http.post(this.baseApiUrl + '/UpdateStateRecommendGroup',{groupCode:d.groupCode,operationType:2}).then(()=>{
              this.canEdit=false;
              this.showTipMessage('作废成功','success');
              this.search();
            },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
          })
          break;
        case 'audit':
          this.canEdit=true;
          this.$http.post(this.baseApiUrl + '/UpdateStateRecommendGroup',{groupCode:d.groupCode,operationType:1}).then(()=>{
            this.canEdit=false;
            this.showTipMessage('审核成功','success');
            this.search();
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
          break
        case 'ck':
          this.canEdit=true;
          this.$http.post(this.baseApiUrl + '/GetRecommendGroupDetail',{groupCode:d.groupCode}).then((res)=>{
            this.curItem=res.data[0];
            this.detailData.DataList=res.data[0].productList;
            this.showDetailPage=true;
            this.canEdit=false;
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
          break
        case 'edit':
          this.channelData.DataList=[];
          this.$http.post(this.baseApiUrl + '/GetRecommendGroupDetail', {groupCode:d.groupCode}).then((res)=>{
            this.curItem={
              timelist:[d.startTime,d.endTime],
              merchantCode:d.merchantCode,
              stageCode:d.stageName,
              Code:d.warehouseCode,
              endTime:d.endTime,
              startTime:d.startTime,
              stageName:d.stageName,
              groupCode:d.groupCode,
              date:d.date};
            this.hotData.DataList=res.data[0].productList
            this.showEditDialog=true;
            this.modeType='edit';
            this.visibleStage=true;
            this.disabledList={stageCode:true,merchantCode:true};
          })
          break
      }
    },
    changeform(d){
      if(d.btnValue=='timelist'&&(this.channelData.DataList.length>0||this.hotData.DataList.length>0)){
        ElementPlus.ElMessageBox.confirm('点击【确定】将清除下方两个列表的数据，是否确认修改起止时间？', '确认对话框', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.channelData.DataList = [];
          this.hotData.DataList = [];
          if(this.modeType=='edit'){this.curItem={...this.curItem,...d}}
          else{this.curItem={...this.curItem,...d}}
        }).catch(()=> {
          if(this.modeType=='edit'){ this.curItem={...this.curItem,...d,timelist:d.oldData.timelist}}
          else{this.curItem={...this.curItem,...d,timelist:d.oldData.timelist}}
        });
      }
      if(this.modeType=='edit'){
        return;
      }
      if((typeof d.merchantCode=='string'&&!d.merchantCode)||d.merchantCode.length==0){
        this.curItem=d;
        this.visibleStage = false;
        return;
      }
      if(d.merchantCode!=this.curItem.merchantCode){
        d.groupCode=this.modeType=='edit'?this.curItem.groupCode:'';
        this.curItem=d;
        this.$http.post('/Ajax/DCLoad', {t:'StageListByMerchantCode',code:d.merchantCode}).then((res)=>{
          this.visibleStage = true;
          this.selectData.stageCode = res.data;
        })
      }
    },
    async submitForm(){
      const formData = await this.$refs.vform.validateForm();
      let arr=[];
      this.hotData.DataList.map((item,index)=>{
        arr.push({ItemSerialCode:item.ItemSerialCode,sort:index+1,taskCode:item.TaskCode});
      })
      let postData={},obj={startTime:formData.timelist[0], endTime:formData.timelist[1], product:JSON.stringify(arr)};
      let startTime= formData.timelist[0].replace(":", "");
      let endTime= formData.timelist[1].replace(":", "");
      if(startTime>endTime){
        this.showTipMessage('开始时间不能大于结束时间，请重新选择后再提交','error');
        return;
      }
      if(this.modeType=='add'){
         postData= {
           merchantCode: formData.merchantCode,
           stageCode: formData.stageCode.join(','),
           ...obj
         }
      }else{
        postData={ ...obj,groupCode:this.curItem.groupCode}
      }
      let url= this.modeType=='edit'?'/RecommendGroupEdit':'/RecommendGroupAdd';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url,postData).then(()=>{
        this.showTipMessage(this.modeType=='edit'?'修改爆款成功！':'新建爆款成功！','success')
        this.channelData.DataList = []
        this.hotData.DataList = []
        this.search();
        this.showEditDialog=false;
        this.canEdit=false;
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    BatchUpdateGroupState(type) {
      let data=this.$refs.mainTable.select();
      if(!data) return;
      let ids=[];
      data.map((item)=>{ids.push(item.id)})
      this.showConfirm(`是否${type==1?'审核':'作废'}所选记录`,()=>{
        this.$http.post('/product/BatchUpdateGroupState', {ids:ids.join(','),operationType:type}).then((res)=>{
          this.showTipMessage(type==1?"审核成功":"作废成功","success");
          this.getListData();
        });
      })
    },
  }
}
</script>

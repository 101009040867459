<template>
  <div class="content_box">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/report',
            searchData: {},
            selectOptions:[],
            orderTypeList:[
                { Code: "31", Name: "城批销售采销单" },
                { Code: "32", Name: "城批销退采销单" },
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { prop: "orderNo", label: '业务单号', width: 210 },
                    { prop: "orderTypeName", label: '订单类型', width: 150 },
                    { prop: 'auditedTime', label: '入账时间', width: 150, },
                    { prop: "operatorName", label: '运营商名称', width: 200 },
                    { prop: 'warehouseName', label: '仓库', width: 100 },
                    { prop: "productCode", label: '产品编码', width: 100 },
                    { prop: "productName", label: '产品名称', width: 150 },
                    { prop: "traderName", label: '客户名称', width: 200 },
                    { prop: "salerName", label: '业务员', width: 120 },
                    { prop: "unitQty", label: '数量', width: 70 },
                    { prop: 'amount', label: '金额', width: 80 },
                    { prop: "unitPrice", label: '产品单价', width: 70 },
                    { prop: 'shipPrice', label: '运费', width:80 },
                    { prop: 'orderStateName', label: '订单状态', minWidth: 70 },


                    { prop: "orderNo", label: '业务单号', width: 200 },
                    { prop: "orderTypeName", label: '业务类型', width: 150 },
                    { prop: "operatorName", label: '销售方', width: 200 },

                    {
                        prop: 'createdTime', label: '制单时间', width: 100, callBack: (item)=>{
                            return this.$formatDate(item.createdTime);
                        }
                    },
                    {
                        prop: 'tradedTime', label: '入账时间', width: 100,  callBack: (item)=>{
                            return this.$formatDate(item.tradedTime);
                        }
                    },

                    { prop: "traderCode", label: '采购方编码', width: 150 },
                    { prop: "traderName", label: '采购方', width: 200 },
                    { prop: "salerName", label: '业务员', width: 150 },

                    { prop: "clientTypeName", label: '客户类型', width: 100 },
                    { prop: 'warehouseName', label: '仓库', width: 150 },
                    { prop: "productCode", label: '商品编码', width: 100 },
                    { prop: 'productName', label: '商品名称', width: 200 },
                    { prop: 'barcode', label: '商品条码', width: 100 },
                    { prop: 'unit', label: '单位', width: 100 },
                    { prop: 'unitQty', label: '数量', width: 100 },
                    { prop: 'unitPrice', label: '单价', width: 70 },
                    { prop: 'amount', label: '金额', width: 100 },
                    { prop: 'cost', label: '成本价', width: 100 },
                    { prop: 'costAmount', label: '成本金额', width: 100 },
                    { prop: 'batch', label: '批次', width: 70 },
                    { prop: 'spec', label: '规格', width: 70 },
                    { prop: 'brandName', label: '品牌', width: 100 },
                    { prop: 'sourceOrderNo', label: '关联单号', width: 150 },
                    { prop: 'packNum', label: '包装数量', width: 100 },
                    { prop: 'boxWeight', label: '箱重', width: 100 },
                    { prop: 'boxVol', label: '箱体积', width: 100 },
                    { prop: 'remark', label: '备注', width: 100 },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "入账日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                { label: "类型:", type: "select", value: "orderType",filterable:false, clearable:true,selectOptions: this.orderTypeList, multiple: true, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询单号/商品编码/商品名称' }
            ]
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
           let orderType=this.formatData();
            this.$http.post(this.baseApiUrl + '/PromotionPurchaseSaleOrderQuery', { ...this.searchData, ...this.pageinfo,orderType:orderType.join(',')}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        formatData(){
          let orderType=[];
          if(!this.searchData.orderType||this.searchData.orderType.length==0){
            this.orderTypeList.map((item)=>{orderType.push(item.Code)})
          }else{
            orderType=this.searchData.orderType;
          }
          return orderType;
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            let orderType=this.formatData();
            this.postExportData({...this.searchData,orderType:orderType.join(',')}, this.baseApiUrl+'/PromotionPurchaseSaleOrderQuery');
        },
    }
}
</script>

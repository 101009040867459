<template>
  <div class="content_box">
  <div class="search_bar">
    <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
  </div>
  <div class="main-box">
    <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
      <template v-slot:operation="actionBtn">
        <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data,'check')" >查看明细</el-button>
        <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data,'audit')" >审核</el-button>
        <el-button :type="actionBtn.data.groupState==5?'info':'danger'" :disabled="actionBtn.data.groupState==5" plain size="small" @click="delItem(actionBtn.data)" >作废</el-button>
      </template>
    </mainTable>
  </div>
  </div>
  <el-dialog v-model="showDetailPage"  :title="modeType=='audit'?'审核城批促销活动':`${editParams.taskCode}-${editParams.promotionTypeName}：促销活动明细`"   width="70%"  :style='modeType=="check"?"padding-bottom:20px":"padding:0px"'>
    <wholesalePromotionAudit ref="editPage" :params="editParams"  @closeDialog="handleClose" v-if="modeType=='audit'"></wholesalePromotionAudit>
    <h4 class="labelTitle" v-if="modeType=='check'&&['MULTI_ITEM_GIFT','ONE_ITEM_GIFT'].indexOf(editParams.promotionType)>-1">搭赠列表</h4>
    <mainTable :mainOptions="giftData" v-if="modeType=='check'&&['MULTI_ITEM_GIFT','ONE_ITEM_GIFT'].indexOf(editParams.promotionType)>-1" ></mainTable>
    <h3 v-if="modeType=='check'&&editParams.promotionType=='BUNDLE_DEAL'">
      组合总价：<span class="textdanger">{{TotalPrice?TotalPrice.toFixed(2)+'/套':'未设置'}}</span>，
      <span>{{bundleLimity==-1?'无限购上限':`限购&nbsp;${bundleLimity}&nbsp;套`}}&nbsp;&nbsp;&nbsp;</span>
    </h3>
    <h4 class="labelTitle" v-if="modeType=='check'" >商品列表</h4>
    <mainTable :mainOptions="detailData" v-if="modeType=='check'" ></mainTable>
  </el-dialog>
</template>
<script>
import wholesalePromotionAudit from "./wholesale-PromotionAudit";

export default {
  components: {
    wholesalePromotionAudit,
  },
  data() {
    return {
      baseApiUrl: '/promotionTask',
      dateBegin:this.$formatDate(0, -2),
      dateEnd:this.$formatDate(0,+2),
      ARR_groupState: [
        { Id: "1", Name: "已确认" },
        { Id: "2", Name: "部分通过" },
        { Id: "3", Name: "已通过" },
        { Id: "4", Name: "已拒绝" },
        { Id: "5", Name: "作废成功" },
      ],
      promotionTypeList:[],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"220", fixed:'left'},
          { label: "运营商", width: 160, prop: "operatorName" },
          { label: "促销活动组编码", width: 180, prop: "taskCode" },
          { label: "促销活动组名称", width: 160, prop: "taskName" },
          { label: "促销类型", width: 80,callBack:(item)=>{ return item.promotionTypeName}},
          { label: "开始时间", width: 150, prop: "startTime" },
          { label: "结束时间", width: 150, prop: "endTime" },
          { label: "区域", width: 100, prop: "allowCoverageAreaName" },
          { label: "渠道", width: 100, prop: "clientChannelName" },
          { label: "状态", width: 80,callBack:(item)=>{ return item.groupStateName},getColor:(item)=>{return ['textsuccess','textsuccess','textwarning','textdanger'][[3,2,1,4].indexOf(item.groupState)]}},
          { label: "审核人", width: 80, prop: "auditedBy" },
          { label: "审核时间", width: 150, prop: "auditedTime" },
          { label: "审核备注", minWidth: 150, prop: "auditRemark" },
        ]
      },
      modeType:'',
      detailData: {
        DataList:[],
        tableHeight:200,
        labelList: [
          { label: "商品名称", minWidth: 260, prop: "itemSerialName" },
          { label: "状态", width: 80,callBack:(item)=>{ return item.detailStateName},getColor:(item)=>{return ['textwarning','textsuccess','textdanger'][item.detailState]} },
          { label: "单价（元）", width: 100, prop: "unitPrice" },
          { label: "运费（元）", width: 100, prop: "freightPrice" },
          {label: "限购数量", prop: "limitQty", width:"100",isShow:false},
          {label: "组合比例", prop: "qty", width:"100",isShow:false},
        ]
      },
      giftData: {
        DataList:[],
        tableHeight:150,
        labelList: [
          {label: "搭赠品", prop: "name", minWidth:"220",},
          {label: "单位",  prop: "saleUnit",width:"60"},
          {label: "规则描述", prop: "ruleDesc", width:"340"},
        ],
      },
      TotalPrice:0,
      bundleLimity:-1,
      OperatorInfo:[],
      editParams:{},
      showDetailPage:false,
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      }
    }
  },
  inject: ['$hasPermission','$openPageTab'],
  computed: {
    searchItemList() {
      return [
        {label: "开始时间",type: "daterange", value: "createtime", defaultValue: [this.dateBegin, this.dateEnd]},
        {placeholder: "请选择运营商", type: "select", value: "operatorCode", selectOptions:this.OperatorInfo, multiple: false, keyValue:['Code','Name'],width:'120'},
        {placeholder: "请选择促销活动类型", type: "select", value: "promotionType", selectOptions: this.promotionTypeList,multiple: false,width:'120',keyValue:['code','name'],},
        {placeholder: "请选择状态", type: "select", value: "groupState", selectOptions: this.ARR_groupState, multiple: true,width:'180'},
        {type: "input", value: "kw",placeholder:'查询促销活动名称'}
      ];
    }
  },
  mounted(){
    this.commonAjax();
    this.getPromotionTyp();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
        this.OperatorInfo=res;
      });
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetPromotionTaskPageList', {...this.searchData,...this.pageinfo,channelType:1}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    //促销活动类型
   getPromotionTyp(){
     this.$http.post(this.baseApiUrl+'/GetPromotionType', {}).then((res) => {
        this.promotionTypeList = res.data;
      });
    },
    handleClose() {
      this.showDetailPage = false;
      this.search();
    },
    showDetail(d,type){
      this.modeType=type;
      this.editParams=d;
      let index= this.detailData.labelList.findIndex(item=>item.prop=='limitQty');
      let qtyIndex= this.detailData.labelList.findIndex(item=>item.prop=='qty');
      this.detailData.labelList[index].isShow=['SPECIAL_DISCOUNT','SEC_KILL'].indexOf(this.editParams.promotionType)>-1?true:false;
      this.detailData.labelList[qtyIndex].isShow=['BUNDLE_DEAL'].indexOf(this.editParams.promotionType)>-1?true:false;
      if(type!='audit'){
        this.$http.post(this.baseApiUrl+'/GetPromotionTaskProducts', {taskCode:d.taskCode,channelType:1}).then((res) => {
          if(['SPECIAL_DISCOUNT','SEC_KILL','BUNDLE_DEAL'].indexOf(this.editParams.promotionType)>-1){
            this.TotalPrice=0;
            res.data.map((item)=>{
              item.limitQty=item.rule.limitQty;
              item.qty=item.rule.qty;
              this.TotalPrice+=item.rule.qty*item.clientPrice;
              this.bundleLimity=item.rule.limitQty||-1;
              return item;
            })
          }
          this.detailData.DataList = res.data;
          let data=[],list=res.data&&res.data[0].rule;
          if(list){
            data= Object.keys(list).map((itemSerialCode) => {
              return {
                itemSerialCode,
                ...list[itemSerialCode]
              };
            });
          }
          this.giftData.DataList = data;

        });
      }
      this.showDetailPage=true;
    },
    delItem(data) {
      this.showConfirm('是否作废此记录！',()=>{
        this.$http.post(this.baseApiUrl + "/UpdateStatePromotionTask",  {taskCode: data.taskCode, groupState: 5, auditeRemark: "", product: ""}).then((res) => {
          this.showTipMessage("作废已提交，稍后刷新列表！","success");
          this.search();
        });
      })
    }
  }
}
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <div>
        <el-button type="success" v-on:click="showForm('add',1)" :disabled="canEdit">新增大类</el-button>
        <el-button type="danger" v-on:click="delItem"  >删除</el-button>
      </div>
    </div>
    <div class="main-box treeC">
      <div class="treeBoxFull">
        <Vtree :treeData="treeData" @nodeclick="showForm" :emitSearch="true" title="商品分类节点" ref="trees"></Vtree>
      </div>
      <div class="formBOXFull" v-if="showTreeForm">
        <div class="formBOXHeader">{{title}}</div>
        <div class="formBOX">
          <Vform :formItemList="formItemList"  @search="search" :setFormInfo="{inline:false,labelWidth:'100px',formRules:rules}">
            <template v-slot:formButton="formBtn">
              <el-form-item>
                <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit">保存</el-button>
                <el-button type="success"  @click="showForm('add',2)" v-if="showCreate">创建小类</el-button>
              </el-form-item>
            </template>
          </Vform>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/product',
      searchData: {},
      showDetailPage:false,
      treeData:[],
      formItemList:[
        {label: "父节点",type: "input", value: "parentName",defaultValue:'',isDisabled:true},
        {label: "编码",type: "input", value: "code",defaultValue:'',placeholder:'系统自编码',isDisabled:true},
        {label: "名称",type: "input", value: "name",defaultValue:''},
        {label: "排序",type: "input", value: "sort",defaultValue:''},
      ],
      showTreeForm:false,
      showCreate:false,
      title:'',
      postData:{},
      modeType:'',
      canEdit:false,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        sort: [
          { required: true, message: "请输入排序", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: '排序是正整数', trigger: 'blur' },
        ]
      }
    }
  },
  inject: ['$hasPermission','$handleTabRemove'],
  mounted(){
    this.getListData();
  },
  methods: {
    async onSubmit(validate) {
      const formData = await validate();
      let form=cloneObject(formData);
      delete form.parentName;
      let url=this.modeType=='edit'?'/ProTypeEdit':'/ProTypeAdd';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,{...form,...this.postData}).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.getListData();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});


    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/ProductTypeView',{}).then((res)=>{
        res.data.map((item)=>{item.value=item.id;item.label=item.name;})
        this.treeData=this.buildTree(res.data,'code','parentCode','children');
      });
    },
    buildTree(source, id, parentId, children){
      let cloneData = cloneObject(source);
      return cloneData.filter(father => {
        let branchArr = cloneData.filter(child => {
          if(father[id] == child[parentId]){
            child.parentName = father['name']
            return true
          }
        });
        branchArr.length > 0 ? father[children] = branchArr : null
        return father[parentId] == 0
      })
    },
    showForm(val,type){
      this.showTreeForm=true;
      this.formItemList[0].defaultValue=val.parentName||'--';
      this.formItemList[1].defaultValue=val.code||'';
      this.formItemList[2].defaultValue=val.name||'';
      this.formItemList[3].defaultValue=val.sort||1;
      if(typeof val=='string'){
        this.modeType='add';
        this.title='新增商品类别';
        this.showCreate=false;
        if(type==1){
          this.curItem={};
          this.$refs.trees.canclSelect();
          this.postData={id:0,parentCode:0,level:1}
        }else{
          this.formItemList[0].defaultValue=this.curItem.name;
          this.postData={id:0,parentCode:this.curItem.code,level:this.curItem.level+1}
        }
      }else{
        this.modeType='edit';
        this.title='编辑商品类别';
        this.postData={id:val.id,parentCode:val.parentCode,level:val.level}
        this.showCreate=val.level==1;
        this.curItem=val;
      }
      this.showTreeForm=true;
    },
    delItem(){
      if(!this.curItem.id){
        this.showTipMessage('请选择要删除的记录!','error');
        return;
      }
      this.showConfirm('是否确认删除所选记录？',()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + "/ProTypeDelete", {id:this.curItem.id}).then(()=>{
          this.showTipMessage("删除成功！","success");
          this.getListData();
          this.canEdit=false;
          this.showTreeForm=false;
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
      })
    }
  }
}
</script>

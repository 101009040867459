<template>
  <div>
      <h3>商品信息：</h3>
      <el-descriptions size="small" :border="true"  :column="3" class="uniform-descriptions">
        <el-descriptions-item label="运营商名称"  :span="2" label-class-name="labelName">
          <el-tag size="small">{{order.operatorName}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="是否有票">{{order.hasInvoice?'是':'否'}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="商品名称" :span="2">{{order.productName}}&nbsp;
          <el-button type="warning" link size="small" v-if="order.isNewPro"><el-icon><InfoFilled /></el-icon>新建</el-button>
        </el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="类型">{{order.typeName}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="品牌">{{order.brandName}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="条码">{{order.barcode}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="产地">{{order.origin}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="规格">{{order.spec}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="保质期（天）">{{order.shelfLife}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="计价单位">{{order.unit}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="包装单位">{{order.packUnit}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="存储库型">{{order.storageType}}</el-descriptions-item>
        <el-descriptions-item label-class-name="labelName" label="使用辅助单位">{{order.useAssistantUnit?'是':'否'}}</el-descriptions-item>
      </el-descriptions>
      <h3>相关附件：</h3>
      <mainTable :mainOptions="mainData" ref="mainTable"></mainTable>
      <div style="text-align:center;padding:30px;">
        <el-button type="primary" @click="NextItem" :disabled="canEdit">下一个</el-button>
        <el-button type="success" @click="saveItem(true)" :disabled="canEdit">同意</el-button>
        <el-button type="danger"  @click="disagreeDialog=true;form={}" :disabled="canEdit">拒绝</el-button>
      </div>
  </div>
  <reject-Popup v-if="disagreeDialog" :rejectInfo="rejectInfo"  :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></reject-Popup>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    }
  },
  emits:['closeDialog'],
  data() {
  return {
    baseApiUrl: '/product',
    disagreeDialog: false,
    canEdit: false,
    rejectInfo:{title:'未通过的原因',value:'auditRemark'},
    order: {},
    id:'',
    mainData: {
      DataList:[],
      tableHeight:'200',
      Total:0,
      labelList: [
        { label: "文件", width: 70,callBack:(item)=>{return item.url}, isHtml:true},
        { label: "文件名", width: 190, prop: "imgName" },
        { label: "大小(kb)", minWidth: 150,callBack:(item)=>{return (item.size/1024).toFixed(2)}},
      ]
    }
  }
},
  created(){
    this.id=this.params.id;
    this.loadOrder();
  },
  watch:{
    params(v){
      let isChange = this.order.id != v.id;
      if (isChange)  this.id=this.params.id; setTimeout(() => { this.loadOrder(); }, 300);
    }
  },
  methods: {
    NextItem(){
      this.loadOrder('NextItem')
    },
    loadOrder(whoCall) {
      let postData=whoCall == 'NextItem'? {previd: this.id}: {id: this.id};
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/GetProductInfo" ,postData).then((res) => {
        this.canEdit=false;
          if(!res.data){
            this.showTipMessage('暂无记录','warning');
            return;
          }
        this.id = res.data.id
        this.order=res.data;
        this.order.isNewPro=res.ext.isNewPro;
        this.mainData.DataList=res.data?.attachment?JSON.parse(res.data.attachment):[];
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    submitForm(value) {
      this.saveItem(false,value);
    },
    saveItem(isAgree,value) {
      let postData
      if (isAgree) {
        postData = { id:this.id};
      } else {
        postData = {id: this.id, ...value}
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/ProductFilingApplyUpState" ,postData).then(() => {
         this.showTipMessage('审核成功!','success');
          this.disagreeDialog=false;
          this.canEdit=false;
          this.$emit('closeDialog');
      },(res)=>{
        this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');
      });
    }
  }
}
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="warning" @click="exportData" size="small">导出</el-button>
      <baseSearch :formItemList="searchItemList" @search="search"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
      </mainTable>
    </div>
  </div>
</template>

<script>

export default {
    data() {
        return {
            baseApiUrl: '/stock',
            searchData: {},
            selectOptions: [],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    {label: "驿站", prop: "warehouseName", width:"130"},
                    {label: "商品编码", prop: "productCode", width:"120"},
                    {label: "商品名称", prop: "productName", width:"250" },
                    {label: "货主", prop: "operatorName", width:"200" },
                    {label: "包装数量", prop: "packQty", width:"80"},
                    {label: "实际库存", prop: "qty", width:"80"},
                    {label: "单位",prop: "unit",width:"50"},
                    {label: "箱数",prop: "boxNum",width:"80"},
                    {label: "包装单位",prop: "packUnit",width:"80"},
                    {label: "快照日期",minWidth:120,callBack:(item)=>{return this.$formatDate(item.shotDate)}},
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    mounted() {
        this.getOperatorInfo()
    },
    computed: {
        searchItemList() {
            return [
                { label: "日期:", type: "daterange", value: "createtime", defaultValue: [this.$formatDate(0, -3), this.$formatDate(0)] },
                { label: "驿站:", type: "select", width:220,value: "wareCode", selectOptions: this.selectOptions, multiple: true ,keyValue:['Code','Name']},
                { type: "input", value: "kw", placeholder: '请输入商品名称/单号' }
            ]
        }
    },
    methods: {
        getOperatorInfo(){
          this.$store.dispatch('cache/loadCacheData', {...this.CacheKeys.StageInfo,query:{...this.CacheKeys.StageInfo.query,state: -1}}).then((res) => {
            this.selectOptions = res;

          });
        },
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            if(postData&&!this.searchData.wareCode){
              this.showTipMessage('请先选中驿站再点击查询!','warning');
              return;
            }
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/GetStageStockShot', { ...this.searchData, ...this.pageinfo }).then((res) => {
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData}, this.baseApiUrl+'/GetStageStockShot');
        },
    }
}
</script>

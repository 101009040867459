<template>
 <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="danger" plain size="small" @click="dismount(actionBtn.data)" >下架</el-button>
          <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data)">设置商城广告位</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="请选择广告位类型" width="480px" >
    <el-form ref="editForm" :model="form" size="small" label-width="60px" :rules="rules" style="margin:auto;">
      <el-form-item label="类型" prop="marketAdsense">
        <el-select v-model="form.marketAdsense" style="width:100%">
          <el-option v-for='(item,index) in ["常规","推荐","促销","上新"]' :key="index" :label="item" :value="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="sortNum">
        <el-input v-model="form.sortNum"  type="Number" />
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="saveItem()" :disabled="canEdit">确定</el-button>
          <el-button v-on:click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
        </span>
    </template>
  </el-dialog>
  <reject-Popup v-if="disagreeDialog" :rejectInfo="rejectInfo"  :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></reject-Popup>
</template>
<script>
import {getMappingName} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/mall',
      ARR_sortTypeData: [
          { Id: "asc", Name: "升序排序" },
          { Id: "desc", Name: "降序排序" }
        ],
      ARR_typeCodesData:[
        { Id: "0", Name: "常规" },
        { Id: "1", Name: "推荐" },
        { Id: "2", Name: "促销" },
        { Id: "3", Name: "上新" }
      ],
      disagreeDialog:false,
      AreaInfo:[],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"190", fixed:'left'},
          { label: "条目编码", width: 100, prop: "itemSerialCode" },
          { label: "商品商城名称", width: 200, prop: "productNameMarket" },
          { label: "价格", width: 100, prop: "marketPrice" },
          { label: "商城", width: 150, prop: "areaName" },
          { label: "运营商名称", width: 150, prop: "operatorName" },
          { label: "商城广告位", width: 100,callBack:(item)=>{return getMappingName(item.marketAdsense,this.ARR_typeCodesData)}},
          { label: "排序", width: 90, prop: "sortNum" },
          { label: "备注", minWidth: 200, prop: "remark" },
        ]
      },
      showDetailPage:false,
      form:{},
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{},
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "商城", type: "select", value: "areaCodes", selectOptions:this.AreaInfo, multiple:true,keyValue:['Code','Name']},
        {label: "类型", type: "select", value: "typeCodes", selectOptions:this.ARR_typeCodesData, multiple:true,width:160},
        {label: "排序", type: "select", value: "sortType", selectOptions:this.ARR_sortTypeData, defaultValue:'asc', multiple:false,width:100},
        {type: "input", value: "kw",placeholder:'查询商品名'}
      ];
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax() {
      this.$store.dispatch('cache/loadCacheData', {key:this.CacheKeys.AreaInfo.key, url:this.CacheKeys.AreaInfo.url, query:this.CacheKeys.AreaInfo.query}).then((res) => {this.AreaInfo=res;});
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetProForMarket', {...this.searchData,...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    showDetail(d){
      this.showDetailPage=true;
      this.form={id:d.id,key: 'MarketList',sortNum:d.sortNum,marketAdsense:d.marketAdsense};
    },
    saveItem() {
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/UpdateMarketAdsense" ,this.form).then(()=>{
        this.showTipMessage('设置成功!','success');
        this.canEdit=false;
        this.showDetailPage = false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    dismount(data) {
      this.showConfirm('是否确认把该商品下架？',()=>{
        this.$http.post(this.baseApiUrl + "/TakeProOffShelves", {id:data.id}).then(()=>{
          this.showTipMessage("下架成功！","success");
          this.search();
        });
      })
    }
  }
}
</script>

<template>
 <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data)">推荐店铺</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" :title="'确认店铺推荐---'+title" width="680px"  @close="form={sortNum:0,marketAdsense:0}">
    <el-form ref="editForm" :model="form" size="small" label-width="60px" :rules="rules" style="margin:auto;">
      <el-form-item label="类型" prop="marketAdsense">
        <el-select v-model="form.marketAdsense" style="width:100%">
          <el-option v-for='(item,index) in ["常规","推荐"]' :key="index" :label="item" :value="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="sortNum">
        <el-input v-model="form.sortNum"  type="Number" />
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="saveItem()" :disabled="canEdit">确定</el-button>
          <el-button v-on:click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
        </span>
    </template>
  </el-dialog>
  <reject-Popup v-if="disagreeDialog" :rejectInfo="rejectInfo"  :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></reject-Popup>
</template>
<script>
import {getMappingName} from '@/utils/common'
export default {
  data() {
    const ARR_sortTypeData = [
      { Id: "asc", Name: "升序排序" },
      { Id: "desc", Name: "降序排序" }
    ];
    const ARR_typeCodesData = [
      { Id: "0", Name: "常规" },
      { Id: "1", Name: "推荐" },
    ];

    return {
      baseApiUrl: '/mall',
      searchItemList:[
        {label: "类型", type: "select", value: "type", selectOptions:ARR_typeCodesData, multiple:true,width:160},
        {label: "排序", type: "select", value: "sortType", selectOptions:ARR_sortTypeData, defaultValue:'asc', multiple:false,width:100},
        {type: "input", value: "kw",placeholder:'查询运营商编码/店铺名'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"100", fixed:'left'},
          { label: "运营商编码", width: 100, prop: "operatorCode" },
          { label: "运营商", width: 180, prop: "operatorName" },
          { label: "店铺名", width: 180, prop: "shopName" },
          { label: "商城广告位", width: 90,callBack:(item)=>{return getMappingName(item.marketAdsense,ARR_typeCodesData)}},
          { label: "排序", minWidth: 60, prop: "sortNum" },
        ]
      },
      showDetailPage:false,
      title:'',
      form:{sortNum:0,marketAdsense:0},
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{},
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/ShopRecommendList', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    showDetail(d){
      this.title=d.shopName;
      this.showDetailPage=true;
      this.form={id:d.id,key: 'MarketShop',marketAdsense:d.marketAdsense||0,sortNum:d.sortNum||0};
    },
    saveItem() {
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/SetShopRecommend" ,this.form).then(() => {
        this.showTipMessage('推荐成功!','success');
        this.canEdit=false;
        this.showDetailPage = false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

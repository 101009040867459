<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data)" >审核</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="商城授信信息" width="50%" >
    <el-form ref="editForm" :model="form" size="small" label-width="120px" :rules="rules" style="margin:auto;">
      <el-form-item label="授信方" prop="operatorName">
        <el-input v-model="form.operatorName" :disabled="true"/>
      </el-form-item>
      <el-form-item label="被授信方" prop="granteeName">
        <el-input v-model="form.granteeName"  :disabled="true"/>
      </el-form-item>
      <el-form-item label="授信额度" prop="creditAmount">
        <el-input v-model="form.creditAmount" :disabled="true" />
      </el-form-item>
      <el-form-item label="账单周期" prop="billPeriod">
        <el-input v-model="form.billPeriod" :disabled="true"/>
      </el-form-item>
      <el-form-item label="账单付款日" prop="billDeadline">
        <el-input v-model="form.billDeadline" :disabled="true" />
      </el-form-item>
      <el-form-item label="审核备注" prop="remark">
        <el-input v-model="form.remark"  type="textarea" placeholder="拒绝请填写审核备注" />
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="submitForm(true)" :disabled="canEdit">同意</el-button>
          <el-button v-on:click="submitForm(false)" :disabled="canEdit">拒绝</el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      baseApiUrl: '/mall',
      OperatorInfo:[],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "授信方", width: 280, prop: "operatorName" },
          { label: "被授信方", width: 280, prop: "granteeName" },
          { label: "授信额度", width: 100, prop: "creditAmount" },
          { label: "账单周期", width: 100,callBack:(item)=>{return  item.billPeriod&&item.billPeriod.join(',') }},
          { label: "账单付款日", minWidth: 100, prop: "billDeadline" },
        ]
      },
      showDetailPage:false,
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      form:{},
      rules: {
        remark: [{ required: true, message: "拒绝原因不能为空", trigger: "blur" }]
      },
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "授信方", type: "select", value: "operatorCode", selectOptions:this.OperatorInfo, multiple:false, keyValue:['Code','Name']},
        {type: "input", value: "kw",placeholder:'被授信方编码/名称'}
      ];
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {this.OperatorInfo=res;});
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/OperatorCreditEditList', {...this.searchData,...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    showDetail(d){
      this.form=d;
      this.showDetailPage=true;
    },
    submitForm(type) {
      if(type){this.saveItem(type);return;}
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.saveItem(false);
        } else {
          return false
        }
      });
    },
    saveItem(isAgree) {
      let postData = {
        id:this.form.id,
        isAgree:isAgree,
        remark: this.form.remark
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/AuditOperatorCreditEditList" ,postData).then(() => {
        this.showTipMessage('审核成功!','success');
        this.canEdit=false;
        this.showDetailPage = false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

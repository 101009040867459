<template>
  <div class="content_box">
    <div class="search_bar">
      <div>
        <el-button type="success"  size="small" @click="showDetailPage=true;fileList=[];ImgOption.list=[]">上传图片</el-button>
        <el-button type="danger"  size="small" @click="DelAdvertisementImgById('',0)">批量删除</el-button>
      </div>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="danger"  plain size="small"  @click="DelAdvertisementImgById(actionBtn.data,'1')">删除</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showDetailPage" title="上传图片" width="700" >
      <uploader-list ref="uploaderlist" :ImgOption="ImgOption" @imglistchange="imglistchange"></uploader-list>
      <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" v-on:click="onSubmit()" :disabled="canEdit">确定保存</el-button>
              <el-button v-on:click="showDetailPage = false">关闭</el-button>
            </span>
      </template>
    </el-dialog>
    </div>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default {
  data() {
    const dateBegin = this.$formatDate(0, -30);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: '/advertisement',
      searchData: {},
      mainData: {
        DataList: [],
        isMultiple:true,
        isSerial:false,
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "文件名", width: 260, prop: "Name" },
          { label: "文件大小(kb)", width: 100,callBack:(item)=>{return (item.Size/1024).toFixed(2)}},
          { label: "上传时间", width: 160, prop: "UpdatedTime" },
          { label: "上传人", width: 80, prop: "UpdatedBy" },
          { label: "文件预览", minWidth: 70,callBack:(item)=>{return item.Url}, isHtml:true},
        ]
      },
      FormItems: [
        {label:"名称",type: "input", value: "Name", span:1},
        {label:"手机号码",type: "input", value: "Phone", span:1},
        {label:"是否平台员工",type: "radio", value: "IsInternalStaff",defaultValue:1,selectOptions:[{Id:1,Name:'是'},{Id:0,Name:'否'}],span:1},
        {label:"驿站",type: "select", value: "StageCode",defaultValue:'',selectOptions:'' ,span:1,visible: false,placeholder: '请选择驿站',keyValue:['Code','Name']}
      ],
      showDetailPage: false,
      ImgOption:{imgData:{},leng:100,isSetting:true,multiple:true,acceptList:'.jpg,.png,.pdf',delUrl: '/Ajax/DelUpYunFile'},
      fileList:[],
      searchItemList: [
        {label: "上传日期", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
        {type: "input", value: "keyword", placeholder: '查询关键字'}
      ],
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      }
    }
  },
  inject: ['$hasPermission'],
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      // 图片上传凭证
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ImgProof).then((d) => {
        this.ImgOption.imgData.policy = d.policy;
        this.ImgOption.imgData.authorization = d.auth;
        this.ImgOption.imageUpUrl = d.host;
        this.ImgOption.imageUrl = d.domain;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/GalleryListPage', {...this.searchData, ...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      let Data=postData?cloneObject(postData):'';
      if (Data) {
        Data.startTime = postData.dateFrom;
        Data.endTime = postData.dateTo;
        delete Data.dateFrom;
        delete Data.dateTo;
      }
      this.searchData=Data||this.searchData;
      this.getListData();
    },
    imglistchange(val) {
      if(val){
        this.fileList=JSON.parse(val);
      }
    },
    //删除
    DelAdvertisementImgById(item,type){
      var title=type==1?"确认删除该张" + "【" + item.Name + "】的图片吗？":"是否确认批量删除这些图片？";
      var idlist=[];
      if(type==0){
        let data =this.$refs.mainTable.select();
        if(!data) return;
        data.forEach((child)=>{idlist.push(child.Id);})
      }else{
        idlist.push(item.Id);
      }
      this.showConfirm(title,()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + '/BatchDeleteImg',{ids:idlist.join(',')}).then(()=>{
          this.showTipMessage('删除成功','success');
          this.canEdit=false;
          this.search();
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
      })
    },
    //上传图片
   onSubmit() {
     var arr = [], flag = false;
     if (!this.fileList || this.fileList.length == 0) {
       this.showTipMessage("请上传图片",'warning');
       return;
     }
     this.fileList.map((item) => {
       if (item.status == 'success') {
         var obj = {}
         obj.name = item.name;
         obj.url = (item.response.url.indexOf("http://") != -1 || item.response.url.indexOf("https://") != -1) ? item.response.url : this.ImgOption.imageUrl + item.response.url;
         obj.size = item.size;
         obj.type = item.name.substring(item.name.lastIndexOf('.')+1,item.name.length);
         arr.push(obj)
       }else {return flag = true;}
     })
     if (flag) {
       this.showTipMessage("请点击【确认上传】，完成上传后再点击完成",'warning');
       return;
     }
      this.$http.post(this.baseApiUrl + '/AddAdvertisementImg' ,{jsonstr:JSON.stringify(arr)}).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.getListData();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
  }
}
</script>

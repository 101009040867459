<template>
  <div class="content_box">
    <div class="search_bar">
      <div>
        <el-button type="success"  size="small" @click="getListData(false);checkList=[]">最近上传</el-button>
        <!-- <el-button type="primary"  size="small" @click="addList('add')">加入轮播图列表</el-button> -->
      </div>
      <baseSearch :formItemList="searchItemList" @search="search"></baseSearch>
    </div>
    <div class="main-box" style="display:block!important">
        <el-card style="height:202px">
          <el-checkbox-group v-model="checkList" class="checkbox-container">
            <div class="item" v-for="(item, index) in DataList" :key="index">
              <img :src="item.Url" style='width:40%;height:50px;object-fit: contain'/>
              <div><el-checkbox :label="item.Id">{{item.Name}}</el-checkbox></div>
            </div>
          </el-checkbox-group>
        </el-card>
        <div class="page-box" style="display: flex;justify-content: space-between;">
          <el-pagination :current-page="pageinfo.pg_idx" :page-size="pageinfo.pg_size" small background :total="Total" @current-change="getPageCurrent"/>
          <el-button type="primary"  size="small" @click="addList('add')">加入轮播图列表</el-button>
        </div>
       <h3 style="margin-top: 20px;">【{{curStage.StageName}}】轮播图<span class="textdanger" style="font-size:14px;">（支持3-5张）</span></h3>
        <mainTable :mainOptions="mainData" ref="mainTable">
          <template v-slot:operation="actionBtn">
            <el-button type="primary"  size="small" @click="submitItem(actionBtn.data,'jump')">设置跳转</el-button>
            <el-button type="danger"  size="small" @click="mainData.DataList.splice(actionBtn.index,1);">移除</el-button>
            <el-button :type="actionBtn.index==0?'info':'success'"  size="small" :disabled="actionBtn.index==0" @click="orderClick(actionBtn.index, actionBtn.data, true)">上移</el-button>
            <el-button :type="actionBtn.index==mainData.DataList.length-1?'info':'warning'"  :disabled="actionBtn.index==mainData.DataList.length-1" size="small" @click="orderClick(actionBtn.index, actionBtn.data, false)" >下移</el-button>
          </template>
        </mainTable>
      <div style="margin-top: 15px;text-align: right">
        <el-button type="primary"  size="small" @click="submitForm()">确定保存</el-button>
        <el-button type="danger"  size="small" @click="closePage()">关闭</el-button>
      </div>
    </div>
    <el-dialog v-model="showDetailPage" :title="title" width="780">
      <el-form ref="editForm" :model="form" size="small" label-width="80px">
        <el-form-item label="跳转类型">
          <el-select v-model="form.jumpType" style="width:100%" @change="changeJumpType">
            <el-option v-for="item in ARR_jumpType" :value="item.Id" :label="item.Name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类品类" v-if="form.jumpType=='分类品类'">
          <el-select v-model="form.categoryName" style="width:100%">
            <el-option v-for="item in ARR_categoryInfo" :value="item.Name" :label="item.Name"></el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex;justify-content: space-between" v-if="form.jumpType=='商品详情'">
          <el-form-item label="商品" style="width:60%">
            <el-input type="input"  v-model="form.productName" disabled="true"/>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="form.kw"  placeholder="查询商品">
              <template #append>
                <el-button icon="Search" type="primary" @click="getPromotionInfo()"/>
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <mainTable :mainOptions="detailData" :mainPage="detailPageInfo" @getPageCurrent="getPageCurrentDetail" ref="mainTable" v-if="form.jumpType=='商品详情'">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.itemSerialCode==curProduct.itemSerialCode?'info':'success'" size="small"  @click="submitItem(actionBtn.data,'product')">{{actionBtn.data.itemSerialCode==curProduct.itemSerialCode?'已选':'选中'}}</el-button>
        </template>
      </mainTable>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" v-on:click="addList('edit')" :disabled="canEdit">确定保存</el-button>
            <el-button v-on:click="showDetailPage = false">关闭</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {cloneObject,compareObjects} from '@/utils/common'
export default {
  data() {
    const dateBegin = this.$formatDate(0, -10);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: '/advertisement',
      checkList: [],
      searchData: {},
      searchItemList: [
        {label: "上传日期", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
        {type: "input", value: "keyword", placeholder: '查询关键字'}
      ],
      DataList: [],
      Total: -1,
      curStage:{},
      mainData: {
        DataList:[],
        tableHeight:260,
        isSerial:false,
        Total:0,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"280", fixed:'right'},
          { label: "轮播图", width: 70,callBack:(item)=>{return item.url}, isHtml:true},
          { label: "文件名", minWidth: 190, prop: "name" },
          { label: "跳转类型", minWidth: 100, prop: "jump_type" },
          { label: "跳转", minWidth: 190, prop: "jump_name" },
        ]
      },
      detailData: {
        DataList:[],
        tableHeight:260,
        isSerial:false,
        Total:0,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'right'},
          { label: "商品名称", minWidth: 190, prop: "productNamePromotion" },
          { label: "货主", width: 260, prop: "operatorName" },
        ]
      },
      ARR_jumpType: [
        { Id: "分类品类", Name: "分类品类" },
        { Id: "商品详情", Name: "商品详情" },
        { Id: "限时抢购", Name: "限时抢购" },
        { Id: "风暴特价", Name: "风暴特价" },
        { Id: "新品", Name: "新品" },
      ],
      ARR_categoryInfo:[],
      showDetailPage: false,
      form:{},
      title:'',
      curImgInfo:{},
      curProduct:{},
      canEdit:false,
      detailPageInfo:{
        pg_idx: 1,
        pg_size: 12
      },
      pageinfo: {
        pg_idx: 1,
        pg_size: 12
      }
    }
  },
  inject: ['$hasPermission'],
  mounted() {
    this.GetStageCarouselList();
  },
  methods: {
    getListData(type) {
      let postData=type==false? {...this.pageinfo}: {...this.searchData, ...this.pageinfo};
      this.$http.post(this.baseApiUrl + '/GalleryListPage',postData).then((res)=>{
        this.DataList = res.data.rows;
        this.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      let Data=postData?cloneObject(postData):'';
      if (Data) {
        Data.startTime = postData.dateFrom;
        Data.endTime = postData.dateTo;
        delete Data.dateFrom;
        delete Data.dateTo;
      }
      this.searchData=Data||this.searchData;
      this.checkList=[]
      this.getListData();
    },
    GetStageCarouselList(){
      this.$http.post('/carousel/GetStageCarouselList', {stageCode:this.$route.query.stageCode}).then((res)=>{
        this.curStage=JSON.parse(JSON.stringify(res.data[0]));
        this.mainData.DataList = res.data[0].JsonData||[];
      });
    },
    // 保存设置图片
    submitForm(){
      let res=compareObjects(this.mainData.DataList,this.curStage.JsonData);
      if(res.length==0){
        this.showTipMessage('无修改内容','warning');
        return;
      }
      let postData= {
        stageCode: this.curStage.StageCode,
        jsonData:JSON.stringify(this.mainData.DataList)
      }
      this.canEdit=true;
      this.$http.post('/carousel/SaveStageCarouselImgList',postData).then(()=>{
        this.showTipMessage('保存成功','success')
        this.canEdit=false;
        setTimeout(()=>{
          this.closePage();
        },3000)
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    closePage(){
      this.$store.dispatch('tags/closeCurrentTag',{$router: this.$router, $route: this.$route});
      this.$router.push({path:'/minapp-BannerManage'})
    },
    //加入轮播图&设置跳转保存
    addList(type){
      if(type=='add'){
        if(this.DataList.length==0){
          this.showTipMessage('请先查询图片列表并选择图片','error');
          return;
        }else if(this.checkList.length==0){
          this.showTipMessage('请先选择图片','error');
          return;
        }
        let selectData = this.DataList.filter(item => this.checkList.includes(item.Id));
        let resultData = selectData.filter(a => !this.mainData.DataList.some(b => a.Url === b.url));
        if(resultData.length==0){
          this.showTipMessage('所选图片已全部加入轮播图列表','error');
          return;
        }
        if(this.checkList.length!=resultData.length){
           this.showTipMessage('所选图片部分已加入轮播图列表，故不再重复加入','warning');
        }
        if(this.mainData.DataList.length+resultData.length>5){
          this.showTipMessage('已超轮播图上限，最多设置5张轮播图','error');
          return;
        }
        resultData.map((item)=>{
          this.mainData.DataList.push({url:item.Url,name:item.Name,jump_type:'',jump_name:'',jump_address:''})
        })
        this.checkList=[];
      }else{
        let jumpName='',jumpAddress='';
        switch (this.form.jumpType) {
          case '商品详情':
            jumpName=this.form.productName;
            jumpAddress=this.curProduct.itemSerialCode;
            break;
          case '分类品类':
            jumpName=this.form.categoryName;
            break;
        }
        this.mainData.DataList.map((item)=>{
          if(item.url==this.curImgInfo.url){
            item.jump_type=this.form.jumpType;
            item.jump_name=jumpName;
            item.jump_address=jumpAddress
          }
          return item;
        })
        this.showDetailPage=false;
      }
    },
    //上移&下移
    orderClick(index,data,direction){
      let pre = direction ? index - 1 : index + 1
      if(pre < 0 || pre >= this.mainData.DataList.length){
        var type=direction ? 'up' : 'dwon';
        if(type=='up'){
          this.showTipMessage("已经是第一个了，不能上移","warning");
        }else if(type=='dwon'){
          this.showTipMessage("已经是最后一个了，不能上移","warning");
        }
        return
      }
      this.mainData.DataList.splice(index,1)
      this.mainData.DataList.splice(pre,0,data)
    },
    // 设置调转&选中
    submitItem(d,type){
      if(type=='jump'){
        this.title = "设置轮播图跳转--"+d.name;
        this.showDetailPage = true;
        this.curImgInfo=d;
        this.form={jumpType:d.jump_type, productName:d.jump_name, categoryName:d.jump_name};
        if(d.jump_type=='商品详情'){
          this.curProduct={productNamePromotion:d.jump_name,itemSerialCode:d.jump_address}
        }
        this.changeJumpType();
      }else{
        this.curProduct=d;
        this.form.productName=d.productNamePromotion;
      }
    },
    changeJumpType(){
      if(this.form.jumpType=='分类品类'){
        this.$http.post('/Ajax/DCLoad', {t: 'CategoryList',code:this.curStage.BindingAreaCode}).then((res)=>{
          this.ARR_categoryInfo=res.data;
        });
      }
    },
    getPageCurrentDetail(val){
      this.detailPageInfo.pg_idx = val;
      this.getPromotionInfo();
    },
    getPromotionInfo(){
      this.$http.post('/carousel/GetPromotionInfo',{kw:this.form.kw||'', stageCode: this.curStage.StageCode, ...this.detailPageInfo}).then((res)=>{
        this.detailData.DataList=res.data.rows;
        this.detailData.Total = res.data.total || -1;
      });
    }
  }
}
</script>
<style>
.checkbox-container {display: block;text-align: center}
.checkbox-container .item {
  width: calc(100%/6 - 20px);
  float: left;
  overflow: hidden;
  height:90px;
  margin:0 10px!important;
  box-sizing: border-box;
}
</style>

<!--可配置项：addressOptions
	citytype: 显示地区级别 1省2市3县4街
	ismultiple: 是否多选
	isShowAll:是否展示结果项所有层级
	checkStrictly:是否联动
	cascaderId:组件id值
	provincecode:省份code，未传入值，取全部地址数据。传入值，取传入省份下所有地区数据
	isdisabled:设置可否编辑


	cityvalueList:默认值字符串,用于回显，例如44,多个用逗号隔开
	引用示例：
	<city-address :cityvalueList="cityvalueList" :addressOptions="addressOptions" @change="cityChange"></city-address>

	取值结果：
	this.$refs.cascader.cityValue
-->
<template>
  <div style="width:100%;" :id="addressOptions.cascaderId">
        <el-cascader ref="cascader" v-model="cityValue"
            :teleported="false"
            :props="cityprops"
            :show-all-levels="addressOptions.isShowAll"
            :options="cityOptions"
            :placeholder="addressOptions.placeholder||'请选择地区'"
            :disabled="addressOptions.isdisabled"
            @change="onAddressChange"
            style="width:100%;background: none!important;border:none!important;" />
  </div>
</template>
<script>
import { loadScripts } from '@/utils/common'
export default{
  emits: ["cityClick"],
  props: {
    addressOptions:{
      type: Object,
      default(){
        return {isShowAll:false,checkStrictly: false, emitData: false,}
      },
    },
    // eslint-disable-next-line vue/require-prop-type-constructor
    cityvalueList:'',
  },
  data() {
    return {
      URL_CITYDATA:window.location.protocol +"//apps.ysc-dt.com/libs/citypicker/city-picker.data.min.js",
      cityprops: {
        multiple: true,
        emitPath:false,
        checkStrictly:false,
      },
      defaultProvince:'44', // 默认数据，默认广东
      isDisable:false,
      cityValue:[],
      cityObj:[],
      cityfatValue:[],
      cityOptions: [],
      codeleng:8,
    }
  },
  watch:{
    'addressOptions.citytype'(val){
      this.DateType(val);
      this.cityValue=[];
      this.buildAddressData();
    },
    cityvalueList: {
      handler (val) {
        this.setCityValue(val)
      },
      deep: true
    },
  },
  created(){
    this.DateType(this.addressOptions.citytype);
    this.cityprops.multiple=this.addressOptions.ismultiple??true;
    this.cityprops.checkStrictly=this.addressOptions.checkStrictly??false;
    const loading = this.AjaxLoading();
    loadScripts(this.URL_CITYDATA, () => {
      this.AjaxLoadEnd(loading);
      this.buildAddressData();
      let funllData=this.buildCityPicker('all')
      if(this.addressOptions.emitData){
        this.$emit("emitdata", funllData);
      }
      this.setCityValue(this.cityvalueList);
     });
  },
  methods: {
    DateType(v){
      switch (v){
        case 1:
          this.codeleng=2;
          break
        case 2:
          this.codeleng=4;
          break
        case 3:
          this.codeleng=6;
          break
        case 4:
          this.codeleng=8;
          break
      }
    },
    buildAddressData(){
      let list =this.buildCityPicker();
      let index = list.findIndex((res)=>res.value === '44')
      let gd = index > -1 ? list.splice(index,1) : []
      this.cityOptions = gd.concat(list);
    },
    // 格式化数据
    buildCityPicker(type) {
      var ChineseDistricts = window.ChineseDistricts || [];
      var list = [];
      var srcData = ChineseDistricts[86];
      for (let n in srcData) {
        srcData[n].forEach((v, i, a) => {
          if(this.addressOptions.provincecode){
            if(v.code==this.addressOptions.provincecode){
              var item = { value: v.code, label: v.address, children: [] };
              this.buildCityTree(item, ChineseDistricts[item.value],type);
              list.push(...item.children);
            }
          }else{
            var item = { value: v.code, label: v.address, children: []};
            list.push(item);
            this.buildCityTree(item, ChineseDistricts[item.value],type);
          }
        });
      }
      return list;
    },
    buildCityTree(parent, d, type) {
      if (d) {
        for (let n in d) {
          var item = { value: n, label: d[n], children: [] };
          if(n.toString().length < (type=='all'?8:this.codeleng)){
            this.buildCityTree(item, ChineseDistricts[item.value],type);
          }
          parent.children.push(item);
        }
      }
    },
    // change事件
    onAddressChange(res) {
       if(this.addressOptions.ismultiple){
         if(res.length>1&&(res[res.length-1].length>=this.codeleng&&res[res.length-2].length>=this.codeleng)){
           if(this.cityValue.indexOf(res[res.length-1])==-1){
             this.cityValue.push(res[res.length-1]);
           }
         }else{
           this.cityValue=res.length==0?[]:[res[res.length-1]];
         }
       }else{
         this.cityValue=res
       }
      this.$nextTick(()=>{
        let nodesObj = this.$refs['cascader'].getCheckedNodes();
        let cityObj=[];
        nodesObj.forEach((item)=>{
          if(item.pathLabels&&item.text){
            cityObj.push({id:item.value,name:item.text});
          }
        })
        this.cityObj = cityObj;
        this.$emit('cityChange',{cityValue:this.cityValue,cityObj:this.cityObj});
      })
    },
    getParentsName(list,index) {
      for (let i in list) {
        if (list[i].label == this.cityvalueList.split('/')[index]) {
          this.cityfatValue.push(list[i].value);
          if (list[i].children) {
            this.getParentsName(list[i].children,index+1)
          }
        }
      }
    },
    //回显
    setCityValue(list){
      if(list){
        if(typeof list =='string'&&list.indexOf("/")>-1){
          this.getParentsName(this.cityOptions,0);
          this.cityValue=this.cityfatValue.length==0?[this.defaultProvince]:this.cityfatValue[this.cityfatValue.length-1];
        }else{
          let curValue=this.addressOptions.ismultiple&&typeof list=='string'?list.split(','):list;
          this.cityValue=curValue;
        }
      }else{
        this.cityValue=this.addressOptions.ismultiple?[]:'';
      }

    }
  }
}
</script>
<style>
  .el-cascader-panel .el-checkbox{z-index: 10;
    width: 80%;
    height: 99%;
    position: absolute;
    top: 10px;
  }
  .el-cascader-panel .el-checkbox__input{
    visibility: hidden;
  }
  .el-cascader-panel .el-cascader-node__postfix{
    top: 10px;
  }
</style>

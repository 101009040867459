<template>
  <div class="dialog-search">
    <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true}" formType="search" @changeform="changeform" class="searchForm">
      <template v-slot:formButton="formBtn">
        <el-form-item>
          <el-button type="primary"  @click="onSubmit(formBtn.validateForm)">查询</el-button>
          <el-button type=""  @click="resetForm('ruleForm')" v-if="showRestbtn">重置</el-button>
        </el-form-item>
      </template>
    </Vform>
  </div>
</template>
<script>
export default {
  name: "BaseSearch",
  props: {
    emitSearch: {
      type: Boolean,
      default: false
    },
    showRestbtn: {
      type: Boolean,
      default: true
    },
    formItemList: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default:[],
    }
  },
  data(){
    return{
      postData:{},
    }
  },
  mounted(){
    if(this.emitSearch){
      let postData =this.$refs.vform.formatData();
      this.formatData(postData);
      this.$emit("search", this.postData);
    }
  },
  methods: {
    async onSubmit(validate) {
      const formData = await validate();
      this.formatData(formData);
      this.$emit("search", this.postData);
    },
    changeform(val){
      this.$emit('changeform',val)
    },
    formatData(list){
      for(let key in list){
        this.postData[key]=typeof list[key]=='object'?list[key].join(','):list[key];
      }
    },
    resetForm() {
      this.$refs.vform.resetForm()
    }
  }
};
</script>

<template>
  <div class="content_box">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/operator',
            searchData: {},
            selectOptions:[],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { prop: "code", label: '编码', width: 100 },
                    { prop: "name", label: '运营商名称', width: 250 },
                    { prop: "amount", label: '可用额度', width: 100 },
                    { prop: "billAmount", label: '冻结额度', width: 100 },
                    { label: '余额', width: 100,callBack: (item)=>{
                            return item.amount + item.billAmount;
                        }
                    },
                    { prop: "citicAccount", label: '中信账号', minWidth: 150 },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "运营商:", type: "select", value: "code", selectOptions: this.selectOptions, multiple: false ,clearable:true,keyValue:['Code','Name']},
            ]
        }
    },
    mounted() {
        this.getOperatorInfo()
    },
    methods: {
        getOperatorInfo(){
          this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
            this.selectOptions = res;
          });
        },
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/OperatorWalletList', { ...this.searchData, ...this.pageinfo,...{isExcel: false}}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData,...{isExcel:true}}, this.baseApiUrl+'/OperatorWalletList');
        },
    }
}
</script>

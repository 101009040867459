<template>
  <div style="padding-bottom:12px;width:100%" >
    <el-upload ref="uploader"
               class="upload-file"
               :name="filename"
               :data="postFileData"
               :headers="headers"
               v-model:file-list="files"
               :multiple="multiple"
               :action="url"
               :accept="accept||'.doc,.docx,.png,.xls,.xlsx,image/*'"
               :auto-upload="false"
               :list-type="hasTemplate?'text':'picture'"
               :on-change="changeFile"
               :on-remove="removeFile"
               :on-success="handleUploadOk"
               :on-error="handleUploadFail">
      <template #trigger>
        <el-button size="small" type="primary">选择文件</el-button>
      </template>
      <template #file="{file}" v-if="!hasTemplate">
        <div class="el-upload-list__item-inner uploadlistItem"  style="cursor: pointer" @click.stop="handlePreview(file)">
          <img class="el-upload-list__item-thumbnail" :src="file.url">
          <span class="el-upload-list__item-name" title="点击预览文件">
            <i class="el-icon-document"></i>{{ file.name }}
          </span>
          <el-icon v-if="file.status === 'success'"
                   class="el-upload-list__item-status-label"
                   color="green"><Check style=" transform: rotate(-45deg);color:#fff;margin-right:7px;margin-top:10px"/>
          </el-icon>
          <el-icon v-if="file.status !== 'uploading'"
                   class="el-upload-list__item-delete uploadlistItemDelete"
                   size="18" @click.stop="handleRemove(file)"><Close />
          </el-icon>
        </div>
      </template>
      <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload">提交上传</el-button>
      <el-button style="margin-left: 10px" size="small" type="danger" @click="reset" v-if="isResetting">重置</el-button>
      <el-button style="margin-left: 10px" size="small" type="warning" @click="downTemplate" v-if="hasTemplate">下载模板</el-button>
      <template #tip>
        <div class="el-upload__tip" style="margin-bottom:12px;">{{tips}}</div>
      </template>
    </el-upload>
    <el-image-viewer v-if="showPreview" @close="showPreview=false" :initial-index="0" :url-list="[imageUrl]" />
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: ""
    },
    tips: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: ""
    },
    postData: {
      type: Object
    },
    fileList: {
      type: Object
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isResetting: {
      type: Boolean,
      default: true
    },
    templateUrl: {
      type: String,
      default: ""
    },
    filename:{
      type:String,
      default: "file"
    }
  },
  data() {
    return {
      imageUrl:'',
      showPreview:false,
      value: this.defaultValue,
      hasTemplate: this.templateUrl != '',
      icon: null,
      postFileData:{},
      // headers: getAjaxHeaders()
    }
  },
  mounted(){
    this.postFileData={...this.postData};
    this.files=JSON.parse(JSON.stringify(this.fileList||[]))
  },
  methods: {
    // 预览
    handlePreview(file){
      this.showPreview=true;
      this.imageUrl=file.url
    },
    submitUpload() {
      this.$refs.uploader.submit()
    },
    downTemplate() {
      window.open(this.templateUrl);
    },
    removeFile(file, list) {
      this.$emit('imgchange',JSON.stringify(list))
    },
    changeFile(file, list) {
      if(!this.multiple){
        if (list.length > 1) {list.splice(0, 1);}
        this.postFileData.fileName=list&&list.length!=0&&list[0].name;
      }
      this.$emit('imgchange',JSON.stringify(list))
    },
    handleUploadOk(resp, file, list) {
      this.$emit("complete", { succ: true, resp, file, list });
      if(this.hasTemplate){
        this.reset();
      }
    },
    handleRemove(file) {
      const index = this.files.indexOf(file);
      if (index > -1) {
        this.files.splice(index, 1);
      }
      this.$emit('imgchange',JSON.stringify(this.files))
    },
    handleUploadFail(err, file, list) {
      this.$emit("complete", { succ: false, err, file, list });
    },
    reset(){
      this.$emit("resetfun");
      this.$refs.uploader.clearFiles();
    }
  }
}
</script>
<style>
.uploadlistItem{display: flex;width:100%}
.uploadlistItem:hover .el-upload-list__item-delete{display: block;}
.uploadlistItemDelete{top:5px!important;right:5px!important;color:#999}
</style>

<template>
 <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.applyState!=0?'info':'warning'" plain size="small" @click="showDetail(actionBtn.data)" :disabled="actionBtn.data.applyState!=0">审核</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="审核商品入驻申请" width="80%">
    <div style="margin:20px 0">
      运营商: <span class="textprimary textspan mr30">{{curItem.operatorName}}</span>
      商品: <span class="textprimary textspan mr30">{{curItem.productName}}</span>
      入驻: <span class="textprimary textspan">{{curItem.areaName}}</span>
    </div>
    <mainTable :mainOptions="imgData" ref="imgTable" ></mainTable>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="saveItem(true)" :disabled="canEdit">同意</el-button>
          <el-button v-on:click="disagreeDialog=true;form={}" :disabled="canEdit">拒绝</el-button>
        </span>
    </template>
  </el-dialog>
  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo"  :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></rejectPopup>
</template>
<script>
export default {
  data() {
    const ARR_RefundStatus = [
      { Id: "0", Name: "未审核" },
      { Id: "1", Name: "通过" },
      { Id: "2", Name: "拒绝" },
    ];
    const dateBegin = this.$formatDate(0, -3);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl:'/product',
      searchItemList:[
        {label: "申请日期", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
        {label: "状态", type: "select", value: "applyState", selectOptions:ARR_RefundStatus, multiple:true},
        {type: "input", value: "kw",placeholder:'查询关键字'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "运营商", width: 160, prop: "operatorName" },
          { label: "产品编码", width: 100, prop: "productCode" },
          { label: "产品商城名称", width: 250, prop: "productName" },
          { label: "状态", width: 70, prop: "applyStateName" },
          { label: "商城", width: 150, prop: "areaName" },
          {label: '入驻/退出', width: 90,callBack:(item)=>{return   item.isSettled == 1 ? "入驻" : "退出"}},
          { label: "申请人", width: 80, prop: "createdBy" },
          { label: "申请时间", width: 150, prop: "createdTime" },
          { label: "审核人", width: 80, prop: "auditedBy" },
          { label: "审核时间", width: 150, prop: "auditedTime" },
          { label: "备注", minWidth: 200, prop: "remark" }
        ]
      },
      rejectInfo:{title:'未通过的原因',value:'remark'},
      imgData: {
        DataList:[],
        tableHeight:'200',
        Total:0,
        labelList: [
          { label: "文件", width: 70,callBack:(item)=>{return item.url}, isHtml:true},
          { label: "文件名", width: 190, prop: "imgName" },
          { label: "大小(kb)", minWidth: 150,callBack:(item)=>{return (item.size/1024).toFixed(2)}},
        ]
      },
      showDetailPage:false,
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      disagreeDialog:false,
      canEdit:false,
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/ProOpeMarketApply', {...this.searchData,...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    showDetail(d){
      this.curItem=d;
      this.$http.post(this.baseApiUrl+'/GetProductAttachment', {operatorCode:d.operatorCode,productCode:d.productCode}).then((res)=>{
        this.imgData.DataList = res.data;
        this.showDetailPage = true;
      });
    },
    submitForm(value) {
      this.saveItem(false,value);
    },
    saveItem(isAgree,value) {
      let postData
      if (isAgree) {
        postData = { pKey: this.curItem.id,feedback:'y'};
      } else {
        postData = {
          pKey: this.curItem.id,
          feedback:'n',
          ...value
        }
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/AuditProOpeMarketApply" ,postData).then(()=>{
        this.showTipMessage('审核成功!','success');
        this.disagreeDialog=false;
        this.canEdit=false;
        this.showDetailPage = false;
        this.search();
      }).then((res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

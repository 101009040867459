<template>
  <div class="content_box">
      <div class="search_bar">
        <div>
          <el-button type="primary" v-on:click="exportData" size="small">导出</el-button>
          <el-button type="success"  size="small" @click="toPage('add')">添加</el-button>
        </div>
        <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
        <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
          <template v-slot:operation="actionBtn">
            <el-button type="primary"  plain size="small" @click="submitItem(actionBtn.data,'ck')" >查看</el-button>
            <el-button type="warning"  plain size="small"  @click="toPage('edit',actionBtn.data)">修改</el-button>
            <el-button type="primary"  plain size="small" @click="submitItem(actionBtn.data,'Password')">密码重置</el-button>
            <el-button type="warning"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.stateName=='启用'">禁用</el-button>
            <el-button type="success"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.stateName=='禁用'">启用</el-button>
            <el-button type="danger"  plain size="small" @click="submitItem(actionBtn.data,'del')" >删除</el-button>
          </template>
        </mainTable>
      </div>
      <el-dialog v-model="showDetailPage" :title="modeType=='edit'?'商品基础资料修改':'商品基础资料查看'" width="700" style="padding-bottom:20px;">
        <el-tabs v-model="curTab" class="demo-tabs" @tab-change="handleClick">
          <el-tab-pane label="基本信息"  :name="0"></el-tab-pane>
          <el-tab-pane label="公司信息"  :name="1"></el-tab-pane>
          <el-tab-pane label="开票信息"  :name="2"></el-tab-pane>
          <el-tab-pane label="银行信息"  :name="3"></el-tab-pane>
          <el-tab-pane label="ERP系统登陆账号" :name="4"></el-tab-pane>
        </el-tabs>
        <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true,labelWidth:100,isDisabled:true}" v-if="showDetailPage" ></Vform>
      </el-dialog>
    </div>
</template>
<script>


export default {
  data() {
    return {
      baseApiUrl: '/operator',
      searchData: {},
      showDetailPage: false,
      searchItemList: [
        {type: "input", value: "kw", placeholder: '查询关键字'}
      ],
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"340", fixed:'left'},
          { label: "编码", width: 100, prop: "operatorCode" },
          { label: "名称", width: 250, prop: "operatorName" },
          { label: "状态", width: 60,callBack:(item)=>{ return item.stateName},getColor:(item)=>{return item.stateName=='禁用'?'textwarning':'textsuccess'}},
          { label: "联系人", width: 100, prop: "contactPerson" },
          { label: "联系电话", width: 100, prop: "contactPhone" },
          { label: "地址", width: 300, prop: "addr" },
          { label: "财务联系人", width: 100, prop: "finContactPerson" },
          { label: "财务联系电话", width: 100, prop: "finContactPhone" },
          { label: "保证金", width: 100, prop: "earnestMoney" },
          { label: "创建人", width: 100, prop: "createdBy" },
          { label: "创建时间", width: 150, prop: "createdTime" },
          { label: "更新人", width: 100, prop: "updateBy" },
          { label: "更新时间", width: 150, prop: "updateTime" },
        ]
      },
      ItemList:[
          [
            {label:"名称",type: "input", value: "operatorName", span:1},
            {label:"运营商类型",type: "radio", value: "operatorType",selectOptions:[{Id:0,Name:'运营商'},{Id:1,Name:'驿站主运营商'}],span:1},
            {label:"合作区域",type: "select", value: "cooperationArea",span:1, multiple:true,keyValue:['Code','Name'],collapseaTags:false},
            {label:"仓库",type: "select", value: "cooperationWarehouse",span:1, multiple:true,keyValue:['Code','Name'],collapseaTags:false},
            {label:"保证金",type: "input", value: "earnestMoney",span:2},
            {label:"是否城批",type: "select", value: "isCityWholesale",selectOptions:[{Id:0,Name:'否'},{Id:1,Name:'是'}],span:2},
            {label:"合同开始时间", type: "date", value: "contractBegin",span:2,placeholder: '未填写'},
            {label:"合同结束时间", type: "date", value: "contractEnd",span:2,placeholder: '未填写'},
          ], [
           {label:"名称法人名", type:"input", value: "legalPersonName", span:2},
           {label:"法人身份证", type:"input", value: "legalPersonId", span:2},
           {label:"公司地址", type:"input", value: "addr", span:1},
           {label:"联系人", type:"input", value: "contactPerson", span:2},
           {label:"联系电话", type:"input", value: "contactPhone", span:2},
           {label:"公司联系邮箱", type:"input", value: "contactEmail", span:2},
           {label:"财务联系人", type:"input", value: "finContactPerson", span:2},
           {label:"财务联系电话", type:"input", value: "finContactPhone", span:2},
           {label:"财务联系邮箱", type:"input", value: "finContactEmail", span:2},
           {label:"税务资质",type: "radio", value: "operatorType",selectOptions:[{Id:0,Name:'一般纳税人'},{Id:1,Name:'小规模纳税人'},{Id:2,Name:'个体工商户'}],span:1},
        ], [
            {label:"社会信用代码", type:"input", value: "socialCreditCode", span:1},
            {label:"开票抬头", type:"input", value: "invoiceTitle", span:1},
            {label:"开票地址", type:"input", value: "invoiceAddr", span:1},
            {label:"开票行", type:"input", value: "invoiceBank", span:1},
            {label:"开票账户", type:"input", value: "invoiceAccount", span:1},
            {label:"联系电话", type:"input", value: "invoicePhone", span:1},
            {label:"开票种类",type: "radio", value: "operatorType",selectOptions:[{Id:0,Name:'普票'},{Id:1,Name:'专票'}],span:1},
            {label:"备注", type:"input", value: "remark", span:1, placeholder: ''},
        ], [
            {label:"账户简称", type:"input", value: "accountNameShort", span:1},
            {label:"账户全称", type:"input", value: "accountNameFull", span:1},
            {label:"开户行", type:"input", value: "bank", span:1},
            {label:"账号", type:"input", value: "cardNumber", span:1},
        ], [
            {label:"登陆账号", type:"input", value: "loginName", span:1},
            {label:"Email", type:"input", value: "email", span:1},
            {label:"用户名", type:"input", value: "realname", span:1},
            {label:"备注", type:"input", value: "URemark", span:1,placeholder: ''},
        ]
      ],
      curItem:{},
      selectData:{},
      FormItems:[],
      curTab:0,
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  computed: {
    formItemList() {
      return this.FormItems.map(item => ({
        ...item,
        selectOptions:this.selectData[item.value]||item.selectOptions||'',
        defaultValue: item.multiple==true?this.curItem[item.value]&&this.curItem[item.value].split(','):this.curItem[item.value]
      }));
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.AreaInfo).then((res) => {
        this.selectData.cooperationArea=res;
      });
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.CommonWarehouse).then((res) => {
        this.selectData.cooperationWarehouse=res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/OperatorInfo', {...this.searchData, ...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    submitItem(d,type){
      let url='',postData={},msg='',title='';
      switch (type){
        case 'upState':
          url='/OperatorUpState';
          postData={id:d.id};
          msg=d.stateName=='禁用'?'启用成功!':'禁用成功!'
          title=d.stateName=='禁用'?'是否启用该运营商？':'是否禁用该运营商？'
          break;
        case 'del':
          url='/OperatorDelete';
          postData={id:d.id};
          msg='删除成功!';
          title='是否删除这条记录？';
          if(d.stateName=='启用'){
            this.showTipMessage('启用模式下禁止删除','error');
            return;
          }
          break
        case 'Password':
          url='/OperatorUpPassword';
          postData={operatorCode:d.operatorCode};
          msg='重置密码成功'
          title='是否重置密码？'
          break
        case 'ck':
          this.$http.post(this.baseApiUrl + '/OperatorInfo', {operatorCode:d.operatorCode,more:1}).then((res)=>{
            if(res.data.rows&&res.data.rows.length!=0){
              this.curItem=res.data.rows[0];
              if(this.curItem.contractBegin=='0001-01-01 00:00:00') this.curItem.contractBegin='';
              if(this.curItem.contractEnd=='0001-01-01 00:00:00') this.curItem.contractEnd='';
              this.showDetailPage=true;
              setTimeout(()=>{this.FormItems=this.ItemList[this.curTab];},100)
            }else{
              this.showTipMessage('找不到该条记录','error');
              return;
            }
          });
          return;
          break
      }
      this.showConfirm(title,()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + url,postData).then(()=>{
          this.showTipMessage(msg,'success');
          this.canEdit=false;
          this.search();
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
      })
    },
    handleClick(){
      this.FormItems=this.ItemList[this.curTab];
    },
    toPage(type,d){
      if(type=='add'){
        this.$store.dispatch('tags/setTagsItem', {
          name: 'operator-OperatorInfoEdit',
          title: '新增运营商',
          path: '/operator-OperatorInfoEdit',
          type: 'child'
        });
        this.$router.push({path:'/operator-OperatorInfoEdit'})
      }else{
        this.$store.dispatch('tags/setTagsItem', {
          name: 'operator-OperatorInfoEdit',
          title: '编辑运营商',
          path: '/operator-OperatorInfoEdit?saveMode='+type+'&operatorCode='+d.operatorCode,
          type: 'child'
        });
        this.$router.push({path:'/operator-OperatorInfoEdit', query:{saveMode:type,operatorCode:d.operatorCode}})
      }
    },
    exportData(){
      this.postExportData({...this.searchData,...this.pageinfo}, this.baseApiUrl+'/OperatorInfo');
    },
  }
}
</script>

<template>
  <div class="content_box">
    <div class="main-box treeC" style="padding-top:20px;">
      <div class="treeBoxFull" style="flex: 1!important">
        <Vtree :treeData="treeData" :showCheckbox="true" refName="leftTree" :defaultExpanded="leftDefault" @nodeclick="showForm" :emitSearch="true" title="所有模块功能" ref="leftTree" ></Vtree>
      </div>
      <div class="treeBoxFull"  style="width:160px!important">
        <div style="line-height: 40px;text-align: center;padding:0 20px">
          <el-button type="success"  @click="addItem()" :disabled="canEdit">
            添加<el-icon class="el-icon--right"><Right /></el-icon>
          </el-button>
          <el-button type="danger"  @click="delItem()" :disabled="canEdit" style="margin-left:0">
            移除<el-icon class="el-icon--right"><Back /></el-icon>
          </el-button>
          <el-button type="warning"  @click="treeRightData=treeRightbaseData" :disabled="canEdit" style="margin-left:0">
            重置<el-icon class="el-icon--right"><Refresh /></el-icon>
          </el-button>
          <el-button type="primary"  @click="saveData()" :disabled="canEdit" style="margin-left:0">
            保存<el-icon class="el-icon--right"><FolderChecked /></el-icon>
          </el-button>
        </div>
      </div>
      <div class="treeBoxFull green"  style="flex: 1!important">
        <Vtree :treeData="treeRightData" :showCheckbox="true" refName="rightTree" :defaultExpanded="rightDefault" @nodeclick="showForm" :emitSearch="true" :title="`【${curPermission.groupName}】的权限`" ref="rightTree"></Vtree>
      </div>
    </div>
  </div>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/system',
      params:{},
      treeData:[],
      rightDefault:[],
      leftDefault:[],
      treeRightData:[],
      treeRightbaseData:[],
      curPermission:{},
      selectData:[],
      postData:{},
      id:'',// 页面操作权限id
      canEdit:false,
    }
  },
  inject: ['$hasPermission'],
  created() {
    this.params.id=this.$route.query.id
  },
  mounted(){
    this.getListData();
  },
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/BusinessModule',{}).then((res)=>{
        res.data.map((item)=>{item.value=item.code;item.label=item.name;})
        this.treeData=this.buildTree(res.data,'code','parentCode','children');
        this.getBusinessGetPermission();
      });
    },
    buildTree(source, id, parentId, children){
      let cloneData = cloneObject(source);
      return cloneData.filter(father => {
        let branchArr = cloneData.filter(child => {
          if(father[id] == child[parentId]){
            child.parentName = father['name']
            return true
          }
        });
        branchArr.length > 0 ? father[children] = branchArr : null
        return father[parentId] == 0
      })
    },
    getBusinessGetPermission(){
      this.$http.post(this.baseApiUrl+'/BusinessGetPermission',{rId:this.params.id}).then((res)=>{
        this.treeRightData=this.filterData(this.treeData,res.data);
        this.treeRightbaseData=JSON.parse(JSON.stringify(this.filterData(this.treeData,res.data)));
        this.curPermission=res.ext.roleInfo;

      });
    },
    filterData(a, b) {
        const codes = b.map(item => item.moduleCode);
        function filterChildren(children) {
          return children
              .filter(child => codes.includes(child.code))
              .map(child => ({
                ...child,
                children: child.children ? filterChildren(child.children) : []
              }));
        }
        return filterChildren(a);
    },
    addItem(){
      let leftArr=this.$refs.leftTree.getTreeData();
      let rightArr=cloneObject(this.treeRightData);
      if(leftArr.length==0){
        this.showTipMessage('请选取左边的项目!','warning');
        return;
      }
      let curSelect=this.getAllCodes(leftArr).selectData;
      this.selectData=this.getAllCodes(rightArr).selectData;
      let data=[...curSelect,...this.selectData];
      let d = data.filter((item, index, self) =>
          index === self.findIndex((t) => (t.moduleCode === item.moduleCode))
      );
      this.treeRightData=this.filterData(this.treeData,d);
      this.rightDefault=this.$refs.rightTree.getExpandedArr();
      this.$refs.leftTree.resetChecked();
    },
    delItem(){
      let allArr=cloneObject(this.treeRightData);
      let rightArr=this.$refs.rightTree.getTreeData();
      if(rightArr.length==0){
        this.showTipMessage('请选取右边的项目!','warning');
        return;
      }
      let allData=this.getAllCodes(allArr).selectData;
      let selectData=this.getAllCodes(rightArr).selectData;
      let d = allData.filter(item => !selectData.some(rItem => rItem.moduleCode === item.moduleCode));
      this.treeRightData=this.filterData(this.treeData,d);
      this.rightDefault=this.$refs.rightTree.getExpandedArr();
    },
    getAllCodes(list) {
      let codes = [],selectData=[];
      function extractCodes(items) {
        items.forEach(item => {
          codes.push(item.code);
          selectData.push({moduleCode:item.code,moduleName:name});
          if (item.children) {
            extractCodes(item.children);
          }
        });
      }
      extractCodes(list);
      return {codes:codes,selectData:selectData};
    },
    saveData(){
      if(this.getAllCodes(this.treeRightData).codes.length==0){
        this.showTipMessage(`请先添加【${this.curPermission.groupName}】权限再点击保存!`,'warning');
        return;
      }
      let postData = {
        roleCode: this.curPermission.groupCode,
        permissions: JSON.stringify(this.getAllCodes(this.treeRightData).codes),
        operates: JSON.stringify([]) // 该参数有待商榷，是否去掉，接口没用上。
      };
      this.canEdit=true;
      this.$http.post(this.baseApiUrl+'/BusinessPermissionSave',postData).then(()=>{
        this.showTipMessage('保存权限成功','success');
        this.canEdit=false;
        setTimeout(()=>{
          this.$store.dispatch('tags/closeCurrentTag',{$router: this.$router, $route: this.$route});
          this.$router.push({path:'/system-authModules'})
        },3000)

      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    }
  }
}
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.auditState!='未审核'?'info':'warning'" plain size="small" @click="submitItem(actionBtn.data)" :disabled="actionBtn.data.auditState!='未审核'">审核商品</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="审核商品建档" width="80%">
    <storage-ProSetupListAudit ref="editPage" :params="editParams"  @closeDialog="handleClose"></storage-ProSetupListAudit>
  </el-dialog>
</template>
<script>
import storageProSetupListAudit from './storage-ProSetupListAudit.vue'
export default {
  components: {
    storageProSetupListAudit,
  },
  data() {
    const ARR_RefundStatus = [
      { Id: "未审核", Name: "未审核" },
      { Id: "已审核", Name: "已审核" },
      { Id: "未通过", Name: "未通过" },
    ];
    const dateBegin = this.$formatDate(0, -30);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl:'/product',
      searchData: {},
      showDetailPage:false,
      searchItemList:[
        {label: "创建时间", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
        {label: "状态", type: "select", value: "applyState", selectOptions:ARR_RefundStatus, defaultValue:["未审核"], multiple:true},
        {type: "input", value: "kw",placeholder:'查询商品名/编码/条码'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"100", fixed:'left'},
          { label: "产品编码", width: 100, prop: "productCode" },
          { label: "产品名称", width: 260, prop: "productName" },
          { label: "状态", width: 80,callBack:(item)=>{ return item.auditState},getColor:(item)=>{return ['textwarning','textsuccess','textdanger'][['未审核','已审核','未通过'].indexOf(item.auditState)]} },
          { label: "创建人", width: 85, prop: "createdBy" },
          { label: "创建时间", width: 150, prop: "createdTime" },
          { label: '审核人', width: 85, prop: "auditedBy" },
          { label: "审核时间", width: 150, prop: "auditedTime" },
          { label: "备注", 'min-width': 150, prop: "auditRemark" }
        ]
      },
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/prosetuplist', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    submitItem(d){
      this.showDetail(d.id);
    },
    handleClose() {
      this.showDetailPage = false;
      this.search();
    },
    showDetail(id){
      this.showDetailPage = true;
      this.editParams = {id};
    }
  }
}
</script>
<style>
.refund-summary{
  color: red; padding: 10px 0px;
}
</style>

<template>
  <div>
    <slot name="title">
      <el-divider :content-position="titlePos" v-if="title !=''">{{title}}</el-divider>
    </slot>
    <el-row>
      <el-col :span="8" v-if="editPackQty !=1">
        <el-form-item :label="boxTitle">
          <el-input-number v-model="editBoxQty" :size="size" @change="changeEditQty" :min="0" :max="99999999" :style="inputStyle" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item :label="unitTitle">
          <el-input-number v-model="editUnitQty" :size="size" @change="changeEditQty" :min="0" :max="numMax" :style="inputStyle" />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="总数">
          <el-input v-model="editTotalQty" :size="size" :disabled="true"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
        props: {
            title: {
                type: String,
                default: ""
            },
            titlePos: {
                type: String,
                default: "left"
            },
            packQty: {
                type: Number,
                default:1
            },
            totalQty: {
                type: Number,
                default: 0
            },
            size: {
                type: String,
                default: "default"
            },
            width: {
                type: String,
                default: ""
            },
            packUnit:{
                type: String,
                default: ""
            },
            unit:{
                type: String,
                default: ""
            },
            isPackSale: {
                type: Boolean,
                default: false
            },
            numMax: {
                type: Number,
                default: 99999999
            }
        },
        watch: {
            totalQty(v){
                this.editTotalQty = v;
            },
            unitPrice(v){
                this.editUnitPrice = v;
            },
            packQty(v){
                this.editPackQty = v;
            }
        },
        data() {
            return {
                editUnitQty: this.totalQty % this.packQty,
                editBoxQty: this.totalQty / this.packQty,
                editTotalQty: this.totalQty,
                editPackQty: this.packQty,
                inputStyle: {},
                boxTitle: '件数',
                unitTitle: '零数'
            }
        },
        created(){
            if (this.width) this.inputStyle['width'] = this.width;
            this.editPackQty = this.editPackQty || 1;
            if (this.editPackQty == 1) {
                this.editUnitQty = this.editTotalQty;
                 this.editBoxQty=0;
            }
            else {
                let res = this.calcPackQty(this.editTotalQty, this.editPackQty);
                this.editUnitQty = res.unitQty;
                this.editBoxQty = res.boxQty;
            }
            if (this.unit !='') this.unitTitle += "(" + this.unit + ")";
            if (this.packUnit != '') this.boxTitle += "(" + this.packUnit + ")";
        },
        methods: {
         calcPackQty(total, packNum) {
            let d = { boxQty: 0, unitQty: 0, totalQty: total };
            if (packNum == 1) {
              d.boxQty = 0;
              d.unitQty = total;
            }
            else {
              d.boxQty = Math.floor(total / packNum);
              d.unitQty = total % packNum;
            }
            return d;
          },
          changeEditQty() {
                let res = this.calcPackQty(this.editPackQty * this.editBoxQty + this.editUnitQty, this.editPackQty);
                this.editTotalQty = res.totalQty;
                this.editUnitQty = res.unitQty;
                this.editBoxQty = res.boxQty;

                this.$emit("update", { totalQty: res.totalQty });
            }
        }
    }
</script>

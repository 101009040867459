import {getInfo,getToken } from '@/api/user'
import { ActionContext } from 'vuex'
export type userState= {
    token: string,
    userInfo: object,
    introduction:string,
    roles:object
}
const state:userState ={
    token: sessionStorage.token||'',
    userInfo:{},
    introduction: '',
    roles: [],
}
const mutations = {
    SET_TOKEN: (state:userState, token:string) => {
        state.token = token;
        sessionStorage.token=token;
    },
    SET_userInfo: (state:userState, userInfo: object) => {
        state.userInfo = userInfo
    },
    SET_ROLES: (state:userState, roles: object) => {
        state.roles = roles
    },
}


const actions = {
    getToken({commit,dispatch}: ActionContext<userState, userState>, data:any) {
        return new Promise((resolve, reject) => {
            getToken(data).then((response:any)=> {
                commit('SET_TOKEN',response.data);
                location.replace("/");
                // dispatch('user/getInfo',null,{ root: true }) //{ root: true }在根命名空间寻找
                resolve(response)
            }).catch((error:any) => {
                reject()
                dispatch('user/resetToken',null,{ root: true });
                location.replace(process.env.VUE_APP_BASE_API + "/_sso/login");
            })
        })
    },

    getInfo({ commit,dispatch}:ActionContext<userState, userState>) {
        return new Promise((resolve, reject) => {
            getInfo().then((response) => {
                const { data } = response
                const { roles,menus} = data.others;
                let rolesList:any=[];
                roles.map((item: Record<string, any>)=>{rolesList.push(item.roleName)})
                commit('SET_ROLES', rolesList);
                commit('SET_userInfo',{realname:data.realname,userId:data.userId,loginName:data.loginName,email:data.email})
                commit('menu/setMenu', menus,{ root: true }) //
                resolve(true)
                }).catch((error:any)=> {
                    dispatch('user/resetToken',null,{ root: true });
                    reject()
                    location.replace(process.env.VUE_APP_BASE_API + "/_sso/login");
                })
        })
    },


    resetToken({ commit }:ActionContext<userState, userState>) {
        return new Promise(resolve => {
            sessionStorage.clear()
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            resolve(true)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

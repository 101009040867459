import { ActionContext } from 'vuex'
import {MenuItem} from "@/store/modules/menu";
export type tagState ={
    list:  { [key: string]: any }[]
}
const state:tagState = {
    list: sessionStorage.tags?JSON.parse(sessionStorage.tags):[]
}
const getters= {
    show: (state:tagState) => {
        return state.list.length > 0;
    },
    nameList: (state:tagState) => {
        return state.list.map(item => item.name);
    }
}
const actions= {
    delTagsItem({state}:ActionContext<tagState,tagState>,index:number) {
        state.list.splice(index, 1);
        sessionStorage.tags=JSON.stringify(state.list);
    },

    setTagsItem: function ({state, commit, rootState}: ActionContext<tagState, tagState>, data: any) {
        if (data.type) {
            let index = state.list.findIndex((item) => item.name === data.name);
            if (index == -1) {
                state.list.push({...data, // @ts-ignore
                    parent_code: rootState.menu.sideId,// @ts-ignore
                    top_code:rootState.menu.topId,});
                let obj = {
                    lv: 3,
                    type:data.type,
                    title: data.title,
                    status: 1,
                    url: '/views/index.html#'+data.path.substring(1,data.path.length), // @ts-ignore
                    parent_code:rootState.menu.sideId,// @ts-ignore
                    top_code:rootState.menu.topId,
                    code: ''
                }
                commit('menu/changeMenu', [obj], {root: true});
            } else {
                state.list.map((item) => {
                    if (data.name == item.name) {
                        item.path = data.path;
                        item.title = data.title;
                    }
                    return item
                })
            }

        } else {
            state.list.push(data);
        }

        sessionStorage.tags = JSON.stringify(state.list);
    },
    clearTags({state,commit}:ActionContext<tagState,tagState>) {
        state.list = [];
        commit('menu/setBreadcrumb', [], {root: true});
        sessionStorage.tags=JSON.stringify(state.list);
    },
    closeTagsOther({state}:ActionContext<tagState,tagState>,data:any) {
        state.list = data;
        sessionStorage.tags=JSON.stringify(state.list);
    },
    closeCurrentTag({state}:ActionContext<tagState,tagState>,data:any) {
        for (let i = 0, len = state.list.length; i < len; i++) {
            const item = state.list[i];
            let activePath=item.params?item.path+'/'+item.params:item.path
            if (activePath === data.$route.fullPath) {
                if (i < len - 1) {
                    data.$router.push(state.list[i + 1].path);
                } else if (i > 0) {
                    data.$router.push(state.list[i - 1].path);
                } else {
                    data.$router.push('/');
                }
                state.list.splice(i, 1);
                break;
            }
        }
        sessionStorage.tags=JSON.stringify(state.list);
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions
}

<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="submitItem(actionBtn.data)" >查看明细</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="订单明细" width="70%" style="padding-bottom:20px;">
    <mainTable :mainOptions="detailData" ref="detailTable"></mainTable>
  </el-dialog>
</template>
<script>
import {cloneObject} from "@/utils/common";
export default {
  data() {
    const ARR_RefundStatus = [
      { Id: "0", Name: "未审核" },
      { Id: "1", Name: "已审核" },
      { Id: "2", Name: "已入库" },
      { Id: "3", Name: "已付款" },
      { Id: "4", Name: "已出库" },
      { Id: "5", Name: "已收款" },
      { Id: "6", Name: "正在分拣" },
      { Id: "7", Name: "待处理" },
      { Id: "17", Name: "退款中" },
      { Id: "18", Name: "已退款" },
      { Id: "13", Name: "已取消" },
    ];
    return {
      baseApiUrl: '/promotion',
      searchData: {},
      showDetailPage:false,
      dateBegin:this.$formatDate(0, -30),
      dateEnd:this.$formatDate(0),
      RefundStatus:ARR_RefundStatus,
      warehouseInfo:[],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"100", fixed:'left'},
          { label: "订单号", width: 200, prop: "orderNo" },
          { label: "仓库", width: 150, prop: "warehouseName" },
          { label: "源单号", width: 150, prop: "sourceOrderNo" },
          { label: "最终价", width: 100,callBack:(item)=>{ return item.finalPrice.toFixed(2)},getColor:()=>{return 'textdanger'}},
          { label: "成交价", width: 100,callBack:(item)=>{ return item.orderAmount.toFixed(2)},getColor:()=>{return 'textwarning'}},
          { label: "客户编码", width: 150, prop: "traderCode" },
          { label: "客户名称", width: 100, prop: "traderName" },
          { label: "联系人电话", width: 150, prop: "phone" },
          { label: "客户渠道", width: 100, prop: "clientTypeName" },
          { label: "订单状态", width: 100,callBack:(item)=>{ return item.orderStateName},getColor:(item)=>{return (item.orderState==0||item.orderState==7)?'textdanger':'textsuccess'}},
          { label: "支付方式", width: 100, prop: "payOffTypeName" },
          { label: "业务员名称", width: 100, prop: "salerName" },
          { label: "备注", width: 100, prop: "remark" },
          { label: "下单时间", width: 150, prop: "createdTime" },
          { label: "SAAS单号", width: 150, prop: "saasOrderNo" },
        ]
      },
      detailData: {
        DataList:[],
        tableHeight:'200',
        Total:0,
        labelList: [
          { label: "商品编码", width: 100, prop: "productCode" },
          { label: "商品名称", minWidth: 100, prop: "productName" },
          { label: "单位", width: 100, prop: "unit" },
          { label: "数量", width: 100, prop: "unitQty" },
          { label: "含税单价", width: 100, prop: "unitPrice" },
          { label: "是否赠品", width: 100, prop: "isGift",callBack:(item)=>{return   item.isGift == 1 ? "赠品" : "销售品"}}
        ]
      },
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "入库仓库", type: "select", value: "warehouseCode", selectOptions: this.warehouseInfo,multiple:false,keyValue:['Code','Name'],clearable:true},
        {label: "订单状态", type: "select", value: "orderState", selectOptions: this.RefundStatus, multiple: true},
        {label: "下单时间", type: "daterange", value: "createtime", defaultValue: [this.dateBegin, this.dateEnd]},
        {type: "input", value: "kw", placeholder: '订单号/SAAS单号/源单号',clearable:true}
      ]
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData', this.CacheKeys.CommonWarehouse).then((res) => {
        this.warehouseInfo=res;
      });
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/WholesaleReturnOrderList', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      let Data=postData?cloneObject(postData):'';
      if (Data) {
        Data.timeStart = postData.dateFrom;
        Data.timeEnd = postData.dateTo;
        delete Data.dateFrom;
        delete Data.dateTo;
      }
      this.searchData=Data||this.searchData;
      this.getListData();
    },
    submitItem(d){
      this.showDetail(d);
    },
    handleClose() {
      this.showDetailPage = false;
      this.search();
    },
    showDetail(d){
      this.$http.post(this.baseApiUrl+'/GetWholesaleReturnOrderDetails', {orderNo:d.orderNo}).then((res) => {
        this.detailData.DataList = res.data.rows;
        this.showDetailPage = true;
      });
    },
  }
}
</script>
<style>
.refund-summary{
  color: red; padding: 10px 0px;
}
</style>

<template>
  <div class="content_box">
      <div class="search_bar" style="margin-bottom: 0;">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true" :showRestbtn="false"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable ref="mainTable" :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" @selectChange="selectChange">
              <template v-slot:operation="actionBtn">
                  <el-button type="primary" plain size="small" @click="detailItem(actionBtn.data)" >查看</el-button>
                  <el-button type="success" plain size="small" @click="orderTrace(actionBtn.data)" >订单跟踪</el-button>
              </template>
          </mainTable>
      </div>
  </div>

  <el-dialog v-model="showDetailDialog" title="订单明细查看" width="800px" >
      <el-descriptions :column="3" size="small" border>
          <el-descriptions-item label="单号">{{detailItemRow.orderNo}}</el-descriptions-item>
          <el-descriptions-item label="订单类型">{{detailItemRow.orderTypeName}}</el-descriptions-item>
          <el-descriptions-item label="订单状态">{{detailItemRow.orderStateName}}</el-descriptions-item>
          <el-descriptions-item label="订单金额">{{detailItemRow.orderAmount}}</el-descriptions-item>
          <el-descriptions-item label="客户/供应商">{{detailItemRow.traderName}}</el-descriptions-item>
          <el-descriptions-item label="结算方式">{{detailItemRow.payOffTypeName}}</el-descriptions-item>
      </el-descriptions>
      <span style="font-size: 14px;" v-if="standardTD.DataList.length > 0">标品:</span>
      <mainTable :mainOptions="standardTD" :showPage="false" v-if="standardTD.DataList.length > 0"></mainTable>
      <span style="font-size: 14px;" v-if="nonStandardTD.DataList.length > 0">非标品:</span>
      <mainTable :mainOptions="nonStandardTD" :showPage="false" v-if="nonStandardTD.DataList.length > 0"></mainTable>
      <div style="height: 20px;"></div>
  </el-dialog>

  <el-dialog v-model="showOrderTraceDialog" :title="orderTraceTitle" width="800px" >
      <mainTable :mainOptions="orderTraceTD" :showPage="false"></mainTable>
      <div style="height: 20px;"></div>
  </el-dialog>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/report',
            searchData: {},
            selectOptions:{},
            stateList:[
               { Code: "0", Name: "未审核" },
               { Code: "1", Name: "已审核" },
               { Code: "2", Name: "已入库" },
               { Code: "3", Name: "已付款" },
               { Code: "4", Name: "已出库" },
               { Code: "5", Name: "已收款" },
               { Code: "7", Name: "正在分拣" },
            ],
            typeList:[
                { Code: "8", Name: "采购单" },
                { Code: "1", Name: "销售单" },
                { Code: "3", Name: "销售退货单" },
                { Code: "9", Name: "采购退厂单" },
                { Code: "2", Name: "采购入库单" },
                { Code: "10", Name: "退货入库单" },
                { Code: "7", Name: "销售出库单" },
                { Code: "11", Name: "退厂出库单" },
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "160", fixed: 'left' },
                    { label: "订单编码", width: 180, prop: "orderNo" },
                    { label: "客户编码", width: 110, prop: "traderCode" },
                    { label: "客户/供应商名称", width: 200, prop: "traderName" },
                    { label: "金额", width: 80, prop: "orderAmount" },
                    { label: "数量", width: 50, prop: "detailCount" },
                    { label: '状态', width: 60, prop: "orderStateName"},
                    { label: "订单类型", width: 80, prop: "orderTypeName" },
                    { label: "结算方式", width: 80, prop: "payOffTypeName" },
                    { label: "运营商编码", width: 100, prop: "operatorCode" },
                    { label: "运营商名称", width: 200, prop: "operatorName" },
                    { label: "创建人", width: 70, prop: "createdBy" },
                    { label: "创建时间", width: 150, prop: "createdTime" },
                    { label: "审核人", width: 70, prop: "auditedBy" },
                    { label: "审核时间", minWidth: 150, prop: "auditedTime" }
                ]
            },
            pageinfo:{
                pg_idx: 1,
                pg_size: 15,
            },

            //showDetailDialog
            showDetailDialog:false,
            detailItemRow:{},
            standardTD:{
                DataList: [],
                tableHeight:250,
                labelList: [
                    { label: "商品编码", width: 100, prop: "productCode" },
                    { label: "商品名称", width: 200, prop: "productName" },
                    { label: "规格", width: 60, prop: "spec" },
                    { label: "计价数量", width: 70, prop: "unitQty" },
                    { label: "单位", width: 50, prop: "unit" },
                    { label: "单价", width: 70, prop: "unitPrice" },
                    { label: "金额", width: 80, prop: "amount" },
                    { label: "包装数量", width: 70, prop: "packNum" },
                    { label: "包装单位", minWidth: 70, prop: "packUnit" }
                ]
            },
            nonStandardTD:{
                DataList: [],
                tableHeight:250,
                labelList: [
                    { label: "商品编码", width: 100, prop: "productCode" },
                    { label: "商品名称", width: 200, prop: "productName" },
                    { label: "规格", width: 60, prop: "spec" },
                    { label: "计价数量", width: 70, prop: "unitQty" },
                    { label: "单位", width: 50, prop: "unit" },
                    { label: "单价", width: 70, prop: "unitPrice" },
                    { label: "金额", width: 80, prop: "amount" },
                    { label: "包装数量", width: 70, prop: "packNum" },
                    { label: "包装单位", minWidth: 70, prop: "packUnit" }
                ]
            },
            //showOrderTraceDialog
            showOrderTraceDialog:false,
            orderTraceTitle:'',
            orderTraceTD:{
                DataList: [],
                tableHeight:250,
                labelList: [
                    { label: "阶段", width: 60, prop: "stageName" },
                    { label: "时间", width: 140, prop: "operatedTime" },
                    { label: "操作人", width: 100, prop: "operatedBy" },
                    { label: "操作", width: 80, prop: "actionName" },
                    { label: "状态", minWidth: 80, prop: "stateName" }
                ]
            }
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "入账日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)],width:170,},
                { label: "运营商:", type: "select", value: "operatorCode", selectOptions: this.selectOptions.operatorList, multiple: false ,clearable:true,keyValue:['Code','Name']},
                { label: "",  placeholder: '状态',type: "select", value: "orderState", selectOptions: this.stateList,multiple: true,filterable:false,width:140,clearable:true, keyValue: ['Code', 'Name'] },
                { label: "",placeholder: '订单类型', type: "select", value: "orderType", selectOptions: this.typeList,multiple: true,filterable:false,width:140, clearable:true,keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询订单编号',width:120}
            ]
        },
    },
    mounted() {
        this.getOperatorInfo()
    },
    methods: {
        getOperatorInfo(){
          this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
              this.selectOptions.operatorList = res;
          });
        },
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/allorderlist', {...this.pageinfo,...this.searchData,cooperationArea:''}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData,cooperationArea:''}, this.baseApiUrl+'/allorderlist')
        },
        detailItem(row){
            let postData = {
                orderNo: row.orderNo,
                orderType: row.orderType
            }
            this.$http.post('/Report/OrderDetails', postData).then((res)=>{
                this.detailItemRow = row
                this.standardTD.DataList = []
                this.nonStandardTD.DataList = []
                res.data.rows.forEach(item=>{
                    if(item.useAssistantUnit == 1){
                        this.nonStandardTD.DataList.push(item)
                    }else{
                        this.standardTD.DataList.push(item)
                    }
                })
                this.showDetailDialog = true
            });
        },
        orderTrace(row){
            let postData = {
                orderNo: row.orderNo,
                operatorCode: row.operatorCode
            }
            this.$http.post(this.baseApiUrl + '/AjaxOrderTraceList', postData).then((res)=>{
                this.orderTraceTD.DataList = res.data.rows
                this.orderTraceTitle = row.orderNo
                this.showOrderTraceDialog = true
            });
        }
    }
}
</script>

<template>
  <div class="search_bar">
    <span></span>
    <baseSearch :formItemList="searchItemList" @search="search" :showRestbtn="false" @changeform="getCities"></baseSearch>
  </div>
  <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
    <template v-slot:operation="actionBtn">
      <el-button :type="actionBtn.data.bank_code==BankUniqueNo?'info':'primary'" size="small"  @click="submitItem(actionBtn.data)">{{actionBtn.data.bank_code==BankUniqueNo?'已选':'选中'}}</el-button>
    </template>
  </mainTable>
</template>
<script>
import {BankInfo} from '@/utils/constant'
export default {
  props: {
    bankcode: '',
    BankUniqueNo: '',
  },
  data() {
    return {
      BankApiUrl: '',
      searchData: {},
      mainData: {
        tableHeight:380,
        DataList: [],
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'right'},
          { label: "支付联行号", width: 160, prop: "bank_code" },
          { label: "支付联行名", minWidth: 190, prop: "lname" },
        ]
      },
      provincesInfo:[],
      citiesInfo:[],
      defaultProvince:'44',
    }
  },
  computed: {
    searchItemList() {
      return [
        {label: "省份", type: "select",value: "provinceCode", defaultValue:this.defaultProvince,selectOptions: this.provincesInfo, multiple: false, keyValue:['key','name'],width:'130'},
        {label: "城市", type: "select", value: "areacode", selectOptions: this.citiesInfo, defaultValue:this.citiesInfo[0]?.key,multiple: false, keyValue:['key','name']},
        {type: "input", value: "keyword", placeholder: '查询关键字',width:'130'}
      ];
    }
  },
  mounted(){
    this.BankApiUrl=BankInfo.BankApiUrl;
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$http.get(this.BankApiUrl + '/query_provinces').then((res) => {
        this.provincesInfo=res.data;
        this.getCities(this.defaultProvince)
      });
    },
    getCities(v){
      if(this.defaultProvince!=v?.provinceCode){
        this.$http.get(this.BankApiUrl + '/query_cities', {params:{parentcode:v?.provinceCode??this.defaultProvince}}).then((res) => {
          this.citiesInfo=res.data;
        });
        this.defaultProvince=typeof v=='string'?v:v.provinceCode;
      }
    },
    getListData() {
      this.$http.get(this.BankApiUrl + '/query_cnaps', {params:{...this.searchData,bankcode: this.bankcode}}).then((res) => {
        this.mainData.DataList = res.data;
      });
    },
    search(postData) {
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    submitItem(d){
      this.$emit('submititem',d)
    }
  }
}
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tags" }
const _hoisted_2 = { class: "tags-close-box" }
const _hoisted_3 = {
  size: "small",
  style: {"outline":"none"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_Fold = _resolveComponent("Fold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      class: "tab-bar",
      modelValue: _ctx.curTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.curTab) = $event)),
      onTabChange: _ctx.handleClick,
      onTabRemove: _ctx.closeTags
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "首页",
          name: "/",
          key: "home"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            label: item.title,
            name: item.path,
            key: index,
            closable: true
          }, null, 8, ["label", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onTabChange", "onTabRemove"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_dropdown, { onCommand: _ctx.handleTags }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, { size: "small" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_dropdown_item, { command: "other" }, {
                default: _withCtx(() => [
                  _createTextVNode("关闭其他")
                ]),
                _: 1
              }),
              _createVNode(_component_el_dropdown_item, { command: "all" }, {
                default: _withCtx(() => [
                  _createTextVNode("关闭所有")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_el_icon, {
              class: "el-icon--right",
              size: "20"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Fold)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["onCommand"])
    ])
  ]))
}
<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success" @click="add" size="small">添加</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="editItem(actionBtn.data)" >编辑</el-button>
          <el-button type="danger" plain size="small" @click="deleteItem(actionBtn.data)" >删除</el-button>
          <el-button type="success" plain size="small" @click="setState(actionBtn.data,1)" v-if="actionBtn.data.state == 0">启用</el-button>
          <el-button type="warning" plain size="small" @click="setState(actionBtn.data,0)" v-if="actionBtn.data.state == 1">禁用</el-button>
        </template>
      </mainTable>
    </div>
  </div>

  <el-dialog v-model="showEditDialog" :title="modeType == 'Add' ? '添加仓库信息' : '编辑仓库信息'" width="50%" style="min-width:350px">
    <Vform ref="vform" :formItemList="formItemList" :setFormInfo="{inline:true,labelWidth:'100px',formRules:rules}" @changeform="changeform" >
      <template v-slot:formButton="formBtn">
        <el-form-item style="margin-left: 100px;">
          <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit">确定提交</el-button>
          <el-button type="danger"  @click="showEditDialog=false">关闭</el-button>
        </el-form-item>
      </template>
    </Vform>
  </el-dialog>
</template>

<script>

    export default {
        data() {
            return {
                baseApiUrl: '/system',
                searchData: {},
                searchItemList:[
                    { type: "input", value: "kw", placeholder: '查询关键词' }
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "200", fixed: 'left' },
                        { label: "仓库编码", width: 100, prop: "code" },
                        { label: "仓库名称", width: 150, prop: "name" },
                        { label: "仓库地址", width: 180, prop: "addr" },
                        { label: "外部编码", width: 90, prop: "externalCode"},
                        {
                            label: "状态", width: 60, prop: "state" , callBack: (item)=>{
                                return ["禁用","启用"][item.state];
                            },getColor:(item)=>{
                                return item.state == 0? 'textdanger' : 'textsuccess'
                            }
                        },
                        {
                            label: "退货仓", width: 80, prop: "isReturn" , callBack: (item)=>{
                                return item.isReturn==1?"是":"否";
                            }
                        },
                        { label: "绑定退货仓", minWidth: 150, prop: "returnName" }
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },

                // showEditDialog
                showEditDialog:false,
                FormItems: [
                    {label:"仓库编码：",type: "input", value: "code", isDisabled:true,placeholder:'自动编码', span:1},
                    {label:"仓库名称：",type: "input", value: "name", span:1},
                    {label:"仓库地址：",type: "address", value: "addressCode",span:1,placeholder: '地址',addressOptions:{checkStrictly:false,ismultiple:false,citytype:4}},
                    {label:"详细地址：",type: "input", value: "addr", span:1},
                    {label:"外部编码：",type: "input", value: "externalCode", span:1},
                    {label:"是否为退货仓：",type: "radio", value: "isReturn",defaultValue:1,selectOptions:[{Id:1,Name:'是'},{Id:0,Name:'否'}],span:1,isChange:true},
                    {label:"绑定退货仓：",type: "select", value: "returnCode",defaultValue:'',selectOptions:[] ,visible: false,placeholder: '请选择驿站',keyValue:['Code','Name'],span:1}
                ],
                rules: {
                    name: [{ required: true, message: "请输入名称", trigger: "manual"}],
                    addr: [{ required: true, message: "请输入详细地址", trigger: "manual"}],
                    externalCode: [{ required: true, message: "请输入外部编码", trigger: "manual"}],
                },
                curItem:{},
                canEdit:false,
                visibleReturn:false,
                modeType:'Add',
                stageInfo:[]
            }
        },
        computed: {
            formItemList() {
                return this.FormItems.map(item => ({
                    ...item,
                    visible:item.value=='returnCode'? this.visibleReturn:true,
                    selectOptions:item.value=='returnCode'? this.stageInfo:(item.selectOptions||''),
                    isDisabled: item.value=='isReturn' && this.modeType == 'Edit' ? true : item.isDisabled??false,
                    defaultValue:item.value=='returnCode'? this.curItem[item.value]??this.stageInfo[0].Code : this.curItem[item.value]??item.defaultValue??'',
                }))
            },
        },
        mounted(){
            this.getStageInfo();
        },
        methods: {
            getStageInfo(){
              this.$store.dispatch('cache/loadCacheData',this.CacheKeys.CommonWarehouse).then((res) => {
                let data=[];
                for (let i = 0; i < res.length; i++) {
                  if (res[i].Id==1) {
                    data.push(res[i]);
                  }
                }
                this.stageInfo = data;
              });
            },
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.$http.post(this.baseApiUrl + '/GetWarehouseData', { ...this.searchData, ...this.pageinfo}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
            changeform(d){
                this.curItem = d
                this.visibleReturn = d.isReturn == 0
            },
            add(){
                this.modeType = 'Add'
                this.curItem = {}
                this.visibleReturn = false
                this.showEditDialog = true
            },
            async onSubmit(validate) {
                const formData = await validate()
                let url = this.modeType=='Edit'?'/WarehouseEdit':'/WarehouseAdd'
                this.canEdit = true
                let postData = formData
                this.$http.post(this.baseApiUrl + url ,postData).then(()=>{
                    this.showTipMessage('操作成功!','success')
                    this.canEdit = false
                    this.showEditDialog = false
                    this.getListData()
                },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')})
            },
            editItem(row){
                if(row.state){
                    return this.showTipMessage("进行编辑操作之前，请先【禁用】该仓库")
                }
                if(row.stageType==2){
                    return this.showTipMessage("驿站关联仓库不允许编辑")
                }
                this.modeType = 'Edit'
                this.curItem = row
                this.visibleReturn = row.isReturn == 0
                this.showEditDialog = true
            },
            deleteItem(row){
                if(row.state){
                    return this.showTipMessage("进行删除操作之前，请先【禁用】该仓库")
                }
                this.showConfirm(`满足以下条件的仓库才能被删除：1、对应仓库没有与商城绑定 2、对应仓库没有与商圈绑定
                 3、对应仓库无商品库存 \n确认删除【${row.name}】吗？`,()=>{
                    this.$http.post(this.baseApiUrl + "/WarehouseDelete", { code: row.code }).then((res)=>{
                        this.showTipMessage(res.msg||"删除成功！",res.msg?'warning':"success")
                        this.getListData()
                    })
                })
            },
            setState(row,state){
                let stateText = state == 1 ? '启用' : '禁用'
                if(row.state == state){
                    return this.showTipMessage("当前状态不可操作"+stateText)
                }
                this.showConfirm(`确认${stateText}【${row.name}】吗？`,()=>{
                    this.$http.post(this.baseApiUrl + "/WarehouseSetState", { code: row.code,state }).then(()=>{
                        this.showTipMessage("状态设置成功", 'success')
                        this.getListData()
                    })
                })
            }
        }
    }
</script>

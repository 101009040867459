<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success"  size="small" @click="showDetailPage=true;modeType='add';curItem={},visibleStage=false,disabledList={}">新增</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data,'edit')" >编辑</el-button>
          <el-button type="danger" plain size="small" @click="showDetail(actionBtn.data,'del')" >删除</el-button>
          <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data,'password')" >修改密码</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" :title="modeType=='password'?'修改账号密码':modeType=='add'?'新增用户账号':'编辑用户账号'" width="50%" style="padding-bottom:20px;min-width:350px">
    <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true,labelWidth:'70px',formRules:dynamicRules}" v-if="showDetailPage" @changeform="changeform" >
      <template v-slot:formButton="formBtn">
        <el-form-item >
          <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit" style="margin-left: 80px">确定提交</el-button>
          <el-button type="danger"  @click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
        </el-form-item>
      </template>
    </Vform>
  </el-dialog>
</template>
<script>

export default {
  data() {
    return {
      baseApiUrl: '/system',
      ARR_stateData: [
        { Id: 0, Name: "禁用" },
        { Id: 1, Name: "启用" }
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"220", fixed:'left'},
          { label: "姓名", width: 120, prop: "realname" },
          { label: "登陆账号", width: 110, prop: "loginName" },
          { label: "角色", width: 230,callBack:(item)=>{ let res = [];for (let c in item.roles) { res.push(item.roles[c]);} return  res.join(",")}},
          { label: "Email", width: 240, prop: "email" },
          { label: "更新时间", width: 150, prop: "created" },
          { label: "状态", minWidth: 80,callBack:(item)=>{ return item.statusName},getColor:(item)=>{return item.status=='0'?'textdanger':'textsuccess'}}
        ]
      },
      showDetailPage:false,
      modeType:'',
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      isDetection:false,
      curItem:{},
      rules: {
        loginName: [
          { required: true, message: "请输入登录账号", trigger: "manual"},
          {pattern: /^1[3-9]\d{9}$/, message: '登录账号格式不正确,请输入11位的手机号码'}
        ],
        realname: [{ required: true, message: "请输入姓名", trigger: "manual"}],
        loginPwd: [{ required: true, message: "请输入密码", trigger: "manual"}],
        newPwd: [{ required: true, message: "请输入密码", trigger: "manual"}],
        email: [
          { required: true, message: "请输入Email", trigger: "manual"},
          {pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/, message: 'Email格式不正确'}
        ],
        roleId: [{ required: true, message: "请选择系统角色", trigger: "manual"}],
        status: [{ required: true, message: "请选择状态", trigger: "manual"}],
      },
      roleInfo:[],
    }
  },
  inject: ['$hasPermission'],
  computed: {
    userData(){
      return this.$store.state.user.userInfo
    },
    formItemList() {
      if(this.modeType=='add'||this.modeType=='edit'){
        return [
          {label:"登录账号",type: "input", value: "loginName",defaultValue:this.curItem.loginName||'', isDisabled:this.modeType=='edit',span:1,isBtn:this.modeType=='add',btnLabel:'检测账号'},
          {label:"姓名",type: "input", value: "realname",defaultValue:this.curItem.realname||'', span:1},
          {label:"密码",type: "input", value: "loginPwd",visible: this.modeType=='add', defaultValue:this.curItem.loginPwd||'',span:1},
          {label:"确认密码",type: "input", value: "loginPwdConfrim",visible: this.modeType=='add', defaultValue:this.curItem.loginPwdConfrim||'',span:1},
          {label:"系统角色", type: "select", value: "roleId",defaultValue:this.curItem.roleId||[], selectOptions:this.roleInfo, multiple:true, keyValue:['roleCode','roleName'],span:1},
          {label:"Email",type: "input", value: "email",defaultValue:this.curItem.email||'', span:1},
          {label:"状态",type: "select", value: "status",defaultValue:this.curItem.status??'',selectOptions:[{Id:1,Name:'正常'},{Id:0,Name:'禁用'}] ,span:1,}
        ]
      }else if(this.modeType=='password'){
        return [
          {label:"登录账号",type: "input", value: "loginName",defaultValue:this.curItem.loginName||'', isDisabled:'true', span:1},
          {label:"姓名",type: "input", value: "realname",defaultValue:this.curItem.realname||'', span:1,isDisabled:'true'},
          {label:"密码",type: "input", value: "newPwd", span:1},
          {label:"确认密码",type: "input", value: "rePwd", span:1},
        ]
      }

    },
    searchItemList() {
      return [
        {label: "状态", type: "select", value: "state", selectOptions:this.ARR_stateData, multiple:false},
        {label: "角色", type: "select", value: "role", selectOptions:this.roleInfo, multiple:false, keyValue:['roleCode','roleName']},
        {type: "input", value: "kw",placeholder:'查询关键字'}
      ];
    },
    dynamicRules() {
      let rules = {};
      for (let item of this.formItemList) {
        if (item.visible!=false) {
          rules[item.value] = this.rules[item.value];
        }
      }
      return rules;
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$http.post(this.baseApiUrl+'/getroleskv', {code:'client_ysc_platform'}).then((res)=>{
        this.roleInfo = res.data;
      });
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/getuserdata', {...this.searchData,...this.pageinfo,appCode:'client_ysc_platform'}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    changeform(d){
      if(d.btnclick){
        if( /^1[3-9]\d{9}$/.test(d.loginName)){
          this.$http.post('/ajax/LoadExitUser',{loginName:d.loginName}).then((res)=>{
            if(res.data){
              this.isDetection=true;
              this.curItem={...d,...res.data,loginPwdConfrim:res.data.loginPwd,loginName:d.loginName,roleId:res.data.roles};
            }else{
              this.curItem={loginName:d.loginName};
            }
          },(res)=>{this.showTipMessage(res.msg||'未知错误','error');});
        }else{
          this.showTipMessage('请输入11位的手机号码作为登录账号','warning');
        }
      }
    },
    //
    showDetail(d,type){
      this.curItem=d;
      let roleId=[];
      for(let i in (d.roles||{})){roleId.push(i)}
      this.curItem.roleId=roleId;
      this.modeType=type;
      if(this.modeType=='del'){
        if(this.userData.loginName==d.loginName){
          this.showTipMessage("年轻人，莫要想不开啊！",'warning');
          return;
        }
        this.showConfirm(`确认删除【${d.loginName}】账号吗`,()=>{
          this.canEdit=true;
          this.$http.post(this.baseApiUrl + '/ajax/LoadExitUser',{loginName:d.loginName}).then(()=>{
            this.showTipMessage('删除成功','success');
            this.canEdit=false;
            this.search();
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
        })
      }else{
        this.showDetailPage=true;
      }
    },
    // 新增&编辑&修改密码
    async onSubmit(validate) {
      const formData = await validate();let postData={},url='';
      if(!this.isDetection&&this.modeType=='add'){
        this.showTipMessage('请先检测账号','warning');
        return;
      }
      if(formData.loginPwd!=formData.loginPwdConfrim&&this.modeType=='add'){
        this.showTipMessage('两次密码输入不一致，请重新输入','warning');
        return;
      }
      if(formData.newPwd!=formData.rePwd&&this.modeType=='password'){
        this.showTipMessage('两次密码输入不一致，请重新输入','warning');
        return;
      }
      if(formData.loginName!=this.curItem.loginName&&this.modeType=='add'){
        this.showTipMessage('登录账号有改变，请重新点击【检测账号】按钮检测账号','warning');
        return;
      }
      postData=this.modeType=='password'?{newPwd:formData.newPwd,rePwd:formData.rePwd,loginName:this.curItem.loginName,id:this.curItem.id}:{...formData,loginName:this.curItem.loginName,mode:this.modeType,roleId:formData.roleId.join(','),isApp:''};
      url=this.modeType=='password'?'/setuserpassword':'/saveuser';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,postData).then(()=>{
        this.showTipMessage('操作成功!','success');
        if(this.modeType=='add') this.isDetection=false;
        this.canEdit=false;
        this.showDetailPage=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
  }
}
</script>

<template>
  <div class="content_box">
      <div class="search_bar">
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
              <template v-slot:operation="actionBtn">
                  <el-button type="warning" plain size="small" @click="audit(actionBtn.data)">审核</el-button>
              </template>
          </mainTable>
      </div>
  </div>

  <el-dialog v-model="showAudit" title="运营商注册申请审核" width="100%" style="margin: 0;">
      <p style="font-size: 18px;font-weight: bold;border-bottom: 1px solid #CDD6DC;">基础信息</p>
      <el-descriptions :column="2" size="small" border>
          <el-descriptions-item label="公司全称" :span="2">{{auditRow.jsonData.operatorName}}</el-descriptions-item>
          <el-descriptions-item label="合同日期" :span="2">{{auditRow.jsonData.contractDate}}</el-descriptions-item>
          <el-descriptions-item label="法人代表姓名">{{auditRow.jsonData.legalPersonName}}</el-descriptions-item>
          <el-descriptions-item label="法人身份证号">{{auditRow.jsonData.legalPersonId}}</el-descriptions-item>
          <el-descriptions-item label="公司通讯地址">{{auditRow.jsonData.addr}}</el-descriptions-item>
          <el-descriptions-item label="通讯邮箱">{{auditRow.jsonData.contactEmail}}</el-descriptions-item>
          <el-descriptions-item label="税务资质" :span="2">
              <el-radio-group v-model="auditRow.jsonData.taxQualification" disabled size="small">
                  <el-radio :label="0">一般纳税人</el-radio>
                  <el-radio :label="1">小规模纳税人</el-radio>
                  <el-radio :label="2">个体工商户</el-radio>
              </el-radio-group>
          </el-descriptions-item>
          <el-descriptions-item label="运营商类型" :span="2">
              <el-radio-group v-model="auditRow.jsonData.taxQualification" disabled size="small">
                  <el-radio :label="0">一般纳税人</el-radio>
                  <el-radio :label="1">小规模纳税人</el-radio>
                  <el-radio :label="2">个体工商户</el-radio>
              </el-radio-group>
          </el-descriptions-item>
          <el-descriptions-item label="在职工人数" :span="2">
              <el-radio-group v-model="auditRow.jsonData.workforce" disabled size="small">
                  <el-radio :label="'少于50人'">少于50人</el-radio>
                  <el-radio :label="'50-150人'">50-150人</el-radio>
                  <el-radio :label="'150-500人'">150-500人</el-radio>
                  <el-radio :label="'500人以上'">500人以上</el-radio>
              </el-radio-group>
          </el-descriptions-item>
          <el-descriptions-item label="业务联系人姓名">{{auditRow.jsonData.contactPerson}}</el-descriptions-item>
          <el-descriptions-item label="业务联系人电话">{{auditRow.jsonData.contactPhone}}</el-descriptions-item>
      </el-descriptions>

      <p style="font-size: 18px;font-weight: bold;border-bottom: 1px solid #CDD6DC;">来往账款银行账号</p>
      <el-descriptions :column="2" size="small" border>
          <el-descriptions-item label="收付款账户名称">{{auditRow.jsonData.accountNameFull}}</el-descriptions-item>
          <el-descriptions-item label="开户银行">{{auditRow.jsonData.bank}}</el-descriptions-item>
          <el-descriptions-item label="收付款账户" :span="2">{{auditRow.jsonData.cardNumber}}</el-descriptions-item>
          <el-descriptions-item label="财务联系人">{{auditRow.jsonData.finContactPerson}}</el-descriptions-item>
          <el-descriptions-item label="财务联系人电话">{{auditRow.jsonData.finContactPhone}}</el-descriptions-item>
          <el-descriptions-item label="支付联行号" :span="2">{{auditRow.jsonData.bankUniqueNo}}</el-descriptions-item>
      </el-descriptions>

      <p style="font-size: 18px;font-weight: bold;border-bottom: 1px solid #CDD6DC;">开户信息</p>
      <el-descriptions :column="2" size="small" border>
          <el-descriptions-item label="开票抬头">{{auditRow.jsonData.invoiceTitle}}</el-descriptions-item>
          <el-descriptions-item label="税号">{{auditRow.jsonData.socialCreditCode}}</el-descriptions-item>
          <el-descriptions-item label="单位地址">{{auditRow.jsonData.invoiceAddr}}</el-descriptions-item>
          <el-descriptions-item label="电话号码">{{auditRow.jsonData.invoicePhone}}</el-descriptions-item>
          <el-descriptions-item label="开户银行">{{auditRow.jsonData.invoiceBank}}</el-descriptions-item>
          <el-descriptions-item label="银行账户">{{auditRow.jsonData.invoiceAccount}}</el-descriptions-item>
      </el-descriptions>

      <p style="font-size: 18px;font-weight: bold;border-bottom: 1px solid #CDD6DC;">合作信息</p>
      <el-descriptions :column="1" size="small" border>
          <el-descriptions-item label="合作产品类别">
              <el-checkbox-group v-model="auditRow.jsonData.productType" disabled >
                  <el-checkbox label="粮油副食" />
                  <el-checkbox label="调味品" />
                  <el-checkbox label="奶制品" />
                  <el-checkbox label="干货" />
                  <el-checkbox label="肉类" />
                  <el-checkbox label="茶水饮料" />
                  <el-checkbox label="蔬果生鲜" />
                  <el-checkbox label="烟酒" />
                  <el-checkbox label="日用百货" />
                  <el-checkbox label="其他" />
              </el-checkbox-group>
          </el-descriptions-item>
          <el-descriptions-item label="开票种类">
              <el-radio-group v-model="auditRow.jsonData.invoiceType" disabled size="small">
                  <el-radio :label="0">普票</el-radio>
                  <el-radio :label="1">专票</el-radio>
              </el-radio-group>
          </el-descriptions-item>
      </el-descriptions>

      <p style="font-size: 18px;font-weight: bold;border-bottom: 1px solid #CDD6DC;">开通功能</p>
      <el-descriptions :column="1" size="small" border>
          <el-descriptions-item label="开通功能">
              <el-checkbox-group v-model="auditRow.jsonData.openFunction" disabled>
                  <el-checkbox label="城批" :checked="auditRow.jsonData.isCityWholesale == 1"/>
                  <el-checkbox label="配销" :checked="auditRow.jsonData.isArea == 1" />
                  <el-checkbox label="商城" :checked="auditRow.jsonData.isDistribution == 1" />
                  <el-checkbox label="代存" :checked="auditRow.jsonData.isLeaveWith == 1" />
                  <el-checkbox label="精简代存" :checked="auditRow.jsonData.isStoredCompact == 1" />
                  <el-checkbox label="资金账户" :checked="auditRow.jsonData.isFundAccount == 1" />
              </el-checkbox-group>
          </el-descriptions-item>
      </el-descriptions>

      <p style="font-size: 18px;font-weight: bold;border-bottom: 1px solid #CDD6DC;">相关资质</p>
      <el-descriptions :column="2" size="small" border v-for="(item,i) in auditRow.jsonData.attachment" :key="i">
          <el-descriptions-item label="文件名" class-name="filename">
              <el-image style="height: 40px;margin-right: 20px;"
                  :src="item.imgUrl"
                  :zoom-rate="1.2"
                  :preview-src-list="[item.imgUrl]"
              />
              <span>{{item.imgName}}</span>
          </el-descriptions-item>
          <el-descriptions-item label="大小">{{item.imgSize}}</el-descriptions-item>
      </el-descriptions>

      <div style="text-align: center;padding: 20px;">
          <el-button type="danger"  @click="disagreeDialog = true" :disabled="auditRow.auditState == '已拒绝'">拒绝</el-button>
          <el-button type="primary"  @click="onSubmit(true)">同意</el-button>
      </div>
  </el-dialog>

  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo" @closedialog="disagreeDialog=false" @submitform="submitForm"></rejectPopup>
</template>
<style>
    .filename{display: flex;align-items: center;}
</style>

<script>
import {GetFileSize} from '@/utils/common'
export default {
    data() {
        const dateBegin = this.$formatDate(0, -7);
        const dateEnd =this.$formatDate(0);
        const STATUS = [
            { Code: "未审核", Name: "未审核" },
            { Code: "已通过", Name: "已通过" },
            { Code: "已拒绝", Name: "已拒绝" },
        ];
        return {
            baseApiUrl: '/operator',
            searchData: {},
            searchItemList: [
                { label: "创建时间:", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
                { label: "审核状态：",placeholder: '请选择状态', type: "select", value: "applyState", selectOptions: STATUS,defaultValue:['未审核','已通过','已拒绝'], width:160, multiple: true, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询关键字' }
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
                    { label: "登录账户", width: 100, prop: "loginName" },
                    { label: "用户名", width: 200, prop: "realName" },
                    {
                        label: "邮箱", width: 180, prop: "email", callBack: (item)=>{
                            return decodeURIComponent(item.email);
                        }
                    },
                    { label: "审核状态", width: 80, prop: "auditState" },
                    { label: "审核人", width: 80, prop: "auditedBy" },
                    { label: "审核时间", width: 180, prop: "auditedTime" },
                    { label: "审核备注", width: 200, prop: "auditRemark" },
                    { label: "创建时间", width: 180, prop: "createdTime" },
                    { label: "创建人", minWidth: 100, prop: "createdBy" }
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
            // showAudit_dialog
            auditRow:{},
            showAudit:false,
            // disagree_dialog
            disagreeDialog:false,
            rejectInfo:{title:'未通过的原因',label:'理由',value:'auditRemark'},
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/OperatorFilingApplyInfo', { ...this.searchData, ...this.pageinfo}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        audit(row){
            if (row.auditState == "已通过") {
                return this.showTipMessage("该记录已通过，不可重复审核！")
            }
            this.auditRow = JSON.parse(JSON.stringify(row))
            this.handleTemp()
            this.showAudit = true
        },
        handleTemp(){
            let tempObj = this.auditRow.jsonData
            //基础信息
            this.auditRow.jsonData.contractDate = this.$formatDate(tempObj.contractBegin)+' -- '+this.$formatDate(tempObj.contractEnd)
            this.auditRow.jsonData.contactEmail = decodeURIComponent(tempObj.contactEmail)
            //合作信息
            this.auditRow.jsonData.productType = tempObj.productType.split(',')
            //相关资质
            this.auditRow.jsonData.attachment = tempObj.attachment.map(item=>{
                item.imgUrl = item.url
                item.imgSize = GetFileSize(item.size)
                return item
            })
        },
        onSubmit(isAgree,postData={}){
            if (isAgree) {
                postData = {
                    id: this.auditRow.id,
                    isAgree: isAgree
                }
            } else {
                postData = {...postData,isAgree: isAgree}
            }
            this.$http.post(this.baseApiUrl + "/OperatorFilingApplyUpState", postData).then((res)=>{
                if (res.succ) {
                    this.showTipMessage(res.msg?res.msg+"。操作成功":'操作成功', 'success')
                    this.showAudit = false;
                    this.getListData();
                }else{
                    this.showTipMessage(res.msg)
                }
                this.disagreeDialog = false;
            })
        },
        submitForm(reasonObj){
            let postData={
                id:this.auditRow.id,
                auditRemark:reasonObj.auditRemark,
            }
            this.onSubmit(false,postData)
        },
    }
}
</script>

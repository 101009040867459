<template>
  <div class="content_box">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
              <template v-slot:operation="actionBtn">
                  <el-button type="warning" plain size="small" @click="audit(actionBtn.data)">审核</el-button>
              </template>
          </mainTable>
      </div>
  </div>

  <el-dialog v-model="showAudit" :title="auditDialogTitle" width="500">
      <el-descriptions :column="1" size="small" border>
          <el-descriptions-item label="凭证:" label-align="center" v-if="auditRow.applyType == '充值'">
              <el-image style="height: 50px"
                  :src="auditRow.imgUrl"
                  :zoom-rate="1.2"
                  :preview-src-list="[auditRow.imgUrl]"
              />
          </el-descriptions-item>
          <el-descriptions-item label-align="center" label="金额:">{{auditRow.amount}}元</el-descriptions-item>
      </el-descriptions>

      <div style="text-align: right;padding: 10px 0;">
          <el-button type="primary"  @click="submit">提交</el-button>
      </div>
  </el-dialog>
</template>
<script>

export default {
    data() {
        const dateBegin = this.$formatDate(0, -3);
        const dateEnd = this.$formatDate(0);
        const STATUS = [
            { Code: "0", Name: "未审核" },
            { Code: "1", Name: "已审核" },
            { Code: "2", Name: "处理中" },
            { Code: "3", Name: "到账成功" },
            { Code: "4", Name: "处理失败" },
        ];
        const OPERATION = [
            { Code: "充值", Name: "充值" },
            { Code: "取现", Name: "取现" }
        ];
        return {
            baseApiUrl: '/finance',
            searchData: {},
            searchItemList: [
                { label: "申请日期:", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
                { label: "",placeholder: '请选择状态', type: "select", value: "applyState", selectOptions: STATUS,width:160, multiple: true, keyValue: ['Code', 'Name'] },
                { label: "", placeholder: '请选择操作',type: "select", value: "applyType", selectOptions: OPERATION, multiple: true,width:160, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询运营商编码/名称' }
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
                    { label: "申请单号", width: 180, prop: "applyNo" },
                    { label: "运营商编号", width: 100, prop: "operatorCode" },
                    { label: "运营商名称", width: 200, prop: "operatorName" },
                    { label: "操作", width: 70, prop: "applyType" },
                    { label: "金额", width: 80, prop: "amount" },
                    { label: "状态", width: 80, prop: "applyStateName" },
                    { label: "申请人", width: 80, prop: "createdBy" },
                    { label: "申请时间", width: 150, prop: "createdTime" },
                    { label: "审核人", width: 70, prop: "auditedBy" },
                    { label: "审核时间", minWidth: 150, prop: "auditedTime" }
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
            // showAudit_dialog
            auditRow:{},
            showAudit:false,
            auditDialogTitle:''
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/OperatorFundsApplyList', { ...this.searchData, ...this.pageinfo,...{isExcel:false} }).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData,...{isExcel:true}}, this.baseApiUrl+'/OperatorFundsApplyList');
        },
        audit(row){
            if (row.applyState !== 0) {
                return this.showTipMessage("该记录已审核！", 'warning')
            }
            this.auditRow = row
            this.auditDialogTitle = row.operatorName + row.applyType + "申请"
            this.showAudit = true
        },
        submit(){
            const postData = {
                id:this.auditRow.id
            }
            this.$http.post(this.baseApiUrl + "/OperatorFundsApplyCheck", postData).then((res)=>{
                this.showTipMessage("审核成功！", 'success');
                this.showAudit = false;
                this.getListData();
            })
        }
    }
}
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success" size="small" @click="exportData">导出</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.RefundState != 2?'info':'warning'" plain size="small" @click="submitItem(actionBtn.data)" :disabled="actionBtn.data.RefundState != 2">财务审核
          </el-button>
          <el-button :type="actionBtn.data.RefundState != 2?'info':'primary'" plain size="small" @click="modifyQty(actionBtn.data)" :disabled="actionBtn.data.RefundState != 2">修改数量
          </el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showEdit" :title="title" width="660" >
    <el-descriptions :column="1" :border="true">
      <el-descriptions-item>
        <template #label>
          关联单号
        </template>
        {{ editItem.LinkNo }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          商品名称
        </template>
        {{ editItem.ProductNamePromotion }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          修改前数量
        </template>
        {{ editItem.UnitQty }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          源订单数量
        </template>
        {{ editItem.SourceUnitQty || '--' }}
      </el-descriptions-item>
    </el-descriptions>
    <br>
    <el-form ref="editForm" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="修改数量" prop="qty">
        <el-input-number v-model="form.qty" :size="size" :min="0" :max="editItem.SourceUnitQty"/>
      </el-form-item>
      <el-form-item label="修改原因" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入修改原因"/>
      </el-form-item>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
              <el-button type="primary" v-on:click="submitForm">提交</el-button>
              <el-button v-on:click="showEdit = false" type="danger">关闭</el-button>
          </span>
    </template>
  </el-dialog>
  <el-dialog v-model="showSubmitBox" :title="title"  width="80%" >
    <div>请核对一下退款明细：</div>
    <el-table :data="details" style="width: 100%" :max-height="400">
      <el-table-column prop="LinkNo" label="关联单号" min-width="20%"></el-table-column>
      <el-table-column prop="ProductName" label="商品名称" min-width="18%"></el-table-column>
      <el-table-column prop="UnitQty" label="数量" min-width="5%"></el-table-column>
      <el-table-column prop="RefundAmount" label="总金额" min-width="5%"></el-table-column>
      <el-table-column prop="RefundTypeName" label="退款类型" width="80px"></el-table-column>
      <el-table-column prop="RefundStateName" label="退款状态" width="80px"></el-table-column>
      <el-table-column prop="RefundReasonName" label="退款原因" width="80px"></el-table-column>
    </el-table>
    <template #footer>
          <span class="dialog-footer">
              <div class="refund-summary">退款总额：{{ refundTotal }} 元</div>
               <div style="text-align: center">
                  <el-button v-on:click="showSubmitBox = false" type="danger">关闭</el-button>
                  <el-button type="primary" v-on:click="saveSubmit">确认</el-button>
               </div>
          </span>
    </template>
  </el-dialog>
</template>
<script>
export default {
  data() {
    const ARR_RefundStatus = [
      {Id: "2", Name: "待审核"},
      {Id: "0", Name: "已提交"},
      {Id: "4", Name: "已审核"},
      {Id: "1", Name: "退款成功"},
      {Id: "3", Name: "退款失败"}
    ];
    const ARR_RefundType = [
      {Id: "0", Name: "仅退款"},
      {Id: "1", Name: "退货退款"}
    ];
    const ARR_RefundReason = [
      {Id: "0", Name: "分拣退货"},
      {Id: "1", Name: "取消订单"},
      {Id: "2", Name: "整单退货"},
      {Id: "3", Name: "产品原因"},
      {Id: "4", Name: "平台原因"},
      {Id: "5", Name: "其他退货"},
      {Id: "6", Name: "历史退货"}
    ];
    const today = new Date();
    const dateBegin = this.$formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: '/promotion',
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"180", fixed:'left'},
          {label: "客户名称", width: 160, prop: "TraderName"},
          {label: "订单号", width: 200, prop: "SourceOrderNo"},
          {label: "退单号", width: 160, prop: "LinkNo"},
          {label: "微信流水号", width: 160, prop: "TransactionNo"},
          {label: "退货商品", width: 160, prop: "ProductNamePromotion"},
          {label: "数量", width: 70, prop: "UnitQty"},
          {label: "单位", width: 60, prop: "Unit"},
          {label: "退货原因", width: 80, prop: "CausedByName"},
          {label: "退货金额", width: 90, prop: "RefundAmount"},
          {label: "运营商", width: 140, prop: "OperatorName"},
          {label: "状态", width: 70, prop: "RefundStateName"},
          {label: "更新时间", width: 140, prop: "UpdatedTime"},
          {label: "审核人", width: 140, prop: "AuditedBy"},
          {label: "备注", width: 160, prop: "Remark"},
          {label: "修改备注", width: 140, prop: "EditRemark"},
          {label: "失败备注", minWidth: 160, prop: "FailRemark"},
        ]
      },
      searchItemList: [
        {label: "日期", type: "daterange", value: "createtime", defaultValue: [dateBegin, dateEnd]},
        {label: "", placeholder: '状态',type: "select", value: "state", selectOptions:ARR_RefundStatus, defaultValue:['2'], multiple:true},
        {label: "", placeholder: '原因',type: "select", value: "reason", selectOptions:ARR_RefundReason, multiple:true},
        {label: "", placeholder: '类型',type: "select", value: "type", selectOptions:ARR_RefundType, multiple:false,width:100},
        {type: "input", value: "kw", placeholder: '查询关键字',width:140}
      ],
      title: "",
      saveMode: "",
      form: {},
      statusList: ARR_RefundStatus,
      refundTypeList: ARR_RefundType,
      reasonList: ARR_RefundReason,
      showEdit: false,
      dateRangeCreated: [dateBegin, dateEnd],
      selectedStatus: ["2"],
      selectedReason: [],
      rules: {
        remark: [{required: true, message: "退货原因不能为空", trigger: "blur"}]
      },
      editItem: {},
      details: [],
      showSubmitBox: false,
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  computed: {
    refundTotal() {
      var res = 0;
      this.details.forEach(it => {
        res += it.RefundAmount;
      });
      return res;
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/RefundDetailList', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    resetForm() {
      this.form = {id: 0};
    },
    modifyQty(d) {
      this.title = '修改数量';
      this.editItem = d;
      this.form = {id: d.Id, qty: d.UnitQty};
      this.showEdit = true;
    },
    submitForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.saveItem();
        } else {
          return false
        }
      });
    },
    saveItem() {
      this.$http.post(this.baseApiUrl + '/ModifyRefundDetailQty', this.form).then(() => {
        this.search();
        this.showEdit = false;
        this.showTipMessage("保存成功.", "success");
      });
    },
    submitItem(d) {
      let refundNo = d.RefundNo;
      this.editItem = d;
      this.$http.post(this.baseApiUrl + '/GetRefundDetailByNo', {refundNo}).then((res) => {
        this.title = '提交退款申请-' + refundNo;
        this.details = res.data || [];
        this.showSubmitBox = true;
      });
    },
    saveSubmit() {
      if (this.details.length == 0) {
        this.showTipMessage('该记录不支持提交')
        return;
      }
      this.$http.post(this.baseApiUrl + '/UpdateRefundDetailState', {refundNo: this.editItem.RefundNo}).then(() => {
        this.search();
        this.showTipMessage("提交成功.", "success");
        this.showSubmitBox = false;
      });
    },
    exportData() {
      let postData = {...this.searchData,_export:1,fAExport:true};
      this.postExportData( postData, this.baseApiUrl+'/RefundDetailList');
    }
  }
}
</script>
<style>
.refund-summary {
  color: red;
  padding: 10px 0px;
}
</style>

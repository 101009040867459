<template>
  <div class="content_box"  style="min-height: calc(100vh - 300px)">
    <el-descriptions size="small" :border="true" direction="vertical" :column="5">
      <el-descriptions-item label="订单编号">
        <el-tag size="small">{{ order.orderNo }}</el-tag>
        <el-button type="primary" size="small" @click="openOrderDetail()" style="margin-left:12px;">详情</el-button>
      </el-descriptions-item>
      <el-descriptions-item label="驿站名称">{{ order.warehouseName }}</el-descriptions-item>
      <el-descriptions-item label="客户名称">{{ order.traderName }}</el-descriptions-item>
      <el-descriptions-item label="订单金额">
        <el-tag size="small" type="danger">￥{{ orderMoney }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="退货原因">{{ order.remark }}</el-descriptions-item>
    </el-descriptions>
    <div class="search_bar">
      <el-button type="success" size="small" @click="addItem" v-if="canEdit">添加</el-button>
      <el-button type="danger" size="small" @click="delItem" v-if="canEdit" :disabled="!canDelete">删除</el-button>
    </div>
    <mainTable :mainOptions="mainData" :mainPage="pageinfo" ref="mainTable" @selectChange="afterChecked">
      <template v-slot:operation="actionBtn">
        <el-button type="primary" plain size="small" @click="editItem(actionBtn.data)" v-if="canEdit">编辑</el-button>
      </template>
    </mainTable>
  </div>
  <el-dialog v-model="showEdit" :title="'订单详情-'+form.orderNo" width="740px">
    <el-form ref="editForm" :model="form" size="small" label-width="120px" :rules="rules" style="margin:auto;">
      <el-row>
        <el-col :span="12">
          <el-form-item label="销售出库单号">
            <el-input v-model="form.sourceOrderNo" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="驿站名称">
            <el-input v-model="form.warehouseName" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户名称">
            <el-input v-model="form.traderName" :disabled="true"/>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
            <el-form-item label="客户地址">
                <el-input v-model="form.deliveryAddr" :disabled="true" />
            </el-form-item>
        </el-col> -->
        <el-col :span="12">
          <el-form-item label="客户渠道">
            <el-input v-model="form.clientTypeName" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="订单金额">
            <el-input v-model="form.orderAmount" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运费金额">
            <el-input v-model="form.shipPriceTotal" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="退货原因" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入退货原因"/>
      </el-form-item>
    </el-form>
    <template #footer>
    <span class="dialog-footer">
      <el-button type="primary" v-on:click="submitForm" v-if="canEdit">提交</el-button>
      <el-button v-on:click="showEdit = false">关闭</el-button>
    </span>
    </template>
  </el-dialog>
  <el-drawer v-model="addProSteps" direction="btt" size="75%" :show-close="false" :with-header="false">
    <template #default>
      <el-steps :active="addProSteps-1" finish-status="success" :simple="true" style="margin-bottom:10px;">
        <el-step title="选择商品"/>
        <el-step title="确认保存"/>
      </el-steps>
      <eltable-selector ref="proSelector" v-show="addProSteps==1" :options="selectOptionss" @selectItem="selectProduct"  source="returnhistoryedit"></eltable-selector>
      <el-row :gutter="10" v-if="addProSteps==2">
        <el-col :span="8">
          <el-descriptions :column="2" :border="true">
            <el-descriptions-item label="编 码" :span="2">{{ form.itemSerialCode }}</el-descriptions-item>
            <el-descriptions-item label="名 称" :span="2">{{ form.productName }}<el-tag size="small" type="danger" v-if="form.isGift">赠品</el-tag></el-descriptions-item>
            <el-descriptions-item label="商品编码" :span="2">{{ form.productCode }}</el-descriptions-item>
            <el-descriptions-item label="批次日期" :span="2">{{form.batch}}</el-descriptions-item>
            <el-descriptions-item label="促销促销编码" :span="2" v-if="form.promotionTaskCode">{{form.promotionTaskCode}}</el-descriptions-item>
            <el-descriptions-item label="促销类型" :span="2" v-if="form.promotionTaskCode">{{form.promotionTypeName}}</el-descriptions-item>
            <el-descriptions-item label="规 格">{{ form.spec }}</el-descriptions-item>
            <el-descriptions-item label="运费">{{ form.shipPrice }}</el-descriptions-item>
            <el-descriptions-item label="单价"><span class="textdanger">{{ form.unitPrice }}</span></el-descriptions-item>
            <el-descriptions-item label="出库数量"><span class="textdanger">{{ form.qty }}</span></el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="14">
          <el-divider content-position="left">退货数量</el-divider>
          <el-form ref="editProForm" :model="form" label-width="90px">
            <input-orderqty :unit-price="form.UnitPrice"
                            :total-qty="editTotalQty"
                            :pack-qty="form.packNum"
                            :pack-unit="form.packUnit"
                            :unit="form.unit"
                            :numMax="form.qty"
                            width="180px"
                            @update="onQtyUpdate" v-if="addProSteps==2"></input-orderqty>
            <el-form-item label="退货原因">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="addProSteps = 0">取消</el-button>
        <el-button type="primary" @click="saveProduct" v-if="addProSteps==2">保存商品</el-button>
      </div>
    </template>
  </el-drawer>

  <el-dialog v-model="showUpdatePro" :title="'修改商品-'+form.productName" width="740px">
    <el-descriptions size="small" :border="true" direction="vertical" :column="5">
      <el-descriptions-item label="条目编号">
        <el-tag size="small">{{ form.itemSerialCode }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="批次日期">{{ form.batch }}</el-descriptions-item>
      <el-descriptions-item label="原单价">{{ (form.unitPrice).toFixed(2) }}</el-descriptions-item>
      <el-descriptions-item label="原数量"><span class="textwarning">{{form.qty}}</span></el-descriptions-item>
      <el-descriptions-item label="出库数量"><span class="textdanger">{{outStockQty}}</span></el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">修改数量</el-divider>
    <el-form ref="updateForm" :model="form" label-width="80px" style="margin:auto;">
      <input-orderqty :total-qty="form.qty"
                      :pack-qty="form.packNum"
                      :pack-unit="form.packUnit"
                      :unit="form.unit"
                      @update="onQtyUpdate" v-if="showUpdatePro"></input-orderqty>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注"/>
      </el-form-item>
    </el-form>
    <template #footer>
    <span class="dialog-footer">
      <el-button @click="showUpdatePro = false">取消</el-button>
      <el-button type="primary" @click="updateProduct">确定修改</el-button>
    </span>
    </template>
  </el-dialog>
</template>
<script>
import {cloneObject,bulkOperateRecord,doFormValidate} from "@/utils/common";
export default {
  props: {
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    }
  },
  data() {
    const baseURL = '/sale';
    const saleDetailUrl="/report/GetPlatformWholesaleOrderDetail";
    return {
      baseApiUrl: baseURL,
      form: {},
      showEdit: false,
      showAddPro: false,
      canEdit: false,
      canDelete: false,
      isGridInited: false,
      showSelectPro: false,
      mainData: {
        DataList: [],
        isMultiple: true,
        labelList: [
          {label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "70", fixed: 'left'},
          {label: "商品编号", width: 130, prop: "itemSerialCode", fixed: 'left'},
          {label: "商品名称", prop: "productName", width: 280, fixed: 'left'},
          {label: "是否赠品",  width: 80, callBack: (item) => {return item.isGift?'是':'否'},getColor:(item)=>{return  item.isGift?'textsuccess':'textdanger'}},
          {label: "促销编码",  width: 160, callBack: (item) => {return item.promotionTaskCode||'无'}},
          {label: "促销类型",  width: 80, callBack: (item) => {return item.promotionTypeName||'无'}},
          {label: "单价", width: 80, callBack: (item) => {return item.bundleQty * item.unitPrice}},
          {label: "数量", width: 70, prop: "unitQty"},
          {label: "金额", width: 100, prop: "amount"},
          {label: '批次', width: 100,callBack: (item) => {return this.$formatDate(item.produceValue1)}},
          {label: "单位", width: 60, prop: "unit"},
          {label: "备注", minWidth: 120, prop: "remark"}
        ]
      },
      rules: {},
      order: {},
      details: [],
      addProSteps: 0,
      selectOptionss: {
        query: {},
        queryLocal: 'productName',
        tableHeight: 300,
        url:saleDetailUrl,
        labelList: [
          {label: "条目编码", width: 120, prop: "itemSerialCode", fixed: 'left'},
          {label: "商品编码", width: 140, prop: "productCode", fixed: 'left'},
          {label: "商品名称", prop: "productName"},
          {label: "是否赠品",  width: 80, callBack: (item) => {return item.isGift?'是':'否'},getColor:(item)=>{return  item.isGift?'textsuccess':'textdanger'}},
          {label: "促销编码",  width: 160, callBack: (item) => {return item.promotionTaskCode||'无'}},
          {label: "促销类型",  width: 80, callBack: (item) => {return item.promotionTypeName||'无'}},
          {label: "出库数", width: 90, prop: "unitQty"},
          {label: "单价", width: 100, prop: "unitPrice"},
          {label: "单位", width: 70, prop: "unit"},
          {label: "批次", width: 100, prop: "batch"}
        ]
      },
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      outStockQty:0,
      editTotalQty: 0,
      showUpdatePro: 0,
      outStockData: []
    }
  },
  computed: {
    orderMoney() {
      var res = 0;
      this.details.forEach(v => {
        res += v.amount;
      });
      return -res.toFixed(2);
    }
  },
  created() {
    this.loadOrder();
  },
  watch: {
    params(v) {
      let isChange = this.order.orderNo != v.orderNo || this.order.orderState != v.state;
      if (isChange) setTimeout(() => {
        this.loadOrder();
      }, 300);
    }
  },
  methods: {
    loadOrder() {
      this.$http.get(this.baseApiUrl + "/GetWholesaleReturnOrder", {params:{orderNo:this.params.orderNo}}).then((res) => {
        this.afterOrderLoaded(res.data)
      });
    },
    afterOrderLoaded(d) {
      d = d || {};
      this.order = d;
      // eslint-disable-next-line vue/no-mutating-props
      this.params.warehouseName = this.order.warehouseName;
      this.canEdit = d.orderState == 0;
      this.loadDetail();
      if (this.canEdit) {
        this.loadSaleDetail(d.sourceOrderNo);
      }
    },
    loadSaleDetail(orderNo) {
      this.$http.post(this.selectOptionss.url, {orderNo}).then((res) => {
        this.outStockData = res.data || [];
      });
    },
    loadDetail() {
      this.$http.get(this.baseApiUrl + "/GetWholesaleReturnOrderDetails", {params:{orderNo: this.params.orderNo}}).then((res) => {
        this.details = res.data || [];
        this.mainData.DataList=res.data.map(item => {
          item.batch=this.$formatDate(item.produceValue1);
          item.qty=item.unitQty;
          return item;
        });
      });
    },
    afterChecked(checkedList) {
      this.checkedList=checkedList;
      this.canDelete = this.checkedList.length > 0;
    },
    openOrderDetail() {
      this.showEdit = true;
      this.form = cloneObject(this.order);
    },
    submitForm() {
      doFormValidate(this, "editForm", () => this.saveItem());
    },
    saveItem() {
      let postData = {orderNo: this.form.orderNo, remark: this.form.remark};
      this.$http.post(this.baseApiUrl + '/WholesaleReturnEdit', postData).then(() => {
        this.showEdit = false;
        this.order.remark = this.form.remark;
        this.showTipMessage("保存成功.", "success");
      });
    },
    addItem() {
      this.title = '添加商品明细';
      this.addProSteps = 1;
      this.selectOptionss.query.orderNo=this.order.sourceOrderNo;
      if (this.$refs.proSelector)
      setTimeout(() => {
        this.$refs.proSelector.search();
      }, 200);
    },
    selectProduct(row) {
      if (this.details.find(v => v.itemSerialCode == row.itemSerialCode&&(this.$formatDate(v.produceValue1)||'').replace(/-/g, '') == row.batch)) {
        this.showTipMessage('当前订单明细已经添加此记录');
        return;
      }
      row.qty=row.unitQty;
      row.batch=this.$formatDate(row.produceValue1)
      delete row.unitQty;
      this.form = cloneObject(row);
      this.form.remark = '';
      this.editTotalQty=0;
      this.addProSteps = 2;
    },
    saveProduct() {
      var d = this.getData4Post();
      if (!d) return;
      if (this.editTotalQty > this.form.qty) {
        this.showTipMessage('退货数量不能超过出库数量');
        return;
      }
      var postData = [d];
      this.$http({headers: {'Content-Type': ' application/json'},
        method:'post',
        url:this.baseApiUrl + '/WholesaleReturnAddDetail',
        data: postData
      }).then(() => {
        this.showTipMessage("商品保存成功.", "success");
        this.$nextTick(() => {
          this.addProSteps = 0;
          this.loadDetail();
        });
      }, null, null, 1);
    },
    editItem(d) {
      this.setEditForm(d)
    },
    setEditForm(d) {
      this.form = cloneObject(d);
      this.showUpdatePro = true;
      this.editTotalQty=this.form.qty
      let obj=this.outStockData.find(item=>item.itemSerialCode==this.form.itemSerialCode&&(this.$formatDate(d.produceValue1)||'').replace(/-/g, '')==item.batch);
      this.outStockQty = obj.unitQty;
    },
    updateProduct() {
      var d = this.getData4Post();
      if (!d) return;
      if (this.editTotalQty > this.outStockQty) {
        this.showTipMessage('退货数量不能超过出库数量');
        return;
      }
      this.$http.post(this.baseApiUrl + '/WholesaleReturnUpdateDetail', d).then(() => {
        this.showTipMessage("商品保存成功.", "success");
        this.$nextTick(() => {
          this.showUpdatePro = false;
          this.loadDetail();
        });
      });
    },
    getData4Post() {
      if (this.form.remark.length == 0) {
        this.showTipMessage('请填写退货原因');
        return;
      }
      if (this.editTotalQty <= 0) {
        this.showTipMessage('请填写退货数量');
        return;
      }
      return {
        id: this.form.id,
        itemSerialCode: this.form.itemSerialCode,
        productCode: this.form.productCode,
        orderNo: this.params.orderNo,
        qty: this.editTotalQty,
        remark: this.form.remark
      }
    },
    delItem() {
      bulkOperateRecord(this.baseApiUrl + '/WholesaleReturnDeleteDetail', this.checkedList, 'delete', 'id', 'ids', () => {
        this.CheckedList=[];
        this.loadDetail();
      }, {orderNo: this.order.orderNo});
    },
    onQtyUpdate(d) {
      this.editTotalQty = d.totalQty;
    }
  }
}
</script>

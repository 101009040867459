<template>
  <div class="content_box">
      <div class="search_bar">
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>
    export default {
        data() {
            const dateBegin = this.$formatDate(0, -360);
            const dateEnd = this.$formatDate(0);
            const orderType = [
                { Code: "0", Name: "未审核" },
                { Code: "1", Name: "已审核" }
            ];
            return {
                baseApiUrl: '/finance',
                searchData: {},
                searchItemList: [
                    { label: "日期:", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
                    { label: "单据类型:", type: "select", value: "receiptState", selectOptions: orderType, multiple: true, keyValue: ['Code', 'Name'] },
                    { type: "input", value: "kw", placeholder: '收款账户/付款方名称' }
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { label: "单据编号", width: 180, prop: "receiptNo" },
                        { label: "收款账户", width: 85, prop: "selfAccountName" },
                        { label: "收款账户卡号", width: 150, prop: "selfCardNumber" },
                        { label: "收款单状态", width: 80, prop: "receiptStateName" },
                        { label: "收款金额", width: 80, prop: "amount" },
                        { label: "已用核销金额", width: 100, prop: "writeOffAmount" },
                        { label: "可用核销金额", width: 100, prop: "unwriteOffAmount" },
                        { label: "待核销审核金额", width: 110, prop: "pendingWriteOff" },
                        { label: "付款方编码", width: 210, prop: "traderCode" },
                        { label: "付款方名称", width: 180, prop: "traderName" },
                        { label: "付款账户名", width: 100, prop: "accountNameFull" },
                        { label: "付款账户开户行", width: 180, prop: "bank" },
                        { label: "付款账户名", width: 100, prop: "cardNumber" },
                        { label: "制单人", width: 80, prop: "createdBy" },
                        { label: "制单时间", width: 150, prop: "createdTime" },
                        { label: "审核人", width: 80, prop: "auditedBy" },
                        { label: "审核时间", width: 150, prop: "auditedTime" },
                        { label: "摘要", width: 150, prop: "remark" },
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },
            }
        },
        methods: {
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.$http.post(this.baseApiUrl + '/ReceiptList', { ...this.searchData, ...this.pageinfo,...{moduleType: 1}}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
        }
    }
</script>

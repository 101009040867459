<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button-group size="small" class="btn-group">
        <el-button type="success" v-on:click="addItem">创建订单</el-button>
        <el-button type="primary" v-on:click="auditItem" :disabled="!canAudit">审核</el-button>
        <el-button type="warning" v-on:click="disauditItem" :disabled="!canCancel">取消审核</el-button>
        <el-button type="danger" v-on:click="delItem" :disabled="!canDelete">删除</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable" @selectChange="afterChecked">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.orderState==0?'primary':'success'" plain size="small"
                     @click="editItem(actionBtn.data)">{{ actionBtn.data.orderState == 0 ? '编辑' : '查看' }}
          </el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showEdit" :title="title" width="85%">
    <eltable-selector v-show="gridSelectType==1" :options="OrderOptions" @selectItem="selectOrder"></eltable-selector>
    <el-form ref="editForm" :model="form" size="small" label-width="120px" :rules="rules" v-show="!gridSelectType">
      <el-row>
        <el-col :span="12">
          <el-form-item label="销售出库单号">
            <el-input v-model="form.orderNo" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="驿站名称">
            <el-input v-model="form.warehouseName" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户名称">
            <el-input v-model="form.traderName" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户地址">
            <el-input v-model="form.deliveryAddr" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户渠道">
            <el-input v-model="form.clientTypeName" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="订单金额">
            <el-input v-model="form.orderAmount" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运费金额">
            <el-input v-model="form.shipPriceTotal" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="退货原因" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入退货原因"/>
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer" v-if="!gridSelectType">
            <el-button type="warning" @click="gridSelectType = 1">上一步</el-button>
            <el-button type="primary" v-on:click="submitForm">提交</el-button>
            <el-button v-on:click="showEdit = false">关闭</el-button>
        </span>
    </template>
  </el-dialog>
  <el-dialog v-model="showDetailPage" :fullscreen="true" :show-close="false" class="fullDialog">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <h3 :id="titleId" :class="titleClass">{{title}}</h3>
        <el-button type="danger" @click="close" size="small"><el-icon class="el-icon--left"><CircleCloseFilled /></el-icon>关闭</el-button>
      </div>
    </template>
    <wholesale-returnhistoryedit ref="editPage" :params="editParams"></wholesale-returnhistoryedit>
  </el-dialog>
</template>
<script>
import {getMappingName,cloneObject,bulkOperateRecord} from "@/utils/common";
import wholesaleReturnhistoryedit from "@/views/wholesale/wholesale-returnhistoryedit";
export default {
  components: {
    wholesaleReturnhistoryedit,
  },
  data() {
    const baseURL = '/sale';
    const reportURL = '/report';
    const ARR_OrderStatus = [
      {Id: 0, Name: "未审核"},
      {Id: 1, Name: "已审核"},
      {Id: 2, Name: "处理中"},
      {Id: 3, Name: "未处理"},
      {Id: 4, Name: "处理超时"},
      {Id: 5, Name: "已处理"},
      {Id: 6, Name: "已关闭"}
    ];
    const ARR_PayType = [
      {Id: 0, Name: "货到付款"},
      {Id: 1, Name: "微信支付"}
    ];
    const dateBegin = this.$formatDate(0, -7);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: baseURL,
      reportApiUrl: reportURL,
      title: "",
      saveMode: "",
      form: {},
      mainData: {
        DataList: [],
        Total: -1,
        isMultiple: true,
        labelList: [
          {label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "70", fixed: 'left'},
          {label: "订单号", width: 180, prop: "orderNo", fixed: 'left'},
          {label: "源单号", width: 180, prop: "sourceOrderNo"},
          {
            label: "驿站仓", width: 100, callBack: (item) => {
              return getMappingName(item.warehouseCode, this.warehouseList, 'Code', 'Name')
            }
          },
          {label: "金额", width: 80, prop: "orderAmount"},
          {
            label: "订单状态", width: 100, callBack: (item) => {
              return getMappingName(item.orderState, ARR_OrderStatus)
            }
          },
          {label: "客户名称", width: 160, prop: "traderName"},
          {label: "联系人电话", width: 150, prop: "phone"},
          {label: "备注", width: 100, prop: "remark"},
          {label: "制单人", width: 100, prop: "createdBy"},
          {label: "下单时间", width: 150, prop: "createdTime"},
          {label: "客户渠道", width: 100, prop: "clientTypeName"},
          {
            label: "支付方式", width: 100, callBack: (item) => {
              return getMappingName(item.payOffType, ARR_PayType)
            }
          },
          {label: "业务员名称", width: 100, prop: "salerName"},
          {label: "审核人", width: 100, prop: "auditedBy"},
          {label: "审核时间", width: 150, prop: "auditedTime"}
        ]
      },
      OrderOptions: {
        query: {orderType: 20},
        isMultiple: false,
        tableHeight: 400,
        url: reportURL + "/PlatformWholesaleOrderList",
        labelList: [
          {label: "订单编码", width: 200, prop: "orderNo"},
          {label: "仓库名称", width: 150, prop: "warehouseName"},
          {label: "客户名称", width: 200, prop: "traderName"},
          {label: "金额", width: 80, prop: "orderAmount"},
          {label: "总运费", width: 80, prop: "shipPriceTotal"},
          {label: "明细数量", width: 80, prop: "detailCount"},
          {label: "状态", width: 60, key: "orderStateName"},
          {label: "订单类型", minWidth: 100, prop: "orderTypeName"},
          {label: "订单来源", width: 80, callBack: (item) => {return item.orderSourceType == 0 ? "城批" : "驿站"}},
          {label: "销售类型", width: 80, callBack: (item) => {return item.saleType == 0 ? "采购" : "代配送"}},
          {label: "业务员", width: 90, prop: "salerName"}
        ]
      },
      statusList: ARR_OrderStatus,
      paytypeList: ARR_PayType,
      warehouseList: [],
      checkedList:[],
      showEdit: false,
      dateRangeCreated: [dateBegin, dateEnd],
      selectedStatus: [0, 1],
      gridSelectType: 0,
      showDetailPage: false,
      canAudit: false,
      canDelete: false,
      canCancel: false,
      rules: {
        remark: [{required: true, message: "退货原因不能为空", trigger: "blur"}]
      },
      editParams: {},
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  watch: {
    dateRangeCreated(arr) {
      this.query.dateFrom = arr[0];
      this.query.dateTo = arr[1];
    },
    selectedStatus(arr) {
      this.query.orderState = arr.join(',');
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {
          label: "日期",
          type: "daterange",
          value: "createtime",
          defaultValue: [this.dateRangeCreated[0], this.dateRangeCreated[1]]
        },
        {
          label: "",
          placeholder: '状态',
          type: "select",
          value: "orderState",
          selectOptions: this.statusList,
          defaultValue: [0, 1],
          multiple: true,
          width: 160
        },
        {
          label: "",
          placeholder: '仓库',
          type: "select",
          value: "warehouseCode",
          selectOptions: this.warehouseList,
          width: 160,
          multiple: false,
          keyValue: ['Code', 'Name']
        },
        {type: "input", value: "kw", placeholder: '查询关键字', width: 140}
      ];
    }
  },
  created() {
    this.loadWarehouse();
  },
  methods: {
    loadWarehouse() {
      this.$http.post('/ajax/DCLoad', {t: 'CommonWarehouseList'}).then((res) => {
        this.warehouseList = res.data || [];
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/QueryWholesaleReturnData', {
        ...this.searchData, ...this.pageinfo,
        orderType: 191
      }).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    afterChecked(checkedList) {
      this.checkedList=checkedList;
      this.canDelete = this.canAudit = this.canCancel = checkedList.length > 0;
      checkedList.forEach(v => {
        if (v.orderState == 0) this.canCancel = false;
        if (v.orderState == 1) this.canAudit = this.canDelete = false;
        else if (v.detailCount == 0) this.canAudit = false;
      });
    },
    resetForm() {
      this.form = {id: 0};
    },
    addItem() {
      this.resetForm();
      this.title = '添加历史退货';
      this.saveMode = 'Add';
      this.showEdit = true;
      this.gridSelectType = 1;
    },
    selectOrder(d) {
      this.form = cloneObject(d);
      this.gridSelectType = 0;
    },
    editItem(d) {
      this.showDetail(d.orderNo, d.warehouseCode, d.orderState);
    },
    submitForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.saveItem();
        } else {
          return false
        }
      });
    },
    saveItem() {
      let isNew = this.saveMode == 'Add',
          act = isNew ? '/WholesaleReturnAdd' : '/WholesaleReturnEdit',
          postData = {remark: this.form.remark};
      if (isNew) {
        postData.sourceOrderNo = this.form.orderNo;
      } else {
        postData.orderNo = this.form.orderNo;
      }
      this.$http.post(this.baseApiUrl + act, postData).then((res) => {
        this.search();
        this.showEdit = false;
        this.showTipMessage("保存成功.", "success");
        this.$nextTick(() => {
          this.showDetail(res.data, this.form.warehouseCode);
        });
      });
    },
    showDetail(orderNo, warehouseCode, state) {
      let warehouseName = getMappingName(warehouseCode, this.warehouseList, 'key', 'name');
      this.showDetailPage = true;
      this.title = '编辑历史退货';
      state = state || 0;
      this.editParams = {orderNo, warehouseName, state};
    },
    delItem() {
      bulkOperateRecord(this.baseApiUrl + '/WholesaleReturnDelete', this.checkedList, 'delete', 'orderNo', 'orderNos', () => {
        this.checkedList=[];
        this.search();
      }, {});
    },
    auditItem() {
      bulkOperateRecord(this.baseApiUrl + '/WholesaleReturnUpdateState', this.checkedList, 'check', 'orderNo', 'orderNos', () => {
        this.checkedList=[];
        this.search();
      }, {state: 1});
    },
    disauditItem() {
      bulkOperateRecord(this.baseApiUrl + '/WholesaleReturnUpdateState', this.checkedList, 'uncheck', 'orderNo', 'orderNos', () => {
        this.checkedList=[];
        this.search();
      }, {state: 0});
    }
  }
}
</script>

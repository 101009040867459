<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary"  plain size="small" @click="submitItem(actionBtn.data)" >查看明细</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="订单明细" width="70%" style="padding-bottom:20px;">
    <div style="margin:20px 0">
      合计金额: <span class="textprimary textspan mr30">{{curItem.fullAmount.toFixed(2)}}元</span>
      合计货款: <span class="textprimary textspan mr30">{{curItem.fullGoodsAmount.toFixed(2)}}元</span>
      合计运费: <span class="textprimary textspan">{{curItem.fullFreight.toFixed(2)}}元</span>
    </div>
    <mainTable :mainOptions="detailData" :mainPage="deatilPageInfo"  @getPageCurrent="getDetailPageCurrent" ref="detailTable"></mainTable>
  </el-dialog>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default {
  data() {
    const ARR_RefundStatus = [
      { Id: "0", Name: "未审核" },
      { Id: "1", Name: "已审核" },
      { Id: "2", Name: "已入库" },
      { Id: "3", Name: "已付款" },
      { Id: "4", Name: "已出库" },
      { Id: "5", Name: "已收款" },
      { Id: "6", Name: "正在分拣" },
      { Id: "7", Name: "待处理" },
      { Id: "8", Name: "已处理" },
      { Id: "9", Name: "部分出库" },
      { Id: "10", Name: "已回单" },
      { Id: "11", Name: "全单拣退" },
      { Id: "12", Name: "未支付" },
      { Id: "13", Name: "已取消" },
      { Id: "14", Name: "已完成" },
      { Id: "17", Name: "退款中" },
      { Id: "16", Name: "已签收" },
      { Id: "18", Name: "已退款" }
    ];
    const ARR_orderSourceType = [
      { Id: "0", Name: "城批" },
      { Id: "1", Name: "驿站" }
    ];
    const ARR_DateType = [
      { Id: "created_time", Name: "下单时间" },
      { Id: "delivery_date", Name: "配送时间" },
    ];
    const dateBegin = this.$formatDate(0, -4);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: '/promotion',
      searchData: {},
      showDetailPage:false,
      searchItemList:[
        {label: "", type: "select", value: "orderSourceType", selectOptions:ARR_orderSourceType, multiple:false,placeholder:'订单来源类型'},
        {label: "订单状态", type: "select", value: "orderState", selectOptions:ARR_RefundStatus, multiple:true},
        {type: "select", value: "dateType", selectOptions: ARR_DateType,defaultValue:'created_time',multiple:false,width:'100'},
        {type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
        {type: "input", value: "kw",placeholder:'订单号/SAAS单号'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"100", fixed:'left'},
          { label: "订单号", width: 200, prop: "orderNo" },
          { label: "最终价", width: 100,callBack:(item)=>{ return item.finalPrice.toFixed(2)},getColor:()=>{return 'textdanger'}},
          { label: "成交价", width: 100,callBack:(item)=>{ return item.orderAmount.toFixed(2)},getColor:()=>{return 'textwarning'}},
          { label: "订单状态", width: 90, callBack:(item)=>{ return item.orderStateName},getColor:(item)=>{return this.getStateColor(item.orderState)}},
          { label: "支付方式", width: 90, prop: "payOffTypeName" },
          { label: "微信交易流水号", width: 150, prop: "transactionSerialNum" },
          { label: "客户编码", width: 150, prop: "traderCode" },
          { label: "客户名称", width: 100, prop: "traderName" },
          { label: "联系人电话", width: 150, prop: "phone" },
          { label: "客户渠道", width: 90, prop: "clientTypeName" },
          { label: "业务员名称", width: 100, prop: "salerName" },
          { label: "配送地址", width: 150, prop: "deliveryAddr" },
          { label: "配送日期", width: 100, callBack:(item)=>{ return item.payOffType?item.deliveryDate.split(" ")[0]:''}},
          { label: "备注", width: 100, prop: "remark" },
          { label: "下单时间", width: 150, prop: "createdTime" },
          {label: "订单来源", width: 100, callBack:(item)=>{ return ['城批','驿站'][item.orderSourceType]}},
          { label: "SAAS单号", width: 150, prop: "saasOrderNo" },
        ]
      },
      baseDataList:[],
      detailData: {
        DataList:[],
        tableHeight:'250',
        Total:0,
        labelList: [
          { label: "商品编码", width: 100, prop: "productCode" },
          { label: "商品名称", width: 200, prop: "productName" },
          { label: "单位", width: 50, prop: "unit" },
          { label: "数量", width: 50, prop: "unitQty" },
          { label: "货款单价", width: 100, prop: "unitPrice" },
          { label: "运费单价", width: 100,callBack:(item)=>{return (item.shipPrice/item.unitQty).toFixed(2)}},
          { label: "货款金额", width: 100, prop: "amount" },
          { label: "运费金额", width: 100, prop: "shipPrice" },
          { label: "合计金额", minWidth: 100,callBack:(item)=>{return (item.amount + item.shipPrice).toFixed(2)}}
        ]
      },
      pageinfo:{pg_idx: 1, pg_size: 15},
      deatilPageInfo:{pg_idx: 1, pg_size: 10},
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/WholesaleOrderList', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      let Data=postData?cloneObject(postData):'';
      if (Data) {
        Data.timeStart = postData.dateFrom;
        Data.timeEnd = postData.dateTo;
        Data.isExcel=false;
        delete Data.dateFrom;
        delete Data.dateTo;
      }
      this.searchData=Data||this.searchData;
      this.getListData();
    },
    getStateColor(state){
      if([0,12,7].indexOf(state)!=-1){
        return 'textdanger'
      }else if([6,9,13,17,11].indexOf(state)!=-1){
        return 'textwarning'
      }else {
        return 'textsuccess'
      }
    },
    submitItem(d){
      this.deatilPageInfo.pg_idx = 1;
      this.showDetail(d);
    },
    handleClose() {
      this.showDetailPage = false;
      this.search();
    },
    // 前端分页
    getCurrentPageData(data) {
      const startIndex = (this.deatilPageInfo.pg_idx - 1) *this.deatilPageInfo.pg_size;
      const endIndex = startIndex +  this.deatilPageInfo.pg_size;
      return data.slice(startIndex, endIndex)
    },
    getDetailPageCurrent(val){
      this.deatilPageInfo.pg_idx = val;
      this.detailData.DataList = this.getCurrentPageData(this.baseDataList);
    },
    showDetail(d){
      this.curItem={...d,fullAmount:0,fullGoodsAmount:0,fullFreight:0};
      this.$http.post(this.baseApiUrl+'/GetWholesaleOrderDetailListByOrderNo', {orderNo:d.orderNo}).then((res) => {
        this.detailData.DataList =this.getCurrentPageData(res.data)
        this.baseDataList=res.data;
        this.baseDataList.map(item=>{
          this.curItem.fullGoodsAmount += item.amount;
          this.curItem.fullFreight += item.shipPrice;
        })
        this.curItem.fullAmount = Number(this.curItem.fullGoodsAmount) + Number(this.curItem.fullFreight);
        this.detailData.Total =res.data.length;
        this.showDetailPage = true;
      });
    }
  }
}
</script>
<style>
.refund-summary{
  color: red; padding: 10px 0px;
}
</style>

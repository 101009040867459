import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {ElMessage, ElMessageBox} from 'element-plus'
import {AjaxLoading, AjaxLoadEnd} from "@/utils/common";
import store from '@/store'
export interface RootResponse {
    data: any;
    msg: any;
    ret: number;
    succ: boolean;
}
const service:AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 500000
})
// visitor服务实例
const serviceVisitor:AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_VISITOR_API,
    timeout: 500000
});
service.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";

let loadingInstance:any,loadingTimeout:any,activeNum = 0;;
function showLoading() {
    if (activeNum === 0) {
        loadingInstance = AjaxLoading();
    }
    activeNum++;
}
function hideLoading() {
    activeNum--;
    if (activeNum === 0) {
        AjaxLoadEnd(loadingInstance);
    }
}
service.interceptors.request.use(
    config => {
        console.log('Request URL:', config.url); // 输出请求的URL
        if (store.state.user.token) {
            config.headers['Authorization'] =sessionStorage.token
        }
        // @ts-ignore
        if((config.url).indexOf('heartbeat')==-1){
            setTimeout(showLoading, 300);
        }
        return config
    },
    error => {
        hideLoading();
        return Promise.reject(error)
    }
)


service.interceptors.response.use(
    // @ts-ignore
    response =>{
        clearTimeout(loadingTimeout);
        hideLoading();
        const res = response.data;
        if((!res.code&&(res.ret != 0||!res.succ))||(res.code&&res.code!=200)){
            ElMessage({
                message: res.msg || '未知错误',
                type: 'error',
                duration: 5 * 1000
            })

            if (res.ret === 501) {
                ElMessageBox.alert('登录已超时，请重新登录', {
                    confirmButtonText: '确定',
                    type: 'warning'
                }).then(() => {
                    sessionStorage.clear()
                    store.commit('user/SET_TOKEN', '')
                    store.commit('user/SET_ROLES', [])
                    location.replace(process.env.VUE_APP_BASE_API + "/_sso/login");
                })
            }
            return Promise.reject(res)
        }else{
            return response.data
        }
    },
    error => {
        hideLoading();
        ElMessage({
            message: error.message||'未知错误',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
export  {serviceVisitor}

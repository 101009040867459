<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button-group>
        <el-button type="success"  size="small" @click="batchAction(0)">更换驿站</el-button>
        <el-button type="warning"  size="small" @click="batchAction(1)">禁/启用</el-button>
        <el-button type="success"  size="small" @click="batchAction(2)">更换业务员</el-button>
        <el-button type="primary"  size="small" @click="exportData">导出</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary"  plain size="small"  @click="submitItem(actionBtn.data,'edit')">编辑</el-button>
          <el-button type="warning"  plain size="small" @click="submitItem(actionBtn.data,'Password')">密码重置</el-button>
          <el-button type="danger"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.state=='1'">禁用</el-button>
          <el-button type="success"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.state=='0'">启用</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showDetailPage" :title="title" :width="curIndex==100?980:700" >
      <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true,labelWidth:'80px',formRules:rules}" v-if="showDetailPage" >
        <template v-slot:formUpload="upload" v-if="curIndex==100">
          <el-form-item label="门头照" style="width:100%">
            <uploader :url="uploadOpt.url" :key="uploadOpt.fileList" :isImg="uploadOpt.isImg" :fileList="uploadOpt.fileList" :postData="uploadOpt.data" :accept="uploadOpt.accept" @complete="uploadFinish" @imgchange="imgchange" @resetfun="fileList=[],imageUrl=''"></uploader>
          </el-form-item>
        </template>
        <template v-slot:formButton="formBtn">
          <el-form-item >
            <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit" style="margin-left: 80px">确定提交</el-button>
            <el-button type="danger"  @click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
          </el-form-item>
        </template>
      </Vform>
    </el-dialog>
    </div>
</template>
<script>
import {compareObjects} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/customer',
      searchData: {},
      showDetailPage: false,
      mainData: {
        isMultiple:true,
        isSerial:false,
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"220", fixed:'left'},
          { label: "客户名称", width: 200, prop: "customerFullName" },
          { label: "联系方式", width: 100, prop: "phone" },
          { label: "收货地址", minWidth: 150, prop: "address" },
          { label: "状态", width: 60,callBack:(item)=>{ return ['禁用','启用'][item.state]},getColor:(item)=>{return item.state=='0'?'textdanger':'textsuccess'}},
          { label: "业务员", width: 80, prop: "saleName" },
          { label: "类型", width: 60, prop: "clientTypeName" },
          { label: "绑定驿站", width: 100, prop: "stageName" },
          { label: "注册时间", width: 150, prop: "createdTime"},
        ]
      },
      editInfo:[ {label:"登录账号",type: "input", value: "accountName",span:2,isDisabled:true},
        {label:"客户名称",type: "input", value: "customerFullName",span:2},
        {label:"联系方式",type: "input", value: "phone",span:2,placeholder: '可填写多个，用逗号隔开'},
        {label:"绑定驿站",type: "select", value: "stageCode",span:2,keyValue:['Code','Name']},
        {label:"地区",type: "address", value: "addressCode",span:2,placeholder: '地区',addressOptions:{checkStrictly:false,ismultiple:false,citytype:4}},
        {label:"详细地址",type: "input", value: "address",span:2},
        {label:"客户类型",type: "select", value: "clientType",span:2,keyValue:['Code','Name']},
        {label:"业务员",type: "select", value: "saleCode",span:2,keyValue:['Code','Name']}],
      batchInfo: [
        {label:"绑定驿站",type: "select", value: "StageCode" ,span:1,visible: false,placeholder: '请选择驿站',keyValue:['Code','Name']},
        {label:"客户状态",type: "select", value: "state",defaultValue:1,selectOptions:[{Id:1,Name:'启用'},{Id:0,Name:'禁用'}] ,span:1,visible: false,placeholder: '客户状态'},
        {label:"业务员",type: "select", value: "saleCode",span:1,visible: false,placeholder: '请选择业务员',keyValue:['Code','Name']},
      ],
      FormItems:[],
      fileList:[],
      uploadOpt:{
        isImg:true,
        fileList:[],
        domainUrl:'',
        url: '',
        accept:'image/*',
        data: {}
      },
      title:'',
      url:'',//批量更换url
      curItem:{},
      selectData:{},
      curIndex:0,
      modeType:0,
      ClientChannelInfo:[],
      StageInfo:[],
      SalemenInfo:[],
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      rules: {
        customerFullName: [{ required: true, message: "请输入客户名称", trigger: "manual"}],
        phone: [{ required: true, message: "请输入联系方式", trigger: "manual"}],
        address: [{ required: true, message: "请输入联系详细地址", trigger: "manual"}],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "业务员", type: "select", value: "saleCode", selectOptions:this.SalemenInfo, multiple:false, keyValue:['Code','Name'],width:'100'},
        {label: "", placeholder:'客户类型',type: "select", value: "clientType", selectOptions:this.ClientChannelInfo, multiple:false, keyValue:['Code','Name'],width:'100'},
        {label: "", placeholder:'请选择驿站',type: "select", value: "stageCode", selectOptions:this.StageInfo, multiple:false, keyValue:['Code','Name'],width:'140'},
        {label: "", placeholder:'请选择状态',type: "select", value: "state", defaultValue:[1],selectOptions:[{Id:1,Name:'启用'},{Id:0,Name:'禁用'}], multiple:true,width:'160'},
        {type: "input", value: "kw",placeholder:'查询关键字',width:'120'}
      ];
    },
    formItemList() {
      return this.FormItems.map((item,index) =>{
        if(this.curIndex!=100) {
          return {
            ...item,
            visible: this.curIndex == index,
            selectOptions: this.curIndex == 0 ? this.StageInfo : this.curIndex == 1 ? item.selectOptions : this.SalemenInfo,
            defaultValue: this.curIndex == 0 ? this.StageInfo[0].Code : this.curIndex == 1 ? 1 : this.SalemenInfo[0].Code,
          }
        }else{
          return {
            ...item,
            isDisabled:item.isDisabled||false,
            selectOptions:this.selectData[item.value]||item.selectOptions||'',
            defaultValue:this.curItem[item.value]??item.defaultValue??''
          }
        }
      })
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ImgProof).then((d) => {
        this.uploadOpt.data.policy = d.policy;
        this.uploadOpt.data.authorization = d.auth;
        this.uploadOpt.url = d.host;
        this.uploadOpt.domainUrl = d.domain;
      });
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.StageInfo,query:{...this.CacheKeys.StageInfo.query,state:1}}).then((res) => {
        this.StageInfo=res;
      });
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.SalemenInfo,query:{...this.CacheKeys.SalemenInfo.query,state:1}}).then((res) => {
        this.SalemenInfo=res;
      });
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ClientChannelInfo).then((res) => {
        this.ClientChannelInfo=res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/IndexList', {...this.searchData, ...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    // 单个禁启，重置密码
    submitItem(d,type){
      let url='',postData={},msg='',title='';
      switch (type){
        case 'upState':
          url='/IsEnable';
          postData={id:d.id};
          msg=d.state=='0'?'启用成功!':'禁用成功!'
          title=d.state=='0'?`是否启用该客户：${d.customerFullName}？`:`是否禁用该客户：${d.customerFullName}？`
          break;
        case 'Password':
          url='/ReSetPw';
          postData={id:d.id};
          msg='重置密码成功'
          title='是否重置密码？'
          break
        case 'edit':
          if(d.state){
            this.showTipMessage('请先禁用客户，再进行编辑操作.');
            return;
          }
          this.title = "编辑客户信息";
          this.showDetailPage=true;
          this.selectData={stageCode:this.StageInfo,clientType:this.ClientChannelInfo,saleCode:this.SalemenInfo}
          this.FormItems=this.editInfo;
          this.curItem=d;
          this.curItem.addressCode=d.addressCode;
          this.fileList=[];
          if(d.imgUrl){
            this.uploadOpt.fileList = [{name: '门头照.jpg', url: d.imgUrl,status:'success'}];
            this.imageUrl=d.imgUrl;
          }else{
          this.uploadOpt.fileList=[];
            this.imageUrl='';
          }
          this.fileList=JSON.parse(JSON.stringify(this.uploadOpt.fileList));
          this.curIndex=100;
          return;
          break
      }
      this.showConfirm(title,()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + url,postData).then(()=>{
          this.showTipMessage(msg,'success');
          this.canEdit=false;
          this.search();
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
      })
    },
    batchAction(type){
      let data =this.$refs.mainTable.select();
      if(!data) return;
      this.curIndex=type
      this.showDetailPage=true;
      this.FormItems=this.batchInfo;
      switch (type) {
        case 0:
          this.title = "批量更改客户绑定的驿站";
          this.url='/BatchModify'
          break;
        case 1:
          this.title = "批量更改用户状态";
          this.url='/BatchDisableEnable'
          break
        case 2:
          this.title = "批量更改业务员";
          this.url='/BatchUpdateSaleCode'
          break
      }
    },
    uploadFinish(obj){
      if(obj.succ==true){
        this.imageUrl =this.uploadOpt.domainUrl+ obj.resp.url;
      }else{
       this.showTipMessage("网络问题，提交失败，请重新上传")
      }
    },
    imgchange(val){
      this.fileList=JSON.parse(val);
      if(this.fileList.length==0){
        this.imageUrl='';
      }
    },
    // 批量修改状态&更换业务员&更换驿站
    async onSubmit(validate) {
      const formData = await validate();let postData={};
      if(this.curIndex!=100){
        let data =this.$refs.mainTable.select(),ids=[];
        data.map((item)=>{ids.push(item.id);})
        postData={ids:ids.join(','),...formData}
      }else{
        this.url='/CustomerInfoEdit'
        postData={...this.curItem,...formData,imgUrl:this.imageUrl}
        let res=compareObjects(this.curItem,postData);
        if(this.fileList.length==0&&!this.imageUrl){
          this.showTipMessage('请先【选择文件】并点击【提交上传】上传门头照','warning');
          return;
        }
        let flag = false;
        this.fileList.map((item) => {
          if (item.status!= 'success') {flag = true;}
        })
        if (flag) {
          this.showTipMessage("存在未上传的图片，请点击【提交上传】",'warning');
          return;
        }
        if(res.length==0){
          this.showTipMessage('无修改项','warning');
          return;
        }
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + this.url ,postData).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.getListData();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
    exportData(){
      this.postExportData({...this.searchData,...this.pageinfo}, this.baseApiUrl+'/IndexList');
    },
  }
}
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
        <el-button type="success"  size="small" @click="showDetailPage=true;modeType='add';curItem={},visibleStage=false,disabledList={}">添加</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary"  plain size="small"  @click="submitItem(actionBtn.data,'edit')">编辑</el-button>
          <el-button type="warning"  plain size="small" @click="submitItem(actionBtn.data,'Password')">密码重置</el-button>
          <el-button type="danger"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.State=='1'">禁用</el-button>
          <el-button type="success"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.State=='0'">启用</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showDetailPage" :title="modeType=='edit'?'编辑业务员':'添加业务员'" width="700" style="padding-bottom:20px;">
      <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:false,labelWidth:100,formRules:dynamicRules}" @changeform="changeform" v-if="showDetailPage" >
        <template v-slot:formButton="formBtn">
          <el-form-item>
            <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit">提交</el-button>
          </el-form-item>
        </template>
      </Vform>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      baseApiUrl: '/sales',
      searchData: {},
      showDetailPage: false,
      searchItemList: [
        {type: "input", value: "Key", placeholder: '查询关键字'}
      ],
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"220", fixed:'left'},
          { label: "业务员名称", width: 160, prop: "Name" },
          { label: "联系方式", width: 180, prop: "Phone" },
          { label: "状态", width: 70,callBack:(item)=>{ return ['禁用','启用'][item.State]},getColor:(item)=>{return item.State=='0'?'textdanger':'textsuccess'}},
          { label: "所属驿站", width: 160, prop: "StageName" },
          { label: "是否平台员工", minWidth: 100, callBack:(item)=>{ return ["否", "是"][item.IsInternalStaff]},getColor:(item)=>{return item.IsInternalStaff=='0'?'textdanger':'textsuccess'}},
        ]
      },
      FormItems: [
        {label:"名称",type: "input", value: "Name", span:1},
        {label:"手机号码",type: "input", value: "Phone", span:1},
        {label:"是否平台员工",type: "radio", value: "IsInternalStaff",defaultValue:1,selectOptions:[{Id:1,Name:'是'},{Id:0,Name:'否'}],span:1,isChange:true},
        {label:"驿站",type: "select", value: "StageCode",defaultValue:'',selectOptions:'' ,span:1,visible: false,placeholder: '请选择驿站',keyValue:['Code','Name']}
      ],
      visibleStage:false,
      disabledList:{},
      curItem:{},
      StageInfo:[],
      modeType:0,
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      rules: {
        Name: [{ required: true, message: "请输入名称", trigger: "manual"}],
        Phone: [
            { required: true, message: "请输入手机号码", trigger: "manual"},
            {pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确'}
        ],
        StageCode: [{ required: true, message: "请选择驿站", trigger: "manual"}],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    formItemList() {
      return this.FormItems.map(item => ({
        ...item,
        visible:item.value=='StageCode'?this.visibleStage:true,
        isDisabled:this.disabledList[item.value]||item.isDisabled||false,
        selectOptions:item.value=='StageCode'?this.StageInfo:(item.selectOptions||''),
        defaultValue:this.curItem[item.value]??item.defaultValue??''
      }));
    },
    dynamicRules() {
      let rules = {};
      for (let item of this.formItemList) {
        if (item.visible!=false) {
          rules[item.value] = this.rules[item.value];
        }
      }
      return rules;
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.StageInfo,query:{...this.CacheKeys.StageInfo.query,state:-1}}).then((res) => {
        this.StageInfo=res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/IndexList', {...this.searchData, ...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    changeform(d){
      d.Id=this.modeType=='edit'?this.curItem.Id:'';
      this.curItem=d;
      this.visibleStage=d.IsInternalStaff==0;
    },
    submitItem(d,type){
      let url='',postData={},msg='',title='';
      switch (type){
        case 'upState':
          url='/IsEnable';
          postData={id:d.Id};
          msg=d.State=='0'?'启用成功!':'禁用成功!'
          title=d.State=='0'?'是否启用该业务员？':'是否禁用该业务员？'
          break;
        case 'Password':
          url='/ReSetPw';
          postData={id:d.Id};
          msg='重置密码成功'
          title='是否重置密码？'
          break
        case 'edit':
          this.showDetailPage=true;
          this.curItem=d;
          this.modeType='edit';
          this.visibleStage=d.IsInternalStaff==0;
          this.disabledList={Name:true,Phone:true};
          return;
          // eslint-disable-next-line no-unreachable
          break
      }
      this.showConfirm(title,()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + url,postData).then(()=>{
         this.showTipMessage(msg,'success');
          this.canEdit=false;
          this.search();
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
      })
    },
    async onSubmit(validate) {
      const formData = await validate();
      let url=this.modeType=='edit'?'/EditSales':'/AddSales';
      let postData=this.modeType=='add'?{id:'',...formData}:{id:this.curItem.Id,...formData};
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,postData).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.getListData();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
  }
}
</script>

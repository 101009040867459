<template>
 <div class="content_box">
      <div class="search_bar">
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>
    export default {
        data() {
            const dateBegin = this.$formatDate(0, -360);
            const dateEnd = this.$formatDate(0);
            const dateType = [
                { Code: "created_time", Name: "日期" },
                { Code: "expired_date", Name: "到期日" },
            ];
            const orderType = [
                { Code: "0", Name: "未审核" },
                { Code: "1", Name: "已审核" }
            ];
            const paymentState = [
                { Code: "0", Name: "未收款" },
                { Code: "1", Name: "未收清" },
                { Code: "2", Name: "已收清" },
            ];
            return {
                baseApiUrl: '/finance',
                searchData: {},
                searchItemList: [
                    { label: "", type: "select", value: "dateType", selectOptions: dateType, defaultValue:'created_time', multiple: false,width:100, keyValue: ['Code', 'Name'] },
                    { label: "", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
                    { label: "单据类型:", type: "select", value: "billState", selectOptions: orderType, multiple: true, keyValue: ['Code', 'Name'] },
                    { label: "",placeholder: '收款状态', type: "select", value: "paymentState", selectOptions: paymentState, multiple: true, keyValue: ['Code', 'Name'] },
                    { type: "input", value: "kw", placeholder: '业务单号/客户名称' }
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { label: "单据编号", width: 180, prop: "billNo" },
                        { label: "业务单号", width: 210, prop: "orderNo" },
                        { label: "客户编码", width: 200, prop: "traderCode" },
                        { label: "客户名称", width: 200, prop: "traderName" },
                        { label: "订单金额", width: 80, prop: "amount" },
                        { label: "已收金额", width: 80, prop: "treatedAmount" },
                        { label: "未收金额", width: 80, prop: "untreatedAmount" },
                        { label: "待核销审核金额", width: 110, prop: "pendingWriteOff" },
                        { label: "到期日", width: 150, prop: "expiredDate" },
                        { label: "结算方式", width: 70, prop: "payOffType" },
                        { label: "收款状态", width: 70, prop: "paymentStateName" },
                        { label: "应收单状态", width: 85, prop: "billStateName" },
                        { label: "应收单备注", width: 90, prop: "remark" },
                        { label: "制单人", width: 80, prop: "createdBy" },
                        { label: "制单日期", width: 150, prop: "createdTime" },
                        { label: "审核人", width: 80, prop: "auditedBy" },
                        { label: "审核日期", width: 150, prop: "auditedTime" },
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },
            }
        },
        methods: {
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.$http.post(this.baseApiUrl + '/BillList', { ...this.searchData, ...this.pageinfo,...{moduleType: 1,isManual: 0,}}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
        }
    }
</script>

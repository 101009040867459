<template>
  <div class="content_box">
      <div class="search_bar" style="margin-bottom: 0;">
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div style="background: rgba(21,162,231,0.2);padding:4px 10px 4px;">
        <el-button-group>
          <el-button type="warning" size="small" @click="auditItem">审核</el-button>
          <el-button type="primary" size="small" @click="exportData">导出</el-button>
        </el-button-group>
      </div>
      <div class="main-box">
          <mainTable ref="mainTable" :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" @selectChange="selectChange">
              <template v-slot:operation="actionBtn">
                  <el-button type="primary" plain size="small" @click="detailItem(actionBtn.data)" >查看明细</el-button>
              </template>
          </mainTable>
      </div>
  </div>
 <el-dialog v-model="showAuditItemDialog" :title="auditItemForm.title" width="600px" >
    <el-form ref="auditItemForm" :model="auditItemForm" size="small" :rules="auditItemFormRules" label-width="80">
        <el-form-item label="运费金额:">
          <el-input v-model="auditItemForm.shipPriceTotal" readonly/>
        </el-form-item>
        <el-form-item label="实际运费:" prop="auditedAmount">
          <el-input v-model="auditItemForm.auditedAmount" type="number"/>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="auditItemForm.remark"/>
        </el-form-item>
      </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="auditItemSubmit">提交</el-button>
          <el-button @click="showAuditItemDialog = false">关闭</el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>


export default {
    data() {
        return {
            baseApiUrl: '/finance',
            searchData: {},
            sourceOrderTypeList:[
                { Code: "31", Name: "城批销售采销单" },
                { Code: "32", Name: "城批销退采销单" },
            ],
            auditedStateList:[
                { Code:"0",Name:"未审核"},
                { Code:"1",Name:"已审核"},
            ],
            selectOptions:{},
            mainData: {
                DataList: [],
                Total: -1,
                isMultiple:true,
                rowClickSelect_kw:'id',
                labelList: [
                    { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "100", fixed: 'left' },
                    { prop: "orderNo", label: '单号', width: 200 },
                    { prop: "sourceOrderTypeName", label: '源单类型', width: 150 },
                    { prop: "warehouseName", label: '仓库名称', width: 120 },
                    { label: '入账时间', width: 100,  callBack: (item)=>{
                            return this.$formatDate(item.tradedTime);
                    }},
                    { prop: "amount", label: '单据金额', width: 90 },
                    { prop: "shipPriceTotal", label: '运费', width: 80 },
                    { prop: "auditedAmount", label: '实际运费', width: 80 },
                    { prop: "auditedStateName", label: '状态', width: 70, callBack: (item)=>{
                            return item.auditedStateName
                        },getColor:(item)=>{
                            return item.auditedStateName == '已审核' ? "textsuccess":"textdanger";
                        }
                    },
                    { prop: "auditedBy", label: '审核人', width: 70 },
                    { label: '审核时间', width: 100 , callBack: (item)=>{
                        return this.$formatDate(item.auditedTime);
                    }},
                    { prop: 'remark', label: '备注', mimWidth: 150 },
                ]
            },
            pageinfo:{
                pg_idx: 1,
                pg_size: 15,
            },
            selectedList:[],

            //auditItemDialog
            showAuditItemDialog:false,
            auditItemForm:{},
            // auditItemFormRules:{
            //     auditedAmount:[{ required: true, message: "实际运费不能为空", trigger: "blur" }]
            // }
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "入账日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                { label: "", placeholder: '仓库',type: "select", value: "warehouseCode", selectOptions: this.selectOptions.warehouseList, multiple: false ,clearable:true,width:160, keyValue:['Code','Name']},
                { label: "",placeholder: '源单类型', type: "select", value: "sourceOrderType", selectOptions: this.sourceOrderTypeList, multiple: true ,filterable:false,clearable:true,keyValue:['Code','Name']},
                { label: "", placeholder: '状态',type: "select", value: "auditedState", selectOptions: this.auditedStateList,multiple: true,width:160, clearable:true,filterable:false,keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询单号',width:150}
            ]
        },
    },
    mounted() {
        this.getWarehouseInfo()
    },
    methods: {
        getWarehouseInfo(){
          this.$store.dispatch('cache/loadCacheData',this.CacheKeys.CommonWarehouse).then((res) => {
            this.selectOptions.warehouseList = res;
          });
        },
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/GetWholesaleFreightRevenueList', {...this.pageinfo,...this.searchData}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData}, this.baseApiUrl+'/GetWholesaleFreightRevenueList')
        },
        detailItem(d){
          this.$store.dispatch('tags/setTagsItem',{name: 'son-WholesaleFreightRevenueDetail', title: '城批运费分账单明细', path: '/son-WholesaleFreightRevenueDetail?orderNo='+d.orderNo,type:'child'});
          this.$router.push({path: '/son-WholesaleFreightRevenueDetail', query:{orderNo:d.orderNo}})
        },
        selectChange(data){
            this.selectedList = data
        },
        auditItem(){
            if(this.selectedList.length <= 0){
                return this.showTipMessage("请选择记录！")
            }
            let errItem = this.selectedList.find(v=>v.auditedState>0);
            if(errItem){
                return this.showTipMessage("存在已审核记录！")
            }
            if(this.selectedList.length>1){
                const postData = {ids : this.selectedList.map(p=>p.id).join(',')}
                this.showConfirm('您现在进行【批量审核】操作，所选记录中的实际审核金额均填入单据运费金额，是否继续执行？',()=>{
                    this.$http.post(this.baseApiUrl + "/BatchAuditWholesaleFreightRevenue", postData).then(()=>{
                        this.showTipMessage("提交成功","success")
                        this.getListData()
                    })
                })
                return;
            }

            this.auditItemForm = this.selectedList[0]
            this.auditItemForm.auditedAmount = this.auditItemForm.shipPriceTotal
            this.auditItemForm.title = "分帐单审核-" + this.auditItemForm.orderNo
            this.showAuditItemDialog = true
        },
        auditItemSubmit(){
            // this.$refs["auditItemForm"].validate((valid) => {
            //     if (valid) {
                    let data = this.auditItemForm
                    const postData={
                        orderNo:data.orderNo,
                        auditedAmount : data.auditedAmount,
                        remark:data.remark || ''
                    }
                    // if(data.shipPriceTotal != postData.auditedAmount && !postData.remark){
                    //     return this.showTipMessage("实际运费与账单不一致，请将原因写入备注")
                    // }
                    this.showAuditItemDialog = false
                    this.showConfirm("您所填写的实际金额为：" + postData.auditedAmount + ",确认继续提交？",()=>{
                        this.$http.post(this.baseApiUrl + "/AuditWholesaleFreightRevenue", postData).then(()=>{
                            this.showTipMessage("提交成功","success")
                            this.getListData()
                        })
                    })
            //     }
            // })
        },
    }
}
</script>

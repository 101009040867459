import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
type MyComponentType = any;
interface RequireContext {
  keys: () => string[];
  (key: string): { default: MyComponentType };
}
function importAll(r: RequireContext): Array<RouteRecordRaw> {
  return r.keys().map((path: string) => {
    // 从文件路径中移除 './' 和 '.vue' 扩展名并转换为路由路径
    const routePath = path.replace(/^\.\/|\.vue$/g, '');
    // 特殊情况处理：如果是默认首页路径 (例如 'Home.vue') 则将其设置为根路径 '/'
    const pathSegments = routePath.split('/');
    const isHomeRoute = pathSegments[pathSegments.length - 1].toLowerCase() === 'home';
    if (isHomeRoute) {
      // 移除最后一个段落 'Home'
      pathSegments.pop();
    }
    //将路径数组转换回字符串形式的路径，并为首页添加 '/' 路径
    const finalRoutePath = isHomeRoute && pathSegments.length === 0 ? '/' : `/${pathSegments[pathSegments.length-1]}`;
    const componentName = pathSegments[pathSegments.length - 1] || 'Home'; // Default to 'Home' if empty

    return {
      path: finalRoutePath,
      name: componentName,
      component: r(path).default as MyComponentType,
    };
  });
}

const routes: Array<RouteRecordRaw>  = importAll(require.context('../views', true, /\.vue$/));
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
  <div class="content_box">
      <div class="search_bar">
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
              <template v-slot:operation="actionBtn">
                <el-button :type="!(actionBtn.data.type==0 && actionBtn.data.orderState == 0)?'info':'primary'" plain size="small" :disabled="!(actionBtn.data.type==0 && actionBtn.data.orderState == 0)" @click="audit(actionBtn.data)">审核调账</el-button>
                <el-button :type="!(actionBtn.data.type==0 && actionBtn.data.orderState == 2)?'info':'warning'" plain size="small" :disabled="!(actionBtn.data.type==0 && actionBtn.data.orderState == 2)" @click="wrongReturn(actionBtn.data)">错账调回</el-button>
                <el-button type="danger" plain size="small" @click="deleteItem(actionBtn.data)">删除</el-button>
              </template>
          </mainTable>
      </div>
  </div>
  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo" :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></rejectPopup>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/finance',
            searchData: {},
            TYPE:[
                { Code: '0', Name: "调款入账" },
                { Code: '1', Name: "错账调回" },
            ],
            STATUS:[
                { Code: '0', Name: "未审核" },
                { Code: '1', Name: "已审核" },
                { Code: '2', Name: "已成功" },
                { Code: '3', Name: "已失败" },
            ],
            selectOptions:[],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "250", fixed: 'left' },
                    { label: "调账单号", width: 180, prop: "orderNo" },
                    { label: "调帐单状态", width: 80, callBack: (item) => {
                        for( let data of this.STATUS) {
                                if (data.Code == item.orderState) return data.Name;
                            }
                        }
                    },
                    { label: "调账类型", width: 80, callBack: (item) => {
                        for( let data of this.TYPE) {
                                if (data.Code == item.type) return data.Name;
                            }
                        }
                    },
                    { label: "运营商名称", width: 200, prop: "operatorName" },
                    { label: "资金分簿编号", width: 170, prop: "accountNo" },
                    { label: "调账金额", width: 80, prop: "tranAmt" },
                    { label: "被调账柜员交易机", width: 120, prop: "hostFlw" },
                    { label: "被调账柜员机交易序号", width: 150, prop: "hostSeq" },
                    { label: "被调账日期", width: 80, prop: "hostDate" },
                    { label: "附言", minWidth: 150, prop: "memo" },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
            wrongReturnRow:{},
            // wrongReturn_dialog
            disagreeDialog:false,
            rejectInfo:{title:'调账单撤回',label:'理由',value:'remark'},
            canEdit:false
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "调账类型：", type: "select", value: "type", selectOptions: this.TYPE, multiple: false,clearable:true,width:140, keyValue: ['Code', 'Name'] },
                { label: "运营商：", type: "select", value: "operatorCode", selectOptions: this.selectOptions, multiple: false,clearable:true, keyValue: ['Code', 'Name'] },
                { label: "", placeholder: '请选择状态', type: "select", value: "orderState", selectOptions: this.STATUS, multiple: false,clearable:true, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询单号' }
            ]
        }
    },
    mounted() {
        this.getOperatorInfo()
    },
    methods: {
        getOperatorInfo(){
          this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
            this.selectOptions = res;
          });
        },
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/AdjustingOrderList', { ...this.searchData, ...this.pageinfo }).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        audit(row){
            if (!(row.type==0 && row.orderState == 0)) {
                return this.showTipMessage("非法操作")
            }
            this.showConfirm('是否确认【审核】该记录?',()=>{
                this.$http.post(this.baseApiUrl + "/AdjustingOrderCheck", { id:row.id, type:0 }).then(()=>{
                    this.showTipMessage("已提交调账请求，请等待5-10分钟查询结果","success");
                    this.getListData();
                });
            })
        },
        wrongReturn(row){
            if (!(row.type==0 && row.orderState == 2)){
                return this.showTipMessage("非法操作")
            }
            this.wrongReturnRow = row
            this.disagreeDialog = true
        },
        submitForm(reasonObj){
            let postData={
                id:this.wrongReturnRow.id,
                remark:reasonObj.remark,
                type:1,
            }
            this.canEdit = true
            this.$http.post(this.baseApiUrl + "/AdjustingOrderCheck", postData).then(()=>{
                this.showTipMessage("已提交错账调回请求，请等待5-10分钟查询结果","success");
                this.getListData()
                this.canEdit = false
                this.disagreeDialog = false
            },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
        },
        deleteItem(row){
            if (row.orderState != 0){
                return this.showTipMessage("未审核记录才可以删除")
            }
            this.showConfirm('是否确认【删除】该记录?',()=>{
                this.$http.post(this.baseApiUrl + "/AdjustingOrderDelete", { id: row.id }).then(()=>{
                    this.showTipMessage("删除成功！","success");
                    this.getListData();
                });
            })
        }
    }
}
</script>

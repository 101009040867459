<template>
  <div class="content_box">
    <div class="main-box treeC" style="margin-top: 10px;">
      <div class="treeBoxFull">
        <Vtree ref="tree" :treeData="treeData" @nodeclick="showForm" refName="tree" :defaultExpanded="treeDefaultExpandItem" title="全局参数节点"></Vtree>
      </div>
      <div class="formBOXFull" v-if="showTreeForm">
        <div class="formBOXHeader">{{title}}</div>
        <div class="formBOX">
          <p style="font-size: 13px;margin-left: 150px;" v-if="title == '驿站参数设置'">tips：设置驿站资金流水T+N天，可提现（T为自然日）</p>
          <Vform :key="refreshVform" :formItemList="formItemList" @search="search"
                 :setFormInfo="{inline:false,labelWidth:'150px',formRules:rules}">
            <template v-slot:formButton="formBtn">
              <el-form-item label="规则" v-if="title == '提现参数管理'">
                <el-collapse accordion style="width: 100%;">
                  <el-collapse-item v-for="(item,i) in withdrawRulesFromList" :key="i" :name="i" class="collapse_item">
                    <template #title>
                      <div style="width: 100%;">
                        <Vform ref="headerFrom" :formItemList="item.fromHeaderList" :setFormInfo="{inline:false,formRules:withdrawHeaderRules}"></Vform>
                      </div>
                    </template>
                    <div>
                      <Vform ref="itemForm" :formItemList="item.fromList" :setFormInfo="{inline:true,labelWidth:'90px',formRules:withdrawRules}"></Vform>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit(formBtn.validateForm)">保存</el-button>
              </el-form-item>
            </template>
          </Vform>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .collapse_item .el-collapse-item__header{
    background-color: #F5F5F5;padding: 0 20px;margin-bottom: 8px;
  }
</style>

<script>
import {cloneObject} from '@/utils/common'
  export default {
    data() {
      return {
        baseApiUrl: '/system',
        treeData: [],
        showTreeForm: false,
        title: '',
        formItemList:[],
        rules: {},
        refreshVform:1,
        withdrawRulesFromList:[],
        withdrawHeaderRules:{},
        withdrawRules:{},
        curItem:{},
        treeDefaultExpandItem:[]
      }
    },
    mounted() {
      this.getListData();
    },
    methods: {
      getListData() {
        this.$http.post(this.baseApiUrl + '/GlobalParamsManager', {}).then((res)=>{
          res.data.map((item) => { item.value = item.id; item.label = item.settingTypeName; })
          this.treeData = this.buildTree(res.data, 'settingType', 'parentType', 'children');
          this.treeDefaultExpandItem = this.$refs.tree.getExpandedArr();
        });
      },
      buildTree(source, id, parentId, children) {
        let cloneData = cloneObject(source);
        return cloneData.filter(father => {
          let branchArr = cloneData.filter(child => {
            if (father[id] == child[parentId]) {
              child.parentName = father['settingTypeName']
              return true
            }
          });
          branchArr.length > 0 ? father[children] = branchArr : null
          return father[parentId] == "root"
        })
      },
      showForm(data) {
        this.formItemList = []
        this.refreshVform += 1
        switch (data.settingType) {
          case 'Finance':
            this.showTreeForm = false;
            break;
          case 'CITIC':
            this.showCITIC(data);
            this.showTreeForm = true;
            break;
          case 'Withdraw':
            this.showWithdraw(data);
            this.showTreeForm = true;
            break;
          case 'Stage':
            this.showStage(data);
            this.showTreeForm = true;
            break;
          case 'AllowSalePartnerBuyProduct':
            this.showParter(data);
            this.showTreeForm = true;
            break;
        }
        this.curItem = data
        this.title = data.settingTypeName
      },
      async showCITIC(data) {
        let AutoAssignInterest = await this.$store.dispatch('cache/loadCacheData', {key:this.CacheKeys.AutoAssignInterest.key, url:this.CacheKeys.AutoAssignInterest.url, query:this.CacheKeys.AutoAssignInterest.query})
        let CallInterest = await this.$store.dispatch('cache/loadCacheData', {key:this.CacheKeys.CallInterest.key, url:this.CacheKeys.CallInterest.url, query:this.CacheKeys.CallInterest.query})
        let AutoAssignTranFee = await this.$store.dispatch('cache/loadCacheData', {key:this.CacheKeys.AutoAssignTranFee.key, url:this.CacheKeys.AutoAssignTranFee.url, query:this.CacheKeys.AutoAssignTranFee.query})
        let FeeType = await this.$store.dispatch('cache/loadCacheData', {key:this.CacheKeys.FeeType.key, url:this.CacheKeys.FeeType.url, query:this.CacheKeys.FeeType.query})
        this.formItemList = [
          { label: "默认计息利率(%)", type: "input", value: "interestRate", defaultValue: data.settings.interestRate*100},
          { label: "自动分配利息", type: "select", value: "autoAssignInterest", defaultValue: `${data.settings.autoAssignInterest}`, selectOptions:AutoAssignInterest, keyValue:['Code','Name']},
          { label: "是否计算利息", type: "select", value: "callInterest", defaultValue: `${data.settings.callInterest}`, selectOptions:CallInterest, keyValue:['Code','Name']},
          { label: "自动分配转账手续费", type: "select", value: "autoAssignTranFee", defaultValue: `${data.settings.autoAssignTranFee}`, selectOptions:AutoAssignTranFee, keyValue:['Code','Name']},
          { label: "手续费收取方式", type: "select", value: "feeType", defaultValue: `${data.settings.feeType}`, selectOptions:FeeType, keyValue:['Code','Name']},
        ]
        this.rules = {
          interestRate:[{required: true, message: "请输入计息利率", trigger: "manual"},
                        { pattern: /^([0-9]{1,2}$)|(^[0-9]{1,2}\.[0-9]{1,10}$)|100$/, message: '默认计息利率(%)必须是0-100的数', trigger: 'manual' },
          ],
          autoAssignInterest:[{required: true, message: "请输入", trigger: "manual"}],
          callInterest:[{required: true, message: "请输入", trigger: "manual"}],
          autoAssignTranFee:[{required: true, message: "请输入", trigger: "manual"}],
          feeType:[{required: true, message: "请输入", trigger: "manual"}],
        }
      },
      showWithdraw(data){
          this.formItemList = [
            { label: "预期到账时间", type: "input", value: "internalDays", defaultValue: data.settings.internalDays},
          ]
          this.rules = {
            internalDays:[{required: true, message: "预期到账时间必须是整数", trigger: "manual"}],
          }
          let subSetting = data.settings.subSetting
          this.withdrawRulesFromList = []
          for(let key in subSetting){
            this.withdrawRulesFromList.push({
              fromHeaderList:[
                { label: "", type: "input", value: "tempText", defaultValue: key},
              ],
              fromList:[
                { label: "类型", type: "select", value: "type", defaultValue: subSetting[key].type, selectOptions:[{Code:"s",Name:"按固定值计算"},{Code:"m",Name:"按比率计算"}], keyValue:['Code','Name']},
                { label: "手续费(峰值)", type: "input", value: "cost", defaultValue: subSetting[key].cost,width:100},
                { label: "比率(默认0)", type: "input", value: "ratio", defaultValue: subSetting[key].ratio,width:100},
              ]
            })
          }
          this.withdrawHeaderRules = {
            tempText:[{ required: true, message: "必须输入整数", trigger: "manual"},
                      { pattern: /^[-+]?\d*$/, message: '必须输入整数', trigger: 'manual' }],
          }
          this.withdrawRules = {
            type:[{required: true, message: "请输入", trigger: "manual"}],
            cost:[{required: true, message: "请输入", trigger: "manual"}],
            ratio:[{required: true, message: "请输入", trigger: "manual"}],
          }
      },
      showStage(data){
        this.formItemList = [
          { label: "资金提现周期", type: "input", value: "fundTerm", defaultValue: data.settings.fundTerm},
        ]
        this.rules = {
          fundTerm:[{ required: true, message: "资金提现周期必须是整数", trigger: "manual"},
                    { pattern: /^[-+]?\d*$/, message: '资金提现周期必须是整数', trigger: 'manual'}
          ],
        }
      },
      showParter(data){
        let defaultValue = [];
        for (const key in data.settings) {
          if (data.settings[key] === 1) {
            defaultValue.push(key);
          }
        }
        this.formItemList = [
          { label: "合伙人可下单范围：", type: "checkbox", value: "orderScope", defaultValue:defaultValue,selectOptions:[{Id:'marketProduct',Name:'运营商商城商品'},{Id:'selfProduct',Name:'运营商自营商品'}]},
        ]
      },
      async onSubmit(validate) {
          let postData = {}, d1, d2, obj = {},settingObj = {}
          const formData = await validate()
          settingObj = formData
          if(this.curItem.settingType == "Withdraw"){
            for(let i=0;i<this.withdrawRulesFromList.length;i++){
              d1 = await this.$refs.headerFrom[i].validateForm()
              d2 = await this.$refs.itemForm[i].validateForm('','缺少必填项')
              obj = {...obj,...{
                [d1.tempText]:d2
              }}
            }
            settingObj = {...formData,subSetting:obj}
          }
          if(this.curItem.settingType == 'CITIC'){
            settingObj.interestRate = String((settingObj.interestRate / 100).toFixed(2))
          }
          if(this.curItem.settingType == 'AllowSalePartnerBuyProduct'){
            let parterObj = { selfProduct: 0, marketProduct: 0 };
            formData.orderScope.forEach(item => {
              if (parterObj.hasOwnProperty(item)) {
                parterObj[item] = 1;
              }
            });
            settingObj=cloneObject(parterObj)
          }
          postData = {
            setting:JSON.stringify(settingObj),
            id:this.curItem.id
          }
          this.$http.post(this.baseApiUrl + '/UpdateGlobalParams', postData).then(()=>{
              this.showTipMessage('修改成功!','success')
              this.getListData()
          })
      }
    }
  }
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="primary" v-on:click="exportData" size="small">导出</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable"></mainTable>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const ARR_RefundStatus = [
      { Id: "0", Name: "未退款" },
      { Id: "1", Name: "已退款" },
    ];
    const ARR_DateType = [
      { Id: "created_time", Name: "制单时间" },
      { Id: "audited_time", Name: "入账时间" },
    ];
    const dateBegin = this.$formatDate(0, -30);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: '/promotion',
      searchData: {},
      showDetailPage:false,
      searchItemList:[
        {type: "select", value: "dateType", selectOptions: ARR_DateType,defaultValue:'created_time',multiple:false,width:'100'},
        {type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
        {label: "状态", type: "select", value: "state", selectOptions:ARR_RefundStatus, multiple:true},
        {type: "input", value: "kw",placeholder:'查询单号'}
      ],

      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          { label: "单号", width: 180, prop: "orderNo" },
          { label: "客户", width: 150, prop: "traderName" },
          { label: "业务员", width: 120, prop: "salerName" },
          { label: "saas实收金额", width: 100,callBack:(item)=>{ return item.saasReceptAmount.toFixed(2)},getColor:()=>{return 'textdanger'}},
          { label: "退款金额", width: 100,callBack:(item)=>{ return item.refundAmount.toFixed(2)},getColor:()=>{return 'textwarning'}},
          { label: "退款状态", width: 70, callBack:(item)=>{ return item.refundStateName},getColor:(item)=>{return (item.refundState==1)?'textsuccess':'textdanger'} },
          { label: "退款操作人", width: 100, prop: "operatedBy" },
          { label: "退款时间", width: 150, prop: "operatedTime" },
          { label: "退款单流水号", width: 150, prop: "refundTransactionNo" },
          { label: "制单时间", width: 150, prop: "createdTime" },
          { label: "入账时间", minWidth: 150, prop: "auditedTime" },
        ]
      },
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/RefundInfoList', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    exportData(){
       this.postExportData({...this.searchData,...this.pageinfo}, this.baseApiUrl+'/RefundInfoList');
    }
  }
}
</script>

<!--<template>
  <div ref="editorContainer"></div>
</template>-->
<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="valueHtml"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onChange="getUEContentTxt"
        @onCreated="handleCreated"
    />

  </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { getCurrentInstance,onBeforeUnmount, ref, shallowRef, onMounted,onActivated } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  props: {
     content: String,
     isF:Boolean,
     ImgOption: Object,
  },
  emits: ["getData"],
  setup(props,context) {
    // 编辑器实例，必须用 shallowRef
    const instance = getCurrentInstance();
    const editorRef = shallowRef()
    const valueHtml = ref('')
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = props.content;
        const editor = editorRef.value
        editor.on('modalOrPanelShow', modalOrPanel => {
          if (modalOrPanel.type !== 'modal') return
          const { $elem } = modalOrPanel
          $elem[0].style.left = '50%';
          $elem[0].style.top = '50%';
          $elem[0].style.bottom = 'auto';
          $elem[0].style. transform= 'translate(-50%, -50%)';
        })
      }, 500)
    })

    const toolbarConfig = {}
    const editorConfig = {
      MENU_CONF: {
        uploadImage: {
          fieldName: 'your-custom-name',
          maxFileSize: 1 * 1024 * 1024,
          maxNumberOfFiles: 10,
          allowedFileTypes: ['image/*'],
          withCredentials: true,
          timeout: 5 * 1000, // 5 秒
          async customUpload(file, insertFn){
            const formData = new FormData();
            formData.append('file', file);
            formData.append('policy', props.ImgOption.imgData.policy);
            formData.append('authorization', props.ImgOption.imgData.authorization);
            instance.proxy.$http.post(props.ImgOption.imageUpUrl,  formData).then((res)=>{
               insertFn(props.ImgOption.imageUrl+res.url, props.ImgOption.imageUrl+res.url, props.ImgOption.imageUrl+res.url)
            });
          }
        }
      },
      placeholder: '请输入内容...'
    }
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })
    const handleCreated = (editor) => {
      editorRef.value = editor
    }
    const getUEContentTxt=()=>{
      context.emit('send', {textValue: editorRef.value.getText().replace(/[\r\n]/g, ''),textHtml:valueHtml.value});
    }

    return {
      instance,
      editorRef,
      valueHtml,
      mode: 'simple', // 或 'default'
      toolbarConfig,
      editorConfig,
      handleCreated,
      getUEContentTxt
    };
  }
}
</script>

<template>
  <div>
    <el-table :data="fileList" :border="true" size="small" style="width:100%" class="uploaderlist">
      <el-table-column  label="图片" width="70" style="text-align: center">
        <template #default="scope">
          <div style=" width: 30px; height: 30px;margin:0 auto;text-align: center" @click="viewerImg(scope.$index)">
            <img :src="scope.row.url" style=" max-width: 100%;max-height: 100%;"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="文件名">
        <template #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="size" label="大小"  width="100">
        <template #default="scope">
          <span>{{scope.row?.size?formatSize(scope.row.size):0}}</span>
        </template>
      </el-table-column>
      <el-table-column  prop:="status" label="状态" width="100">
        <template #default="scope">
          <span>{{scope.row.status=='ready'?'准备中':'已完成'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  fixed="right" :width="moveFun==false?100:240">
        <template #default="scope">
          <el-button type="danger" size="small" @click="deleteclick(scope.$index,scope.row)" >删除</el-button>
          <el-button :type="scope.$index==0?'info':'success'"  plain size="small" :disabled="scope.$index==0" @click="orderClick(scope.$index, scope.row, true)" v-if="moveFun">上移</el-button>
          <el-button :type="scope.$index==fileList.length-1?'info':'warning'"  :disabled="scope.$index==fileList.length-1" plain size="small" @click="orderClick(scope.$index, scope.row, false)" v-if="moveFun">下移</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="btns">
      <el-upload ref="upload"
                 :show-file-list="false" v-model:file-list="fileList" class="upload-demo"
                 :limit="ImgOption.leng"
                 :action="ImgOption.imageUpUrl"
                 :data="ImgOption.imgData"
                 :multiple="ImgOption.multiple||false"
                 :accept="ImgOption.acceptList||'.jpg,.jpeg,.png,.JPG,.JPEG,.PNG'"
                 :on-change="handleChange"
                 :on-success="handleUploadOk"
                 list-type="picture"
                 :on-exceed="onExceed"
                 :before-upload="beforeUpload" :auto-upload="false">
        <template #trigger>
          <el-button type="primary" size="small">选择文件</el-button>&nbsp;&nbsp;&nbsp;&nbsp;
        </template>
        <el-button class="ml-3" type="success" @click="submitUpload" size="small">
          确认上传
        </el-button>
      </el-upload>
    </div>
  </div>
  <el-image-viewer v-if="showPreview" @close="showPreview=false" :initial-index="initIndex" :url-list="imgList" />
</template>
<script>
import {showConfirm} from '@/utils/common'
import {serviceVisitor} from '@/utils/http'
export default {
  name: "uploaderList",
  emits: ["imglistchange"],
  props: {
    ImgOption: {},
    moveFun: {
      type: Boolean,
      default: true
    },
    apiType:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      showPreview:false,
      initIndex:0,
      imgList:'',
      message: "Hello Element Plus",
      fileList: [],
      imageUrl:[],
    }
  },
  watch:{
    ImgOption: {
      handler (val) {
        this.fileList=val.list||[];
        this.$emit('imglistchange',JSON.stringify(val.list||[]))
      },
      deep: true
    },
  },
  mounted(){
    this.fileList=this.ImgOption.list;
  },
  methods:{
    formatSize(size){
      if(size/1024/1024>1){
        return (size/1024/1024).toFixed(2)+'M'
      }else{
        return (size/1024).toFixed(2)+'KB'
      }
    },
    submitUpload(){
      this.$refs.upload.submit()
    },
    onExceed(){
      this.showTipMessage("图片不可超过"+this.ImgOption.leng+"张","warning");
    },
    orderClick(index,data,direction){
      let pre = direction ? index - 1 : index + 1
      if(pre < 0 || pre >= this.fileList.length){
        var type=direction ? 'up' : 'dwon';
        if(type=='up'){
          this.showTipMessage("已经是第一个了，不能上移","warning");
        }else if(type=='dwon'){
          this.showTipMessage("已经是最后一个了，不能上移","warning");
        }
        return
      }
      this.fileList.splice(index,1)
      this.fileList.splice(pre,0,data)
    },
    setFirstImg(index){
      this.fileList.map((item,i)=>{
        if(i==index) item.setting=1;
        else item.setting=0;
      })
    },
    handleChange(file, list) {
      const size2M = file.size/1024/1024 <2;
      if(!size2M){
        this.showTipMessage("图片大小不可超过2M","warning");
        list.pop();
        return;
      }
      this.$emit('imglistchange',JSON.stringify(list))
    },
    formatFile(index){
      this.fileList.splice(index, 1);
      this.$emit('imglistchange',JSON.stringify(this.fileList))
    },
    viewerImg(index){
      this.showPreview=true;
      this.imgList=[];
      this.fileList.map((item)=>{
        this.imgList.push(item.url);
      })
      this.initIndex=index;

    },
    // 删除
    deleteclick(index,item){
      if(item.status!='success'){
        showConfirm(`确认删除该张图片吗？`,()=> {
          this.formatFile(index);

        })
      }else{
        let url;
        if((item.response.url).indexOf("http://")!=-1||(item.response.url).indexOf("https://")!=-1){
          url=item.response.url;
        }else{
          url=this.ImgOption.imageUrl + item.response.url;
        }
        showConfirm(`确认删除该张图片吗？`,()=> {
          if(this.apiType=='visitor'){
            serviceVisitor.post(this.ImgOption.delUrl, {fileUrl:url}).then(() => {
              this.formatFile(index);
            },()=>{
              this.formatFile(index);
            });
          }else{
            this.$http.post(this.ImgOption.delUrl, {fileUrl:url}).then(() => {
              this.formatFile(index);
            },()=>{
              this.formatFile(index);
            });
          }

        })
      }
    }
  }
}
</script>

<style>
.uploaderlist .el-table__empty-block{display: none;}
.btns{border:1px solid #ebeef5;border-top:none;padding:10px 20px;text-align: left}
</style>

<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data)">审核</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" :title="title" width="70%">
    <div style="margin:20px 0">
      运营商编码: <span class="textprimary textspan mr30">{{curItem.operatorCode}}</span>
      运营商名称: <span class="textprimary textspan mr30">{{curItem.operatorName}}</span>
    </div>
    <mainTable :mainOptions="imgData" ref="imgTable"></mainTable>
    <div style="text-align:center;padding:30px;">
      <el-button type="success" v-on:click="saveItem(true)" :disabled="canEdit">准入</el-button>
      <el-button type="danger" v-on:click="disagreeDialog=true;" :disabled="canEdit">拒绝</el-button>
    </div>
  </el-dialog>
  <reject-Popup v-if="disagreeDialog" :rejectInfo="rejectInfo" :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></reject-Popup>
</template>
<script>
export default {
  data() {
    return {
      baseApiUrl: '/info',
      params:{type:''},
      searchItemList:[
        {type: "input", value: "kw",placeholder:'查询关键字'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "运营商编码", width: 100, prop: "code" },
          { label: "运营商名", width: 280, prop: "operatorName" },
          { label: "状态", width: 100, prop: "amendState" },
          { label: "申请时间", width: 150, prop: "createdTime" },
          { label: "审核人", width: 100, prop: "auditedBy" },
          { label: "审核时间", minWidth: 150, prop: "auditedTime" },
        ]
      },
      rejectInfo:{title:'未通过的原因',value:'remark'},
      imgData: {
        DataList:[],
        tableHeight:'200',
        Total:0,
        labelList: [
          { label: "文件", width: 70,callBack:(item)=>{return item.url}, isHtml:true},
          { label: "文件名", width: 190, prop: "imgName" },
          { label: "大小(kb)", minWidth: 150,callBack:(item)=>{return (item.size/1024).toFixed(2)}},
        ]
      },
      showDetailPage:false,
      title:'',
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      amendColObj:{},
      disagreeDialog:false,
      canEdit:false,
    }
  },
  inject: ['$hasPermission'],
  created() {
    this.params.type=this.$route.query.type;
  },
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/AmendLogList', {...this.searchData,type:this.params.type,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    showDetail(d){
      this.curItem=d;
      this.title=this.params.type=='area'?'审核运营商入驻商城':'审核运营商入驻城批';
      this.$http.post(this.baseApiUrl+'/LoadAttachments', {operatorCode:d.operatorCode,code:d.operatorCode,type:this.params.type=='area'?"is_area":'is_city_wholesale'}).then((res) => {
        this.imgData.DataList = res.data;
        this.showDetailPage = true;
      });
      this.amendColObj={};
      this.$http.post(this.baseApiUrl+'/AmendDetail', {operatorCode:d.operatorCode,code:d.operatorCode,type:this.params.type=='area'?"is_area":'is_city_wholesale'}).then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].isAmendCol === 1) {
            this.amendColObj = res.data[i];

          }
        }
      });
    },
    submitForm(value) {
      this.saveItem(false,value);
    },
    saveItem(isAgree,value) {
      let postData
      if (isAgree) {
        postData = {
          logs: JSON.stringify([{...this.amendColObj,pass:1}]),
          type: this.params.type=='area'?'operator-Area':'operator-Wholesale'
        };
      } else {
        postData = {
          logs: JSON.stringify([ {...this.amendColObj,pass:0,...value}]),
          type: this.params.type=='area'?'operator-Area':'operator-Wholesale'
        }
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/AuditAmendLog" ,postData).then(() => {
        this.showTipMessage('审核成功!','success');
        this.disagreeDialog=false;
        this.canEdit=false;
        this.showDetailPage = false;
        this.search();
      },(res)=>{this.canEdit=false; this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

import request from "@/utils/http";
import {AjaxLoading,AjaxLoadEnd,showTipMessage} from "@/utils/common";

// 缓存数据
const CacheKeys = {
    MerchantInfo:{key:"MerchantInfo",url:"/Ajax/DCLoad",query:{t: "MerchantList"}},
    OperatorInfo:{key:"OperatorInfo",url:"/Ajax/DCLoad",query:{t: "OperatorList"}},
    AreaInfo:{key:"AreaInfo",url:"/Ajax/DCLoad",query:{t: "CommonAreaList"}},
    BrandInfo:{key:"BrandInfo",url:"/Ajax/DCLoad",query:{t: "BrandList"}},
    ProductTypeInfo:{key:"ProductTypeInfo",url:"/Ajax/DCLoad",query:{t: "ProductTypeList"}},
    CategoryInfo:{key:"CategoryInfo",url:"/Ajax/DCLoad",query:{t: "CategoryList"}},
    StageInfo:{key:"StageInfo",url:"/Ajax/DCLoad",query:{t: "AllStageList"}},
    CommonWarehouse:{key:"CommonWarehouse",url:"/Ajax/DCLoad",query:{t: "CommonWarehouseList"}},
    SalemenInfo:{key:"SalemenInfo",url:"/Ajax/DCLoad",query:{t: "Salemen"}},
    ClientChannelInfo:{key:"ClientChannelInfo",url:"/Ajax/DCLoad",query:{t: "ClientChannelList"}},
    ImgProof:{key:"ImgProof",url:"/Ajax/GetUpYunInfo"},
    AutoAssignInterest:{key:"AutoAssignInterest",url:"/Ajax/DCLoad",query:{t: "AutoAssignInterest"}},
    CallInterest:{key:"CallInterest",url:"/Ajax/DCLoad",query:{t: "CallInterest"}},
    AutoAssignTranFee:{key:"AutoAssignTranFee",url:"/Ajax/DCLoad",query:{t: "AutoAssignTranFee"}},
    FeeType:{key:"FeeType",url:"/Ajax/DCLoad",query:{t: "FeeType"}},
}
// // 导出
const postExportData=(data:object, url:string)=> {
    const loading = AjaxLoading('正在导出文件中');
    request.post(url, {...data,_export:1}).then((res) => {
        AjaxLoadEnd(loading);
        // @ts-ignore
        if(!res.succ) showTipMessage(res.msg)
        // @ts-ignore
        else location.replace(process.env.VUE_APP_BASE_API+'/ajax/getexportfile' + '?file=' + res.data + '&type=' + res.ext.FileType);
    });
}
// // 获取支行数据
const BankInfo={
    BankApiUrl:'https://webapi.8weiyuan.com/api/bank',
    query:(callback:any)=>{
        request.get(BankInfo.BankApiUrl+'/query_banknames',{}).then((res) => {
            let list = res.data||[];
            if(list[0].name=='中原银行') list.shift();
            callback(list);
        });
    }
}
export {CacheKeys,postExportData,BankInfo}

<!--可配置项：
  emitSearch 打开页面是否立即查询
  showRestbtn 是否隐藏重置按钮
  setFormInfo.inline 是否开启行内表单模式
  setFormInfo.labelWidth 标签的宽度
  setFormInfo.isDisabled 整个表单所有元素是否禁用
  formItemList  示例 [{label:"操作",type: "input", value: "kw", placeholder: '查询关键字', span:3}]
  formItemList可配置项
   type 表单元素类型
   value 表单元素的绑定的key值
   label label值
   span el-col span属性
   selectOptions (select下拉||radio单选组)数据  multiple 下拉是否多选  keyValue 下拉绑定值
   defaultValue 默认值
   width
   isChange 是否监听change方法
   input 是否监听inputFun方法
   visible是否显示
   isDisabled是否禁用
-->
<template>
  <el-form :ref="setFormInfo.refName||'editForm'" :inline="setFormInfo.inline" :model="form" size="small" :label-width="setFormInfo.labelWidth||'auto'">
    <el-form-item  v-for="(item, index) in formItemList" :key="index"  v-show="item.visible!=false" :span="item.span||'auto'" :rules="(setFormInfo.formRules && setFormInfo.formRules[item.value])||[]" :label="item.label" :prop="item.value" :style='item.span?`width: calc((100% - ${item.span*12}px) / ${item.span})`:`width:auto;`'>
       <el-input v-if="item.type == 'input'||item.type == 'textarea'||item.type == 'password'"
            v-model.trim="form[item.value]"
            :type="item.type || ''"
            :placeholder="item.placeholder==undefined?'请输入':item.placeholder"
            :disabled="setFormInfo.isDisabled||item.isDisabled||false"
            :clearable="item.clearable || formType=='search' || false"
             @change="item.isChange?changeForm('',item.value):''"
             @input="item.inputFun?changeForm('',item.value):''"
            :style='!item.span?!setFormInfo.inline?`width:100%;flex:1`:`width:${item.width||180}px;flex:1`:`width:100%;flex:1`'/>
        <el-input-number v-if="item.type == 'inputNum'"
            v-model.trim="form[item.value]"
            :min="item.min || -Infinity"
            :max="item.max || Infinity"
            :disabled="setFormInfo.isDisabled||item.isDisabled||false"
            :controls="item.controls || false"/>
        <el-select v-if="item.type == 'select'"
                   v-model="form[item.value]"
                   :multiple="item.multiple"
                   :placeholder="item.placeholder==undefined?'请选择':item.placeholder"
                   :collapse-tags="item.collapseaTags==false?false:true"
                   :disabled="setFormInfo.isDisabled||item.isDisabled||false"
                   @change="changeForm()"
                   :filterable="item.filterable ?? true"
                   :clearable="item.clearable || false"
                   :style='!item.span?!setFormInfo.inline?`width:100%`:`width:${item.width||180}px`:`width:100%`' >
          <el-option v-for="(seleItem, seleIndex) in item.selectOptions"
                     :key="seleIndex"
                     :label="item.keyValue==undefined?seleItem.Name:seleItem[item.keyValue[1]]"
                     :value="item.keyValue==undefined?seleItem.Id:seleItem[item.keyValue[0]]">
          </el-option>
        </el-select>
        <el-radio-group v-if="item.type == 'radio'"
                        v-model="form[item.value]"
                        @change="item.isChange?changeForm('',item.value):''">
          <el-radio  v-for="(seleItem, seleIndex) in item.selectOptions"
                     :key="seleIndex"
                     :disabled="setFormInfo.isDisabled||item.isDisabled||false"
                     :label="item.keyValue==undefined?seleItem.Id:seleItem[item.keyValue[0]]">{{item.keyValue==undefined?seleItem.Name:seleItem[item.keyValue[1]]}}
          </el-radio>
        </el-radio-group>
        <el-checkbox-group v-if="item.type == 'checkbox'" v-model="form[item.value]"  @change="changeForm()">
          <el-checkbox v-for="(seleItem, seleIndex) in item.selectOptions" :key="seleIndex"
                       :disabled="setFormInfo.isDisabled||item.isDisabled||false"
                       :label="item.keyValue==undefined?seleItem.Id:seleItem[item.keyValue[0]]">{{item.keyValue==undefined?seleItem.Name:seleItem[item.keyValue[1]]}}
          </el-checkbox>
        </el-checkbox-group>
        <el-date-picker v-if="(item.type == 'daterange'||item.type == 'date'||item.type=='datetimerange')"
              v-model="form[item.value]"
              value-format="YYYY-MM-DD"
              :disabled="setFormInfo.isDisabled||item.isDisabled||false"
              :clearable="false"
              size="small"
              :style='!item.span?!setFormInfo.inline?`width:100%`:`width:${item.width||180}px`:`width:100%`'
              :type="item.type || ''"
              range-separator="-"
              :placeholder="item.placeholder==undefined?'请选择时间':item.placeholder"
              start-placeholder="开始时间"
              end-placeholder="结束时间"></el-date-picker>
        <city-address v-if="item.type == 'address'"
             ref="cascader"
             @emitdata="emitdata"
             @cityChange="cityChange"
             :cityvalueList="form[item.value]"
             :addressOptions="item.addressOptions"
             style="width:100%">
        </city-address>
        <span v-if="item.type == 'datetime'&&form[item.value]" style="display:flex;width:440px;height:24px">
          <el-time-select v-model="form[item.value][0]"  :min-time="item.startMinTime" @change="changeForm('',item.value)" :clearable="false" placeholder="开始时间" start="00:00" step="00:05" end="23:59" size="small"/><span style="margin:0 5px;">-</span>
          <el-time-select v-model="form[item.value][1]"  :min-time="form[item.value][0]" @change="changeForm('',item.value)" :clearable="false" placeholder="结束时间" start="00:00" step="00:05" end="23:59" size="small"/>
        </span>
        <el-button v-if="item.isBtn||false" type="primary"  size="small"  @click="changeForm('btn',item.value)" style="margin-left: 10px">{{item.btnLabel}}</el-button>
        <span v-if="item.unit||false" style="margin-left:5px">{{item.unit}}</span>
        <el-popover placement="top-start" title="" :width="300" trigger="hover" :content="item.tipContent" v-if="item.isTip">
          <template #reference>
            <el-icon color="#666" :size="18" style="float:left;margin: 3px;cursor: pointer"><WarningFilled /></el-icon>
          </template>
        </el-popover>
     </el-form-item>
      <slot name="formUpload"></slot>
      <slot name="formButton" :data="form" :validateForm="validateForm" ></slot>
  </el-form>
</template>

<script lang="ts">
import {defineComponent, toRefs, ref, reactive, onMounted, PropType, getCurrentInstance, watch, nextTick} from 'vue'
  import {showTipMessage} from '@/utils/common'
  interface FormItem {
    label: string;
    type: string;
    value: string;
    placeholder: string;
    span?: number;
    defaultValue?: string; // 可选属性
  }
  export default defineComponent({
    props: {
      emitSearch: {
        type: Boolean,
        default: false
      },
      showRestbtn: {
        type: Boolean,
        default: true
      },
      setFormInfo:{
        type:  Object,
        default: {},
      },
      formType:{
        type: String,
        default:'Form',
      },
      formItemList: {
        type: Array as PropType<FormItem[]>,
        default:[{label:"操作",type: "input", value: "kw", placeholder: '查询关键字', span:3,visible:true}]
      }
    },
    setup(props,context){
      const instance = getCurrentInstance()
      const cascader = ref(null)
      const state:{ [key: string]: any }= reactive({
        form:{},
        oldForm:{},
        items:[],
      })
      const resetForm=()=> {
        nextTick(()=>{
          let name=props.setFormInfo.refName||'editForm';
          if (instance && instance.refs[name]) {
            // @ts-ignore
            instance.refs[name].resetFields()
          }
          let form:{[key: string]: any}={};
          for (const obj of props.formItemList) {
            form[obj.value] = obj.defaultValue ?? "";
          }
          state.form = form;
        })
      }
      watch(() => props.formItemList, (toPath) => {
        state.form={};
        for (const obj of props.formItemList) {
          state.form[obj.value] = obj.defaultValue??'';
        }
        state.oldForm=JSON.parse(JSON.stringify(state.form));
        resetForm();
      },{immediate: true,deep: true});
      onMounted(() => {
        for (const obj of props.formItemList) {
          state.form[obj.value] = obj.defaultValue??'';
        }
        state.oldForm=JSON.parse(JSON.stringify(state.form));
      });
      const emitdata=(val:any)=>{context.emit('send', val);}
      const cityChange=(val:any)=>{
        for (const obj of props.formItemList) {
          if(obj.type=='address'){
            state.form[obj.value] = val.cityValue;
          }
        }
      }
      const changeForm=(type:any,btnValue:string)=>{
        let oldData=btnValue=='timelist'?{oldData:state.oldForm}:{};
        context.emit('changeform',{...formatData(),btnclick:type?true:false,btnValue:btnValue,refName:props.setFormInfo.refName||'editForm',...oldData})
      }
      const validateForm=(refName:string,msg?:string)=> {
        let name=refName||'editForm';
        return new Promise((resolve) => {
          if (instance && instance.refs[name]) {
             let refElement = instance.refs[name] as any;
            refElement.validate((valid:any) => {
              if (valid) {
                resolve(formatData());
              } else if (msg) {
               showTipMessage(msg)
              } else {
                console.log('验证不通过',)
              }
            });
          }
        });
      }
      const formatData=()=>{
        let postData=JSON.parse(JSON.stringify(state.form));
        props.formItemList.forEach((item:{[key: string]: any }) => {
          if (item.visible === false && item.value in postData) {
            delete postData[item.value];
          }
          if(item.type=='address'&&Array.isArray(cascader.value)){
            // @ts-ignore
            postData[item.value]=cascader.value[0]?.cityValue;
            if(item.addressOptions.backObj){
              // @ts-ignore
              postData[item.value+'Text']=cascader.value[0]?.cityObj;
            }
          }
        });
        if (Array.isArray(postData.createtime) && postData.createtime.length === 2) {
          postData.dateFrom = postData.createtime[0];
          postData.dateTo = postData.createtime[1];
          delete postData.createtime;
        }
        return postData;
      }

      return {
        ...toRefs(state),
        instance,
        cascader,
        emitdata,
        cityChange,
        changeForm,
        validateForm,
        formatData,
        resetForm
      }
    }
  })
</script>

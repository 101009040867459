import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_Link = _resolveComponent("Link")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_Vform = _resolveComponent("Vform")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_scrollbar, {
      class: "left-side",
      id: "left-side",
      style: _normalizeStyle(!_ctx.iscollapse?'left:0px':'left:-260px')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, null, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "menuSearch",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPage=true)),
              style: _normalizeStyle(_ctx.iscollapse?'justify-content: center':'justify-content: space-between')
            }, [
              (!_ctx.iscollapse)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "搜索菜单快捷跳转"))
                : _createCommentVNode("", true),
              _createVNode(_component_el_icon, { size: "14" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Search)
                ]),
                _: 1
              })
            ], 4),
            _createVNode(_component_el_menu, {
              "active-text-color": "#28a3ef",
              "background-color": "263238",
              "collapse-transition": false,
              "unique-opened": true,
              "default-active": _ctx.curSideMenu,
              class: "el-menu-side",
              "text-color": "#999",
              ref: "menu",
              onSelect: _ctx.handleMenuSelect
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideMenu, (item, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
                    (!item.children)
                      ? (_openBlock(), _createBlock(_component_el_menu_item, {
                          key: 0,
                          index: item
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["index"]))
                      : _createCommentVNode("", true),
                    (item.children)
                      ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                          key: 1,
                          index: item.code
                        }, {
                          title: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(i>_ctx.iconList.length-1?'EditPen':_ctx.iconList[i])))
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("span", null, _toDisplayString(item.title), 1)
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (subItem) => {
                              return (_openBlock(), _createBlock(_component_el_menu_item, {
                                key: subItem,
                                index: subItem.code
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_icon, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Link)
                                    ]),
                                    _: 1
                                  }),
                                  _createTextVNode(_toDisplayString(subItem.title), 1)
                                ]),
                                _: 2
                              }, 1032, ["index"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["index"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ]),
              _: 1
            }, 8, ["default-active", "onSelect"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["style"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showPage,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showPage) = $event)),
      title: "功能检索与快捷启动",
      width: "550px",
      style: {"padding-bottom":"10px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Vform, {
          ref: "menuform",
          formItemList: _ctx.formItemList,
          setFormInfo: {inline:false,labelWidth:'70px',formRules:_ctx.rules}
        }, {
          formButton: _withCtx((formBtn) => [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: ($event: any) => (_ctx.onSubmit(formBtn.validateForm))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("跳转页面")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["formItemList", "setFormInfo"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
<template>
  <div class="tags">
    <el-tabs class="tab-bar" v-model="curTab"  @tab-change="handleClick"  @tab-remove="closeTags">
      <el-tab-pane label="首页"  name='/'  key="home" ></el-tab-pane>
      <el-tab-pane v-for="(item, index) in list" :label="item.title" :name='item.path' :key="index" :closable="true"></el-tab-pane>
    </el-tabs>
    <div class="tags-close-box">
      <el-dropdown @command="handleTags">
        <span size="small"  style="outline: none">
          <el-icon class="el-icon--right" size="20">
            <Fold />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu size="small">
            <el-dropdown-item command="other">关闭其他</el-dropdown-item>
            <el-dropdown-item command="all">关闭所有</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, toRefs, watch} from "vue";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
export default defineComponent({
  setup() {
     type State = {
       curTab:string,
       showPage:boolean,
       route:{ [key: string]: any }
    }
    const router = useRouter();
    const store = useStore();
    const state:State = reactive({
      curTab:'',
      showPage: false,
      route:{}
    })
    const setTags=(route:any)=>{
      state.route=route;
      state.curTab= decodeURI(route.fullPath);
      const isExist = list.value.some((item:any) => {
          return item.path === decodeURI(route.fullPath);
      });
      changeTab(route,isExist);
      if (!isExist) {
        let name='';
        sideMenuList.value.map((item:any)=>{
          let url=item.url.substring(item.url.indexOf('#')+1,item.url.length);
          if(`/${url}`==route.fullPath){
            name=item.title;
          }
        })
        if(!name&&list.value.length==0) router.push('/')
        if(name&&route.fullPath!=='/'){
          store.dispatch('tags/setTagsItem',{
            name: route.name,
            title: name,
            path: route.fullPath
          });
        }
      }
    }
    const changeTab=(route:any,isExist:any)=>{
      let menu= sideMenuList.value.find((v:any) =>{let url=v.url.substring(v.url.indexOf('#')+1,v.url.length);return`/${url}`==decodeURI(route.fullPath);});
      if(!menu){menu=list.value.find((v:any) =>{return decodeURI(v.path)==decodeURI(route.fullPath);});}
      if(menu&&(menu.lv==3||menu.type)){
        let pMenu=sideMenuList.value.find((v:any) =>v.code == menu.parent_code);
        let rMenu=store.state.menu.topMenu.find((v:any) =>v.code == pMenu.parent_code);
        let fMenu, breadcrumbInfo=[rMenu?.title,pMenu?.title,menu?.title];
        if(menu.type=='child'){
           pMenu=sideMenuList.value.find((v:any) =>v.code == menu.parent_code);
           rMenu=store.state.menu.topMenu.find((v:any) =>v.code == menu.top_code);
           fMenu=sideMenuList.value.find((v:any) =>v.code == pMenu.parent_code);
           breadcrumbInfo=[rMenu?.title,fMenu?.title,pMenu?.title,menu?.title]
        }
        store.commit('menu/setBreadcrumb',breadcrumbInfo);
        store.commit('menu/changeTopId',!menu.top_code?rMenu.code:menu.top_code);
        store.commit('menu/changeSideId',!menu.top_code?menu.code:menu.parent_code);
      } else{
        if(isExist&&route.path!='/'&&store.state.menu.topId){return}
        store.commit('menu/changeTopId',menus.value[0]?.code);
        store.commit('menu/changeSideId','');
      }
    }
    const list = computed(() => store.state.tags.list);
    const sideMenuList = computed(() =>store.state.menu.sideMenu||'');
    const menus = computed(() =>store.state.menu.topMenu);
    const curUrl = computed(() => store.state.menu.breadcrumb);
    watch(() => router.currentRoute.value.path, (toPath) => {
          if(sessionStorage.token){
             setTags(router.currentRoute.value);
         }
    },{immediate: true,deep: true});
    watch(() => menus.value, (newVal: any) => {
      if(sessionStorage.token) {
          setTags(router.currentRoute.value);
        }
    },{immediate:true, deep: true });
    const closeTags = (path:any)=>{
      let index=list.value.findIndex((val:any)=>{return val.path==path})
       store.dispatch('tags/delTagsItem',index);
      const item = list.value[index] ? list.value[index] : list.value[index - 1];
      if (item) {
        router.push(item.path);
      } else {
        router.push('/');
      }
    }
    const handleTags=(command:any)=>{
      command === 'other' ? closeOther() : closeAll();
    }
    const closeAll=()=>{
      store.dispatch('tags/clearTags');
      router.push('/');
    }
    const closeOther=()=> {
      const curItem = list.value.filter((item:any) => {
        let activePath=item.params?item.path+'/'+item.params:item.path
        return activePath === state.route.fullPath;
      });
      store.dispatch('tags/closeTagsOther',curItem);
    }
    const queryParamsToObject=(query:any)=> {
      const params = new URLSearchParams(query);
      const result:{[key:string]:any} = {};
      for (const [key, value] of params.entries()) {
        result[key] = value;
      }
      return result;
    }
    const handleClick=(hash:any)=>{
      let params={}
      if(hash.indexOf('?')!=-1){
        let query = hash.split('?')[1];
        params = queryParamsToObject(query);
        router.push({path:hash,query:params})
      }else{
        router.push({path:hash})
      }
    }
    return {
      ...toRefs(state),
      sideMenuList,
      list,
      menus,
      curUrl,
      setTags,
      changeTab,
      closeTags,
      handleTags,
      closeAll,
      closeOther,
      handleClick,
      queryParamsToObject
    }
  }
});
</script>

<style>
.tags {
  display:flex ;
  /*border:1px solid red;*/
  position: absolute;
  width:100%;
  top:0;
  box-sizing: border-box;
  height: 30px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 5px 10px #ddd;
}
.tags .tab-bar{width:calc(100% - 10px);}
.tags-close-box {
  position: absolute;
  right: 0px;
  top: 0;
  box-sizing: border-box;
  padding-top: 3px;
  text-align: center;
  width: 40px;
  height: 30px;
  background: #fff;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>

<template>
  <div>
  <el-descriptions size="small" :border="true"  :column="3" class="uniform-description">
    <el-descriptions-item label="运营商" label-class-name="labelName">
      <el-tag size="small"> {{order.operatorName}}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="价格组编码">{{order.groupCode}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="价格组名">{{order.groupName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="渠道">{{order.clientChannelName}}&nbsp;</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="区域" :span="2">{{order.allowCoverageAreaName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="仓库" :span="3">{{order.warehouseName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="开始时间">{{order.startTime}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="结束时间">{{order.endTime}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="状态">
      <span :class="['textsuccess','textsuccess','textwarning','textdanger'][[3,2,1,4].indexOf(order.groupState)]">
        {{order.groupStateName||'已确认'}}
      </span>
    </el-descriptions-item>
  </el-descriptions>
  <br/>
  <mainTable :mainOptions="mainData" ref="mainTable">
    <template v-slot:operation="actionBtn">
      <el-radio-group v-model="actionBtn.data.isSelect" v-if="order.groupState==1">
        <el-radio :label="1" size="small">通过</el-radio>
        <el-radio :label="2" size="small">拒绝</el-radio>
      </el-radio-group>
    </template>
  </mainTable>
  <div style="text-align:center;padding:30px;" v-if="order.operatorName">
    <el-button type="primary" @click="NextItem" :disabled="canEdit" style="margin-right:40px;">下一个</el-button>
    <el-button type="success" @click="submitForm('agree')" :disabled="canEdit" v-if="order.groupState==1">全部同意</el-button>
    <el-button type="danger"  @click="submitForm('disagree')" :disabled="canEdit" v-if="order.groupState==1">全部拒绝</el-button>
    <el-button type="primary" v-on:click="submitForm('submit')" :disabled="canEdit" v-if="order.groupState==1">提交</el-button>
  </div>
  </div>
  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo" :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="saveItem"></rejectPopup>
</template>
<script>

export default {
  props: {
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    }
  },
  data() {
    return {
      baseApiUrl: '/promotion',
      groupId:'',
      previd:'',
      isMarket:'false',
      form: {freightUnit:'件'},
      disagreeDialog: false,
      agreeDialog:false,
      canEdit: false,
      rejectInfo:{title:'未通过的原因',value:'remark',label:'理由'},
      rules: {
        freightUnit: [{ required: true, message: "运费单位不能为空", trigger: "blur" }],
        freightPrice: [{ required: true, message: "请输入运费单价", trigger: "blur" }]
      },
      order: {},
      modeType:'',
      amendColObj:{},
      mainData: {
        DataList:[],
        tableHeight:'320',
        Total:0,
        labelList: [
          { label: "商品名称", width: 260, prop: "itemSerialName" },
          { label: "状态", width: 80, callBack:(item)=>{ return item.detailStateName},getColor:(item)=>{return item.detailState==1?'textsuccess':'textdanger'}},
          { label: "单价", Width: 100,prop: "unitPrice" },
          { label: "运费（元）", width: 100, prop: "freightPrice" },
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',minWidth:"180"},
        ]
      }
    }
  },
  created(){
    this.groupId=this.params.groupId;
    this.order=this.params;
    this.loadOrder();
  },
  watch:{
    params(v){
      let isChange = this.order.groupId != v.groupId;
      if (isChange)  this.groupId=this.params.groupId; this.order=this.params; setTimeout(() => { this.loadOrder(); }, 300);
    }
  },
  methods: {
    NextItem(){
      this.loadOrder('NextItem')
    },
    loadOrder(whoCall) {
      let postData=whoCall == 'NextItem'?{previd:this.groupId,type:1}:{groupCode:this.params.groupCode,type:1};
      let url=whoCall == 'NextItem'? '/GetNextChannelPriceGroupProducts': '/GetChannelPriceGroupProducts';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,postData).then((res) => {
        this.canEdit=false;
        if(!res.data||(res.data&&res.data.length==0)){
          if(whoCall == 'NextItem'){
            this.showTipMessage('暂无记录','warning');
          }else{
            this.mainData.DataList = [];
          }
          return;
        }
        if(whoCall == 'NextItem'){
          this.order= res.ext.group;
          this.groupId=res.ext.group.groupId;
        }
        this.mainData.DataList = res.data;
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    submitForm(type) {
      this.modeType=type;
       switch (type) {
         case 'agree':
           this.showConfirm('是否全部同意？',()=>{
             this.saveItem(type)
           })
           break;
         case 'disagree':
           this.disagreeDialog=true;
           this.rejectInfo.title="请填写全部拒绝备注"
           break;
         case 'submit':
           // eslint-disable-next-line no-case-declarations
           let data=this.mainData.DataList.findIndex((item)=>{return !item.isSelect});
           // eslint-disable-next-line no-case-declarations
           let disAgreeData=this.mainData.DataList.findIndex((item)=>{return item.isSelect==2});
           if (data!=-1) {
             this.showTipMessage('有未审核的记录！','warning');
             return;
           } else if(disAgreeData!=-1){
             this.disagreeDialog=true;
             this.rejectInfo.title="请填写审核的原因"
           }else{
             this.saveItem();
           }

       }
    },
    saveItem(value) {
      let postData;
      if (this.modeType=='agree') {
        postData = {groupCode: this.order.groupCode, groupState: 3, product: '',auditeRemark:''};
      }else if (this.modeType=='disagree'){
          postData = {groupCode: this.order.groupCode, groupState:4, product:'',auditeRemark:value.remark};
      }else{
        var product={};
        this.mainData.DataList.forEach(item => {
              product[item.itemSerialCode]=item.isSelect;
        })
        postData = {groupCode: this.order.groupCode, groupState:2, product:JSON.stringify(product),auditeRemark:(value?.remark||'')};
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl+"/UpdateStateChannelPriceGroup" ,postData).then(() => {
        this.showTipMessage('提交成功!','success');
        this.disagreeDialog=this.agreeDialog=false;
        this.canEdit=false;
        this.$emit('closeDialog');
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

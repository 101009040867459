<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button-group>
        <el-button type="success" size="small" @click="submitItem('','add')">添加</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.state=='1'?'success':'primary'" plain size="small"
                     @click="submitItem(actionBtn.data,'edit')">{{ actionBtn.data.state == '1' ? '查看' : '编辑' }}
          </el-button>
          <el-button type="warning" plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit"
                     v-if="actionBtn.data.state=='1'">禁用
          </el-button>
          <el-button type="success" plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit"
                     v-if="actionBtn.data.state=='0'">启用
          </el-button>
          <el-button type="primary" plain size="small" @click="submitItem(actionBtn.data,'service')">运营信息</el-button>
          <el-button type="danger" plain size="small" @click="submitItem(actionBtn.data,'del')">删除</el-button>
          <el-button type="success" plain size="small" @click="toPage(actionBtn.data)">设置品类</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showService" :title="title" width="700">
      <h4 class="labelTitle">区域查询</h4>
      <Vform :formItemList="serviceItem"
             ref="vform"
             :setFormInfo="{inline:true,labelWidth:'80px',formRules:rules}"
             @changeform="changeform"
             @send="getAddressData">
        <template v-slot:formButton="formBtn">
          <el-form-item>
            <el-button type="success" @click="AddAreaTag(formBtn.validateForm)" :disabled="canEdit"
                       style="margin-left: 60px">添加区域
            </el-button>
          </el-form-item>
          <h4 class="labelTitle">选中区域</h4>
          <div style="padding-left:20px">
            <el-tag v-for="(tag,i) in tags" closable :key="tag.name"
                    class="mx-1"
                    :type="tag.type"
                    style="margin-right:10px;margin-bottom:8px;"
                    @close="tags.splice(i, 1)">{{ tag.name }}
            </el-tag>
          </div>
          <br>
          <h4 class="labelTitle">滚动公告</h4>
          <el-form-item label="公告内容" style="width:100%">
            <el-input v-model="rollingAnnouncement" type="textarea" placeholder="请输入公告不超过50字"/>
          </el-form-item>
          <h4 class="labelTitle">运费参考设置</h4>
          <el-form-item label="运费图片" style="width:100%">
            <uploader :url="uploadOpt.url"
                      :key="uploadOpt.fileList"
                      :isImg="uploadOpt.isImg"
                      :isResetting="uploadOpt.isResetting"
                      :fileList="uploadOpt.fileList"
                      :postData="uploadOpt.data"
                      :accept="uploadOpt.accept"
                      @complete="uploadFinish"
                      @imgchange="imgchange">

            </uploader>
          </el-form-item>
        </template>
      </Vform>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" v-on:click="submitForm()" :disabled="canEdit">确定提交</el-button>
            <el-button type="danger" v-on:click="showService = false">关闭</el-button>
          </span>
      </template>
    </el-dialog>
    <!--新增&编辑-->
    <el-dialog v-model="showDetailPage" :title="title" width="700">
      <Vform :formItemList="editInfoItem"
             ref="editform"
             :setFormInfo="{inline:true,labelWidth:'80px',formRules:rules,isDisabled:modeType=='edit'&&curItem.state==1}"
             @changeform="changeform">
      </Vform>
      <template #footer v-if="modeType=='add'||(modeType=='edit'&&curItem.state==0)">
          <span class="dialog-footer">
            <el-button type="primary" v-on:click="submitForm()" :disabled="canEdit">确定提交</el-button>
            <el-button type="danger" v-on:click="showDetailPage = false">关闭</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {cloneObject, formatAddressData, compareObjects} from '@/utils/common'

export default {
  data() {
    return {
      tags: [],
      baseApiUrl: '/system',
      searchData: {},
      searchItemList: [
        {type: "input", value: "kw", placeholder: '查询关键字', width: '160'}
      ],
      showDetailPage: false,
      showService: false,
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          {label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "360", fixed: 'left'},
          {label: "商圈编码", width: 260, prop: "code"},
          {label: "商圈名称", minWidth: 100, prop: "name"},
          {
            label: "状态", width: 60, callBack: (item) => {
              return ['禁用', '启用'][item.state]
            }, getColor: (item) => {
              return item.state == '0' ? 'textdanger' : 'textsuccess'
            }
          }
        ]
      },
      modeType: '',
      serviceForm: {},
      addressList: [],// 地址数据
      oldservice: {},
      serviceInfo: [
        {
          label: "行政级别",
          type: "radio",
          value: "citytype",
          defaultValue: 3,
          selectOptions: [{Id: 3, Name: '县市'}, {Id: 4, Name: '街镇'}],
          isChange: true,
          span: 1
        },
        {
          label: "地区",
          type: "address",
          value: "serviceArea",
          span: 1,
          placeholder: '请选择区域',
          addressOptions: {
            checkStrictly: true,
            ismultiple: true,
            isShowAll: false,
            emitData: true,
            citytype: 3,
            backObj: true
          }
        }],
      editInfo: [
        {label: "商圈名称", type: "input", value: "name", span: 1},
        {label: "联系人", type: "input", value: "contactName", span: 1},
        {label: "联系电话", type: "input", value: "contactPhone", span: 1}],
      FormItems: [],
      rollingAnnouncement: '',
      fileList: [],
      uploadOpt: {
        isImg: true,
        isResetting: false,
        fileList: [],
        domainUrl: '',
        url: '',
        accept: 'image/*',
        data: {}
      },
      title: '',
      curItem: {},
      canEdit: false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      rules: {
        name: [{required: true, message: "请输入商圈名称", trigger: "blur"}],
        contactName: [{required: true, message: "请输入联系人", trigger: "blur"}],
        contactPhone: [
          {required: true, message: "请输入联系电话", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确，请输入11位手机号', trigger: "blur"}
        ]
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    serviceItem() {
      return this.serviceInfo.map((item) =>{
        return {
          ...item,
          defaultValue:this.serviceForm[item.value]??item.defaultValue??''
        }
      })
    },
    editInfoItem() {
      return this.editInfo.map((item) => {
        return {
          ...item,
          defaultValue: this.curItem[item.value] ?? item.defaultValue ?? ''
        }
      })
    }
  },
  mounted() {
    this.commonAjax();
  },
  methods: {
    commonAjax() {
      this.$store.dispatch('cache/loadCacheData', this.CacheKeys.ImgProof).then((d) => {
        this.uploadOpt.data.policy = d.policy;
        this.uploadOpt.data.authorization = d.auth;
        this.uploadOpt.url = d.host;
        this.uploadOpt.domainUrl = d.domain;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/QueryMerchantData', {...this.searchData, ...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    // 运营信息，编辑，添加
    submitItem(d, type) {
      let url = '', postData = {}, msg = '', title = '';
      this.modeType = type;
      switch (type) {
        case 'upState':
          url = '/MerchantUpdateState';
          postData = {code: d.code, state: d.state == '0' ? 1 : 0};
          msg = d.state == '0' ? '启用成功!' : '禁用成功!'
          title = d.state == '0' ? `确认启用【${d.name}】吗？` : `确认禁用【${d.name}】吗？`
          break;
        case 'del':
          url = '/MerchantInfoDelete';
          postData = {code: d.code};
          msg = '删除成功'
          title = `确认删除【${d.name}】商圈吗？`
          break
        case 'service':
          this.title = `商圈区域设置--【${d.name}】`;
          this.showService = true;
          this.serviceInfo[1].addressOptions.citytype = 3;
          this.serviceForm = {};
          this.oldservice = {
            code: d.code,
            areas: d.coverageAreaCode && d.coverageAreaCode.join(','),
            rollingAnnouncement: d.rollingAnnouncement,
            url: d.url
          };
          this.curItem = d;
          this.rollingAnnouncement = d.rollingAnnouncement;
          this.fileList = [];
          if (d.url) {
            this.uploadOpt.fileList = [{name: '运费设置图片.jpg', url: d.url, status: 'success'}];
            this.imageUrl = d.url;
          } else {
            this.uploadOpt.fileList = [];
            this.imageUrl = '';
          }
          this.fileList = JSON.parse(JSON.stringify(this.uploadOpt.fileList));
          if (this.addressList.length != 0) {
            this.tags = formatAddressData(this.addressList, this.curItem.coverageAreaCode);
          }
          return;
          break
        case 'edit':
          this.title = d.state == '1' ? `查看商圈-【${d.name}】` : `编辑商圈-【${d.name}】`;
          this.showDetailPage = true;
          this.curItem = d;
          return;
          break
        case 'add':
          this.title = `添加商圈`;
          this.showDetailPage = true;
          this.curItem = {};
          return;
          break
      }
      this.showConfirm(title, () => {
        this.canEdit = true;
        this.$http.post(this.baseApiUrl + url, postData).then(() => {
          this.showTipMessage(msg, 'success');
          this.canEdit = false;
          this.search();
        }, (res) => {
          this.canEdit = false;
          this.showTipMessage(res.msg || '未知错误', 'error');
        });
      })
    },
    getAddressData(list) {
      if (this.addressList.length == 0) {
        this.addressList = list;
      }
      if ((!this.serviceForm.serviceArea || this.serviceForm.serviceArea.length == 0) && this.curItem.coverageAreaCode) {
        this.tags = formatAddressData(this.addressList, this.curItem.coverageAreaCode);
      }
    },

    // 添加服务区域
    async AddAreaTag(validate) {
      const formData = await validate()
      let selectData = cloneObject(formData.serviceArea);
      if (selectData.length == 0 || !selectData[0]) {
        this.showTipMessage('请先选择区域', 'warning');
        return;
      }
      let allHave=selectData.every((item)=> {
        return this.tags.some((tagItem)=> {
          return tagItem.id === item;
        });
      });
      if (allHave) {this.showTipMessage('所选的区域已被添加', 'warning');return;}
      let hadArea = false;
      selectData.forEach((item) => {
        for (let i = 0; i < this.tags.length; i++) {
          let curCode = item.toString();
          let code = this.tags[i].id.toString()
          if (code.startsWith(curCode)) {
            this.tags.splice(i, 1);
            i--;
          } else if (curCode.startsWith(code)) {
            hadArea = true;
          }
        }
      })
      if (hadArea) {
        this.showTipMessage('所选的区域已被包含', 'warning');
        return;
      }
      let allTags = [...this.tags, ...formData.serviceAreaText];
      this.tags = allTags.filter((item, index, arr) => {
        return arr.findIndex(x => x.id == item.id) == index
      })
    },
    uploadFinish(obj) {
      if (obj.succ == true) {
        this.imageUrl = this.uploadOpt.domainUrl + obj.resp.url;
      } else {
        this.showTipMessage("网络问题，提交失败，请重新上传")
      }
    },
    imgchange(val) {
      this.fileList = JSON.parse(val);
      if (this.fileList.length == 0) {
        this.imageUrl = '';
      }
    },
    changeform(d) {
      if (this.modeType == 'service') {
        this.serviceForm = d;
        this.serviceInfo[1].addressOptions.citytype = d.citytype
      }
    },
    // 保存商圈信息
    async submitForm() {
      let postData = {}, url = '';
      if (this.modeType == 'service') {
        let serviceArea = [];
        this.tags.forEach((item) => {
          serviceArea.push(Number(item.id))
        });
        postData = {
          code: this.curItem.code,
          areas: serviceArea.join(','),
          rollingAnnouncement: this.rollingAnnouncement,
          url: this.imageUrl
        };
        url = '/MerchantUpdateArea';
        let flag = false;
        this.fileList.map((item) => {
          if (item.status != 'success') {
            flag = true;
          }
        })
        if (flag) {
          this.showTipMessage("存在未上传的图片，请点击【提交上传】", 'warning');
          return;
        }
        if (this.tags.length == 0) {
          this.showTipMessage("请先添加商圈服务区域", 'warning');
          return;
        }
        let res = compareObjects(postData, this.oldservice);
        if (res.length == 0) {
          this.showTipMessage("无修改项", 'warning');
          return;
        }
      } else {
        let formData = await this.$refs.editform.validateForm();
        let user = this.modeType == 'edit' ? (this.curItem.saasAccount != '' ? JSON.parse(this.curItem.saasAccount) : {}) : {};
        let d = {
          code: this.curItem.code || '',
          warehouseCode: this.curItem.warehouseCode || '',
          UserName: user.UserName || '',
          Password: user.Password || ''
        }
        postData = {...formData, ...d};
        url = this.modeType == 'edit' ? '/MerchantInfoEdit' : '/MerchantInfoAdd';
        let res = compareObjects(this.curItem, formData), flag = false;
        for (let key in formData) {
          if (res.indexOf(key) > -1) flag = true
        }
        if (this.modeType == 'edit' && !flag) {
          this.showTipMessage("无修改项", 'warning');
          return;
        }
      }
      this.canEdit = true;
      this.$http.post(this.baseApiUrl + url, postData).then(() => {
        this.showTipMessage(this.modeType == 'service' ? '保存商圈运营信息成功' : '保存商圈信息成功', 'success');
        this.canEdit = false;
        this.showDetailPage = false;
        this.showService=false;
        this.search();
      }, (res) => {
        this.canEdit = false;
        this.showTipMessage(res.msg || '未知错误', 'error');
      });
    },
    toPage(d) {
      this.$store.dispatch('tags/setTagsItem', {
        name: 'minapp-MerchantInfoManage-Category',
        title: '设置品类',
        path: '/minapp-MerchantInfoManage-Category?code=' + d.code + '&name=' + d.name,
        type: 'child'
      });
      this.$router.push({path: '/minapp-MerchantInfoManage-Category', query: {code: d.code, name: d.name}});
    }
  }
}
</script>

import request from '@/utils/http'
export interface postData {}
// 获取信息
export function getInfo() {
    return request({
        url: '/user/infoinit',
        method: 'post',
    })
}
export function getToken(data: string): Promise<any> {
    function jsonp(url: string, callbackName = 'callback'): Promise<any> {
        return new Promise((resolve, reject) => {
            let callbackId = 'jsonp_callback_' + Math.random().toString(36).substr(2);
            (window as any)[callbackId] = (responseData: any) => {
                resolve(responseData);
                delete (window as any)[callbackId];
                script.remove();
            };

            url += (url.includes('?') ? '&' : '?') + callbackName + '=' + callbackId;

            let script = document.createElement('script');
            script.src = url;
            script.async = true;
            script.onerror = () => {
                reject(new Error(`JSONP request failed for ${url}`));
                delete (window as any)[callbackId];
                script.remove();
            };
            document.head.appendChild(script);
        });
    }
    const baseUrl: string = process.env.VUE_APP_BASE_API || '';
    return jsonp(baseUrl + '/_sso/enter?_t=' + encodeURIComponent(data), 'callback');
}



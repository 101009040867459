<template>
  <div class="content_box" style="padding-top: 10px;">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/finance',
            orderNo:'',
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { prop: "orderNo", label: '采销单号', width: 200 },
                    { prop: "orderTypeName", label: '单据类型', width: 150 },
                    { prop: "operatorName", label: '运营商名称', width: 200 },
                    { label: '入账时间', width: 100,  callBack: (item)=>{
                            return this.$formatDate(item.tradedTime);
                        }
                    },
                    { prop: "productCode", label: '商品编码', width: 100 },
                    { prop: 'productName', label: '商品名称', width: 200 },
                    { prop: 'itemSerialCode', label: '条目编码', width: 100 },
                    { prop: 'unitPrice', label: '单价', width: 70 },
                    { prop: 'unitQty', label: '数量', width: 100 },
                    { prop: 'amount', label: '金额', width: 100 },

                    { prop: 'shipPrice', label: '运费', width: 100 },
                    { prop: 'totalAmt', label: '合计金额', width: 100 },
                    { prop: 'transactionSerialNum', label: '微信交易流水号', width: 230 },
                    { prop: 'collectionTime', label: '收款时间', width: 100 },
                    { prop: 'refundTime', label: '退款时间', width: 100 },
                    { prop: 'refundAmount', label: '退款金额', width: 100 },

                    { prop: 'spec', label: '规格', width: 70 },
                    { prop: 'barcode', label: '商品条码', width: 120 },
                    { prop: 'warehouseName', label: '仓库', width: 150 },
                    { prop: 'unit', label: '计价单位', width: 100 },
                    { prop: 'packNum', label: '包装数量', width: 100 },
                    { prop: 'packUnit', label: '包装单位', width: 100 },
                    { prop: 'batch', label: '批次', width: 100 },
                    { prop: 'remark', label: '备注', minWidth: 100 },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    props:{
        params:Object
    },
    created() {
    this.orderNo=this.$route.query.orderNo
    },
    mounted() {
        this.getListData()
    },
    methods: {
        getListData() {
          this.$http.post(this.baseApiUrl + '/GetWholesaleRevenuePSDetails', {...this.pageinfo,orderNo:this.orderNo}).then((res) => {
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({orderNo:this.orderNo}, this.baseApiUrl+'/GetWholesaleRevenuePSDetails')
        },
    }
}
</script>

<!--可配置项：
  isSearch 是否显示查询模块
  title tree模块标题
  treeData tree模块数据
-->
<template>
  <div class="treeBox" >
    <div class="treeHeader">
      <span>{{title}}</span>
      <el-input v-model="filterText" placeholder="搜索品牌名称" size="small" clearable prefix-icon="Search" style="width:140px" v-if="isSearch"/>
    </div>
    <el-tree :data="treeData"
        :default-expanded-keys="defaultExpanded"
        :props="defaultProps"
        node-key="id"
        :show-checkbox="showCheckbox"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
        class="treeContent"
        :ref="refName"/>
  </div>
</template>
<script>
export default {
  name: "BaseSearch",
  props: {
    isSearch: {
      type: Boolean,
      default: false
    },
    showCheckbox:{
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '标题'
    },
    refName: {
      type: String,
      default: 'myTree'
    },
    treeData: {
      type: Array,
      default:[],
    },
    defaultExpanded: {
      type: Array,
      default:[],
    },
  },
  data() {
    return {
      filterText:'',
      selectedNode: null,
    };
  },
  watch:{
    'filterText'(val){
      this.$refs[this.refName].filter(val);
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    canclSelect(){
      this.$refs[this.refName].setCurrentKey(null)
    },
    handleNodeClick(item){
      this.$emit("nodeclick", item);
    },
    getTreeData(){
      return this.showCheckbox==true?this.$refs[this.refName].getCheckedNodes():this.$refs[this.refName].getCurrentNode();
    },
    resetChecked() {
      this.$refs[this.refName].setCheckedKeys([], false)
    },
    getExpandedArr(){
      let nodesMap = this.$refs[this.refName].store.nodesMap;
      let expandedNodes = Object.keys(nodesMap).filter(key => nodesMap[key].expanded);
      return expandedNodes;
    }
  }
};
</script>
<style>
.treeBox{width:100%;flex-grow: 1;border:1px solid #039BE5;border-radius: 5px;padding-bottom:10px;position: relative}
.treeBox .treeHeader{background:#039BE5;color:#fff;padding:8px 10px;display: flex;justify-content: space-between}
.green .treeBox{border:1px solid #a2e4de;}
.green .treeBox .treeHeader{background:#ddf3f5;color:#03b8cf}
.treeContent{position: absolute;width:calc(100% - 10px);height:calc(100% - 55px);top:50px;left:10px;overflow-y: scroll}
</style>

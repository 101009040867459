<template>
 <div class="content_box">
    <div class="search_bar" style="border-bottom: 3px solid #B0E0E6;">
        <el-button type="success" @click="OnImport" size="small">导入</el-button>
        <baseSearch :formItemList="searchItemList" @search="search"></baseSearch>
    </div>
    <div v-if="billAddedList.length !== 0">
        <el-form :model="accountForm" inline>
            <el-form-item label="收款账户:">
                <el-select v-model="accountForm.selfAccountName">
                    <el-option v-for="(item, index) in selfBankAccountIdAll" :key="index"
                               :label="item.Name"
                               :value="item.Name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="总金额:">
                <el-input v-model="accountForm.totalAmount" readonly></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="success" @click="onsubmitClick">提交</el-button>
            </el-form-item>
        </el-form>

        <div class="bill_detail" v-for="(item,i) in billList" :key="i">
            <div class="bill_detail_top">
                <span>
                    {{item.orderNo}} | {{item.paymentStateName}} | 订单金额:{{item.amount}} | 已收款:{{item.treatedAmount}} | 待核销审核:{{item.pendingWriteOff}} | 订单备注:{{item.remark}}
                </span>
                <span>
                    <el-button type="primary" size="small" @click="orderDetail(item)">详情</el-button>
                    <el-button type="danger" size="small" @click="deleteItem(i)">删除</el-button>
                </span>
            </div>
            <div class="bill_detail_bottom">
                <span>{{item.traderName}}</span>
                <span>金额:{{item.untreatedAmount}}</span>
                <span>实收: <el-input-number v-model="item.payMoney" :min="0" :controls="false" size="small" style="width:100px"/></span>
                <span>
                    <el-select v-model="item.selfAccountName" size="small" style="width: 120px;">
                        <el-option v-for="(item, index) in selfBankAccountIdAll" :key="index"
                                :label="item.Name"
                                :value="item.Name">
                        </el-option>
                    </el-select>
                </span>
                <span>备注: <el-input :ref="`orderRemark${i}`" v-model="item.orderRemark" size="small" style="width: 150px;"></el-input></span>
            </div>
        </div>
    </div>
</div>

<el-dialog v-model="showSearch" title="选择订单" width="1000">
    <mainTable :mainOptions="mainData" :showPage="false" ref="mainTable">
        <template v-slot:operation="actionBtn">
            <el-button type="primary" plain size="small" @click="addBill(actionBtn.data)">添加</el-button>
        </template>
    </mainTable>
    <div style="height: 10px;"></div>
</el-dialog>

<el-dialog v-model="showOrderDetail" title="出库单明细查看" width="800">
    <el-descriptions :column="3" size="small" border style="margin-bottom: 10px;">
        <el-descriptions-item label="单号:">{{orderDetailInfo.orderNo}}</el-descriptions-item>
        <el-descriptions-item label="订单类型:">销售出库单</el-descriptions-item>
        <el-descriptions-item label="订单状态:">{{orderDetailInfo.orderStateName}}</el-descriptions-item>
        <el-descriptions-item label="客户:">{{orderDetailInfo.traderName}}</el-descriptions-item>
        <el-descriptions-item label="金额:">￥{{orderDetailInfo.orderAmount || 0}}</el-descriptions-item>
        <el-descriptions-item label="运费:">￥{{orderDetailInfo.shipPriceTotal || 0}}</el-descriptions-item>
        <el-descriptions-item label="结算方式:">{{orderDetailInfo.payOffTypeName}}</el-descriptions-item>
        <el-descriptions-item label="备注:" :span="2">{{orderDetailInfo.remark}}</el-descriptions-item>
    </el-descriptions>

    <mainTable :mainOptions="orderDetails" :showPage="false"></mainTable>
    <div style="height: 10px;"></div>
</el-dialog>

<el-dialog v-model="showImport" title="批量导入订单" width="600px">
    <uploader :url="uploadOpt.url"
              filename="import_file"
               accept=".xls,.xlsx"
              :templateUrl="uploadOpt.templateUrl"
              :postData="uploadOpt.data"
              :tips="uploadOpt.tips"
              v-on:complete="uploadFinish"></uploader>
</el-dialog>
</template>
<style>
    .bill_detail{
        width: 80%;border: 1px solid #DDDDDD;margin-bottom: 10px;
    }
    .bill_detail_top{
        display: flex;justify-content: space-between;padding: 3px; border-bottom: 1px solid #DDDDDD; font-weight: bold; background-color: #F3F1F1;
    }
    .bill_detail_bottom{
        display: flex;justify-content: space-between;padding: 3px;
    }
</style>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/Finance',
            searchData: {},
            searchItemList: [
                { type: "input", value: "kw", placeholder: '查询单号' }
            ],
            mainData: {
                DataList: [],
                labelList: [
                    { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "70", fixed: 'left' },
                    { label: "单据编码", width: 180, prop: "billNo" },
                    { label: "业务单号", width: 210, prop: "orderNo", },
                    { label: "客户名称", width: 200, prop: "traderName", },
                    { label: "订单金额", width: 80, prop: "amount", },
                    { label: "已收金额", width: 80, prop: "treatedAmount", },
                    { label: "未收金额", minWidth: 80, prop: "untreatedAmount", },
                ]
            },
            //导入
            showImport:false,
            uploadOpt:{
                url: '/finance/ImportPlatformBillSubmit',
                templateUrl:'./templates/platform_bill.xlsx',
                tips: '提示：请选择上传表格，仅支持.xlsx后缀的文件',
                data: {}
            },

            // showSearch_dialog
            showSearch:false,
            billList:[],
            billAddedList:[],


            accountForm:{
                selfAccountName:'收款账户1',
                totalAmount:0
            },
            selfBankAccountIdAll:[
                { Code: "0", Name: "收款账户1" },
                { Code: "1", Name: "收款账户2" }
            ],

            // showOrderDetail_dialog
            showOrderDetail:false,
            orderDetails: {
                DataList: [],
                tableHeight:300,
                labelList: [
                    { label: "商品编码", width: 100, prop: "productCode" ,fixed: 'left'},
                    { label: "商品名称", width: 200, prop: "productName" ,fixed: 'left'},
                    { label: "批次", width: 80, prop: "batch"  ,fixed: 'left'},
                    { label: "规格", width: 50, prop: "spec" },
                    { label: "单价", width: 80, prop: "unitPrice" },
                    { label: "件价", width: 70, prop: "packPrice" },
                    { label: "数量", width: 70, prop: "unitQty" },
                    { label: "金额", width: 70, prop: "amount" },
                    { label: "运费", width: 50, prop: "shipPrice" },
                    { label: "运费补差", width: 70, prop: "shipPriceBalance" },
                    { label: "单位", width: 50, prop: "unit" },
                    { label: "包装单位", width: 70, prop: "packUnit" },
                    { label: "备注", width: 100, prop: "remark" },
                ]
            },
        }
    },
    watch:{
        'accountForm.selfAccountName'(newVal){
            this.billList.forEach(item => {
                item.selfAccountName = newVal
            });
        },
        billList:{
            handler() {
                this.accountForm.totalAmount = 0
                this.billList.forEach(item => {
                    this.accountForm.totalAmount += item.payMoney
                });
            },
            immediate:true,
            deep:true
        }
    },
    methods: {
        search(postData) {
            if (postData.kw.length < 4) {
                this.showTipMessage("请正确输入单号，长度至少10个字符")
                return
            }
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/PlatformBillList', { ...this.searchData,...{moduleType: 1,isManual: 0,isPayBill: 1}}).then((res)=>{
                if (!res.data.rows || res.data.rows.length == 0) {
                    return this.showTipMessage("找不到未付款的单据")
                }
                if (res.data.rows.length == 1) {
                    this.addBill(res.data.rows[0]);
                }else {
                    this.mainData.DataList = res.data.rows;
                    this.showSearch = true
                }
            });
        },
        //导入
        OnImport(){
            this.showImport = true;
        },
        uploadFinish(obj){
            if(obj.resp.succ){
                this.showTipMessage(obj.resp.data || '导入成功',"success")
                this.showImport = false
            }else{this.showTipMessage(obj.resp.msg)}
        },
        onsubmitClick(){
            let bills = []
            for (let [idx,item] of this.billList.entries()){
                if(Math.abs((item.amount - item.treatedAmount) - item.payMoney) > 0.5){
                    if (item.orderRemark.length < 2) {
                        this.$refs[`orderRemark${idx}`][0]?.focus()
                        return this.showTipMessage("订单金额差异，至少写两个字解释一下吧~")
                    }
                }
                item.unwriteOffAmount = item.payMoney > item.untreatedAmount - item.pendingWriteOff ? (item.payMoney - item.untreatedAmount + item.pendingWriteOff) : 0;//可核销金额
                bills.push({
                    orderNo: item.orderNo,
                    traderCode: item.traderCode,
                    sourceOrderType: item.sourceOrderType,
                    traderName: item.traderName,
                    receiptNo: item.billNo,
                    amount: item.payMoney,
                    pendingWriteOff: item.pendingWriteOff + item.payMoney - item.unwriteOffAmount,
                    unwriteOffAmount: item.unwriteOffAmount,
                    moduleType: 1,
                    remark: item.orderRemark,
                    isManual: 0,
                    source: 0,
                    selfCardNumber: item.selfCardNumber || '',
                    selfAccountName: item.selfAccountName
                })
            }
            this.showConfirm('是否提交订单?',()=>{
                this.$http.post(this.baseApiUrl + "/PayPlatformBillSubmit", { bills: JSON.stringify(bills) }).then(()=>{
                    this.showTipMessage("提交成功！","success")
                    this.billList = []
                    this.billAddedList = []
                });
            })
        },

        addBill(row){
            if (this.billAddedList.includes(row.orderNo)) {
                return this.showTipMessage("该订单已经在列表中")
            }
            this.billList.push(row)
            this.billAddedList.push(row.orderNo)
            this.billList.forEach((item)=>{
                item.selfAccountName = item.selfAccountName || this.accountForm.selfAccountName
                item.payMoney = item.payMoney || item.untreatedAmount
                item.orderRemark = item.orderRemark || ''
            })
            this.showSearch = false
        },
        orderDetail(row){
            this.$http.post(this.baseApiUrl + '/WarehouseOrderDetail', { orderNo: row.orderNo }).then((res)=>{
                this.orderDetailInfo = res.ext.order
                this.orderDetails.DataList = res.ext.details
                this.showOrderDetail = true
            })
        },

        deleteItem(idx){
            this.billList.splice(idx,1)
            this.billAddedList.splice(idx,1)
        },
    }
}
</script>

<template>
  <div class="container">
    <div class="search_bar" v-if="options.isSearch!=false">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-input v-model.trim="query.kw" placeholder="请输入关键字" :clearable="true" style="width:180px"></el-input>
        </el-form-item>
        <el-form-item style="margin-right: 0px;">
          <el-button type="primary"  @click="search"  :disabled="isbtn">查询</el-button>
          <el-button type="success"  @click="select"  :disabled="isbtn">选中</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable"></mainTable>
    </div>
  </div>
</template>
<style>
  .container{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
<script>
    export default{
        props:{
            options:Object,
            //options包含query{},tableHeight,url,showPage,labelList[]配置项
            //query{} 是该表格查询所需的参数 tableHeight是该表格的高度 url是该表格请求的地址 showPage是否展示分页器 labelList[]是el-table-column的配置项
        },
        emits:['selectItem','toBack'],
        data(){
            return{
                pageinfo:{},
                query:{},
                mainData:{},
                tableData:[],
                total:'',
                isbtn:false,
                pageSize:20,
                selectedItem:''
            }
        },
        watch: {
          'options.query': {
            handler() {
              this.pageinfo={
                pg_idx:this.options.query.pg_idx||1,
                pg_size:this.options.query.pg_size||this.pageSize
              }
            },
            deep: true
          }
        },
        mounted(){
            this.mainData.isMultiple=this.options.isMultiple;
            this.mainData.tableHeight=this.options.tableHeight;
            this.mainData.labelList=this.options.labelList;
            this.query.kw=this.options.query.kw;
            this.pageinfo={
              pg_idx:this.options.query.pg_idx||1,
              pg_size:this.options.query.pg_size||this.pageSize
            }
            this.search()
        },
        methods:{
            search(){
                this.isbtn=true
                this.$http.post(this.options.url,{...this.options.query,...this.pageinfo}).then((res)=> {
                  this.isbtn=false;
                  if (res?.ext?.details) {
                    this.mainData.DataList = res.ext.details;
                  } else if ('rows' in res.data) {
                    this.mainData.DataList = res.data.rows||[];
                  } else {
                    this.mainData.DataList = res.data;
                  }
                  this.mainData.Total = res.data.total;
                  if(this.options.queryLocal){
                    let data=JSON.parse(JSON.stringify(this.mainData.DataList));
                    this.mainData.DataList = this.options.query.kw ? data.filter(v => v[this.options.queryLocal].indexOf(this.options.query.kw) != -1) : this.mainData.DataList;
                  }

                },(res)=>{
                  this.showTipMessage(res.msg ? res.msg : "未知错误.");
                  this.isbtn=false;
                })
            },
          toBack(){
            this.$emit("toBack");
          },
           getPageCurrent(e){
                this.pageinfo.pg_idx = e;
                this.search()
            },
           select(){
               var data =this.$refs.mainTable.select();
               if(data){
                 this.$emit("selectItem",data);
                  return data;
               }
            }
        }
    }
</script>

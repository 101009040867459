export interface MenuItem {
    lv: number;
    url:string,
}
export type menuState ={
    iscollapse:boolean,
    topId: string;
    sideId: string;
    breadcrumb:[],
    sideMenu:MenuItem[];
    topMenu:MenuItem[];
}
const state: menuState={
    iscollapse:false,
    topId:sessionStorage.topId??'',
    sideId: sessionStorage.sideId??'',
    breadcrumb:[],
    topMenu:[],
    sideMenu:[]
}

const mutations = {
    setMenu: (state:menuState, menus:MenuItem[]) => {
        state.topMenu=menus.filter((item)=>item.lv==1);
        state.sideMenu=menus.filter((item)=>item.lv!=1);
    },
    setBreadcrumb: (state:menuState, info:[]) => {
        state.breadcrumb=info;
    },
    changeMenu: (state:menuState, menu:MenuItem[]) => {
      let index= state.sideMenu.findIndex((item)=>item.url==menu[0].url)
      if(index==-1){
          state.sideMenu.push(menu[0]);
      }
    },
    changeCollapse: (state:menuState,collapse:boolean) => {
        if(collapse!=undefined){
            state.iscollapse= collapse
        }else{
            state.iscollapse=!state.iscollapse
        }

    },
    changeTopId: (state:menuState, curTopId:string) => {
        state.topId=curTopId;
        sessionStorage.curTopId=curTopId;
    },
    changeSideId: (state:menuState, curSideId:string) => {
        state.sideId=curSideId;
        sessionStorage.sideId=curSideId;
    },
}
export default {
    namespaced: true,
    state,
    mutations,
}

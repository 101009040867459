<template>
  <div class="content_box">
      <div class="search_bar">
        <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
        <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
          <template v-slot:operation="actionBtn">
            <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data,'check')" >查看明细</el-button>
            <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data,'audit')" >审核</el-button>
            <el-button type="danger" plain size="small" @click="delItem(actionBtn.data)" >作废</el-button>
          </template>
        </mainTable>
      </div>
    </div>
    <el-dialog v-model="showDetailPage" :title="modeType=='audit'?'审核城批特价组':'特价组明细'" :fullscreen="modeType=='audit'?true:false"  width="70%" style="padding-bottom:20px;">
      <wholesale-ChannelPriceGroupAudit ref="editPage" :params="editParams"  @closeDialog="handleClose" v-if="modeType=='audit'"></wholesale-ChannelPriceGroupAudit>
      <mainTable :mainOptions="detailData" v-if="modeType=='check'" ></mainTable>
    </el-dialog>
</template>
<script>
import {getMappingName} from '@/utils/common'
import wholesaleChannelPriceGroupAudit from './wholesale-ChannelPriceGroupAudit'
export default {
  components: {
    wholesaleChannelPriceGroupAudit,
  },
  data() {
    return {
      baseApiUrl: '/promotion',
      dateBegin:this.$formatDate(0, -2),
      dateEnd:this.$formatDate(0,+2),
      ARR_saasChannelTypeData: [
        { Id: "0", Name: "风暴特价" },
        { Id: "1", Name: "优选秒杀" },
        // { Id: "2", Name: "旧货处理" },
      ],
      ARR_groupState: [
        // { Id: "0", Name: "未确认" },
        { Id: "1", Name: "已确认" },
        { Id: "2", Name: "部分通过" },
        { Id: "3", Name: "已通过" },
        { Id: "4", Name: "已拒绝" },
        // { Id: "6", Name: "同步中" },
        // { Id: "7", Name: "同步成功" },
        // { Id: "8", Name: "同步失败" },
        // { Id: "5", Name: "作废中" },
        { Id: "5", Name: "作废成功" },
        // { Id: "10", Name: "作废失败" },
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"210", fixed:'left'},
          { label: "运营商", width: 100, prop: "operatorName" },
          { label: "价格组编码", width: 100, prop: "groupCode" },
          { label: "价格组名", width: 160, prop: "groupName" },
          { label: "特价类型", width: 80,callBack:(item)=>{ return getMappingName(item.saasChannelType,this.ARR_saasChannelTypeData)},getColor:(item)=>{return ['textdanger','textwarning'][item.saasChannelType]}},
          { label: "开始时间", width: 150, prop: "startTime" },
          { label: "结束时间", width: 150, prop: "endTime" },
          { label: "区域", width: 100, prop: "allowCoverageAreaName" },
          { label: "渠道", width: 100, prop: "clientChannelName" },
          { label: "状态", width: 80,callBack:(item)=>{ return item.groupStateName},getColor:(item)=>{return ['textsuccess','textsuccess','textwarning','textdanger'][[3,2,1,4].indexOf(item.groupState)]}},
          { label: "审核人", width: 80, prop: "auditedBy" },
          { label: "审核时间", width: 150, prop: "auditedTime" },
          { label: "审核备注", minWidth: 150, prop: "auditRemark" },
        ]
      },
      modeType:'',
      detailData: {
        DataList:[],
        tableHeight:250,
        labelList: [
          { label: "商品名称", minWidth: 260, prop: "itemSerialName" },
          { label: "状态", width: 80,callBack:(item)=>{ return item.detailStateName},getColor:(item)=>{return ['textwarning','textsuccess','textdanger'][item.detailState]} },
          { label: "单价", width: 100, prop: "unitPrice" },
          { label: "运费（元）", width: 100, prop: "freightPrice" },
        ]
      },
      OperatorInfo:[],
      editParams:{},
      showDetailPage:false,
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "开始时间",type: "daterange", value: "createtime", defaultValue: [this.dateBegin, this.dateEnd]},
        {placeholder: "请选择运营商", type: "select", value: "operatorCode", selectOptions:this.OperatorInfo, multiple: false, keyValue:['Code','Name'],width:'160'},
        {placeholder: "请选择特价类型", type: "select", value: "saasChannelType", selectOptions: this.ARR_saasChannelTypeData,multiple: false,width:'120'},
        {placeholder: "请选择状态", type: "select", value: "groupState", selectOptions: this.ARR_groupState, multiple: true,width:'160'},
        {type: "input", value: "kw",placeholder:'查询价格组名'}
      ];
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
        this.OperatorInfo=res;
      });
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetChannelPriceGroups', {...this.searchData,...this.pageinfo,type:1}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    handleClose() {
      this.showDetailPage = false;
      this.search();
    },
    showDetail(d,type){
      this.modeType=type;
      if(type=='audit'){
        this.editParams=d;
      }else{
        this.$http.post(this.baseApiUrl+'/GetChannelPriceGroupProducts', {groupCode:d.groupCode,type:1}).then((res) => {
          this.detailData.DataList = res.data
        });
      }
      this.showDetailPage=true;
    },
    delItem(data) {
      this.showConfirm('是否作废此记录！',()=>{
        this.$http.post(this.baseApiUrl + "/UpdateStateChannelPriceGroup",  {groupCode: data.groupCode, groupState: 5, auditeRemark: "", product: ""}).then(() => {
          this.showTipMessage("作废已提交，稍后刷新列表！","success");
          this.search();
        });
      })
    }
  }
}
</script>

<!--可配置项：mainOptions
  Total：数据总条数
  mainPage：页数条数
	DataList: 显示的数据
	tableHeight:表格高度
	isMultiple:  是否允许多选
	isSerial:是否显示序号
	labelList:表格column属性，示例如下：
	labelList:[ {label: "状态", width:"80",prop:'stateName',callBack:(item)=>{return  item.stateName},getColor:(item)=>{return  item.supplierState== 0? "textdanger" : "textsuccess"}}]
  labelList项可配置：
  prop:对应列字段名称
  width：对应列的宽度
  sortable:是否可排序
  minWidth:对应列的最小宽度
  fixed:对应列是否固定
  callBack 自定义列显示内容
  getColor 列内容颜色
  header 值为tips，添加该项标题解释说明，不设置tips则无解释说明
  isEdit 是否可编辑
  type 值为slot 插槽，示例： {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"220", fixed:'right',},
	引用示例：
	 <mainTable :mainOptions="mainOptions" :mainPage="mainPage" @getPageCurrent="getPageCurrent" ref="mainTable">
      <template v-slot:operation="actionBtn"><el-button type="primary" size="small">修改</el-button></template>
   </mainTable>
	获取结果表格选中项：
	let data= this.$refs.mainTable.select()
-->
<template>
  <div class="tableBox">
    <el-table :data="mainOptions.DataList" border stripe :style='tableStyle' class="table-box" highlight-current-row size="small" @row-click="rowClick" ref="elTable" @selection-change="selectChange">
      <el-table-column type="selection" width="40" v-if="mainOptions.isMultiple"/>
      <el-table-column label="序号" align="center" type="index" :index="indexMethod" :fixed="setIndexFixed()" v-if="mainOptions.isSerial!=false" width="60"/>
      <el-table-column v-for="(item,i) in visibleColumns"
                       :key="i"
                       :label="item.label"
                       :sortable="item.sortable"
                       :prop="item.prop"
                       :width="item.width"
                       :fixed="item.fixed || false"
                       :align="item.align || 'left'"
                       :min-width="item.minWidth"
                       :show-overflow-tooltip="true">
        <template #default="scope" >
          <template v-if="item.callBack">
            <div style=" width: 30px; height: 30px;margin:0 auto;display: flex;align-items: center"  v-if="item.isHtml&&item.callBack(scope.row)"  @click="showPreviewFun(item.callBack(scope.row))">
              <el-image  style=" max-width: 100%;max-height: 100%;display: flex" :lazy='true' :src="item.callBack(scope.row)" fit="cover"/>
            </div>
            <span v-else :class="item.getColor?.(scope.row)" @click="item.getClick?.(scope.row)" >{{item.callBack(scope.row)}}</span>
          </template>
          <template  v-if="item.isEdit">
            <span v-if="!scope.row.iseditrow">{{scope.row[item.prop]}}</span>
            <el-input-number v-if="scope.row.iseditrow"
                             v-model="scope.row[item.prop]"
                             :precision="2" size="small"
                             :min="0"
                             :controls="false"
                             :style="`width:calc(${item.width}px - 20px)`"/>
          </template>
          <template  v-if="item.type=='slot'">
            <slot :name="item.slot_name" :data="scope.row" :index="scope.$index"></slot>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box" v-if="mainOptions?.Total && (mainOptions.showPage??true)">
      <el-pagination :current-page="mainPage?.pg_idx||1"
                     :page-size="mainPage?.pg_size||20" small background :total="mainOptions?.Total" @current-change="getPageCurrent" />
    </div>
  </div>
  <el-image-viewer v-if="showPreview" @close="showPreview=false" :url-list="[imgUrl]" />
</template>
<script>
export default{
  props:{
    mainOptions:Object,
    mainPage:Object,
  },
  emits:['selectChange','getPageCurrent'],
  data(){
    return{
      showPreview:false,
      tableData:[],
      tableStyle:{},
      imgUrl:'',
      total:'',
      pageSize:20,
      selectedItem:'',
      selectedList:[]
    }
  },
  watch: {
    'mainOptions.DataList': {
      handler() {
       this.selectedItem='';
      },
      deep: true
    },
    'mainOptions.tableHeight': {
      handler(val) {
        this.tableStyle= {
          height: (val||400)+'px'
        }
      },
      deep: true
    },
  },
  computed: {
    visibleColumns() {
      if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
        this.mainOptions.labelList.map((item)=>{
          item.fixed=false;
          return item;
        })
      }
      return this.mainOptions&&this.mainOptions.labelList&&this.mainOptions.labelList.filter(column => column.isShow!==false);
    }
  },
  mounted() {
    this.tableStyle= {
      height: (this.mainOptions.tableHeight||400)+'px'
    }
  },
  methods:{
    showPreviewFun(img){
      this.showPreview=true;
      this.imgUrl=img;
    },
    getPageCurrent(e){
      this.$emit("getPageCurrent",e);
    },
    rowClick(row){
      this.selectedItem = row
      if(this.mainOptions.rowClickSelect_kw){//行选中，rowClickSelect_kw用能表示row唯一的字段
        this.selectedList = this.$refs.elTable.getSelectionRows()
        const selected = this.selectedList.some(item => item[this.mainOptions.rowClickSelect_kw] === row[this.mainOptions.rowClickSelect_kw])
        if(!selected){
          this.selectedList.push(row)
          this.$refs.elTable.toggleRowSelection(row,true)
        }else{
          this.selectedList = this.selectedList.filter(item=>{
            return item[this.mainOptions.rowClickSelect_kw] !== row[this.mainOptions.rowClickSelect_kw]
          })
          this.$refs.elTable.toggleRowSelection(row,false)
        }
      }
    },
    selectChange(){
      var data =this.$refs.elTable.getSelectionRows();
      this.$emit("selectChange",data);
    },
    /** 自定义编号 */
    indexMethod(index){
      let pageNum = (this.mainPage?.pg_idx||1) - 1;
      if((pageNum!==-1 && pageNum!==0)){
        return (index + 1) + (pageNum  * (this.mainPage?.pg_size||20));
      }else{
        return (index + 1)
      }
    },
   setIndexFixed(){
     let data=this.mainOptions.labelList,flag=false;
     if(data){
       data.forEach((item)=>{
         if(item.fixed=='left'){
           flag=true;
         }
       })
       return !flag?false:'left';
     }
   },
    select(){
      if(this.mainOptions.isMultiple){
        var data =this.$refs.elTable.getSelectionRows()
        if (data.length==0) {
          this.showTipMessage("请选择需要操作的记录","warning");
          return;
        }
      }else{
        if(!this.selectedItem){
          this.showTipMessage("请选择需要操作的记录",'warning')
          return;
        }
      }
      let selectedItem =this.mainOptions.isMultiple?data:this.selectedItem;
      return selectedItem;
    }
  }
}
</script>

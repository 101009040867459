<template>
  <div class="content_box">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent">
              <template v-slot:operation="actionBtn">
                  <el-button type="primary" plain size="small" @click="detailItem(actionBtn.data)" >查看</el-button>
              </template>
          </mainTable>
      </div>
  </div>

  <el-dialog v-model="showDetailDialog" title="订单详情" width="800px" >
      <mainTable :mainOptions="detailTD" :showPage="false"></mainTable>
      <div style="height: 20px;"></div>
  </el-dialog>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/report',
            searchData: {},
            selectOptions:[],
            orderStateList:[
                { Code: "0", Name: "未审核" },
                { Code: "1", Name: "已审核" },
                { Code: "2", Name: "已入库" },
                { Code: "3", Name: "已付款" },
                { Code: "4", Name: "已出库" },
                { Code: "5", Name: "已收款" },
                { Code: "7", Name: "正在分拣" },
                { Code: "17", Name: "退款中" },
                { Code: "18", Name: "已退款" }
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "80", fixed: 'left' },
                    { label: "订单编码", width: 210, prop: "orderNo" },
                    { label: "仓库名称", width: 130, prop: "warehouseName" },
                    { label: "客户编码", width: 200, prop: "traderCode" },
                    { label: "客户/供应商名称", width: 200, prop: "traderName" },
                    { label: "金额", width: 80, prop: "orderAmount" },
                    { label: "总运费", width: 70, prop: "shipPriceTotal" },
                    { label: "明细数量", width: 70, prop: "detailCount" },
                    { label: '状态', width: 60, prop: "orderStateName" },
                    { label: "订单类型", width: 120, prop: "orderTypeName" },
                    {
                        label: "订单来源类型", width: 100, prop: "orderSourceType",callBack: (item)=>{
                            return item.orderSourceType == 0 ? "城批" : "驿站";
                        }
                    },
                    {
                        label: "销售类型", width: 80, prop: "saleType", callBack: (item)=>{
                            return item.saleType == 0 ? "采购" : "代配送";
                        }
                    },
                    { label: "业务员编码", width: 150, prop: "salerCode" },
                    { label: "业务员姓名", width: 90, prop: "salerName" },
                    { label: "客户渠道类型", width: 100, prop: "clientTypeName" },
                    { label: "联系电话", width: 120, prop: "phone" },
                    { label: "微信交易流水号", width: 200, prop: "transactionSerialNum" },
                    { label: "备注", width: 150, prop: "remark" },
                    { label: "创建人", width:70, prop: "createdBy" },
                    { label: "创建时间", width: 150, prop: "createdTime" },
                    { label: "审核人", width: 70, prop: "auditedBy" },
                    { label: "审核时间", minWidth: 150, prop: "auditedTime" }
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },

            //showDetailDialog
            showDetailDialog:false,
            detailTD:{
                DataList: [],
                tableHeight:250,
                labelList: [
                    { label: "商品编码", width: 90, prop: "productCode" },
                    { label: "商品名称", width: 200, prop: "productName" },
                    { label: "批次", width: 90, prop: "batch" },
                    { label: "规格", width: 70, prop: "spec" },
                    { label: "单价", width: 60, prop: "unitPrice" },
                    { label: "件价", width: 70, prop: "packPrice" },
                    { label: "数量", width: 60, prop: "unitQty" },
                    { label: "金额", width: 70, prop: "amount" },
                    { label: "单位", width: 50, prop: "unit" },
                    { label: "包装单位", width: 70, prop: "packUnit" },
                    { label: "备注", minWidth: 100, prop: "remark" },
                ]
            }
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "入账日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                { label: "状态:", type: "select", value: "orderState", clearable:true,selectOptions: this.orderStateList, multiple: true, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询订单编号' }
            ]
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/PlatformWholesaleOrderList', { ...this.searchData, ...this.pageinfo}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData}, this.baseApiUrl+'/PlatformWholesaleOrderList');
        },
        detailItem(row){
            this.$http.post(this.baseApiUrl + '/GetPlatformWholesaleOrderDetail', {orderNo:row.orderNo}).then((res)=>{
                this.detailTD.DataList = res.data;
                this.showDetailDialog = true
            });
        }
    }
}
</script>

<template>
 <div class="content_box">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/finance',
            searchData: {},
            selectOptions:[],
            logtype:[
                { Code: "取现", Name: "取现" },
                { Code: "充值", Name: "充值" },
                { Code: "销售订单", Name: "销售订单" },
                { Code: "调账入款", Name: "调账入款" },
                { Code: "错账调回", Name: "错账调回" },
                { Code: "仓内退货申请", Name: "仓内退货申请" },
                { Code: "仓内申购采销单  ", Name: "仓内申购采销单" }
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { prop: "linkNo", label: '关联单号', width: 200 },
                    { prop: "operatorName", label: '运营商名称', width: 220 },
                    { prop: "amount", label: '金额', width: 100 },
                    { label: '结算状态', width: 100 ,callBack: (item)=>{
                            return item.state == 1 ? "已结算" : "未结算";
                        },getColor:(item)=>{
                            return item.state == 1 ? "textsuccess" : "textdanger";
                        }
                    },
                    { prop: "balance", label: '结余', width: 100 },
                    { prop: "logType", label: '日志类型', width: 120 },
                    { prop: "createdTime", label: '时间', width: 150 },
                    { prop: 'remark', label: '备注', minWidth: 150 },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0),this.$formatDate(0, -7)]},
                { label: "运营商:", type: "select", value: "operatorCode", selectOptions: this.selectOptions, multiple: false ,clearable:true,keyValue:['Code','Name']},
                { label: "类型:", type: "select", value: "logtype", selectOptions: this.logtype, multiple: true, clearable:true,keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '关联单号' }
            ]
        }
    },
    mounted() {
        this.getOperatorInfo()
    },
    methods: {
        getOperatorInfo(){
          this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
              this.selectOptions = res;
          });
        },
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/FundFlowLogsList', { ...this.searchData, ...this.pageinfo,...{isExcel: false}}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData,...{isExcel:true}}, this.baseApiUrl+'/FundFlowLogsList');
        },
    }
}
</script>

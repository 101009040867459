<template>
  <div class="content_box">
      <div class="search_bar">
        <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
        <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
          <template v-slot:operation="actionBtn">
            <el-button type="warning" plain size="small" @click="confirmMoney(actionBtn.data)" >确认到账</el-button>
          </template>
        </mainTable>
      </div>
    </div>
</template>
<script>

export default {
  data() {
    const STATUS = [
          { Code: '0', Name: "未收款" },
          { Code: '2', Name: "已收款" },
    ];
    return {
      baseApiUrl:'/finance',
      searchData: {},
      searchItemList: [
        { label: "状态：", type: "select", value: "paymentState", selectOptions: STATUS, defaultValue: ['0'], multiple: true ,keyValue:['Code','Name']},
        { type: "input", value: "kw",placeholder:'查询关键字'}
      ],
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "100", fixed: 'left' },
          { label: "运营商编码", width: 100, prop: "operatorCode" },
          { label: "名称", width: 200, prop: "operatorName" },
          { label: "状态", width: 70, prop: "paymentStateName" },
          { label: "保证金", width: 80, prop: "earnestMoney" },
          { label: "审核人", width: 70, prop: "auditedBy" },
          { label: "审核时间", width: 150, prop: "auditedTime" },
          { label: "备注", minWidth: 80, prop: "remark" }
        ]
      },
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  methods: {
    search(postData) {
      this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/EarnestConfirmList',{ ...this.searchData, ...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    confirmMoney(row){
      if (row.paymentStateName == "未收款") {
          this.showConfirm('是否确认该保证金已到账?',()=>{
            this.$http.post(this.baseApiUrl + "/ConfirmEarnestMoney", { operatorCode: row.operatorCode }).then(()=>{
              this.showTipMessage("操作成功！","success");
              this.getListData();
            });
          })
      } else {
        this.showTipMessage("该记录已处理！",'warning');
      }
    }
  }
}
</script>

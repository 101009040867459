<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data)" >编辑</el-button>
          <el-button type="warning" plain size="small" @click="toPage(actionBtn.data)" >权限</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="修改权限模块信息" width="50%" style="padding-bottom:20px;min-width:350px">
    <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true,labelWidth:'80px',formRules:rules}" v-if="showDetailPage" >
      <template v-slot:formButton="formBtn">
        <el-form-item >
          <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit" style="margin-left: 80px">确定提交</el-button>
          <el-button type="danger"  @click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
        </el-form-item>
      </template>
    </Vform>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      baseApiUrl: '/system',
      searchItemList: [
          {type: "input", value: "kw",placeholder:'查询关键字'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"140", fixed:'left'},
          { label: "编码", width: 160, prop: "groupCode" },
          { label: "名称", minWidth: 100, prop: "groupName" }
        ]
      },
      showDetailPage:false,
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{},
      rules: {
        groupName: [{required: true, message: "请输入模块名称", trigger: "manual"}],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    formItemList() {
      return [
        {label:"模块编码",type: "input", value: "groupCode",defaultValue:this.curItem.groupCode||'', isDisabled:true,span:1},
        {label:"模块名称",type: "input", value: "groupName",defaultValue:this.curItem.groupName||'', span:1},
      ]
    }
  },
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetBusinessGroup', {...this.searchData,...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    // 修改
    showDetail(d){
      this.curItem=d;
      this.showDetailPage=true;
    },
    // 编辑提交
    async onSubmit(validate) {
      const formData = await validate();
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + '/UpdateBussinessGroup' ,{...formData,id:this.curItem.id}).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
    toPage(d){
      this.$store.dispatch('tags/setTagsItem',{name: 'system-BusinessPermisson', title: '角色权限设置', path: '/system-BusinessPermisson?id='+d.id,type:'child'});
      this.$router.push({path: '/system-BusinessPermisson', query:{id:d.id}});
    }
  }
}
</script>

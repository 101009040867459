<template>
  <div class="content_box">
    <h3>商圈---{{merchantInfo.name}}</h3>
    <div class="search_bar">
      <el-button-group>
        <el-button type="success"  size="small" @click="submitItem('','add')">添加</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.index==0&&pageinfo.pg_idx==1?'info':'success'"  plain size="small" :disabled="actionBtn.index==0&&pageinfo.pg_idx==1" @click="submitItem(actionBtn.data,'move',0)">上移</el-button>
          <el-button :type="actionBtn.index==mainData.DataList.length-1&&Math.ceil(mainData.Total/pageinfo.pg_size)==pageinfo.pg_idx?'info':'warning'" plain  :disabled="actionBtn.index==mainData.DataList.length-1&&Math.ceil(mainData.Total/pageinfo.pg_size)==pageinfo.pg_idx" size="small"  @click="submitItem(actionBtn.data,'move',1)" >下移</el-button>
          <el-button type="primary"  plain size="small"  @click="submitItem(actionBtn.data,'edit')">编辑</el-button>
          <el-button type="warning"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.state=='1'">禁用</el-button>
          <el-button type="success"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.state=='0'">启用</el-button>
          <el-button type="danger"  plain size="small" @click="submitItem(actionBtn.data,'del')">删除</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showDetailPage" :title="title" width="800" >
        <span class="textwarning" style="line-height:20px;margin-bottom: 20px;display: flex;align-items: center">
          <el-icon size="25"><InfoFilled /></el-icon>&nbsp;
          若修改<span class="textdanger textspan">品类名称</span>，点击【确认提交】后，将同步至对应的未作废的城批条目，将其品类配更新为修改值
        </span>
      <Vform :formItemList="formItemList"  ref="vform" :setFormInfo="{inline:true,labelWidth:'80px',formRules:rules}">
        <template  v-slot:formButton="formBtn">
          <el-form-item label="图片" style="width:100%">
            <uploader :url="uploadOpt.url" :key="uploadOpt.fileList" :isImg="uploadOpt.isImg" :isResetting="uploadOpt.isResetting" :fileList="uploadOpt.fileList" :postData="uploadOpt.data" :accept="uploadOpt.accept" @complete="uploadFinish" @imgchange="imgchange" ></uploader>
          </el-form-item>
        </template>
      </Vform>
      <template #footer>
              <span class="dialog-footer">
                <el-button type="primary" v-on:click="submitForm()" :disabled="canEdit">确定提交</el-button>
                <el-button type="danger" v-on:click="showDetailPage = false">关闭</el-button>
              </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      tags:[],
      merchantInfo:{},
      baseApiUrl: '/merchant',
      searchData: {},
      searchItemList:[
        {type: "input", value: "keyWord",placeholder:'查询关键字',width:'160'}
      ],
      showDetailPage: false,
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"310", fixed:'left'},
          { label: "品类名称", width: 190, prop: "categoryName" },
          { label: "状态", minWidth: 60,callBack:(item)=>{ return ['禁用','启用'][item.state]},getColor:(item)=>{return item.state=='0'?'textdanger':'textsuccess'}}
        ]
      },
      modeType:'',
      FormItems:[{label:"品类",type: "input", value: "categoryName",span:1,placeholder:'请输入品类名称'}],
      fileList:[],
      uploadOpt:{
        isImg:true,
        isResetting:false,
        fileList:[],
        domainUrl:'',
        url: '',
        accept:'image/*',
        data: {}
      },
      title:'',
      curItem:{},
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      rules: {
        categoryName: [{ required: true, message: "请输入品类名称", trigger: "blur"}],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    formItemList() {
      return this.FormItems.map((item) =>{
          return {
            ...item,
            defaultValue: this.curItem[item.value] ?? item.defaultValue ?? ''
          }
      })
    }
  },
  mounted(){
    this.merchantInfo=this.$route.query;
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ImgProof).then((d) => {
        this.uploadOpt.data.policy = d.policy;
        this.uploadOpt.data.authorization = d.auth;
        this.uploadOpt.url = d.host;
        this.uploadOpt.domainUrl = d.domain;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/GetCategoryInfo', {...this.searchData, ...this.pageinfo,code:this.$route.query.code}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    // 删除，编辑，添加，禁用/启用 上移&下移
    submitItem(d,type,movement){
      let url='',postData={},msg='',title='';
      this.modeType=type;
      switch (type){
        case 'upState':
          url='/EditCategoryState';
          postData={id:d.id,state:d.state=='0'?1:0};
          msg=d.state=='0'?'启用成功!':'禁用成功!'
          title=d.state=='0'?`确认启用【${d.categoryName}】品类吗？`:`确认禁用【${d.categoryName}】品类吗？`
          break;
        case 'move':
          url='/EditCategorySort';
          postData={id:d.id,code:this.merchantInfo.code,movement:movement};
          msg=movement=='0'?'上移成功!':'下移成功!'
          title=movement=='0'?`确认上移【${d.categoryName}】品类吗？`:`确认下移【${d.categoryName}】品类吗？`
          break;
        case 'del':
          url='/DelCategoryById';
          postData={id:d.id,code:this.merchantInfo.code};
          msg='删除成功'
          title=`确认删除【${d.categoryName}】品类吗？`
          if(d.state=='1'){
            this.showTipMessage('进行删除操作之前，请先【禁用】该品类','warning');
            return;
          }
          break
        case 'edit':
          this.title = `编辑品类-【${d.categoryName}】`;
          this.showDetailPage=true;
          this.curItem=d;
          this.fileList=[];
          if(d.url){
            this.uploadOpt.fileList = [{name: '品类图片.jpg', url: d.url,status:'success'}];
            this.imageUrl=d.url;
          }else{
            this.uploadOpt.fileList=[];
            this.imageUrl='';
          }
          this.fileList=JSON.parse(JSON.stringify(this.uploadOpt.fileList));
          return;
          // eslint-disable-next-line no-unreachable
          break
        case 'add':
          this.title = `添加品类`;
          this.showDetailPage=true;
          this.curItem={};
          this.fileList=[];
          this.uploadOpt.fileList=[];
          this.imageUrl='';
          return;
          break
      }
      this.showConfirm(title,()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + url,postData).then(()=>{
          this.showTipMessage(msg,'success');
          this.canEdit=false;
          this.search();
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
      })
    },
    uploadFinish(obj){
      if(obj.succ==true){
        this.imageUrl =this.uploadOpt.domainUrl+ obj.resp.url;
      }else{
        this.showTipMessage("网络问题，提交失败，请重新上传")
      }
    },
    imgchange(val){
      this.fileList=JSON.parse(val);
    },
    // 保存商圈信息
    async submitForm(){
      let postData={},url='',flag = false;
      let formData = await this.$refs.vform.validateForm();
      if(this.fileList.length==0){
        this.showTipMessage('请先【选择文件】并点击【提交上传】上传品类图片','warning');
        return;
      }
      this.fileList.map((item) => {
        if (item.status!= 'success') {flag = true;}
      })
      if (flag) {
        this.showTipMessage("存在未上传的图片，请点击【提交上传】",'warning');
        return;
      }
      postData = {
        id: this.curItem.id||'',
        categoryName: formData.categoryName,
        url:this.imageUrl,
        code: this.merchantInfo.code,
      }
      url = this.modeType == 'edit' ? '/EditCategory' : '/AddCategory';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl +url,postData).then(()=>{
        this.showTipMessage('保存品类信息成功','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

import request from "@/utils/http";
import {postExportData,CacheKeys} from "@/utils/constant";
import {showTipMessage,formatTime,AjaxLoading,AjaxLoadEnd,showConfirm} from "@/utils/common";

export default {
    install(app:any) {
        app.config.globalProperties.$http = request;
        app.config.globalProperties.CacheKeys = CacheKeys;
        app.config.globalProperties.$formatDate = formatTime;
        app.config.globalProperties.showTipMessage = showTipMessage;
        app.config.globalProperties.postExportData = postExportData;
        app.config.globalProperties.showConfirm = showConfirm;
        app.config.globalProperties.AjaxLoading = AjaxLoading;
        app.config.globalProperties.AjaxLoadEnd = AjaxLoadEnd;

    }
};

import request from '@/utils/http'
import { ActionContext } from 'vuex'
export type cacheState = {
    DictData:{ [key: string]: any }
    EnumData: { [key: string]: any }
    CacheData: { [key: string]: any }
}
const state: cacheState = {
    DictData: {},
    EnumData: {},
    CacheData: {}
}

const mutations = {
    setCacheData: (state:cacheState, info:any) => {
        state.CacheData[info.key] = info.data;
    },
    setDictData:(state:cacheState, info:any) => {
        state.DictData[info.key] = info.data;
    },
    setEnumData:(state:cacheState, info:any) => {
        state.EnumData[info.key] = info.data;
    },
    clearCache:(state:cacheState, key:string) => {
        if (key) delete state.CacheData[key];
    },
    clearDict:(state:cacheState, key:string) => {
        if (key) delete state.DictData[key];
    }
}
const actions = {
    loadCacheData({commit,state}:ActionContext<cacheState,cacheState>,data:any) {
        return new Promise((resolve, reject) => {
            let d = state['CacheData'][data.key];
            if (d) {
                resolve(d);
                return;
            }
            request.post(data.url, data.query).then((res) => {
                commit('setCacheData', {key:data.key, data: res.data });
                resolve(res.data);
            }, () => reject);
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions

}

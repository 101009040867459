<template>
  <div class="content-body" style="background: rgb(240,242,245);position: fixed;top:0;left:0;width:100%;height:100%;z-index:999;overflow-y: hidden">
    <div class="banner">
      <div class="img"><img src="//apps.ysc-dt.com/yunshicheng/images/login_banner.jpg" /></div>
    </div>
    <div class="login-box">
      <fieldset style="border:none;">
        <legend>用户登录</legend>
      </fieldset>
      <el-form :model="form" ref="loginForm" label-width="0px" style="padding:20px 20px 0; border-top:1px solid #eee" :rules="rules">
        <el-form-item label="" prop="loginName">
          <el-input v-model="form.loginName"  placeholder="请输入11位手机号"  prefix-icon="Avatar"/>
        </el-form-item>
        <el-form-item label=""  prop="loginPwd">
          <el-input v-model="form.loginPwd"  placeholder="请输入密码" prefix-icon="Lock"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('login')" style="width:100%">登录</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="onSubmit('regist')" style="width:100%">注册</el-button>
        </el-form-item>
      </el-form>

    </div>
    <p class="copy">&copy;2015-2023 云食城数科 ysc-dt.com All Rights Reserved.</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form:{},
      rules: {
        loginName: [
          {required: true, message: "请输入登录账号", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '登录账号必须是手机号'}
        ],
        loginPwd: [{required: true, message: "请输入密码", trigger: "blur"}],
      }
    }
  },
  inject: ['$hasPermission'],
  mounted(){
    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = '///apps.ysc-dt.com/yunshicheng/css/login.css';
    document.head.appendChild(link);
  },
  methods: {
    // 保存信息
    onSubmit(type){
      if(type=='login'){
        this.$refs['loginForm'].validate((valid) => {
          if (valid) {
            sessionStorage.operator_regist=JSON.stringify({...this.form,modeType:'edit'});
            this.$router.push({path: '/regist', query:{modeType:'edit'}})
          }
        })
      }else{
        sessionStorage.operator_regist=JSON.stringify({modeType:'add'});
        this.$router.push({path: '/regist', query:{modeType:'add'}})
      }

    }
  }
}
</script>



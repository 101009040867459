<template>
  <div class="content_box" style="padding-top:20px">
    <el-steps :active="addActive" align-center v-if="saveMode=='add'">
      <el-step  title="填写基础信息" ></el-step>
      <el-step  title="填写公司信息" ></el-step>
      <el-step  title="填写开票信息" ></el-step>
      <el-step  title="填写收付款账号信息" ></el-step>
      <el-step  title="设置主账号" ></el-step>
      <el-step  title="勾选开通业务" description="提交上传附件"></el-step>
    </el-steps>
    <div style="flex-grow: 1;margin:0 0 0 5%;position: relative;overflow: hidden;">
      <div style="width: 160px;margin-top: 20px;position: absolute" v-if="saveMode=='edit'">
        <el-tabs tab-position="left" v-model="updateActive" @tab-change="tabChange()">
          <el-tab-pane label="基础信息" :name="0"></el-tab-pane>
          <el-tab-pane label="公司信息" :name="1"></el-tab-pane>
          <el-tab-pane label="开票资料" :name="2"></el-tab-pane>
          <el-tab-pane label="收付款账户" :name="3"></el-tab-pane>
          <el-tab-pane label="ERP主账户" :name="4"></el-tab-pane>
          <el-tab-pane label="开通业务&附件" :name="5"></el-tab-pane>
        </el-tabs>
      </div>
      <div :style="{width: saveMode=='add'?'100%':'calc(100% - 160px)',boxSizing: 'border-box',right:0,position:'absolute',paddingRight:'5%',height:'100%',overflow: 'auto',marginTop:'20px'}">
        <el-form :model="form" label-width="110px" size="small"  ref="profile" :rules="profileRules" label-position="right" v-if="(saveMode=='add'&&addActive==ItemList.length-1)||(saveMode=='edit'&&updateActive==ItemList.length-1)">
          <p style="padding:0 0;margin:0 0 20px">开通渠道：</p>
          <div style="padding-left:60px">
            <el-row class="mb18">
              <el-col :span="6" style="width:130px">
                <el-checkbox v-model="form.isLeaveWith"
                             :true-label="1"
                             :false-label="0"
                             size="small"
                             @change="checked => handleChange(checked, 'isLeaveWith')">代存
                </el-checkbox>
                <span style="margin-right:30px;font-size:14px;">或</span>
                <el-checkbox v-model="form.isStoredCompact"
                             :true-label="1"
                             :false-label="0"
                             size="small"
                             @change="checked => handleChange(checked, 'isStoredCompact')">精简代存
                </el-checkbox>
              </el-col>
              <el-col :span="16" v-show="form.isLeaveWith|| form.isStoredCompact">
                <el-form-item label="代存仓库" prop="cooperationWarehouse" style="margin-bottom: 0">
                  <el-select v-model="form.cooperationWarehouse" placeholder="请选择代存仓库" collapse-tags :filterable="true" :multiple="true" style="width: 100%">
                    <el-option v-for="item in cooperationWarehouse" :label="item.Name" :value="item.Code" :key="item.Code"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="mb18">
              <el-col :span="6" style="width:130px">
                <el-checkbox v-model="form.isArea"
                             :true-label="1"
                             :false-label="0"
                             size="small"
                             @change="checked => handleChange(checked, 'isArea')">商城
                </el-checkbox>
              </el-col>
              <el-col :span="16" v-show="form.isArea">
                <el-form-item label="入驻商城" style="margin-bottom: 0">
                  <el-select v-model="form.cooperationArea" placeholder="请选择入驻商城" collapse-tags :multiple="true" style="width: 100%">
                    <el-option v-for="item in cooperationArea" :label="item.Name" :value="item.Code" :key="item.Code"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="mb18">
              <el-col :span="6" style="width:130px">
                <el-checkbox v-model="form.isCityWholesale"
                             :true-label="1"
                             :false-label="0"
                             size="small"
                             @change="checked => handleChange(checked, 'isCityWholesale')">城批
                </el-checkbox>
              </el-col>
            </el-row>
            <el-row class="mb18">
              <el-col :span="6" style="width:130px">
                <el-checkbox v-model="form.isDistribution"
                             :true-label="1"
                             :false-label="0"
                             size="small"
                             @change="checked => handleChange(checked, 'isCityWholesale')">配销
                </el-checkbox>
              </el-col>
            </el-row>
            <el-row class="mb18">
              <el-col :span="6" style="width:130px">
                <el-checkbox v-model="form.isFundAccount"
                             :true-label="1"
                             :false-label="0"
                             size="small"
                             @change="checked => handleChange(checked, 'isFundAccount')">资金账户
                </el-checkbox>
                <el-popover placement="top-start" title="" :width="300" trigger="hover" :content="tipsArr[operatorInfo.profileInfo?.isFundAccount]">
                  <template #reference>
                    <el-icon color="#666" :size="15" style="margin: 3px;cursor: pointer"><WarningFilled /></el-icon>
                  </template>
                </el-popover>
                <el-button type="warning" v-on:click="ReRegisterBankAccount()" ref="myInput" size="small" :disabled="canEdit" v-if="saveMode=='edit'&&(!operatorInfo.profileInfo?.isFundAccount||operatorInfo.profileInfo?.isFundAccount==1)">重新注册</el-button>
              </el-col>
              <el-col :span="16" v-if="(form.isFundAccount&&!operatorInfo.profileInfo.legalPersonId&&saveMode=='add')||(saveMode=='edit'&&form.isFundAccount)" >
                <el-form-item label="法人身份证号" prop="legalPersonId" style="margin-bottom: 0">
                  <el-input v-model="form.legalPersonId" placeholder="若开通资金账户需填写" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <p class="textprimary" style="font-size:20px;" v-if="saveMode=='add'">{{titles[addActive]}}</p>
        <div style="height:40px;" v-if="saveMode=='edit'&&updateActive!=1&&updateActive!=2"></div>
        <Vform :formItemList="formItemList"
               ref="vform"
               :setFormInfo="{inline:(addActive==0&&saveMode=='add')||(updateActive==0&&saveMode=='edit')?false:true,labelWidth:130,formRules:Rules,isDisabled:formStatus}"
               @changeform="changeform">
        </Vform>
        <div v-if="(saveMode=='add'&&addActive==ItemList.length-1)||(saveMode=='edit'&&updateActive==ItemList.length-1)">
          <div style="color:rgb(184,112,26);background:#FEF1E0;padding:10px;font-size:12px">
            <p><el-icon><BellFilled/></el-icon>以下资料皆需盖公章，建议附件使用图片格式(.jpg .jpeg .png)。</p>
            <p class="textspan textdanger">！！！！！需要提交以下证件哦！（缺漏或图片名与内容不对应，都会导致审核不通过哦~）</p>
            <p class="textspan" style="color:#000">
              <span v-for="(item,index) in commonAttachment" :key="index">{{item}}<span v-if="index<commonAttachment.length-1">，</span></span>
              <span v-if="form.isCityWholesale==1">，城批模拟</span>
              <span v-if="form.isDistribution==1">，配销模拟</span>
              <span v-if="form.isArea==1">，食品流通许可证，开票资料</span>
            </p>
          </div>
          <uploader-list ref="uploaderlist" :ImgOption="ImgOption" :moveFun="false" @imglistchange="imglistchange"></uploader-list>
        </div>
        <div style="text-align: right;margin: 50px 0">
          <div v-if="saveMode=='add'">
            <el-button type="info" v-on:click="prevItem()" size="small" v-if="addActive>0&&addActive<ItemList.length-1" :disabled="canEdit">返回上一步</el-button>
            <el-button type="primary" v-on:click="nextItem()" ref="myInput" size="small" :disabled="canEdit">{{addActive>=ItemList.length-1?'完成':'下一步'}}</el-button>
          </div>
          <div v-if="saveMode=='edit'">
            <el-button type="primary" v-on:click="nextItem()" ref="myInput" size="small" :disabled="canEdit">保存修改</el-button>
            <el-button type="danger" v-on:click="closePage()" size="small" >关闭</el-button>

          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="showBankInfo" title="支行联行信息查询" width="780" style="padding-bottom:20px;">
    <BankInfo :bankcode="bankcode" :BankUniqueNo="BankUniqueNo" @submititem="submitBankItem" v-if="showBankInfo"></BankInfo>
  </el-dialog>
</template>
<script>
import {compareObjects} from '@/utils/common'
import {BankInfo} from '@/utils/constant'
export default {
  data() {
    return {
      baseApiUrl: '/operator',
      addActive: 0,
      updateActive:0,
      operatorCode:'',// 运营商编码
      tipsArr:["阶段：未开通钱包", "阶段：已开通钱包", "阶段：已开通中信银行账号"],
      operatorInfo:{},
      titles:['填写基础信息','填写公司信息','填写开票信息','填写收付款账号信息','设置ERP主账号','提交上传附件'],
      infoKey:['baseInfo','companyInfo','invoiceInfo','bankInfo','userInfo','profileInfo'],
      commonAttachment:["三证复印件", "开户行许可证复印件", "经营许可证复印件(食品/酒类)", "法人代表身份证复印件", "收结款人身份证复印件", "银行结算资料", "委托结算函"],
      form:{isStoredCompact:0,isCityWholesale:0,isDistribution:0,isArea:0,isLeaveWith:0,isFundAccount:0},
      saveMode:'edit',
      showBankInfo:false,
      BankUniqueNo:'',
      bankcode:'',
      formStatus:false,
      checkCode:'',// 已检测的账号信息
      cooperationArea:[],
      cooperationWarehouse:[],
      ItemList:[
        [
          {label:"公司名称：",type: "input", value: "operatorName",span:1},
          {label:"合同日期：", type: "daterange", value: "createtime",span:1.3,width:'200'},
          {label:"运营商类型：",type: "radio", value: "operatorType",defaultValue:0,selectOptions:[{Id:0,Name:'运营商'},{Id:1,Name:'驿站主运营商'}],span:2},
          {label:"保证金：",type: "input", value: "earnestMoney",span:3}
        ], [
          {label:"税务资质",type: "radio", value: "taxQualification",defaultValue:0,selectOptions:[{Id:0,Name:'一般纳税人'},{Id:1,Name:'小规模纳税人'},{Id:2,Name:'个体工商户'}],span:1},
          {label:"法人名", type:"input", value: "legalPersonName", span:2},
          {label:"法人身份证", type:"input", value: "legalPersonId", span:2},
          {label:"公司地址", type:"input", value: "addr", span:1},
          {label:"业务联系人", type:"input", value: "contactPerson", span:2},
          {label:"业务联系电话", type:"input", value: "contactPhone", span:2},
          {label:"业务通讯邮箱", type:"input", value: "contactEmail", span:1.2},
          {label:"", type: "checkbox", value: "isSync",defaultValue:[],selectOptions:[{Id:1,Name:'同步为财务联系方式'}]},
          {label:"财务联系人", type:"input", value: "finContactPerson", span:2},
          {label:"财务联系电话", type:"input", value: "finContactPhone", span:2},
          {label:"财务联系邮箱", type:"input", value: "finContactEmail", span:1},
        ], [
          {label:"社会信用代码", type:"input", value: "socialCreditCode", span:1,isBtn:true,btnLabel:'检测是否存在',isChange:true},
          {label:"开票抬头", type:"input", value: "invoiceTitle", span:1,isDisabled:true},
          {label:"开票地址", type:"input", value: "invoiceAddr", span:1,isDisabled:true},
          {label:"开票行",type: "select", value: "invoiceBank",span:1,keyValue:['name','name']},
          {label:"开票账户", type:"input", value: "invoiceAccount", span:1,isDisabled:true},
          {label:"联系电话", type:"input", value: "invoicePhone", span:1,isDisabled:true},
          {label:"开票种类",type: "radio", value: "invoiceType",defaultValue:0,selectOptions:[{Id:0,Name:'普票'},{Id:1,Name:'专票'}],span:1},
          {label:"备注", type:"input", value: "remark", span:1, placeholder: '',isDisabled:true},
        ], [
          {label:"收付款账号名称", type:"input", value: "accountNameFull", span:1},
          {label:"开票行",type: "select", value: "bank",span:1,keyValue:['name','name']},
          {label:"收付款账号", type:"input", value: "cardNumber", span:1},
          {label:"支付联行号", type:"input", value: "bankUniqueNo",span:1,placeholder: '若开户行非“中信银行”，则需要设置',isDisabled:true,isBtn:true,btnLabel:'设置联行号'},
        ], [
          {label:" ", type: "checkbox", value: "isSyncSalesMan",defaultValue:[],selectOptions:[{Id:1,Name:'使用业务联系人信息作为主账号'}]},
          {label:"登陆账号", type:"input", value: "loginName", span:1,inputFun:true,isTip:true,tipContent:"若为新账号，则默认密码为987654"},
          {label:"Email", type:"input", value: "email", span:1},
          {label:"用户名", type:"input", value: "realname", span:1},
          // {label:"备注", type:"input", value: "URemark", span:1,placeholder: ''},
        ], []
      ],
      ImgOption:{imgData:{},leng:100,isSetting:true,acceptList:'.jpg,.png,.pdf',delUrl:'/ajax/DelUpYunFile'},
      fileList:[],
      imageUrl:'',
      Rules: {
        operatorName: [{ required: true, message: "请输入公司名称", trigger: "blur"}],
        createtime:[{ required: true, message: "请选择合同日期", trigger: "blur" }],
        earnestMoney: [
          {required: true, message: "请输入输入保证金", trigger: "blur"},
          { pattern: /^\d+(\.\d+)?$/, message: '保证金只能输入数字', trigger: 'blur' },
        ],
        legalPersonName: [{ required: true, message: "请输入法人名称", trigger: "blur"}],
        // legalPersonId: [
        //   { required: true, message: "请输入法人身份证号", trigger: "blur"},
        //   {min:18,max:18,message: "身份证号格式不正确，长度为18", trigger: "blur"},
        // ],
        contactPerson: [{ required: true, message: "请输入业务联系人姓名", trigger: "change"}],
        contactPhone: [
          {required: true, message: "请输入业务联系人电话", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '业务联系人电话格式错误，需为11位数的手机号'}
        ],
        contactEmail: [
          {required: true, message: "请输入通讯邮箱", trigger: "blur"},
          {pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/, message: '必须是邮箱格式'}
        ],
        finContactPerson: [{ required: true, message: "请输入财务联系人姓名", trigger: "change"}],
        finContactPhone: [
          {required: true, message: "请输入财务联系人电话", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '财务联系人电话格式错误，需为11位数的手机号'}
        ],
        finContactEmail: [
          {required: true, message: "请输入通讯邮箱", trigger: "blur"},
          {pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/, message: '必须是邮箱格式'}
        ],
        socialCreditCode:[{ required: true, message: "请输入社会信用代码", trigger: "blur"}],
        invoiceTitle:[{ required: true, message: "请输入开票抬头", trigger: "blur"}],
        invoiceAddr:[{ required: true, message: "请输入开票地址", trigger: "blur"}],
        invoiceBank:[{ required: true, message: "请选择开票行", trigger: "blur"}],
        invoiceAccount:[{ required: true, message: "请输入开票账户", trigger: "blur"}],
        invoicePhone:[{ required: true, message: "请输入联系电话", trigger: "blur"}],
        accountNameFull:[{ required: true, message: "请输入收付款账号名称", trigger: "blur"}],
        bank:[{ required: true, message: "请输入开户行", trigger: "blur"}],
        cardNumber:[{ required: true, message: "请输入收付款账号", trigger: "blur"}],
        loginName: [
          {required: true, message: "请输入登录账号", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '登录账号必须是手机号'}
        ],
        email: [
          {required: true, message: "请输入email", trigger: "blur"},
          {pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/, message: '必须是邮箱格式'}
        ],
        realname: [{ required: true, message: "请输入用户名", trigger: "blur"}]
      },
      banknfo:{},
      curItem:{},
      selectData:{},
      salesmanInfo:{},
      FormItems:[],
      canEdit:false,
      postUEL:['/OperatorBaseEdit','/CompanyInfoEdit','/InvoiceInfoEdit','/BankAccountCreate','/UsersCreate','/UpdateOperatorBaseAndImg'],
      getUEL:['/GetOperatorBaseInfo','/GetCompanyInfo','/GetInvoiceInfo','/OperatorBankAccountList','/GetUsers','/BusinessProfile'],
    }
  },
  inject: ['$hasPermission','$openPageTab','$handleTabRemove'],
  computed: {
    formItemList() {
      return this.FormItems.map(item => ({
        ...item,
        selectOptions:this.selectData[item.value]||item.selectOptions||'',
        defaultValue: this.getDefaultValue(item),
      }));
    }
  },
  mounted(){
    BankInfo.query((res)=>{
      this.selectData.invoiceBank=res;
      this.selectData.bank=res;
    });
    this.saveMode=this.$route.query.operatorCode?'edit':'add';
    if(this.saveMode=='edit'){
      this.operatorCode=this.$route.query.operatorCode;
      this.ItemList = this.ItemList.map(subArray => {
        return subArray.filter(item => {
          if (item.value === "isSync"|| item.value=='isSyncSalesMan') return false;
          if (item.value === "contactEmail") item.span = 1;
          return true;
        });
      });
    }
    let index=this.saveMode=='edit'?this.updateActive:this.addActive
    this.FormItems=this.ItemList[index];
    this.commonAjax();
    this.getItemInfo();
  },
  methods: {
    commonAjax(){
      // 图片上传凭证
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ImgProof).then((d) => {
        this.ImgOption.imgData.policy = d.policy;
        this.ImgOption.imgData.authorization = d.auth;
        this.ImgOption.imageUpUrl = d.host;
        this.ImgOption.imageUrl = d.domain;
      });
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.AreaInfo).then((res) => {
        this.cooperationArea=res;
      });
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.CommonWarehouse).then((res) => {
        this.cooperationWarehouse=res;
      });
    },
    getDefaultValue(item){
      if(!this.curItem||Object.keys(this.curItem).length === 0){
        if(item.value=='invoiceBank'||item.value=='bank'){
         return this.selectData.invoiceBank&&this.selectData.invoiceBank[0].name
        }else{
          return  item.defaultValue??"";
        }
      }else{
        return this.curItem[item.value]??(item.type=='checkbox'?[]:'')
      }
    },
    changeform(d){
      this.curItem={...this.curItem,...d};
      if(this.addActive==0||this.updateActive==0){
        this.curItem.createtime=[this.curItem.dateFrom,this.curItem.dateTo];
      }
      //公司信息=>同步为财务联系方式
      if(this.addActive==1&&d.isSync.length!=0){
        if(!d.contactEmail||!d.contactPerson||!d.contactPhone){
          this.showTipMessage('请先填写公司联系人姓名、电话、邮箱，再勾选【同步为财务联系方式】','warning');
          this.curItem={...d,isSync:[]};
        }else {
          this.curItem = {
            ...d,
            finContactPerson: d.contactPerson,
            finContactPhone: d.contactPhone,
            finContactEmail: d.contactEmail
          };
        }
      }
      //按钮点击
      if(d.btnclick){
        if(d.btnValue=='socialCreditCode') {
          if (!d[d.btnValue]) {this.showTipMessage("请填写社会信用代码，再进行检测");return;}
          if(d.socialCreditCode==this.checkCode){
            this.showTipMessage("该社会信用代码已检测，勿重复检测","warning");
            return;
          }
          this.curItem={...d};
          this.CheckSocialCode(d[d.btnValue]);
        }else if(d.btnValue=='bankUniqueNo'){
          if(!d.bank) {this.showTipMessage("请先选择开票行");return;}
          this.showBankInfo=true;
          let obj=this.selectData.bank.find((item)=>  item.name==d.bank)
          this.bankcode=obj.key;
          this.banknfo={...this.banknfo,...d};
          return;
        }
      }
      if(d.bank!=this.banknfo.bank){ // 切换了开户行，置空支行信息
        this.curItem.bankUniqueNo=d.bankUniqueNo='';
      }
      //设置主账号=>使用业务联系人信息作为主账号
      if(this.addActive==4&&d.isSyncSalesMan.length!=0&&d.btnValue!='loginName'){
        this.checkSSOUser(this.salesmanInfo.loginName,d.isSyncSalesMan);
      }else if(this.addActive==4&&d.isSyncSalesMan.length==0&&d.btnValue!='loginName'){
        this.curItem={realname:'', email:'', URemark:'',loginName:'',isSyncSalesMan:[]};
        this.formatItems([])
      }
      if((this.addActive==4||this.updateActive==4)&&d.btnValue=='loginName'){
        if( /^1[3-9]\d{9}$/.test(d.loginName)){
          if(d.loginName!=this.salesmanInfo.loginName){
            this.checkSSOUser(d.loginName,[])
          }
        }
      }
    },
    // 检验账号是否登录, isSyncValue.length!=0=》同步业务员信息作为主账号
    checkSSOUser(mobile,isSyncValue){
      this.$http.post(this.baseApiUrl+'/Ajax/LoadExitUser', {loginName:mobile}).then((res) => {
        if(res.data){
          this.curItem={
            userId:res.data.userId,
            realname:res.data.realname,
            email:res.data.email,
            URemark:res.data.remark,
            loginName:mobile,
            isSyncSalesMan:isSyncValue||[]
          };
         this.formatItems(['email','realname','URemark']);
        }else{
          if(isSyncValue&&isSyncValue.length!=0) this.curItem={...this.salesmanInfo,isSyncSalesMan:isSyncValue||[]};
          else this.curItem={userId:'', realname:'', email:'', URemark:'',loginName:mobile,isSyncSalesMan:isSyncValue||[]};
          this.formatItems([]);
        }
      });
    },
    formatItems(disableList){
      this.FormItems.map((item)=>{
        if(disableList&&disableList.length==0) item.isDisabled=false
        if(disableList.indexOf(item.value)>-1) item.isDisabled=true
        // if(item.hasOwnProperty('value') && d.hasOwnProperty(item.value)){
        //    item.defaultValue = d[item.value];
        // }
        return item;
      })
    },
    // 检测社会信用代码
    CheckSocialCode(socialCreditCode){
      this.$http.post(this.baseApiUrl+'/DetectionInvoice', {socialCreditCode:socialCreditCode}).then((res)=> {
        this.formatItems([]);
        this.checkCode=socialCreditCode;
        if(res.data){
          this.curItem={...res.data};
          return;
        }
        this.showTipMessage("可以创建，请继续填写其他信息","success");
      });
    },
    uploadFinish(obj){
      if(obj.succ==true){
        this.imageUrl =this.uploadOpt.domainUrl+ obj.resp.url;
      }else{
        this.showTipMessage("网络问题，提交失败，请重新上传")
      }
    },
    imglistchange(val) {
      if(val){
        this.fileList=JSON.parse(val);
      }
    },
    // 设置支行
    submitBankItem(d){
      this.showBankInfo=false;
      this.curItem={...this.banknfo,bankUniqueNo:d.bank_code};
    },
    // 开通渠道关联
    handleChange(val,name) {
      if(['isCityWholesale','isDistribution','isArea'].indexOf(name)>-1&&val){
        this.form={...this.form,isLeaveWith:1,isFundAccount:1}
      }
      if((['isLeaveWith','isFundAccount'].indexOf(name)>-1&&!val&&this.saveMode=='add')||(name=='isLeaveWith'&&!val)){
        this.form={...this.form,isCityWholesale:0,isDistribution:0,isArea:0}
      }
      if(name=='isStoredCompact'&&val){
        this.form={...this.form,isStoredCompact:1,isCityWholesale:0,isDistribution:0,isArea:0,isLeaveWith:0,isFundAccount:0}
      }
      if(name!='isStoredCompact'&&val){
        this.form={...this.form,isStoredCompact:0}
      }
      if(name=='isFundAccount'&&this.saveMode=='edit'){
        this.form={...this.form,isFundAccount:this.operatorInfo.profileInfo?.isFundAccount>0?1:0}
        this.showTipMessage('不允许修改','warning')
      }
    },
    tabChange(){
      if(this.updateActive<this.ItemList.length-1) this.FormItems=this.ItemList[this.updateActive];
      else this.FormItems=[];
      if(this.updateActive==2){
        this.formatItems([]);
      }
      this.formStatus=false;
      this.getItemInfo();
    },
    //查询信息
    getItemInfo(){
      let index=this.saveMode=='add'?this.addActive:this.updateActive;
      let postData=index==3?{code:this.operatorCode}:{operatorCode:this.operatorCode};
      this.FormItems=this.ItemList[index];
      this.canEdit=true;
      this.$http.post(this.baseApiUrl+this.getUEL[index], postData).then((res) => {
        if(res.data&&res.data.rows){
            if(res.data.rows[0]){
              this.curItem= {
                accountNameFull: res.data.rows[0].accountNameFull,
                bank: res.data.rows[0].bank,
                bankUniqueNo: res.data.rows[0].bankUniqueNo,
                cardNumber: res.data.rows[0].cardNumber,
                operatorCode: res.data.rows[0].operatorCode
              }
            }else{
                this.curItem={};
            }
          this.operatorInfo[this.infoKey[index]]=JSON.parse(JSON.stringify(this.curItem));
        }else{
          this.curItem=res.data||{};
          this.operatorInfo[this.infoKey[index]]=JSON.parse(JSON.stringify(res.data||{}));
          if(index==2){this.checkCode=res.data?.socialCreditCode??''}
          if(index==5){
            this.form={
              ...res.data,
              isFundAccount:res.data.isFundAccount>0?1:0,
              cooperationWarehouse:res.data.cooperationWarehouse?res.data.cooperationWarehouse.split(','):[],
              cooperationArea:res.data.cooperationArea?res.data.cooperationArea.split(','):[]};
            this.loadAttachments();
          }
          if(index==0&&res.data){
            this.formatItems(['earnestMoney']);
          }
        }
        if(['bankInfo','userInfo'].indexOf(this.infoKey[index])>-1&&Object.keys(this.operatorInfo[this.infoKey[index]]||{}).length!=0){
          this.formStatus=true;
        }else{this.formStatus=false}
        if(res.data?.contractBegin&&res.data?.contractEnd&&res.data?.contractEnd!="0001-01-01 00:00:00"){
          this.curItem.createtime=[res.data.contractBegin,res.data.contractEnd];
        }
        this.canEdit=false;
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    // 开通业务&附件验证
    validateImg(){
      if((this.form.isLeaveWith||this.form.isStoredCompact)&&(!this.form.cooperationWarehouse||this.form.cooperationWarehouse.length==0)){
        this.showTipMessage("勾选了代存或者精简代存需选择代存仓库",'warning');
        return;
      }
      if(this.form.isArea&&(!this.form.cooperationArea||this.form.cooperationArea.length==0)){
        this.showTipMessage("勾选了商城需选择入驻商城",'warning');
        return;
      }
      if((this.form.isFundAccount)&&(!this.form.legalPersonId||this.form.legalPersonId.length!=18)){
        this.showTipMessage("开通资金账户需填写法人身份证号,身份证号长度为18位",'warning');
        return;
      }

      var arr = [], flag = false;
      if (!this.fileList || this.fileList.length == 0) {
        this.showTipMessage("请上传图片",'warning');
        return;
      }
      this.fileList.map((item) => {
        if (item.status == 'success') {
          var obj = {}
          obj.imgName = item.name;
          obj.url = (item.response.url.indexOf("http://") != -1 || item.response.url.indexOf("https://") != -1) ? item.response.url : this.ImgOption.imageUrl + item.response.url;
          obj.size = item.size;
          obj.type = item.name.substring(item.name.lastIndexOf('.')+1,item.name.length);
          arr.push(obj)
        }else {return flag = true;}
      })
      if (flag) {
        this.showTipMessage("请点击【确认上传】，完成上传后再点击完成",'warning');
        return;
      }
      return arr
    },
    // 提交信息
    postItemInfo(formData){
      let data={},index=this.saveMode=='edit'?this.updateActive:this.addActive;
      if(index==5){
          if(!this.form.isLeaveWith&&!this.form.isStoredCompact){this.form.cooperationWarehouse=''}
          if(!this.form.isArea){this.form.cooperationArea=''}
          let res = compareObjects(this.curItem, this.form);
          data={...this.form,isFundAccount:this.saveMode=='add'?this.form.isFundAccount:this.operatorInfo.profileInfo?.isFundAccount}
          data.update=JSON.stringify([{cols:res}]);
          data.operatorCode=this.operatorCode;
          data.cooperationWarehouse=this.form.cooperationWarehouse?this.form.cooperationWarehouse.join(','):'';
          data.cooperationArea=this.form.cooperationArea?this.form.cooperationArea.join(','):'';
          let arr=this.validateImg();
          if(!arr){return;}
          data.attachment=JSON.stringify(arr);
      }
      if(this.infoKey[index]=='invoiceInfo'){
        if(formData.socialCreditCode!=this.checkCode){
          this.showTipMessage("请先检测社会信用代码","warning");
          return;
        }
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl+this.postUEL[index],index==5?data:formData).then((res) => {
        this.canEdit=false;
        this.showTipMessage("保存成功"+(res.msg||''),"success");
        if(this.saveMode=='edit'){return}
        if(index==5&&this.saveMode=='add'){
          setTimeout(()=>{this.closePage();},3000)
        }
        if(this.saveMode=='add'&&index!=5){
          if(res.data?.operatorCode){this.operatorCode=res.data.operatorCode;}
          if(index==1){this.salesmanInfo={loginName:formData.contactPhone,email:formData.contactEmail,realname:formData.contactPerson}}
          this.addActive++;
          this.curItem={};
          if(this.addActive<this.ItemList.length-1){
            this.FormItems=this.ItemList[this.addActive];
          }
          else {this.FormItems=[]}
          this.getItemInfo();
        }
       },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    // 获取附件信息
    loadAttachments(){
       this.$http.post(this.baseApiUrl+'/LoadAttachments', {code:this.operatorCode}).then((res) => {
         let Imgs=res.data;
         Imgs.map((item)=>{
           item.name=item.imgName;
           item.response={url:item.url};
           item.status='success'
         });
         this.ImgOption.list=Imgs;
       });
     },
    async nextItem(){
      let formData = await this.$refs.vform.validateForm();
      let index=this.saveMode=='edit'?this.updateActive:this.addActive;
      if(index==0){
        formData.contractBegin = formData.dateFrom;
        formData.contractEnd = formData.dateTo;
        delete formData.dateFrom;
        delete formData.dateTo;
      }

      if(this.operatorInfo[this.infoKey[this.addActive]]||this.addActive!=0||this.saveMode=='edit'){formData.operatorCode=this.operatorCode;}
      // 去除不必要的参数
      // eslint-disable-next-line no-unused-vars
      const {isSync, ...fieldsToSubmit} = formData;
      formData=JSON.parse(JSON.stringify(fieldsToSubmit))
      let res = compareObjects((this.operatorInfo[this.infoKey[index]]||{}), formData);
      if(this.saveMode=='edit'){
        if(res.length==0||(res.length==1&&res[0]=='operatorCode')){this.showTipMessage("无修改项",'warning');return;}
        if(index==3&&formData.bank!='中信银行'&&!formData.bankUniqueNo){
          this.showTipMessage("若开票行非“中信银行”，需要设置支付联行号",'warning');return;
        }
        this.postItemInfo(formData);
        return;
      }
      if(['bankInfo','userInfo'].indexOf(this.infoKey[index])>-1&&Object.keys(this.operatorInfo[this.infoKey[index]]||{}).length!=0){
        this.addActive++;
        this.curItem={};
        if(this.addActive<this.ItemList.length-1){
          this.FormItems=this.ItemList[this.addActive];
        }
        else {this.FormItems=[]}
        this.formStatus=true
        this.getItemInfo();
      }else{
        this.formStatus=false
        if(index==3&&formData.bank!='中信银行'&&!formData.bankUniqueNo){
          this.showTipMessage("若开票行非“中信银行”，需要设置支付联行号",'warning');return;
        }
        this.postItemInfo(formData);
      }
    },
    prevItem(){
      this.addActive--;
      if(this.addActive==3||this.addActive==4){this.formStatus=true}
      else{this.formStatus=false}
      this.getItemInfo();

    },
    closePage(){
      this.$store.dispatch('tags/closeCurrentTag',{$router: this.$router, $route: this.$route});
      this.$router.push({path:'/operator-OperatorInfo'})
    },
    ReRegisterBankAccount(){
      this.showConfirm('请确认修改的信息是否保存噢，该功能不包含保存功能，是否继续【重新注册银行账号】？',()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl+'/ReRegisterBankAccount', {operatorCode:this.operatorCode}).then(() => {
          this.showTipMessage('提交成功，稍等片刻重新查看','success');
          this.canEdit=false;
          setTimeout(()=>{this.closePage();},3000);
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
      })

    }
  }
}
</script>

<style>
.mb18{margin-bottom: 18px}
</style>

<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data)" >审核</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="查看商品明细" width="80%">
    <el-descriptions size="small" :border="true"  :column="3" class="uniform-descriptions">
      <el-descriptions-item label="城批商品名称"  label-class-name="labelName" :span="2">
        {{curItem.productNamePromotion}}<el-tag size="small" type="danger" v-if="detailsInfo.isGift">赠品</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="销售单位">{{curItem.unit}}</el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="运营商" :span="2">{{curItem.operatorName}}</el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="可否散出"><span class="textwarning">{{curItem.isPackSale}}</span></el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="上架商圈">{{curItem.merchantName}}</el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="品类">{{curItem.saasType}}</el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="客户类型">
        <el-tag size="small" type="success"  v-for="item in curItem.clientChannel" style="margin-right:10px;">{{item}}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="退货说明" :span="3">{{curItem.refundRequire}}</el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="明细商品" :span="3">
        <el-descriptions size="small" :border="true"  direction="vertical"  :column="3" class="uniform-descriptions">
          <el-descriptions-item label-class-name="labelName" label="基础商品名称"  >{{curItem.productName}}</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="规格">{{curItem.spec}}</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="批次策略">
            <span class="textdanger">{{curItem.batch}}</span>
            <span class="textwarning" v-if="curItem.batchDate">&nbsp;（{{curItem.batchDate}}）</span>
          </el-descriptions-item>
        </el-descriptions>
      </el-descriptions-item>
      <el-descriptions-item label-class-name="labelName" label="图片" :span="3">
        <el-image v-for="(item,index) in curItem.imgList" style="width: 60px; height: 60px;margin-right:10px;border:1px solid #ddd" :src="item" :zoom-rate="1.2" :preview-src-list="curItem.imgList" :initial-index="index" fit="cover"/>
      </el-descriptions-item>
    </el-descriptions>
    <div style="text-align:center;padding:30px;">
      <el-button type="primary" size="small" @click="NextItem" :disabled="canEdit">下一个</el-button>
      <el-button type="success" @click="agreeDialog=true;form={onSaleLv:'1'}" :disabled="canEdit">同意</el-button>
      <el-button type="danger"  @click="disagreeDialog=true;form={onSaleLv:'1'}" :disabled="canEdit">拒绝</el-button>
    </div>
  </el-dialog>
  <el-dialog v-model="agreeDialog" title="请选择特价优先等级" width="740px">
    <el-form ref="editForm" :model="form" size="small" label-width="120px" style="margin:auto;">
      <el-form-item label="特价优先等级" prop="freightUnit">
        <el-select v-model="form.onSaleLv"  placeholder="请选择特价优先等级"  style="width: 100%;">
          <el-option key="0" label="普通" value="0" />
          <el-option key="1" label="优先" value="1" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="submitForm(true)" :disabled="canEdit">提交</el-button>
          <el-button v-on:click="agreeDialog = false">关闭</el-button>
        </span>
    </template>
  </el-dialog>
  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo"  :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></rejectPopup>
</template>
<script>
import {formatBatchDate,formatBatch} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/promotion',
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "条目编码", width: 90, prop: "itemSerialCode" },
          { label: "商品条目名称", width: 200, prop: "productNamePromotion" },
          { label: "是否赠品", width: 70,callBack:(item)=>{return item.isGift?'是':'否'},getColor:(item)=>{return item.isGift?'textsuccess':'textdanger'}},
          { label: "商圈", width: 120, prop: "merchantName" },
          { label: "运营商", width: 165, prop: "operatorName" },
          { label: "状态", width: 70, prop: "isOnShelfName" },
          { label: "退货说明", minWidth: 150, prop: "refundRequire" },
          { label: "备注", width: 160, prop: "remark" }
        ]
      },
      rejectInfo:{title:'拒绝原因',value:'auditRemark'},
      showDetailPage:false,
      disagreeDialog:false,
      agreeDialog:false,
      detailsInfo:{},
      form:{},
      MerchantInfo:[],
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{},
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "商圈", type: "select", value: "merchantCode", selectOptions: this.MerchantInfo,defaultValue:'', multiple: false, keyValue:['Code','Name'],width:'120'},
        {type: "input", value: "kw",placeholder:'查询商品名/编码/条码'}
      ];
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData', {...this.CacheKeys.MerchantInfo, query:{...this.CacheKeys.MerchantInfo.query,state: -1}}).then((res) => {
        this.MerchantInfo=res;
      });
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/ProOperatorPromotionAudit', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    NextItem(){
      this.showDetail('NextItem')
    },
    showDetail(d){
      let postData=d == 'NextItem'?{previd:this.curItem.id}:{itemSerialCode:d.itemSerialCode,previd:0};
      this.canEdit=true;
      this.$http.post(this.baseApiUrl+'/GetPropromotionInfo',postData).then((res) => {
        this.canEdit=false;
        if(!res.ext&&!res.data){
          this.showTipMessage('暂无记录','warning');
          return;
        }
        let data = res.ext.ProPromotionData;
        let details=data.details[0];
        this.detailsInfo=details;
        let imgList=[];
        data.imgData.forEach((item)=>{imgList.push(item.url)});
        this.curItem={
          id:data.ProPromotionData.id,
          productNamePromotion:data.ProPromotionData.productNamePromotion,
          unit:data.proBase.unit,
          operatorName:data.operatorName,
          isPackSale:data.ProPromotionData.isPackSale==0?'否':'是',
          merchantName:data.ProPromotionData.merchantName,
          saasType:data.ProPromotionData.saasType,
          clientChannel:data.ProPromotionData.clientChannelName.split(','),
          refundRequire:data.ProPromotionData.refundRequire||'无',
          productName:details.productName,
          spec:data.proBase.spec,
          imgList:imgList,
          batch:formatBatch(details.produceCondition),
          batchDate:formatBatchDate(details.produceCondition, details.produceDate1,details.produceDate2)
        }
        this.showDetailPage=true;
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');})
    },
    submitForm(type) {
      if(type!=true){this.saveItem(false,type);return;}
      else{this.saveItem(type); }
    },
    saveItem(isAgree,value) {
      let obj={id:this.curItem.id,SAASTYPE:this.curItem.saasType,isAgree:isAgree}
      let postData = isAgree?{onSaleLv:this.form.onSaleLv,...obj}:{...obj,...value}
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/ProOperatorPromotionListUpState" ,postData).then(() => {
        this.showTipMessage('审核成功!','success');
        this.disagreeDialog=this.agreeDialog=this.showDetailPage=false;
        this.canEdit=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

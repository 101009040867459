<template>
  <div class="content_box">
        <div class="search_bar">
            <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
        </div>
        <div class="main-box">
            <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
                <template v-slot:operation="actionBtn">
                    <el-button type="success" plain size="small" @click="dispose(actionBtn.data)">处理</el-button>
                </template>
            </mainTable>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            baseApiUrl: '/finance',
            searchData: {},
            searchItemList: [
                { type: "input", value: "kw", placeholder: '单号/运营商编号/名称' }
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "70", fixed: 'left' },
                    { label: "单号", width: 180, prop: "applyNo" },
                    { label: "运营商名称", width: 200, prop: "operatorName" },
                    { label: "充值银行账号", width: 200, prop: "bankNumber" },
                    { label: "充值银行", width: 120, prop: "bankName" },
                    { label: "充值金额", width: 100, prop: "amount" },
                    { label: '充值时间', minWidth: 150, prop: "rechargeTime" },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/fundAdjustApply', { ...this.searchData, ...this.pageinfo }).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        dispose(row){
          this.$store.dispatch('tags/setTagsItem',{name: 'son_FundAdjustDispose', title: '调账处理', path: '/son_FundAdjustDispose?applyNo='+row.applyNo,type:'child'});
          this.$router.push({path: '/son_FundAdjustDispose', query:{applyNo:row.applyNo}});
        }
    }
}
</script>

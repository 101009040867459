<template>
 <div class="content_box">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>

export default {
    data() {
        const TYPE = [
            { Code: "WH", Name: "驿站主" },
            { Code: "OP", Name: "运营商" },
        ];
        return {
            baseApiUrl: '/finance',
            searchData: {},
            searchItemList: [
                { label: "状态:", type: "select", value: "type", selectOptions: TYPE, multiple: false, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询编码/名称' }
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { label: "编码", width: 100, prop: "code" },
                    { label: "名称", width: 200, prop: "name" },
                    { label: "账户全称", width: 200, prop: "accountNameFull" },
                    { label: "开户银行", width: 230, prop: "bank" },
                    { label: "卡号", width: 180, prop: "cardNumber" },
                    { label: "开通渠道", minWidth: 100, prop: "channelName" },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/GetBankAccountList', { ...this.searchData, ...this.pageinfo}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData}, this.baseApiUrl+'/GetBankAccountList');
        },
    }
}
</script>

import { createStore } from 'vuex'
interface VuexModule {
  namespaced:boolean,
  state:object
  mutations:any,
}
export interface RootState {
  menu: any,
  user:any,
}
const modulesFiles = require.context('./modules', true, /\.ts$/)
const modules = modulesFiles.keys().reduce((modules: Record<string, VuexModule>, modulePath:string) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
const store = createStore<RootState>({
  modules
})

export default store


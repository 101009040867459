<template>
  <el-scrollbar class="left-side" id="left-side" :style="!iscollapse?'left:0px':'left:-260px'">
    <el-aside>
      <div class="menuSearch" @click="showPage=true" :style="iscollapse?'justify-content: center':'justify-content: space-between'">
        <span v-if="!iscollapse">搜索菜单快捷跳转</span>
        <el-icon size="14"><Search /></el-icon>

      </div>
      <el-menu active-text-color="#28a3ef"
               background-color="263238"
               :collapse-transition="false"
               :unique-opened="true"
               :default-active="curSideMenu"
               class="el-menu-side"
               text-color="#999"
               ref="menu"
               v-on:select="handleMenuSelect">
        <template v-for="(item,i) in sideMenu" :key="item">
          <el-menu-item v-if="!item.children" :index="item">{{item.title}}</el-menu-item>
          <el-sub-menu v-if="item.children" :index="item.code" >
            <template #title>
              <el-icon> <component :is="i>iconList.length-1?'EditPen':iconList[i]"></component> </el-icon>
              <span>{{item.title}}</span>
            </template>
            <el-menu-item v-for="(subItem) in item.children" :key="subItem" :index="subItem.code" ><el-icon><Link /></el-icon>{{subItem.title}}</el-menu-item>
          </el-sub-menu>
        </template>
      </el-menu>
    </el-aside>
  </el-scrollbar>
  <el-dialog v-model="showPage" title="功能检索与快捷启动" width="550px" style="padding-bottom:10px">
      <Vform ref="menuform"
             :formItemList="formItemList"
             :setFormInfo="{inline:false,labelWidth:'70px',formRules:rules}">
          <template v-slot:formButton="formBtn">
            <el-form-item>
              <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" >跳转页面</el-button>
            </el-form-item>
          </template>
      </Vform>
  </el-dialog>
</template>
<script lang="ts">
import {defineComponent, toRefs, reactive, computed, onMounted} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      showPage:false,
      icons: [['Box','Shop','School','Iphone','Avatar','ShoppingBag'],[],['DataLine','Coin','HomeFilled','School'],['Document'],['Bell','setting','Key','School']],
      rules: {
        pageKey: [{ required: true, message: "请选择跳转页面", trigger: "blur"}],
      }
    })
    const formItemList = computed(() =>{
       return [
           {
             label: "页面", placeholder:"请选择跳转页面", type: "select", value: "pageKey",
             selectOptions:store.state.menu.sideMenu.filter((item:{[key: string]: any})=>item.lv==3), multiple:false, keyValue:['code','title']
           },
      ];
    });
    const isMobile =computed(()=> {
        const userAgent = navigator.userAgent;
        return /iPhone|iPod|Android/i.test(userAgent);
    })
    const iscollapse = computed(() => store.state.menu.iscollapse);
    const curTopId = computed(() => store.state.menu.topId);
    const sideMenuList= computed(() => store.state.menu.sideMenu||'');
    const iconList = computed(() => {
      let index =store.state.menu.topMenu.findIndex((item:{[key: string]: any})=>item.code==curTopId.value);
      return state.icons[index??0]
    })
    const sideMenu= computed(() => {return  formatMenu()});
    const curSideMenu = computed(() => store.state.menu.sideId);
    const formatMenu=()=>{
      if(sideMenuList.value){
        var mList = sideMenuList.value.filter((item:{[key: string]: any})=>item.parent_code==curTopId.value);
        mList.forEach((item:{[key: string]: any}) => {
          item.children = sideMenuList.value.filter((child:{[key: string]: any}) => item.code == child.parent_code);
        });
        return  mList
      }
    }
    const onSubmit= async(validate:any)=> {
      const formData = await validate();
      let menu =  sideMenuList.value.find((v:{[key: string]: any}) => { return v.code == formData.pageKey });
      state.showPage=false
      let hash = menu.url.substring(menu.url.indexOf('#')+1, menu.url.indexOf('?')==-1?menu.url.length:menu.url.indexOf('?')),query='',params={};
      if(menu.url.indexOf('?')!=-1){
        query = menu.url.split('?')[1];
        params = queryParamsToObject(query);
      }
      router.push({path:'/'+hash,query:params})
    }
    const queryParamsToObject=(query:any)=> {
      const params = new URLSearchParams(query);
      const result:{[key: string]: any} = {};
      for (const [key, value] of params.entries()) {
        result[key] = value;
      }
      return result;
    }
    const handleMenuSelect=(code:any)=>{
      let menu =  sideMenuList.value.find((v:any) => { return v.code == code });
      let hash = menu.url.substring(menu.url.indexOf('#')+1, menu.url.indexOf('?')==-1?menu.url.length:menu.url.indexOf('?')),query='',params={};
      if(menu.url.indexOf('?')!=-1){
        query = menu.url.split('?')[1];
        params = queryParamsToObject(query);
      }
       router.push({path:'/'+hash,query:params})
      if(isMobile.value){
        store.commit('menu/changeCollapse');
      }
    }
    return {
      ...toRefs(state),
      iscollapse,
      router,
      formItemList,
      isMobile,
      curTopId,
      sideMenuList,
      iconList,
      sideMenu,
      curSideMenu,
      formatMenu,
      onSubmit,
      queryParamsToObject,
      handleMenuSelect
    }
  }
})
</script>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import locale from 'element-plus/es/locale/lang/zh-cn'
import ElementPlus from 'element-plus'
import globalMethods from '@/utils/plugins/globalMethods';
import 'element-plus/dist/index.css'
import './assets/css/index.scss';
import './assets/css/app.scss';

const app=createApp(App)

const globalComponents = require.context('./components/global', false, /[A-Z]\w+\.(vue|ts)$/);
globalComponents.keys().forEach((fileName) => {
    const componentConfig = globalComponents(fileName);
    const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
    app.component(componentName, componentConfig.default || componentConfig);
});

// 注册elementplus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.use(store).use(router).use(globalMethods).use(ElementPlus, { locale }).mount('#app')

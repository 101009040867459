<template>
    <el-container class="app-container"  v-if="topMenu&&topMenu.length!=0" >
      <el-header class="header">
        <main-header  ref="tabHeader"></main-header>
      </el-header>
      <el-container>
        <main-side></main-side>
        <el-main class="main-container" style="position: relative">
          <v-tags></v-tags>
          <main-breadcrumb></main-breadcrumb>
          <router-view v-slot="{ Component,route}">
            <keep-alive>
              <component :is="Component" :key="route.fullPath"/>
            </keep-alive>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
</template>
<script>
import {postExportData,CacheKeys} from "@/utils/constant";
import {showTipMessage,formatTime,showConfirm} from "@/utils/common";
import vTags from './main/main-tags.vue'
import MainHeader from './main/main-header.vue'
import mainBreadcrumb from './main/main-breadcrumb.vue'
import MainSide from './main/main-side.vue'
export default {
  name: 'App',
  provide(){
    return {
      $hasPermission:'',
      $showTipMessage:showTipMessage,
      $showConfirm:showConfirm,
      $postExportData:postExportData,
      $CacheKeys:CacheKeys,
      $formatTime:formatTime,
    }
  },
  components: {
    vTags,
    MainHeader,
    MainSide,
    mainBreadcrumb,
  },
  computed: {
    topMenu(){
      return this.$store.state.menu.topMenu
    },
  },
  mounted(){
    this.getToken();
    if(sessionStorage.token){
      this.$store.dispatch('user/getInfo')
    }
  },
  methods:{
    getUrlRequestParam() {
      var url = location.search;
      var _req = new Object();
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          _req[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return _req;
    },
    getToken(){
      var tk = this.getUrlRequestParam()._t;
      if (tk) {
        this.$store.dispatch('user/getToken',tk)
      }else{
        if(!sessionStorage.token){
          location.replace(process.env.VUE_APP_BASE_API + "/_sso/login");
        }
      }
    }
  }

}
</script>

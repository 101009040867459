<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="warning" @click="exportData" size="small">导出</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
    </div>
  </div>
</template>

<script>

    export default {
        data() {
            return {
                baseApiUrl: '/operator',
                searchData: {},
                searchItemList:[
                    { type: "input", value: "kw", placeholder: '支持驿站名称查询' }
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { prop: "code", label: '编码', width: 100 },
                        { prop: "name", label: '驿站名称', width: 200 },
                        { label: '余额', width: 150, callBack: (item)=>{
                                return (item.amount + item.billAmount).toFixed(2);
                            }
                        },
                        { prop: "updatedTime", label: '更新时间', minWidth: 150 }
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },
            }
        },
        methods: {
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.$http.post(this.baseApiUrl + '/StageWalletList', { ...this.searchData, ...this.pageinfo,...{isExcel: false}}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
            exportData(){
                this.postExportData({...this.searchData,...{isExcel:true}}, this.baseApiUrl+'/StageWalletList');
            },
        }
    }
</script>

<template>
  <div class="content_box" style="background: rgb(240,242,245);position: fixed;top:0;left:0;width:100%;height:100%;z-index:999;">
    <div style="width:100%;height:100%;overflow-y: scroll">
      <h4 class="textprimary pointer"  @click="toBack()"><el-icon><HomeFilled /></el-icon>返回首页</h4>
      <div style="background: #fff;padding:10px 10px 30px;margin-top: 20px;" v-if="modeType=='edit'">
        <h4 class="labelTitle">审核记录</h4>
        <el-descriptions :column="2" border size="small" style="max-width:1300px">
          <el-descriptions-item label="登录账号"><span class="textspan">{{operatorInfo.loginName}}</span></el-descriptions-item>
          <el-descriptions-item label="审核状态"><span class="textspan textdanger">{{operatorInfo.auditState}}</span></el-descriptions-item>
          <el-descriptions-item label="审核备注">{{operatorInfo.auditRemark}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div style="background: #fff;padding:10px;margin-top: 20px;">
        <h4 class="labelTitle">erp登录账号</h4>
        <Vform ref="erpform"
               :formItemList="erpItemList"
               :setFormInfo="{inline:true,labelWidth:'90px',formRules:erpRules,refName:'erpform'}"
               style="max-width:1300px"
               @changeform="changeform">
        </Vform>
      </div>
      <div style="background: #fff;padding:10px;margin-top: 20px;">
        <h4 class="labelTitle">基础信息</h4>
        <Vform ref="basisform"
               :formItemList="basisItemList"
               :setFormInfo="{inline:true,labelWidth:'120px',formRules:basisRules,refName:'basisform'}"
               style="max-width:1300px"
               @changeform="changeform">
          <template v-slot:formButton="formBtn">
            <el-form-item label="公司地址" style="width:calc(100% - 12px)" :error="addrError">
              <el-input v-model="addr" placeholder="请输入详细地址" class="input-with-select" size="small" :validateState="addrValidateState" @blur="validateaddr">
                <template #prepend>
                  <city-address ref="cascader" @emitdata="emitdata" :cityvalueList="coverageAreaCode"  @cityChange="cityChange"  :addressOptions="addressOptions" style="width:300px">
                  </city-address>
                </template>
              </el-input>
            </el-form-item>
          </template>
        </Vform>
      </div>
      <div style="background: #fff;padding:10px;margin-top: 20px;">
        <h4 class="labelTitle">银行信息</h4>
        <Vform ref="bankform"
               :formItemList="bankItemList"
               :setFormInfo="{inline:true,labelWidth:'120px',formRules:bankRules,refName:'bankform'}"
               style="max-width:1300px"
               @changeform="changeform">
        </Vform>
      </div>
      <div style="background: #fff;padding:10px;margin-top: 20px;">
        <h4 class="labelTitle">开票信息</h4>
        <Vform  ref="Invoicingform"
                :formItemList="InvoicingItemList"
                :setFormInfo="{inline:true,labelWidth:'120px',formRules:InvoicingRules,refName:'Invoicingform'}"
                style="max-width:1300px">
        </Vform>
      </div>
      <div style="background: #fff;padding:10px;margin-top: 20px;">
        <h4 class="labelTitle">合作信息</h4>
        <Vform :formItemList="cooperateItemList" ref="cooperateform" :setFormInfo="{inline:true,labelWidth:'120px',refName:'cooperateform'}" ></Vform>
      </div>
      <div style="background: #fff;padding:10px;margin-top: 20px;">
        <el-form :model="form" label-width="120px" size="small">
          <h4 class="labelTitle">开通渠道</h4>
          <el-form-item label="开通渠道：">
            <el-checkbox v-model="form[item.Id]"
                         v-for="item in channelList"
                         :true-label="1"
                         :false-label="0"
                         size="small"
                         @change="checked => handleChange(checked, item)">
              {{item.Name}}</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <div style="background: #fff;padding:10px;margin-top: 20px;">
        <h4 class="labelTitle">相关附件</h4>
        <div style="color:rgb(184,112,26);background:#FEF1E0;padding:15px;font-size:12px">
          <p><el-icon><BellFilled/></el-icon>以下资料皆需盖公章，建议附件使用图片格式(.jpg .jpeg .png)。</p>
          <p class="textspan textdanger">！！！！！需要提交以下证件哦！（缺漏或图片名与内容不对应，都会导致审核不通过哦~）</p>
          <p class="textspan" style="color:#000">
            <span v-for="(item,index) in Attachment">{{item}}<span v-if="index<Attachment.length-1">，</span></span>
            <span v-if="basisinfo.taxQualification==0">，一般纳税人资格认定</span>
            <span v-if="form.isCityWholesale==1">，城批模拟</span>
            <span v-if="form.isDistribution==1">，配销模拟</span>
            <span v-if="form.isArea==1">，食品流通许可证，开票资料</span>
          </p>
        </div>
        <uploader-list ref="uploaderlist" :ImgOption="ImgOption" @imglistchange="imglistchange" apiType="visitor"></uploader-list>
      </div>
      <div style="margin: 20px 0;text-align: right">
        <el-button type="danger" v-on:click="toBack()">关闭</el-button>
        <el-button type="primary" v-on:click="submitForm()" :disabled="canEdit">保存信息</el-button>
      </div>
      <el-dialog v-model="showBankInfo" title="支行联行信息查询" width="780" style="padding-bottom:20px;">
        <BankInfo :bankcode="bankcode" :BankUniqueNo="BankUniqueNo" @submititem="submitBankItem" v-if="showBankInfo"></BankInfo>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {serviceVisitor} from '@/utils/http'
import {BankInfo} from '@/utils/constant'
export default {
  data() {
    const productTypeList=  [
      {Id:'粮油副食',Name:'粮油副食'},
      {Id:'调味品',Name:'调味品'},
      {Id:'奶制品',Name:'奶制品'},
      {Id:'干货',Name:'干货'},
      {Id:'肉类',Name:'肉类'},
      {Id:'茶水饮料',Name:'茶水饮料'},
      {Id:'蔬果生鲜',Name:'蔬果生鲜'},
      {Id:'烟酒',Name:'烟酒'},
      {Id:'日用百货',Name:'日用百货'},
      {Id:'其他',Name:'其他'}
    ];
    return {
      paramsInfo:'',
      addrError:'',
      form:{isStoredCompact:0,isCityWholesale:0,isDistribution:0,isArea:0,isLeaveWith:0,isFundAccount:0},
      basisinfo:{},
      channelinfo:{channel:[]},
      erpinfo:{},
      banknfo:{},
      fileList:[],
      addr:'',
      coverageAreaCode:'',
      addrValidateState:'',
      checkedEmail:false,
      addressOptions:{checkStrictly:false,ismultiple:false,citytype:4,placeholder:"请选择省市区"},
      ImgOption:{imgData:{},leng:100,isSetting:true,acceptList:'.jpg,.png,.pdf',delUrl: '/DelUpYunFile'},
      modeType:'add',
      cityText:'',
      selectData:{},
      showBankInfo:false,
      BankUniqueNo:'',
      bankcode:'',
      Attachment:[],
      commonAttachment:["三证复印件", "开户行许可证复印件", "经营许可证复印件(食品/酒类)", "法人代表身份证复印件", "收结款人身份证复印件", "银行结算资料", "委托结算函"],
      promotionAttachment:["城批模拟"],
      areaAttachment:["食品流通许可证", "开票资料"],
      distributionAttachment:["配销模拟"],
      taxpayerAttachment:["一般纳税人资格认定"],
      channelList:[
        {Id:'isCityWholesale',Name:'城批'},
        {Id:'isDistribution',Name:'配销'},
        {Id:'isArea',Name:'商城'},
        {Id:'isLeaveWith',Name:'代存'},
        {Id:'isStoredCompact',Name:'精简代存'},
        {Id:'isFundAccount',Name:'资金账户'},
      ],
      erpItemList:[
        {label:"登录账号：",type: "input", value: "loginName",placeholder: '11位手机号',span:3},
        {label:"密码：",type: "password", value: "loginPwd",span:3},
        {label:"确认密码：",type: "password", value: "loginPwdConfirm",span:3},
        {label:"Email：",type: "input", value: "email",isBtn:true,btnLabel:'检测',span:3,isChange:true},
        {label:"用户名：",type: "input", value: "realname",span:3},
        {label:"备注：",type: "textarea", value: "remark",span:1}],
      basisItemList:[
        {label:"公司全称：",type: "input", value: "operatorName",span:2},
        {label:"合同日期：", type: "daterange", value: "createtime",span:2},
        {label:"法人代表姓名：",type: "input", value: "legalPersonName",span:2},
        {label:"法人身份证号：",type: "input", value: "legalPersonId",placeholder: '若开通资金账户需填写',span:2},
        {label:"业务联系人姓名：",type: "input", value: "contactPerson",span:2},
        {label:"业务联系电话：",type: "input", value: "contactPhone",span:2},
        {label:"通讯邮箱：",type: "input", value: "contactEmail",span:2},
        {label:"税务资质：",type: "radio", value: "taxQualification",defaultValue:0,selectOptions:[{Id:0,Name:'一般纳税人'},{Id:1,Name:'小规模纳税人'},{Id:2,Name:'个体工商户'}],span:2},
        {label:"运营商类型：",type: "radio", value: "operatorType",defaultValue:0,selectOptions:[{Id:0,Name:'运营商'},{Id:1,Name:'驿站主运营商'}],span:2},
        {label:"在职员工人数：",type: "radio", value: "workforce",defaultValue:'少于50人',selectOptions:[{Id:'少于50人',Name:'少于50人'},{Id:'50-150人',Name:'50-150人'},{Id:'150-500人',Name:'150-500人'},{Id:'500人以上',Name:'500人以上'}],span:2}],
      bankItems:[
        {label:"收付款账号名称：",type: "input", value: "accountNameFull",span:2},
        {label:"收付款账号：",type: "input", value: "cardNumber",span:2},
        {label:"财务联系人：",type: "input", value: "finContactPerson",span:2},
        {label:"财务联系电话：",type: "input", value: "finContactPhone",span:2},
        {label:"开户银行",type: "select", value: "bank",span:2,keyValue:['name','name']},
        {label:"支付联行号：",type: "input", value: "bankUniqueNo",placeholder: '若开户行非“中信银行”，则需要设置',isDisabled:true,span:2,isBtn:true,btnLabel:'设置联行号'}],
      InvoicingItemList:[
        {label:"开票种类：",type: "radio", value: "invoiceType",defaultValue:0,selectOptions:[{Id:0,Name:'普票'},{Id:1,Name:'专票'}],span:1},
        {label:"税号：", type: "input", value: "socialCreditCode",span:2},
        {label:"开票抬头：",type: "input", value: "invoiceTitle",span:2},
        {label:"单位地址：",type: "input", value: "invoiceAddr",span:2},
        {label:"开户银行",type: "select", value: "invoiceBank",span:2,keyValue:['name','name']},
        {label:"银行账户：",type: "input", value: "invoiceAccount",span:2},
        {label:"电话号码：",type: "input", value: "invoicePhone",span:2}],
      cooperateItemList:[
        {label:"合作产品类别：",type: "checkbox", defaultValue:[],value: "productType",selectOptions:productTypeList,span:1}],
      operatorInfo:{},
      erpRules: {
        loginName: [
          {required: true, message: "请输入登录账号", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '登录账号必须是手机号'}
        ],
        loginPwd: [{ required: true, message: "请输入密码", trigger: "blur"}],
        loginPwdConfirm: [{ required: true, message: "请再一次输入密码", trigger: "blur"}],
        email: [
          {required: true, message: "请输入email", trigger: "blur"},
          {pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/, message: '必须是邮箱格式'}
        ],
        realname: [{ required: true, message: "请输入用户名", trigger: "blur"}]
      },
      basisRules: {
        operatorName: [{ required: true, message: "请输入收付款账号名称", trigger: "blur"}],
        legalPersonName: [{ required: true, message: "请输入法人代表姓名", trigger: "blur"}],
        legalPersonId: [
          { required: true, message: "请输入法人身份证号", trigger: "blur"},
          {min:18,max:18,message: "身份证号格式不正确，长度为18", trigger: "blur"},
        ],
        contactPerson: [{ required: true, message: "请输入业务联系人姓名", trigger: "change"}],
        createtime:[{ required: true, message: "请选择合同日期", trigger: "blur" }],
        contactPhone: [
          {required: true, message: "请输入业务联系电话", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '业务联系电话格式错误，需为11位数的手机号'}
        ],
        contactEmail: [
          {required: true, message: "请输入通讯邮箱", trigger: "blur"},
          {pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/, message: '必须是邮箱格式'}
        ]
      },
      bankRules: {
        accountNameFull: [{ required: true, message: "请输入公司全称", trigger: "blur"}],
        cardNumber: [{ required: true, message: "请输入收款账号", trigger: "blur"}],
        finContactPerson: [{ required: true, message: "请输入财务联系人", trigger: "blur"}],
        finContactPhone: [
          {required: true, message: "请输入财务联系电话", trigger: "blur"},
          {pattern: /^1[3-9]\d{9}$/, message: '财务联系电话格式错误，需为11位数的手机号',trigger: "blur"}
        ]
      },
      InvoicingRules: {
        socialCreditCode: [{ required: true, message: "请输入税号", trigger: "blur"}],
        invoiceTitle: [{ required: true, message: "请输入开票抬头", trigger: "blur"}],
        invoiceAddr: [{ required: true, message: "请输入单位地址", trigger: "blur"}],
        invoiceAccount: [{ required: true, message: "请输入银行账户", trigger: "blur"}],
        invoicePhone: [{ required: true, message: "请输入电话号码", trigger: "blur"}]
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    bankItemList() {
      return this.bankItems.map((item) =>{
        return {
          ...item,
          selectOptions: this.selectData[item.value] || item.selectOptions || '',
          defaultValue: this.banknfo[item.value] ?? item.defaultValue ?? ''
        }
      })
    }
  },
  mounted(){
    BankInfo.query((res)=>{
      let formItem=[this.erpItemList,this.basisItemList,this.bankItems,this.InvoicingItemList,this.cooperateItemList];
      this.selectData.bank=res;
      formItem.forEach((list)=> {
          list.map((item)=> {
            if (item.value == 'bank' || item.value == 'invoiceBank') {
              item.selectOptions = res;
              item.defaultValue = res[0].name;
            }
            return item;
        })
      })
    });
    // 图片上传凭证
    serviceVisitor.post('/GetUpYunInfo', {}).then((res)=> {
      this.ImgOption.imgData.policy = res.data.policy;
      this.ImgOption.imgData.authorization = res.data.auth;
      this.ImgOption.imageUpUrl = res.data.host;
      this.ImgOption.imageUrl = res.data.domain;
    });
    this.Attachment=[...this.commonAttachment,...this.taxpayerAttachment];
    this.paramsInfo=sessionStorage.operator_regist?JSON.parse(sessionStorage.operator_regist):'';
    if(!this.paramsInfo){
      this.$store.dispatch('tags/setTagsItem',{name: 'frontPage', title: '运营商注册', path: '/frontPage',type:'child'});
      this.$router.push({path: '/frontPage'})
      return;
    }
    this.modeType=this.paramsInfo.modeType;
    if(this.modeType=='edit'){
      this.getListData();
    }
  },
  methods: {
    toBack(){
      sessionStorage.operator_regist='';
      this.$store.dispatch('tags/setTagsItem',{name: 'frontPage', title: '运营商注册', path: '/frontPage',type:'child'});
      this.$router.push({path: '/frontPage'})
    },
    getListData() {
      serviceVisitor.post('/GetOperatorInfo' , {loginName: this.paramsInfo.loginName,loginPwd: this.paramsInfo.loginPwd,saveMode: this.paramsInfo.modeType}).then((res) => {
        let info=res.data.jsonData;
        this.operatorInfo ={...info,...res.data};
        // 赋值开通渠道
        Object.keys(this.form).forEach(key => {
          if (key in  this.operatorInfo) {
            this.form[key] = this.operatorInfo[key];
          }
        });
        let formItem=[this.erpItemList,this.basisItemList,this.bankItems,this.InvoicingItemList,this.cooperateItemList];
        formItem.forEach(item=> this.showDetail(item,this.operatorInfo))
        // 地址赋值
        let addr= decodeURIComponent(this.operatorInfo.addr)
        if(addr.indexOf('/')==-1) this.addr=addr
        else this.addr=addr.split("/")[addr.split("/").length-1];this.cityText=addr.substring(0, addr.lastIndexOf('/'));
        this.coverageAreaCode=addr
        if(this.operatorInfo.attachment){
          let productImg=this.operatorInfo.attachment.map((item)=>{
            item.name=item.imgName;
            item.response={url:item.url};
            item.status='success'
            return item
          });
          this.ImgOption.list=productImg;
        }
      });
    },
    showDetail(list,operatorInfo){
      list.map((item)=>{
        if(item.value=='email'||item.value=='contactEmail') {
          item.defaultValue = operatorInfo[item.value]&&decodeURIComponent(operatorInfo[item.value])
        }else if(item.value=='loginPwdConfirm') {
          item.defaultValue=operatorInfo['loginPwd'];
        }else if(item.value=='createtime') {
          item.defaultValue=[operatorInfo.contractBegin,operatorInfo.contractEnd];
        }else if(item.value=='productType') {
          item.defaultValue=operatorInfo[item.value]&&operatorInfo[item.value].split(',');
        }else{
          item.defaultValue=operatorInfo[item.value]??(item.type=="checkbox"?[]:'')
          item.isDisabled=item.value=='loginName'?true:item.isDisabled||false;
        }
        return item;
      })
    },
    cityChange(obj){
      this.cityText=obj.cityObj[0].name;
      this.validateaddr();
    },
    // 开通渠道关联
    handleChange(val,item) {
      if(['isCityWholesale','isDistribution','isArea'].indexOf(item.Id)>-1&&val){
        this.form={...this.form,isLeaveWith:1,isFundAccount:1}
      }
      if(['isLeaveWith','isFundAccount'].indexOf(item.Id)>-1&&!val){
        this.form={...this.form,isCityWholesale:0,isDistribution:0,isArea:0}
      }
      if(item.Id=='isStoredCompact'&&val){
        this.form={...this.form,isStoredCompact:1,isCityWholesale:0,isDistribution:0,isArea:0,isLeaveWith:0,isFundAccount:0}
      }
      if(item.Id!='isStoredCompact'&&val){
        this.form={...this.form,isStoredCompact:0}
      }
    },
    changeform(d){
      if(d.email&&d.email!=this.erpinfo.email){
        this.checkedEmail=false;
      }
      if(d.refName=='channelform') this.channelinfo=d;
      if(d.refName=='basisform') this.basisinfo=d;
      if(d.refName=='erpform') this.erpinfo=d;
      if(d.btnclick){
        if(d.btnValue=='email'){
          if(!d[d.btnValue]) {
            this.showTipMessage("请填写email，再进行检测");
            return;
          }
          this.CheckEmail(d[d.btnValue]);
        }else if(d.btnValue=='bankUniqueNo'){
          this.showBankInfo=true;
          let obj=this.selectData.bank.find((item)=>  item.name==d.bank)
          this.bankcode=obj.key;
          this.BankUniqueNo=this.banknfo.bankUniqueNo;
          this.banknfo={...this.banknfo,...d};
          return;
        }
      }
      if(d.refName=='bankform'&&d.bank!=this.banknfo.bank&&d.bankUniqueNo){ // 切换了开户行，置空支行信息
        this.banknfo.bankUniqueNo=d.bankUniqueNo='';
      }
    },
    submitBankItem(d){
      this.showBankInfo=false;
      this.banknfo.bankUniqueNo=d.bank_code;
    },
    uploadFinish(obj){
      if(obj.succ==true){
        this.imageUrl =this.uploadOpt.domainUrl+ obj.resp.url;
      }else{
        this.showTipMessage("网络问题，提交失败，请重新上传")
      }
    },
    imglistchange(val) {
      if(val){
        this.fileList=JSON.parse(val);
      }
    },
    CheckEmail(email){
      serviceVisitor.post('/CheckEmail', {email:email}).then((res)=> {
        this.checkedEmail = res.data;
        if(!this.checkedEmail){
          this.showTipMessage("该email已被注册！");
          return;
        }
        this.showTipMessage("验证成功","success");
      });
    },
    // 验证公司地址
    validateaddr() {
      return new Promise((resolve, reject) => {
        if (!this.cityText||!this.addr) {
          this.addrValidateState = 'error';
          if(!this.cityText) this.addrError='公司地址是必填，请选择省市区';
          if(!this.addr) this.addrError='公司地址是必填，请填写详细地址';
          this.showTipMessage("缺少必填项","error");
          reject();
        } else {
          this.addrValidateState = '';
          this.addrError = '';
          resolve();
        }
      });
    },
    // 保存信息
    async submitForm(){
      let [erpInfo, basisInfo,InvoicingInfo,cooperateInfo,bankInfo] = await Promise.all([
        this.$refs.erpform.validateForm('erpform','缺少必填项'),
        this.$refs.basisform.validateForm('basisform','缺少必填项'),
        this.$refs.Invoicingform.validateForm('Invoicingform','缺少必填项'),
        this.$refs.cooperateform.validateForm('cooperateform','缺少必填项'),
        this.$refs.bankform.validateForm('bankform','缺少必填项'),
        this.validateaddr()
      ]);
      basisInfo.contractBegin = basisInfo.dateFrom;
      basisInfo.contractEnd = basisInfo.dateTo;
      delete basisInfo.dateFrom;
      delete basisInfo.dateTo;
      basisInfo.addr=this.cityText.replace(/\s+/g, '')+'/'+this.addr;
      // cooperateInfo.productTypeText=cooperateInfo.productType;
      // delete cooperateInfo.productType;
      let flag=false
      for (let key in this.form){if(this.form[key]==1){flag=true}}
      let postData={...erpInfo,...basisInfo,...InvoicingInfo,...cooperateInfo,...bankInfo,...this.form};
      if (postData.loginPwd != postData.loginPwdConfirm) {
        this.showTipMessage("两次密码不一致！",'warning'); return
      }
      if(postData.bank!='中信银行'&&!postData.bankUniqueNo){
        this.showTipMessage('您选择的开户行非“中信银行”，请点击【设置联行号】设置支行名称','warning');return;
      }
      if(postData.productType&&postData.productType.length==0){
        this.showTipMessage('请勾选合作信息中合作产品类别','warning');return;
      }
      if(!flag){
        this.showTipMessage('请勾选开通渠道','warning');return;
      }
      var arr = [], imgflag = false;
      if (!this.fileList || this.fileList.length == 0) {
        this.showTipMessage("请上传相关附件",'warning');
        return;
      }
      this.fileList.map((item) => {
        if (item.status == 'success') {
          var obj = {}
          obj.name = item.name;
          obj.url = (item.response.url.indexOf("http://") != -1 || item.response.url.indexOf("https://") != -1) ? item.response.url : this.ImgOption.imageUrl + item.response.url;
          obj.size = item.size;
          obj.type = item.name.substring(item.name.lastIndexOf('.')+1,item.name.length);
          arr.push(obj)
        }else {return imgflag = true;}
      })
      if (imgflag) {
        this.showTipMessage("请点击【确认上传】，完成上传后再点击完成",'warning');
        return;
      }
      if(!this.checkedEmail){
        this.showTipMessage('请先验证email','warning');return;
      }
      postData.attachment = JSON.stringify(arr)
      if(this.modeType=='edit')postData.id=this.operatorInfo.id;
      this.canEdit=true;
      serviceVisitor.post('/EditOperator',postData).then(() => {
        this.showTipMessage(this.modeType=='edit'?'修改信息成功':'添加信息成功','success');
        this.canEdit=false;
        this.showDetailPage=false;
        if(this.modeType=='add'){
          this.modeType='edit';
          sessionStorage.operator_regist=JSON.stringify({"loginName":postData.loginName,"loginPwd":postData.loginPwd,"modeType":"edit"})
          this.paramsInfo={"loginName":postData.loginName,"loginPwd":postData.loginPwd,"modeType":"edit"};
        }
        this.getListData();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>
<style>
.input-with-select .el-input-group__append, .input-with-select .el-input-group__prepend{padding:0px 0 1px 0!important;box-shadow:0 0 0 1px #dcdfe6 inset;;}
.input-with-select .el-input-group__append .el-input__wrapper, .input-with-select .el-input-group__prepend .el-input__wrapper{background: #f5f7fa;box-shadow:none;}
</style>


<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable"></mainTable>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const dateBegin = this.$formatDate(0, -30);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: '/product',
      searchData: {},
      showDetailPage:false,
      searchItemList:[
        {label: "修改时间", type: "daterange", value: "createtime",defaultValue:[dateBegin,dateEnd]},
        {type: "input", value: "kw",placeholder:'查询商品名/编码'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          { label: "商品编码", width: 120, prop: "code" },
          { label: "商品名称", width: 250, prop: "name" },
          { label: "修改人", width: 110, prop: "modifiedBy" },
          { label: "修改日期", width: 110, prop: "modifiedTime" },
          { label: "修改原因", minWidth: 150, prop: "reason" },
        ]
      },
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetModifiedLog', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
  }
}
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button-group>
        <el-button type="success"  size="small" @click="submitItem('','add')">添加</el-button>
        <el-button type="warning" size="small" @click="exportData('','add')">导出</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.State=='1'?'success':'primary'"  plain size="small"  @click="submitItem(actionBtn.data,'edit')">{{actionBtn.data.State=='1'?'查看':'编辑'}}</el-button>
          <el-button type="warning"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.State=='1'">禁用</el-button>
          <el-button type="success"  plain size="small" @click="submitItem(actionBtn.data,'upState')" :disabled="canEdit" v-if="actionBtn.data.State=='0'">启用</el-button>
          <el-button type="danger"  plain size="small" @click="submitItem(actionBtn.data,'del')">删除</el-button>
          <el-button type="primary"  plain size="small"  @click="submitItem(actionBtn.data,'service')">服务范围</el-button>
          <el-button type="primary"  plain size="small"  @click="showQrcode=true;qrcodeForm=actionBtn.data">生成二维码</el-button>
        </template>
      </mainTable>
    </div>
    <!--添加&编辑-->
    <el-dialog v-model="showDetailPage" :title="title" width="980">
      <Vform :formItemList="formItemList" v-show="showDetailPage" ref="vform"
             :setFormInfo="{inline:true,labelWidth:'100px',formRules:rules,isDisabled:modeType=='edit'&&curItem.State==1}"
             @changeform="changeform" @send="getAddressData">
        <template v-slot:formButton="formBtn">
          <el-form-item label="门头照" style="width:100%">
            <uploader v-if="curItem.State!=1" :url="uploadOpt.url" :key="uploadOpt.fileList" :isImg="uploadOpt.isImg"
                      :fileList="uploadOpt.fileList" :postData="uploadOpt.data" :accept="uploadOpt.accept"
                      @complete="uploadFinish" @imgchange="imgchange" @resetfun="fileList=[],imageUrl=''"></uploader>
            <el-image :lazy='true' :src="imageUrl" @click="this.showPreview=true;"
                      style="width:auto;height:100%;max-height:160px;border:1px solid #ddd;cursor: pointer"
                      v-if="curItem.State==1&&imageUrl"/>
            <span v-if="curItem.State==1&&!imageUrl" class="textwarning">未设置门头照</span>
          </el-form-item>
        </template>
      </Vform>
      <template #footer v-if="modeType=='add'||(modeType=='edit'&&curItem.State==0)">
          <span class="dialog-footer">
            <el-button type="primary" v-on:click="submitForm()" :disabled="canEdit">确定提交</el-button>
            <el-button type="danger" v-on:click="showDetailPage = false">关闭</el-button>
          </span>
      </template>
    </el-dialog>
    <!--设置支行信息-->
    <el-dialog v-model="showBankInfo" title="支行联行信息查询" width="780" style="padding-bottom:20px;">
      <BankInfo :bankcode="bankcode" :BankUniqueNo="BankUniqueNo" @submititem="submitBankItem"
                v-if="showBankInfo"></BankInfo>
    </el-dialog>
    <!--预览-->
    <el-image-viewer v-if="showPreview" @close="showPreview=false" :initial-index="0" :url-list="[imageUrl]"/>
    <!--服务范围-->
    <el-dialog v-model="showService" :title="title" width="700">
      <h4 class="labelTitle">区域查询</h4>
      <Vform :formItemList="serviceItem"
             ref="vform"
             :setFormInfo="{inline:true,labelWidth:'80px',formRules:rules}"
             @changeform="changeform"
             @send="getAddressData">
        <template v-slot:formButton="formBtn">
          <el-form-item>
            <el-button type="success" @click="AddAreaTag(formBtn.validateForm)" :disabled="canEdit" style="margin-left: 60px">添加区域</el-button>
          </el-form-item>
          <h4 class="labelTitle">选中区域</h4>
          <div style="padding-left:20px">
            <el-tag v-for="(tag,i) in tags" closable :key="tag.name"
                    class="mx-1"
                    :type="tag.type"
                    style="margin-right:10px;margin-bottom:8px;"
                    @close="tags.splice(i, 1)">{{ tag.name }}
            </el-tag>
          </div>
        </template>
      </Vform>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="submitForm()" :disabled="canEdit">保存服务区域</el-button>
          <el-button type="danger" v-on:click="showService = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="showQrcode" title="生成驿站静态二维码" width="700" @close="qrcodeForm.salerCode='';qrcodeForm.clientType='',qrcodeUrl=''">
      <el-form ref="ruleFormRef" style="max-width: 600px" :model="qrcodeForm" status-icon :rules="rules" size="small" label-width="auto" class="demo-ruleForm">
        <el-form-item label="驿站：" prop="age">
          {{qrcodeForm.StageName}}
        </el-form-item>
        <el-form-item label="客户渠道：">
          <el-select v-model="qrcodeForm.clientType" placeholder="请选择" style="width:60%;margin-right: 20px" @change="clearQrcode();qrcodeUrl=''">
            <el-option v-for="(item,index) in ClientChannelList" :key="index" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定业务员：">
          <el-select v-model="qrcodeForm.salerCode" placeholder="请选择" filterable style="width:60%;margin-right: 20px" @change="clearQrcode();qrcodeUrl=''">
            <el-option v-for="(item,index) in SalemenInfo" :key="index" :label="item.Name" :value="item.Code">
            </el-option>
          </el-select>
          <el-button type="primary" v-on:click="qrcode" >生成二维码</el-button>
        </el-form-item>
        <el-form-item label="二维码：" prop="age" style="justify-content: center">
          <qrcode id="qrcode" :url="qrcodeUrl" ref="qrcode" :key="qrcodeUrl"></qrcode>
        </el-form-item>
      </el-form>
      <template #footer >
          <span class="dialog-footer">
            <el-button type="success" v-on:click="downloadClick()" :disabled="!qrcodeUrl">下载二维码</el-button>
            <el-button type="danger" v-on:click="showQrcode = false,qrcodeForm.salerCode='';qrcodeForm.clientType='';qrcodeUrl=''">关闭</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {cloneObject,formatAddressData,compareObjects} from '@/utils/common'
import {BankInfo} from '@/utils/constant'
export default {
  data() {
    return {
      tags:[],
      baseApiUrl:'/operator',
      searchData: {},
      searchItemList:[
        {type: "input", value: "kw",placeholder:'查询关键字',width:'160'}
      ],
      showPreview:false,
      showDetailPage: false,
      showService:false,
      showBankInfo:false,
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"390", fixed:'left'},
          { label: "驿站编码", width: 90, prop: "StageCode" },
          { label: "驿站名称", width: 160, prop: "StageName" },
          { label: "状态", width: 60,callBack:(item)=>{ return ['禁用','启用'][item.State]},getColor:(item)=>{return item.State=='0'?'textdanger':'textsuccess'}},
          { label: "驿站主", width: 80, prop: "PersonInCharge" },
          { label: "联系方式", width: 100, prop: "ContactPhone" },
          { label: "商圈", minWidth: 140, prop: "merchantName" },
          { label: "起送金额", width: 80, prop: "DeliveryAmount" },
          { label: "上架商品", width: 70,callBack:(item)=>{ return ['不允许','允许'][item.isCityWholesale]},getColor:(item)=>{return item.isCityWholesale=='0'?'textdanger':'textsuccess'}},
        ]
      },
      modeType:'',
      serviceForm:{},
      addressList:[],// 地址数据
      serviceInfo: [
        {
          label: "行政级别",
          type: "radio",
          value: "citytype",
          defaultValue: 3,
          selectOptions: [{Id: 3, Name: '县市'}, {Id: 4, Name: '街镇'}],
          span: 1,
          isChange: true
        },
        {
          label: "地区",
          type: "address",
          value: "serviceArea",
          span: 1,
          placeholder: '请选择区域',
          addressOptions: {
            checkStrictly: true,
            ismultiple: true,
            isShowAll: false,
            emitData: true,
            citytype: 3,
            backObj: true
          }
        }],
      FormItems: [
        {label: "驿站名称", type: "input", value: "StageName", span: 2},
        {label: "驿站全称", type: "input", value: "StageFullName", span: 2},
        {label: "驿站主", type: "input", value: "PersonInCharge", span: 2},
        {label: "联系电话", type: "input", value: "ContactPhone", span: 2},
        {
          label: "地区",
          type: "address",
          value: "AddressCode",
          span: 2,
          placeholder: '地区',
          addressOptions: {checkStrictly: false, ismultiple: false, citytype: 4}
        },
        {label: "详细地址", type: "input", value: "DetailAddress", span: 2},
        {label: "商圈", type: "select", value: "BindingAreaCode", span: 2, keyValue: ['Code', 'Name']},
        {
          label: "上架产品",
          type: "radio",
          value: "isCityWholesale",
          defaultValue: 1,
          selectOptions: [{Id: 1, Name: '允许'}, {Id: 0, Name: '不允许'}],
          span: 2,
          isChange: true
        },
        {label: "收款账号", type: "input", value: "CardNumber", span: 2},
        {label: "收款账号名称", type: "input", value: "AccountNameFull", span: 2, keyValue: ['Code', 'Name']},
        {label: "收款开户银行", type: "select", value: "Bank", span: 2, keyValue: ['name', 'name']},
        {
          label: "支行名",
          type: "input",
          value: "BranchName",
          span: 2,
          isDisabled: true,
          isBtn: true,
          btnLabel: '设置',
          placeholder: '若开户行非“中信银行”，则需要点击【设置】设置支行名称'
        },
        {label: "起送金额(元)", type: "input", value: "DeliveryAmount", span: 1}],
      fileList:[],
      uploadOpt:{
        isImg:true,
        fileList:[],
        domainUrl:'',
        url: '',
        accept:'image/*',
        data: {}
      },
      title:'',
      bankcode:'',
      BankUniqueNo:'',
      url:'',//批量更换url
      curItem:{},
      oldItem:{},
      oldcurItem:{},
      selectData:{},
      disabledList:{},
      canEdit:false,
      qrcodeUrl:'',
      qrcodeForm:{},
      SalemenInfo:[],
      ClientChannelList:[],
      showQrcode:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
      rules: {
        StageName: [{ required: true, message: "请输入驿站名称", trigger: "manual"}],
        StageFullName: [{ required: true, message: "请输入驿站全称", trigger: "manual"}],
        PersonInCharge: [{ required: true, message: "请输入驿站主", trigger: "manual"}],
        ContactPhone: [
          {required: true, message: "请输入联系电话", trigger: "manual"},
          {pattern: /^1[3-9]\d{9}$/, message: '联系电话格式不正确，请输入11位手机号'}
        ],
        CardNumber: [
            { required: true, message: "请输入收款账号", trigger: "manual"},
            {pattern: /^[0-9]*[1-9][0-9]*$/, message: '收款账号不正确，只能输入数字'}
        ],
        AccountNameFull: [{ required: true, message: "请输入收款账号名称", trigger: "manual"}],
        DetailAddress: [{ required: true, message: "请输入详细地址", trigger: "manual"}],
        DeliveryAmount: [
            { required: true, message: "请输入起送金额", trigger: "manual"},
            {pattern: /^\d*\.?\d*$/, message: '起送金额不正确，只能输入正数'}
        ],
      }
    }
  },
  inject: ['$hasPermission'],
  computed: {
    serviceItem() {
      return this.serviceInfo.map((item) => {
        return {
          ...item,
          defaultValue: this.serviceForm[item.value] ?? item.defaultValue ?? ''
        }
      })
    },
    formItemList() {
      return this.FormItems.map((item) => {
        return {
          ...item,
          isDisabled: this.disabledList[item.value] || item.isDisabled || false,
          selectOptions: this.selectData[item.value] || item.selectOptions || '',
          defaultValue: this.curItem[item.value] ?? item.defaultValue ?? '',
          addressOptions: item.addressOptions ? {...item.addressOptions, isdisabled: this.curItem.State == 1} : '',
          isBtn: item.isBtn ? this.curItem.State != 1 : ''
        }
      })
    }
  },
  mounted(){
    BankInfo.query((res)=>{this.selectData.Bank=res});
    this.commonAjax();
  },
  methods: {
    qrcode(){
      if(!this.qrcodeForm.clientType){
        this.showTipMessage('请先选择客户渠道','error');
        return;
      }
      if(!this.qrcodeForm.salerCode){
        this.showTipMessage('请先选择绑定业务员','error');
        return;
      }
      this.$http.post(this.baseApiUrl + '/GenerateQRCode', {stageCode:this.qrcodeForm.StageCode,salerCode:this.qrcodeForm.salerCode,clientChannelCode:this.qrcodeForm.clientType}).then((res) => {
        this.qrcodeUrl=`https://stagemp-api.ysc-dt.com/?StageCode=${this.qrcodeForm.StageCode}&SaleCode=${this.qrcodeForm.salerCode}&ClientType=${this.qrcodeForm.clientType}&${res.data}`;
      });
    },
    clearQrcode(){
      this.$refs.qrcode.clearQrcode();
    },
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ImgProof).then((d) => {
        this.uploadOpt.data.policy = d.policy;
        this.uploadOpt.data.authorization = d.auth;
        this.uploadOpt.url = d.host;
        this.uploadOpt.domainUrl = d.domain;
      });
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.MerchantInfo, query:{...this.CacheKeys.MerchantInfo.query,state:1}}).then((res) => {
        this.selectData.BindingAreaCode=res;
      });
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.SalemenInfo,query:{...this.CacheKeys.SalemenInfo.query,state:1}}).then((res) => {
        this.SalemenInfo=res;
      });
      this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.ClientChannelInfo}).then((res) => {
        this.ClientChannelList=res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/QueryStageData', {...this.searchData, ...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    // 单个禁启用，服务范围，编辑，添加
    submitItem(d,type){
      let url='',postData={},msg='',title='';
      this.modeType=type;
      switch (type){
        case 'upState':
          url='/StageUpdateState';
          postData={code:d.StageCode,state:d.State=='0'?1:0};
          msg=d.State=='0'?'启用成功!':'禁用成功!'
          title=d.State=='0'?`确认启用【${d.StageName}】吗？`:`确认禁用【${d.StageName}】吗？`
          break;
        case 'del':
          url='/StageInfoDelete';
          postData={code:d.StageCode};
          msg='删除成功'
          title=`确认删除【${d.StageName}】吗？`
          break
        case 'service':
          this.title = `编辑【${d.StageName}】服务范围`;
          this.showService = true;
          this.serviceInfo[1].addressOptions.citytype = 3;
          this.curItem=d;
          this.oldcurItem=JSON.parse(JSON.stringify(d));
          this.serviceForm={};
          if(this.addressList.length!=0&&this.curItem.serviceArea){
            this.tags=formatAddressData(this.addressList,this.curItem.serviceArea);
          }else{
            this.tags=[];
          }
          return;
          // eslint-disable-next-line no-unreachable
          break
        case 'edit':
          this.title = d.State==1?`查看【${d.StageName}】信息`:`编辑【${d.StageName}】信息`;
          this.showDetailPage=true;
          this.fileList=[];
          if(d.ImgUrl){
            this.uploadOpt.fileList = [{name: '门头照.jpg', url: d.ImgUrl,status:'success'}];
            this.imageUrl=d.ImgUrl;
          }else{
            this.uploadOpt.fileList=[];
            this.imageUrl='';
          }
          this.fileList=JSON.parse(JSON.stringify(this.uploadOpt.fileList));
          this.disabledList={BindingAreaCode:true};
          // AjaxLoading();
          this.$http.post(this.baseApiUrl + '/StageBank',{code:d.StageCode}).then((res) => {
            this.curItem={...d,...res.data}
            this.oldItem={
              StageName: d.StageName,
              StageFullName: d.StageFullName,
              PersonInCharge: d.PersonInCharge,
              ContactPhone: d.ContactPhone,
              AddressCode: d.AddressCode,
              DetailAddress: d.DetailAddress,
              BindingAreaCode:d.BindingAreaCode,
              isCityWholesale: d.isCityWholesale,
              CardNumber: res.data.CardNumber,
              AccountNameFull: res.data.AccountNameFull,
              Bank: res.data.Bank,
              BranchName:  res.data.BranchName,
              DeliveryAmount: d.DeliveryAmount,
              StageCode: d.StageCode,
              BankUniqueNo: res.data.BankUniqueNo,
              ImgUrl: d.ImgUrl
            }
          },(res)=>{this.showTipMessage(res.msg||'未知错误','error');});
          return;
          // eslint-disable-next-line no-unreachable
          break
        case 'add':
          this.showDetailPage=true;
          this.title = `添加驿站`;
          this.uploadOpt.fileList=[];
          this.imageUrl='';
          this.curItem={BindingAreaCode:this.selectData.BindingAreaCode[0].Code,Bank:this.selectData.Bank[0].name};
          this.disabledList={};
          return;
          // eslint-disable-next-line no-unreachable
          break
      }
      this.showConfirm(title,()=>{
        this.canEdit=true;
        this.$http.post(this.baseApiUrl + url,postData).then(() => {
          this.showTipMessage(msg,'success');
          this.canEdit=false;
          this.search();
        },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
      })
    },
    getAddressData(list){
      if(this.addressList.length==0){
        this.addressList=list;
      }
      if((!this.serviceForm.serviceArea||this.serviceForm.serviceArea.length==0)&&this.oldcurItem.serviceArea){
        this.tags=formatAddressData(this.addressList,this.oldcurItem.serviceArea);
      }else{
        this.tags=[];
      }
    },
    changeform(d){
      if(d.btnclick){
        this.showBankInfo=true;
        let obj=this.selectData.Bank.find((item)=>  item.name==d.Bank)
        this.bankcode=obj.key;
        this.BankUniqueNo=this.curItem.BankUniqueNo;
        this.curItem={...this.curItem,...d};
        return;
      }
      if(d.Bank!=this.curItem.Bank){ // 切换了开户行，置空支行信息
        this.curItem.BankUniqueNo=d.BankUniqueNo='';
        this.curItem.BranchName=d.BranchName='';
      }
      this.curItem={...this.curItem,...d};
      if(this.modeType=='service'){
        this.serviceForm=d;
        this.serviceInfo[1].addressOptions.citytype = d.citytype
      }
    },
    submitBankItem(d){
      this.showBankInfo=false;
      this.curItem.BankUniqueNo=d.bank_code;
      this.curItem.BranchName=d.lname;
    },
    uploadFinish(obj){
      if(obj.succ==true){
        this.imageUrl =this.uploadOpt.domainUrl+ obj.resp.url;
      }else{
        this.showTipMessage("网络问题，提交失败，请重新上传")
      }
    },
    imgchange(val){
      this.fileList=JSON.parse(val);
    },
    // 添加服务区域
    async AddAreaTag(validate) {
      const formData = await validate()
      let selectData=cloneObject(formData.serviceArea);
      if(selectData.length==0||!selectData[0]){
        this.showTipMessage('请先选择区域','warning');
        return;
      }
      let allHave=selectData.every((item)=> {
        return this.tags.some((tagItem)=> {
          return tagItem.id === item;
        });
      });
      if (allHave) {this.showTipMessage('所选的区域已被添加', 'warning');return;}
      let hadArea=false;
      selectData.forEach((item)=>{
        for (let i = 0; i < this.tags.length; i++) {
          let curCode=item.toString();
          let code=this.tags[i].id.toString()
          if (code.startsWith(curCode)) {
            this.tags.splice(i, 1);
            i--;
          }else if(curCode.startsWith(code)){
            hadArea=true;
          }
        }
      })
      if(hadArea){
        this.showTipMessage('所选的区域已被包含','warning');
        return;
      }
      let allTags=[...this.tags,...formData.serviceAreaText];
      this.tags=allTags.filter((item, index, arr) =>{return arr.findIndex(x =>x.id == item.id) == index})
    },

    // 保存服务区域
    async submitForm(){
      let postData={},url='';
      if(this.modeType=='service'){
        let serviceArea=[];
        this.tags.forEach((item)=>{serviceArea.push(Number(item.id))});
        if(JSON.stringify(serviceArea)==JSON.stringify(this.curItem.serviceArea)){
          this.showTipMessage('服务范围无变化','warning');
          return;
        }
        postData={stageCode:this.curItem.StageCode,serviceArea:serviceArea.join()};
        url='/StageInfoEditAddressCode';
      }else{
        let formData = await this.$refs.vform.validateForm();
        if(!formData.AddressCode){
          this.showTipMessage('请选择地区', 'warning');
          return;
        }
        if(formData.Bank!='中信银行'&&!formData.BranchName){
          this.showTipMessage('您选择的开户行非“中信银行”，请点击【设置支行】设置支行名称','warning');
          return;
        }
        // if(this.fileList.length==0){
        //   this.showTipMessage('请先【选择文件】并点击【提交上传】上传门头照','warning');
        //   return;
        // }
        let flag = false;
        this.fileList.map((item) => {
          if (item.status!= 'success') {flag = true;}
        })
        if (flag) {
          this.showTipMessage("存在未上传的图片，请点击【提交上传】",'warning');
          return;
        }
        if(this.fileList.length==0){this.imageUrl=''}
        postData={...formData,StageCode:this.curItem.StageCode,BankUniqueNo:this.curItem.BankUniqueNo,ImgUrl:this.imageUrl};
        if(this.modeType=='edit'){
          postData.StageCode=this.curItem.StageCode;
          let res=compareObjects(this.oldItem,postData);
          if(res.length==0){
            this.showTipMessage('无修改内容','warning');
            return;
          }
        }
        url=this.modeType=='edit'?'/StageInfoEdit':'/StageInfoAdd';
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl +url,postData).then(() => {
        this.showTipMessage(this.modeType=='service'?'修改服务范围成功':'保存驿站信息成功','success');
        this.canEdit=false;
        this.showDetailPage=false;
        this.showService=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    // 下载二维码
    downloadClick() {
      // 先找到canvas节点下的二维码图片
      const myCanvas = document.getElementById('qrcode').getElementsByTagName('canvas')
      const img = document.getElementById('qrcode').getElementsByTagName('img')
      // 创建一个a节点
      const a = document.createElement('a')
      // 设置a的href属性将canvas变成png格式
      const imgURL = myCanvas[0].toDataURL('image/jpg')
      const ua = navigator.userAgent
      if (ua.indexOf('Trident') !== -1 && ua.indexOf('Windows') !== -1) { // IE内核 并且  windows系统 情况下 才执行;
        var bstr = atob(imgURL.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        const blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, '下载' + '.' + 'png')
      } else if (ua.indexOf('Firefox') > -1) { // 火狐兼容下载
        const blob = this.base64ToBlob(imgURL); //new Blob([content]);
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        a.download = ' '; //下载图片名称，如果填内容识别不到，下载为未知文件，所以我这里就不填为空
        a.href = URL.createObjectURL(blob);
        a.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));
      } else { // 谷歌兼容下载
        img.src = myCanvas[0].toDataURL('image/jpg')
        a.href = img.src
        let curSaler=this.SalemenInfo.find(item=>item.Code==this.qrcodeForm.salerCode)
        a.download = `${this.qrcodeForm.StageName}业务员【${curSaler.Name}】二维码`;
        // 点击
        a.click();

        this.$message({
          message: '下载成功，已保存到桌面',
          type: 'success'
        });
      }
    },

    // base64转blob
    base64ToBlob(code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {
        type: contentType
      });
    },
    exportData(){
      this.postExportData({...this.searchData}, this.baseApiUrl+'/QueryStageData')
    }
  }
}
</script>

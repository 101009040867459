<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success" @click="add" size="small">添加</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="viewItem(actionBtn.data)">查看</el-button>
          <el-button :type="actionBtn.data.status==1?'info':'primary'" plain size="small"
                     :disabled="actionBtn.data.status==1" @click="editItem(actionBtn.data)">编辑
          </el-button>
          <el-button type="danger" plain size="small" @click="deleteItem(actionBtn.data)">删除</el-button>
          <el-button :type="actionBtn.data.status==1?'warning':'success'" plain size="small"
                     @click="setState(actionBtn.data)">{{ actionBtn.data.status == 1 ? '禁用' : '启用' }}
          </el-button>
        </template>
      </mainTable>
    </div>
  </div>

  <el-dialog v-model="showEditDialog" :title="modeType == 'Add' ? '添加商城信息' : '编辑商城信息'" width="700">
    <Vform ref="vform" :formItemList="formItemList" :setFormInfo="{inline:true,labelWidth:'120px',formRules:rules}"
           @changeform="changeForm">
      <template v-slot:formButton="formBtn">
        <el-form-item style="margin-left: 120px;">
          <el-button type="primary" @click="onSubmit(formBtn.validateForm)" :disabled="canEdit">确定提交</el-button>
          <el-button type="danger" @click="showEditDialog=false">关闭</el-button>
        </el-form-item>
      </template>
    </Vform>
  </el-dialog>

  <el-dialog v-model="showViewDialog" title="仓库信息" width="800px">
    <mainTable :mainOptions="viewTD" :showPage="false"></mainTable>
    <div style="height: 20px;"></div>
  </el-dialog>

</template>
<script>
import {compareObjects} from "@/utils/common";

export default {
  data() {
    return {
      baseApiUrl: '/system',
      searchData: {},
      searchItemList: [
        {type: "input", value: "kw", placeholder: '查询关键词'}
      ],
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          {label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "280", fixed: 'left'},
          {label: "商品编码", width: 100, prop: "code"},
          {label: "商城名称", width: 120, prop: "name"},
          {label: "仓库总数", width: 100, prop: "warehouseNum"},
          {
            label: "状态", minWidth: 100, prop: "status", callBack: (item) => {
              return item.status == 1 ? "启用" : "禁用";
            }, getColor: (item) => {
              return item.status == 1 ? 'textsuccess' : 'textdanger'
            }
          }
        ]
      },
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },

      // showEditDialog
      showEditDialog: false,
      FormItems: [
        {label: "商城编码：", type: "input", value: "code", isDisabled: true, placeholder: '自动编码', span: 1},
        {label: "商城名称：", type: "input", value: "name", span: 1},
        {
          label: "绑定仓库：",
          type: "select",
          value: "warehouse",
          defaultValue: '',
          selectOptions: [],
          multiple: true,
          placeholder: '请选择仓库',
          keyValue: ['Code', 'Name'],
          span: 1
        },
        {label: "佣金费率：", type: "input", placeholder: '请输入百分比', value: "commissionRate", span: 1, unit: '%'},
        {label: "佣金上限：", type: "input", placeholder: '请输入数字', value: "upperLimit", span: 1.2, unit: '元'},
        {label: "", type: "checkbox", value: "isSet", defaultValue: [], selectOptions: [{Id: 1, Name: '不设置上限'}]},
      ],
      rules: {
        name: [{required: true, message: "请输入名称", trigger: "manual"}],
        commissionRate: [
          {required: true, message: "请输入佣金费率", trigger: "blur"},
          {pattern: /^(100(\.00?)?|[0-9]{1,2}(\.[0-9]{1,2})?)$/, message: '佣金费率为0-100之间的数，最多保留两位小数', trigger: "blur"}
        ],
        upperLimit: [
          {required: true, message: "请输入佣金上限", trigger: "blur"},
          {pattern: /^(-1|0\.\d{1,2}|[1-9]\d*(\.\d{1,2})?)$/, message: '只能输入-1，或者大于0的数字，最多保留两位小数', trigger: "blur"}
        ],
        warehouse: [{required: true, message: "请选择仓库", trigger: "manual"}],
      },
      curItem: {},
      canEdit: false,
      modeType: 'Add',
      stageInfo: [],
      oldWarehouseCode: [],
      oldWarehouse: {},

      // showViewDialog
      showViewDialog: false,
      viewTD: {
        DataList: [],
        tableHeight: 250,
        labelList: [
          {label: "仓库编码", width: 100, prop: "code"},
          {label: "仓库名称", width: 100, prop: "name"},
          {label: "仓库地址", minWidth: 150, prop: "addr"}
        ]
      }
    }
  },
  computed: {
    formItemList() {
      return this.FormItems.map(item => ({
        ...item,
        isDisabled: item.value == 'upperLimit' && this.curItem.isSet && this.curItem.isSet.length != 0 ? true : (item.isDisabled || false),
        selectOptions: item.value == 'warehouse' ? this.stageInfo : (item.selectOptions || ''),
        defaultValue: this.curItem[item.value] ?? item.defaultValue ?? '',
      }))
    },
  },
  mounted() {
    this.getStageInfo();
  },
  methods: {
    getStageInfo() {
      this.$store.dispatch('cache/loadCacheData', this.CacheKeys.CommonWarehouse).then((res) => {
        this.stageInfo = res
      });
    },
    search(postData) {
      this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/GetAreaData', {...this.searchData, ...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    add() {
      this.modeType = 'Add'
      this.curItem = {}
      this.showEditDialog = true
    },
    changeForm(d) {
      this.curItem = {...d, upperLimit: d.isSet.length != 0 ? -1 : ''};
    },
    async onSubmit(validate) {
      const formData = await validate()
      // eslint-disable-next-line no-unused-vars
      const {isSet, ...postData} = formData;
      if (this.modeType == 'Edit') {
        let res = compareObjects(this.oldWarehouse, postData);
        if (res.length == 0) {
          return this.showTipMessage('没有需要更新的资料', 'warning')
        }
        postData.oldWarehouseCode = this.oldWarehouseCode
      }
      let url = this.modeType == 'Edit' ? '/AreaEdit' : '/AreaAdd'
      this.canEdit = true
      this.$http.post('/System' + url, {
        ...postData,
        warehouse: postData.warehouse.join(),
        oldWarehouseCode: this.oldWarehouseCode.join()
      }).then(() => {
        this.showTipMessage('操作成功!', 'success')
        this.canEdit = false
        this.showEditDialog = false
        this.getListData()
      }, (res) => {
        this.canEdit = false;
        this.showTipMessage(res.msg || '未知错误', 'error')
      })
    },
    editItem(row) {
      if (row.status == 1) {
        return this.showTipMessage("启用状态不允许修改！")
      }
      this.modeType = 'Edit'
      this.curItem = JSON.parse(JSON.stringify(row));
      this.curItem.isSet = this.curItem.upperLimit == -1 ? [1] : [];
      this.curItem.commissionRate = (row.commissionRate ?? 0) * 100;
      this.$http.post(this.baseApiUrl + "/GetAreaWarehouseData", {areaCode: row.code}).then((res) => {
        this.oldWarehouseCode = []
        res.data.forEach(item => {
          this.oldWarehouseCode.push(item.warehouseCode)
        })
        this.curItem.warehouse = this.oldWarehouseCode;
        this.oldWarehouse = {
          name: this.curItem.name,
          code: this.curItem.code,
          warehouse: this.oldWarehouseCode,
          commissionRate: (row.commissionRate ?? 0) * 100,
          upperLimit: this.curItem.upperLimit
        }
        this.showEditDialog = true
      })
    },
    viewItem(row) {
      this.$http.post(this.baseApiUrl + "/GetAreaWarehouseList", {areaCode: row.code}).then((res) => {
        this.viewTD.DataList = res.data.rows
        this.showViewDialog = true
      })
    },
    deleteItem(row) {
      if (row.status == 1) {
        return this.showTipMessage("启用状态不允许删除！")
      }
      this.showConfirm(`确认删除该记录吗？`, () => {
        this.$http.post(this.baseApiUrl + "/AreaDelete", {code: row.code}).then(() => {
          this.showTipMessage("删除成功！", "success")
          this.getListData()
        })
      })
    },
    setState(row) {
      this.$http.post(this.baseApiUrl + "/UpdateAreaStatus", {code: row.code}).then(() => {
        this.showTipMessage("更新成功！", 'success')
        this.getListData()
      })
    }
  }
}
</script>

<template>
  <div>
  <h3>商品信息：</h3>
  <el-descriptions size="small" :border="true"  :column="3" class="uniform-description">
    <el-descriptions-item label="运营商名称" label-class-name="labelName">
      <el-tag size="small">{{order.operatorName}}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="类型">{{order.typeName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="是否有票"><span :class="order.hasInvoice?'textsuccess':'textdanger'">{{order.hasInvoice?'是':'否'}}</span></el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="商品名称" :span="3">{{order.productName}}&nbsp;
      <el-button type="warning" link size="small" v-if="order.isNewPro"><el-icon><InfoFilled /></el-icon>新建</el-button>
    </el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="品牌">{{order.brandName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="条码">{{order.barcode}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="产地">{{order.origin}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="规格">{{order.spec}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="保质期（天）">{{order.shelfLife}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="计价单位">{{order.unit}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="包装单位">{{order.packUnit}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="存储库型">{{order.storageType}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="使用辅助单位">{{order.useAssistantUnit?'是':'否'}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="客户渠道" :span="3" v-if="!isMarket">
       <el-tag size="small" type="info"  v-for="item in order.clientChannel" style="margin-right:10px;">{{item}}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="销售区域" :span="3"  v-if="!isMarket">
      <el-tag size="small" type="info" v-for="item in order.coverageArea" style="margin-right:10px;">{{item}}</el-tag>
    </el-descriptions-item>
  </el-descriptions>
  <h3>相关附件：</h3>
  <mainTable :mainOptions="mainData" ref="mainTable"></mainTable>
  <div style="text-align:center;padding:30px;" v-if="order.operatorName">
    <el-button type="primary" @click="NextItem" :disabled="canEdit">下一个</el-button>
    <el-button type="success" @click="isMarket?saveItem(true):agreeDialog=true;form={}" :disabled="canEdit">同意</el-button>
    <el-button type="danger"  @click="disagreeDialog=true;form={}" :disabled="canEdit">拒绝</el-button>
  </div>
  </div>
  <el-dialog v-model="agreeDialog" title="运营商信息" width="740px">
    <el-form ref="editForm" :model="form" size="small" label-width="120px" :rules="rules" style="margin:auto;">
      <el-form-item label="运费单位" prop="freightUnit"  v-if="agreeDialog">
        <el-select v-model="form.freightUnit"  placeholder="运费单位" :clearable="true" style="width: 100%;">
          <el-option key="件" label="件" value="件" />
          <el-option key="公斤" label="公斤" value="公斤" />
          <el-option key="立方米" label="立方米" value="立方米" />
        </el-select>
      </el-form-item>
      <el-form-item label="运费单价" prop="freightPrice" v-if="agreeDialog">
        <el-input v-model="form.freightPrice" placeholder="请输入运费单价" type="number"/>
      </el-form-item>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" v-on:click="submitForm(true)" :disabled="canEdit">提交</el-button>
            <el-button v-on:click="agreeDialog = false">关闭</el-button>
          </span>
    </template>
  </el-dialog>
  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo" :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></rejectPopup>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    }
  },
  data() {
    return {
      baseApiUrl: '/product',
      operatorCode:'',
      code:'',
      id:'',
      previd:'',
      isMarket:'false',
      form: {freightUnit:'件'},
      disagreeDialog: false,
      agreeDialog:false,
      canEdit: false,
      rejectInfo:{title:'未通过的原因',value:'remark'},
      rules: {
        freightUnit: [{ required: true, message: "运费单位不能为空", trigger: "blur" }],
        freightPrice: [{ required: true, message: "请输入运费单价", trigger: "blur" }]
      },
      order: {},
      amendColObj:{},
      mainData: {
        DataList:[],
        tableHeight:'150',
        Total:0,
        labelList: [
          { label: "文件", width: 70,callBack:(item)=>{return item.url}, isHtml:true},
          { label: "文件名", width: 190, prop: "imgName" },
          { label: "大小(kb)", minWidth: 150,callBack:(item)=>{return (item.size/1024).toFixed(2)}},
        ]
      }
    }
  },
  created(){
    this.operatorCode=this.params.operatorCode;
    this.code=this.params.code;
    this.id=this.params.id;
    this.isMarket=this.params.type=='market';
    this.loadAmendDetail();
    this.loadOrder();
  },
  watch:{
    params(v){
      let isChange = this.id != v.id||this.previd;
      if (isChange){
        this.id=this.params.id;
        this.operatorCode=this.params.operatorCode;
        this.code=this.params.code;
        this.isMarket=this.params.type=='market';
        setTimeout(() => { this.loadAmendDetail();this.loadOrder();}, 300);
      }
    }
  },
  methods: {
    NextItem(){
      this.loadOrder('NextItem')
    },
    loadOrder(whoCall) {
      let postData=whoCall == 'NextItem'?this.isMarket?{id: this.previd,type:this.params.type}:{previd: this.previd}: {id: this.id,productCode:this.code};
      let url;
      if(this.isMarket){
        url=whoCall == 'NextItem'? '/GetNextProductView': '/GetProductView';
      }else{
        url=whoCall == 'NextItem'? '/GetProductViewNext': '/GetProductView';
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,postData).then((res)=>{
        this.canEdit=false;
        if(!res.data){
          this.showTipMessage('暂无记录','warning');
          return;
        }
        if(whoCall == 'NextItem') {
          this.previd=this.isMarket?res.data.nextId:res.data.productView.id;
        }else if(!this.isMarket){
          this.previd=this.id;
        }
        this.afterOrderLoaded(res.data.productView,res.data.extendData||this.params.extendData);
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    loadAmendDetail(){
      this.amendColObj={};
      let postData={operatorCode:this.operatorCode,code:this.code,type:this.params.type}
      this.$http.post('/info/AmendDetail' ,postData).then((res)=>{
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].isAmendCol == 1) {
            this.amendColObj = res.data[i];
          }
        }
        if(this.isMarket)  this.previd=this.amendColObj.id;

      });
    },
    afterOrderLoaded(d,ext){
      this.order = d||{};
      if(!this.isMarket){
        var arr=ext.split(';')
        this.order.clientChannel=arr[1].split(',');
        this.order.coverageArea =arr[0].split(',')
      }
      this.mainData.DataList=d.attachment?JSON.parse(d.attachment):[];
    },
    submitForm(type) {
      if(type!=true){this.saveItem(false,type);return;}
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.saveItem(type);
        } else {
          return false
        }
      });
    },
    saveItem(isAgree,value) {
      let postData;
      if (isAgree) {
        postData = {
          logs: JSON.stringify([{...this.amendColObj, pass: 1}]),
          type: this.params.type
        };
        if(!this.isMarket){
          postData.freightPrice=this.form.freightPrice;
          postData.freightUnit=this.form.freightUnit;
        }
      } else {
        postData = {
          logs: JSON.stringify([{...this.amendColObj, ...value, pass: 0}]),
          type: this.params.type
        }
      }
      this.canEdit=true;
      this.$http.post('/Info/AuditAmendLog' ,postData).then(()=>{
        this.showTipMessage('操作成功!','success');
        this.disagreeDialog=this.agreeDialog=false;
        this.canEdit=false;
        this.$emit('closeDialog');
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

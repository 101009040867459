<template>
  <el-descriptions size="small" :border="true"  :column="3" class="uniform-description">
    <el-descriptions-item label="运营商" label-class-name="labelName">
      <el-tag size="small"> {{order.operatorName}}</el-tag>
    </el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="促销活动编码">{{order.taskCode}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="促销活动名称">{{order.taskName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="渠道">{{order.clientChannelName}}&nbsp;</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="区域" :span="2">{{order.allowCoverageAreaName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="仓库" :span="3">{{order.warehouseName}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="开始时间">{{order.startTime}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="结束时间">{{order.endTime}}</el-descriptions-item>
    <el-descriptions-item label-class-name="labelName" label="状态">
      <span :class="['textsuccess','textsuccess','textwarning','textdanger'][[3,2,1,4].indexOf(order.groupState)]">
        {{order.groupStateName||'已确认'}}
      </span>
    </el-descriptions-item>
  </el-descriptions>
  <br/>
  <h4 class="labelTitle" v-if="['MULTI_ITEM_GIFT','ONE_ITEM_GIFT'].indexOf(order.promotionType)>-1">搭赠列表</h4>
  <mainTable :mainOptions="giftData" v-if="['MULTI_ITEM_GIFT','ONE_ITEM_GIFT'].indexOf(order.promotionType)>-1"></mainTable>
  <h3 v-if="order.promotionType=='BUNDLE_DEAL'">
    组合总价：<span class="textdanger">{{TotalPrice?TotalPrice.toFixed(2)+'/套':'未设置'}}</span>，
    <span>{{bundleLimity==-1?'无限购上限':`限购&nbsp;${bundleLimity}&nbsp;套`}}&nbsp;&nbsp;&nbsp;</span>
  </h3>
  <h4 class="labelTitle">商品列表</h4>
  <mainTable :mainOptions="mainData" ref="mainTable">
    <template v-slot:operation="actionBtn">
      <el-radio-group v-model="actionBtn.data.isSelect" v-if="order.groupState==1">
        <el-radio :label="1" size="small">通过</el-radio>
        <el-radio :label="2" size="small">拒绝</el-radio>
      </el-radio-group>
    </template>
  </mainTable>
  <div style="text-align:center;padding:30px;" v-if="order.operatorName">
    <el-button type="primary" @click="NextItem" :disabled="canEdit" style="margin-right:40px;">下一个</el-button>
    <el-button type="success" @click="submitForm('agree')" :disabled="canEdit" v-if="order.groupState==1">全部同意</el-button>
    <el-button type="danger"  @click="submitForm('disagree')" :disabled="canEdit" v-if="order.groupState==1">全部拒绝</el-button>
    <el-button type="primary" v-on:click="submitForm('submit')" :disabled="canEdit" v-if="order.groupState==1">提交</el-button>
  </div>
  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo" :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="saveItem"></rejectPopup>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    }
  },
  data() {
    return {
      baseApiUrl: '/promotionTask',
      groupId:'',
      previd:'',
      isMarket:'false',
      form: {freightUnit:'件'},
      disagreeDialog: false,
      agreeDialog:false,
      canEdit: false,
      rejectInfo:{title:'未通过的原因',value:'remark',label:'理由'},
      rules: {
        freightUnit: [{ required: true, message: "运费单位不能为空", trigger: "blur" }],
        freightPrice: [{ required: true, message: "请输入运费单价", trigger: "blur" }]
      },
      order: {},
      TotalPrice:0,
      bundleLimity:-1,
      modeType:'',
      amendColObj:{},
      giftData: {
        DataList:[],
        tableHeight:150,
        labelList: [
          {label: "搭赠品", prop: "name", minWidth:"220",},
          {label: "单位",  prop: "saleUnit",width:"60"},
          {label: "规则描述", prop: "ruleDesc", width:"340"},
        ],
      },
      mainData: {
        DataList:[],
        tableHeight:'250',
        Total:0,
        labelList: [
          { label: "商品名称", width: 260, prop: "itemSerialName" },
          { label: "状态", width: 80, callBack:(item)=>{ return item.detailStateName},getColor:(item)=>{return item.detailState==1?'textsuccess':'textdanger'}},
          { label: "单价（元）", Width: 100,prop: "unitPrice" },
          {label: "限购数量", prop: "limitQty", width:"100",isShow:false},
          {label: "组合比例", prop: "qty", width:"100",isShow:false},
          { label: "运费（元）", width: 100, prop: "freightPrice" },
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',minWidth:"180"},
        ]
      }
    }
  },
  created(){
    this.groupId=this.params.id;
    this.order=this.params;
    this.loadOrder();
  },
  watch:{
    params(v){
      let isChange = this.order.id != v.id;
      if (isChange)  this.id=this.params.id; this.order=this.params; setTimeout(() => { this.loadOrder(); }, 300);
    }
  },
  methods: {
    NextItem(){
      this.loadOrder('NextItem')
    },
    loadOrder(whoCall) {
      let postData=whoCall == 'NextItem'?{previd:this.groupId,channelType:1}:{taskCode:this.params.taskCode,channelType:1};
      let url=whoCall == 'NextItem'? '/GetNextPromotionTaskProducts': '/GetPromotionTaskProducts';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,postData).then((res) => {
        this.canEdit=false;
        if(!res.data||(res.data&&res.data.length==0)){
          if(whoCall == 'NextItem'){
            this.showTipMessage('暂无记录','warning');
          }else{
            this.mainData.DataList = [];
          }
          return;
        }
        this.TotalPrice=0;
        if(whoCall == 'NextItem'){
          this.order= res.ext.item;
          this.groupId=res.ext.item.id;
        }
        let index=  this.mainData.labelList.findIndex(item=>item.prop=='limitQty');
        let qtyIndex=  this.mainData.labelList.findIndex(item=>item.prop=='qty');
        this.mainData.labelList[index].isShow=['SPECIAL_DISCOUNT','SEC_KILL'].indexOf(this.order.promotionType)>-1?true:false;
        this.mainData.labelList[qtyIndex].isShow=['BUNDLE_DEAL'].indexOf(this.order.promotionType)>-1?true:false;
        if(['SPECIAL_DISCOUNT','SEC_KILL','BUNDLE_DEAL'].indexOf(this.order.promotionType)>-1){
          console.log("进入", res.data)
          res.data.map((item)=>{
            item.limitQty=item.rule.limitQty;
            item.qty=item.rule.qty;
            this.TotalPrice+=item.rule.qty*item.clientPrice;
            console.log("进入",item.rule.qty,item.clientPrice)
            this.bundleLimity=item.rule.limitQty||-1;
            return item;
          })
        }
        this.mainData.DataList = res.data;
        let data=[],list=res.data&&res.data[0].rule;
        if(list){
          data= Object.keys(list).map((itemSerialCode) => {
            return {
              itemSerialCode,
              ...list[itemSerialCode]
            };
          });
        }
        this.giftData.DataList = data;
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    },
    submitForm(type) {
      this.modeType=type;
      switch (type) {
        case 'agree':
          this.showConfirm('是否全部同意？',()=>{
            this.saveItem(type)
          })
          break;
        case 'disagree':
          this.disagreeDialog=true;
          this.rejectInfo.title="请填写全部拒绝备注"
          break;
        case 'submit':
          let data=this.mainData.DataList.findIndex((item)=>{return !item.isSelect});
          let disAgreeData=this.mainData.DataList.findIndex((item)=>{return item.isSelect==2});
          if (data!=-1) {
            this.showTipMessage('有未审核的记录！','warning');
            return;
          } else if(disAgreeData!=-1){
            this.disagreeDialog=true;
            this.rejectInfo.title="请填写审核的原因"
          }else{
            this.saveItem();
          }

      }
    },
    saveItem(value) {
      let postData;
      if (this.modeType=='agree') {
        postData = {taskCode: this.order.taskCode, groupState: 3, product: '',auditeRemark:''};
      }else if (this.modeType=='disagree'){
        postData = {taskCode: this.order.taskCode, groupState:4, product:'',auditeRemark:value.remark};
      }else{
        var product={};
        this.mainData.DataList.forEach(item => {
          product[item.id]=item.isSelect;
        })
        postData = {taskCode: this.order.taskCode, groupState:2, product:JSON.stringify(product),auditeRemark:(value?.remark||'')};
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl+"/UpdateStatePromotionTask" ,postData).then((res) => {
        this.showTipMessage('提交成功!','success');
        this.disagreeDialog=this.agreeDialog=false;
        this.canEdit=false;
        this.$emit('closeDialog');
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

<template>
 <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="showDetail(actionBtn.data)">推荐设置</el-button>
          <el-button type="danger" plain size="small"  @click="showDetail(actionBtn.data,'upload')">上传logo图</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" :title="type=='upload'?'上传品牌Logo':'确认品牌推荐'" width="680px"  @close="form={}">
    <el-form ref="editForm" :model="form" size="small" label-width="80px" :rules="rules" style="margin:auto;">
      <el-form-item label="品牌logo" v-if="type=='upload'">
        <uploader :url="uploadOpt.url" :key="uploadOpt.fileList" :isImg="uploadOpt.isImg" :fileList="uploadOpt.fileList" :postData="uploadOpt.data" :accept="uploadOpt.accept" @complete="uploadFinish" @imgchange="imgchange" @resetfun="fileList=[],imageUrl='',form.imgUrl=''"></uploader>
      </el-form-item>
      <el-form-item label="类型" prop="marketAdsense"  v-if="type!='upload'">
        <el-select v-model="form.marketAdsense" style="width:100%">
          <el-option v-for='(item,index) in ["常规","推荐"]' :key="index" :label="item" :value="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="排序" prop="sortNum"  v-if="type!='upload'">
        <el-input v-model="form.sortNum"  type="Number" />
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="saveItem()" :disabled="canEdit">确定</el-button>
          <el-button v-on:click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import {getMappingName} from '@/utils/common'
export default {
  data() {
    const ARR_sortTypeData = [
      { Id: "asc", Name: "升序排序" },
      { Id: "desc", Name: "降序排序" }
    ];
    const ARR_typeCodesData = [
      { Id: "0", Name: "常规" },
      { Id: "1", Name: "推荐" },
    ];

    return {
      baseApiUrl: '/mall',
      searchItemList:[
        {label: "类型", type: "select", value: "typeCodes", selectOptions:ARR_typeCodesData, multiple:true,width:160},
        {label: "排序", type: "select", value: "sortType", selectOptions:ARR_sortTypeData, defaultValue:'asc', multiple:false,width:100},
        {type: "input", value: "kw",placeholder:'查询品牌编号/名称'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"190", fixed:'left'},
          { label: "品牌编号", width: 90, prop: "code" },
          { label: "品牌名称", width: 160, prop: "name" },
          { label: "品牌logo", width: 70,callBack:(item)=>{return item.imgUrl}, isHtml:true},
          { label: "商城广告位", width: 90,callBack:(item)=>{return getMappingName(item.marketAdsense,ARR_typeCodesData)}},
          { label: "排序", minWidth: 60, prop: "sortNum" },
        ]
      },
      showDetailPage:false,
      fileList:[],
      uploadOpt:{
        isImg:true,
        fileList:[],
        domainUrl:'',
        url: '',
        accept:'image/*',
        data: {}
      },
      type:'',//Recommend设置推荐，upload上传logo
      imageUrl:'',
      form:{sortNum:0,marketAdsense:0},
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{}, // 当前选中行数据
    }
  },
  inject: ['$hasPermission'],
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ImgProof).then((d) => {
        this.uploadOpt.data.policy = d.policy;
        this.uploadOpt.data.authorization = d.auth;
        this.uploadOpt.url = d.host;
        this.uploadOpt.domainUrl = d.domain;
      });
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/BrandRecommendList', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    uploadFinish(obj){
      if(obj.succ==true){
        this.imageUrl =this.uploadOpt.domainUrl+ obj.resp.url;
        this.form.imgUrl=this.imageUrl;
      }else{
       this.showTipMessage("网络问题，提交失败，请重新上传")
      }
    },
    imgchange(val){
      this.fileList=JSON.parse(val);
    },
    showDetail(d,type){
      if (d.imgUrl == ""&&!type) {
       this.showTipMessage('请先上传品牌LOGO！','error');
        return;
      }
      this.type=type?type:'Recommend';
      this.curItem=d;
      this.form=type?{brandCode:d.code,imgUrl:d.imgUrl}:{id:d.id,key: 'Brand',sortNum:d.sortNum,marketAdsense:d.marketAdsense};
      this.fileList=[];
      if(this.type=='upload'&&d.imgUrl) {
        this.uploadOpt.fileList = [{name: 'logo.jpg', url: d.imgUrl,status:'success'}];
      }else{
        this.uploadOpt.fileList=[];
      }
      this.fileList=JSON.parse(JSON.stringify(this.uploadOpt.fileList));
      this.showDetailPage=true;
    },
    // 设置推荐&上传logo
    saveItem() {
      let url=this.type=='Recommend'?'/SetBrandRecommend':'/SetBrandLogo';
      if(this.type=='Recommend'&&this.curItem.sortNum==this.form.sortNum&&this.curItem.marketAdsense==this.form.marketAdsense){
        this.showTipMessage('无修改项!','warning');
        return;
      }else if(this.type=='upload'){
        if(this.fileList.length==0){
          this.showTipMessage('请先【选择文件】并点击【提交上传】上传门头照','warning');
          return;
        }
        let flag = false;
        this.fileList.map((item) => {
          if (item.status!= 'success') {flag = true;}
        })
        if (flag) {
          this.showTipMessage("存在未上传的图片，请点击【提交上传】",'warning');
          return;
        }
        if(this.curItem.imgUrl==this.form.imgUrl){
          this.showTipMessage('无修改项!','warning');
          return;
        }
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + url ,this.form).then(() => {
        this.showTipMessage(this.type=='Recommend'?'设置成功!':'上传成功','success');
        this.canEdit=false;
        this.showDetailPage = false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

<template>
 <div class="content_box">
      <div class="search_bar">
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable"></mainTable>
      </div>
  </div>
</template>
<script>
    export default {
        data() {

            return {
                baseApiUrl: '/finance',
                searchData: {},
                searchItemList: [
                    { type: "input", value: "kw", placeholder: '查询对方账号' }
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { label: "对方账号", width: 180, prop: "oppAccNo" },
                        { label: "对方账户名称", width: 200, prop: "oppAccName" },
                        { label: "对方开户行名称", width: 180, prop: "oppBranchName" },
                        { label: "柜员机交易号", width: 120, prop: "hostFlw" },
                        { label: "交易序号", width: 80, prop: "hostSeq" },
                        { label: "交易日期", width: 80, prop: "tranDate" },
                        { label: "交易时间", width: 80, prop: "tranTime" },
                        { label: "交易金额", width: 80, prop: "tranAmt" },
                        { label: "对方支付联行号", width: 120, prop: "oppBankNo" },
                        { label: "附言", minWidth: 160, prop: "resume" },
                        { label: "调帐单", width: 180, prop: "adjustingOrderNo" },
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },
            }
        },
        methods: {
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.$http.post(this.baseApiUrl + '/PendingAdjustingList', { ...this.searchData, ...this.pageinfo }).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
        }
    }
</script>

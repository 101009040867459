<template>
  <div class="content_box">
        <div class="search_bar">
            <el-button type="warning" @click="exportData" size="small">导出</el-button>
            <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
        </div>
        <div class="main-box">
            <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
        </div>
    </div>
 </template>
 <script>

export default {
    data() {
        return {
            baseApiUrl: '/promotion',
            searchData: {},
            selectOptions:[],
            orderTypeList:[
              { Code: "20", Name: "城批销售出库单" },
              { Code: "21", Name: "城批退货入库单" },
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { prop: "orderNo", label: '业务单号', width: 210 },
                    { prop: "orderTypeName", label: '订单类型', width: 150 },
                    { prop: 'auditedTime', label: '入账时间', width: 150, },
                    { prop: "operatorName", label: '运营商名称', width: 200 },
                    { prop: 'warehouseName', label: '仓库', width: 100 },
                    { prop: "productCode", label: '产品编码', width: 100 },
                    { prop: "productName", label: '产品名称', width: 150 },
                    { prop: "traderName", label: '客户名称', width: 200 },
                    { prop: "salerName", label: '业务员', width: 120 },
                    { prop: "unitQty", label: '数量', width: 70 },
                    { prop: 'amount', label: '金额', width: 80 },
                    { prop: "unitPrice", label: '产品单价', width: 70 },
                    { prop: 'shipPrice', label: '运费', width:80 },
                    { prop: 'orderStateName', label: '订单状态', minWidth: 70 },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "入账日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                { label: "单据类型:", type: "select", value: "orderType",clearable:true,filterable:false,selectOptions: this.orderTypeList, multiple: true, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询单号/商品编码/商品名称' }
            ]
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/PlatfromWarehouseOrderDetail', { ...this.searchData, ...this.pageinfo,isExcel: false}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData,isExcel: true}, this.baseApiUrl+'/PlatfromWarehouseOrderDetail');
        },
    }
}
</script>

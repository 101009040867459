<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button :type="actionBtn.data.state!=0?'info':'warning'" :disabled="actionBtn.data.state!=0?true:false" plain size="small" @click="submitItem(actionBtn.data)" >处理</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="订单明细" width="70%">
     <mainTable :mainOptions="detailData" ref="detailTable"></mainTable>
     <el-form ref="editForm" :model="form" size="small" label-width="140px" :rules="rules" style="margin:30px auto;">
       <el-form-item label="方案说明">
         <el-popover placement="right" :width="400" trigger="click">
           <template #reference>
             <el-button style="margin-right: 16px" type="success">查看方案说明</el-button>
           </template>
           <div >
             <p>方案1：不做退款，但由货主开销售单补齐差异。</p>
             <p>方案2：按saas端的差异计算退款。</p>
             <p>方案3：按wms端的差异计算退款。</p>
           </div>
         </el-popover>
       </el-form-item>
       <el-form-item label="方案选择">
         <el-radio-group v-model="form.settledVia">
           <el-radio label="1">方案1</el-radio>
           <el-radio label="2">方案2</el-radio>
           <el-radio label="3">方案3</el-radio>
         </el-radio-group>
       </el-form-item>
       <el-form-item label="预计此次退款金额(元)" prop="refundAmount">
         <el-input v-model="form.refundAmount"  :disabled="true"/>
       </el-form-item>
       <el-form-item label="备注" :prop="form.settledVia==1?'remark':''">
         <el-input v-model="form.remark"  type="textarea" placeholder="方案1必填备注" />
       </el-form-item>
     </el-form>
     <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="submitForm(true)" :disabled="canEdit">确定</el-button>
          <el-button v-on:click="showDetailPage=false" :disabled="canEdit">关闭</el-button>
        </span>
     </template>
  </el-dialog>
</template>
<script>
export default {
  data() {
    const ARR_RefundStatus = [
      { Id: "0", Name: "未处理" },
      { Id: "1", Name: "全部收" },
      { Id: "2", Name: "全部退" },
    ];
    return {
      baseApiUrl: '/promotion',
      searchData: {},
      showDetailPage:false,
      searchItemList:[
        {label: "状态", type: "select", value: "state", selectOptions:ARR_RefundStatus, multiple:true},
        {type: "input", value: "kw",placeholder:'查询单号'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "单号", width: 180, prop: "orderNo" },
          { label: "客户", width: 150, prop: "traderName" },
          { label: "业务员", width: 120, prop: "salerName" },
          { label: "联系电话", width: 140, prop: "phone" },
          { label: "状态", width: 120,callBack:(item)=>{ return item.stateName},getColor:(item)=>{return ['textdanger','textwarning','textprimary'][item.state]}},
          { label: "处理方案", width: 120, prop: "settledViaName" },
          { label: "处理人", width: 100, prop: "auditedBy" },
          { label: "处理时间", minWidth: 150, prop: "auditedTime" },
        ]
      },
      detailData: {
        DataList:[],
        tableHeight:'200',
        Total:0,
        labelList: [
          { label: "商品编码", width: 100, prop: "productCode" },
          { label: "商品名称", minWidth: 130, prop: "productName" },
          { label: "商品条目", width: 100, prop: "itemSerialCode" },
          { label: "运营商名称", width: 180, prop: "operatorName" },
          { label: "与SAAS差异", width: 100, prop: "saasDifferUnitQty" },
          { label: "与WMS差异", width: 100, prop: "wmsDifferUnitQty" },
        ]
      },
      curItem:{},
      form:{settledVia:'1',refundAmount:0},
      rules: {
        remark: [{ required: true, message: "方案1的备注是必填项", trigger: "blur" }]
      },
      canEdit:false,
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  watch: {
    'form.settledVia'(val) {
      var refundAmount = 0;
      if(val!=1){
        var qty=0;
        this.detailData.DataList.forEach(item => {
          qty = val==2? item.saasDifferUnitQty: item.wmsDifferUnitQty;
          refundAmount += item.refundUnitPrice * qty;
        });
      }
      this.form.refundAmount=Math.abs(Math.round(refundAmount*100)/100)
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/PendingDealSortingReturnList', {...this.searchData,...this.pageinfo},).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      if(postData) this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    submitItem(d){
      this.showDetail(d);
    },
    handleClose() {
      this.showDetailPage = false;
      this.search();
    },
    showDetail(d){
      this.curItem=d;
      this.$http.post(this.baseApiUrl+'/PendingDealSortingReturnDetail', {orderNo:d.orderNo}).then((res) => {
        this.detailData.DataList = res.data;
        this.showDetailPage = true;
      });
    },
    submitForm() {
      if(this.form.settledVia!=1){ this.$refs["editForm"].clearValidate();}
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.saveItem();
        } else {
          return false
        }
      });
    },
    saveItem() {
      let postData = {
        orderNo:this.curItem.orderNo,
        settledVia: this.form.settledVia,
        remark:this.form.remark||''
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/PendingDealSortingReturnConfim" ,postData).then(() => {
        this.showTipMessage('提交成功!','success');
        this.canEdit=false;
        this.showDetailPage = false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>
<style>
.refund-summary{
  color: red; padding: 10px 0px;
}
</style>

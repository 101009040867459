<template>
  <div class="content_box">
    <el-tabs v-model="Active" @tab-change="handleClick" >
      <el-tab-pane label="已发公告" :name="0"></el-tab-pane>
      <el-tab-pane label="草稿" :name="1"></el-tab-pane>
    </el-tabs>
    <div class="search_bar" style="margin:0 0!important;">
      <el-button-group>
        <el-button type="success"  size="small" @click="toPage('add')" v-if="Active==1">新建</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="success"  plain size="small"  @click="submitItem(actionBtn.data,'ck')">查看</el-button>
          <el-button type="warning"  plain size="small"  @click="toPage('edit',actionBtn.data)"  v-if="Active==1">修改</el-button>
          <el-button type="danger"  plain size="small" @click="submitItem(actionBtn.data,'del')" :disabled="canEdit" v-if="Active==1">删除</el-button>
          <el-button type="warning"  plain size="small" @click="submitItem(actionBtn.data,'release')" v-if="Active==1">发布</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showDetailPage" :title="curItem.title||'查看公告信息'" width="900" style="padding-bottom:20px;">
      <div v-html="curItem.content" class="announcement_content"></div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      Active:0,
      baseApiUrl: '/Inform',
      params:{type:''},
      searchData: {},
      searchItemList:[
        {type: "input", value: "kw",placeholder:'查询关键字',width:'160'}
      ],
      mainLabelList:[
        [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "标题", minWidth: 200, prop: "title" },
          { label: "发布时间", width: 150, prop: "createdTime" },
          { label: "发布人", width: 150, prop: "createdBy" },
        ],[
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"250", fixed:'left'},
          { label: "标题", minWidth: 200, prop: "title" },
          { label: "创建时间", width: 150, prop: "createdTime" },
          { label: "创建人", width: 80, prop: "createdBy" },
          { label: "更新时间", width: 150, prop: "updatedTime" },
          { label: "更新人", width: 80, prop: "updatedBy" }
        ]
      ],
      showDetailPage: false,
      mainData: {
        DataList: [],
        Total: -1,
        labelList: []
      },
      modeType:'',
      url:'',//批量更换url
      curItem:{},
      canEdit:false,
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  activated() {
    this.getListData();
  },
  created() {
    this.params.type=this.$route.query.type;
  },
  mounted(){
    this.mainData.labelList=this.mainLabelList[this.Active];
  },
  methods: {
    handleClick(){
      this.mainData.labelList=this.mainLabelList[this.Active];
      this.search();
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/InformList', {...this.searchData, ...this.pageinfo,isDraft:this.Active,type:this.params.type}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    // 发布，删除，查看
    submitItem(d,type){
      let url='',postData={},msg='',title='';
      this.modeType=type;
      postData={id:d.id};
      switch (type){
        case 'release':
          url='/PublishDraft';
          msg='发布成功!'
          title='发布成功后将无法撤回，是否确认发布？'
          break;
        case 'del':
          url='/DraftDelete';
          msg='删除成功'
          title=`确认删除【${d.title}】公告吗？`
          break
        case 'ck':
          this.$http.post(this.baseApiUrl +'/GetAnnounceContent',{id:d.id,isDraft:this.Active}).then((res)=>{
            this.showDetailPage=true;
            this.curItem={content:res.data.replace(/\/n/g, '<br>'),title:d.title};
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
          break
      }
      if(type!=='ck'){
        this.showConfirm(title,()=>{
          this.canEdit=true;
          this.$http.post(this.baseApiUrl +url,postData).then(()=>{
            this.search();
            this.canEdit=false;
            this.showTipMessage(msg,'success');
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
        })
      }
    },
    toPage(type,d){
      let title=this.params.type=='market'?'商城':'运营';
      sessionStorage.inform=type=='add'?'{}':JSON.stringify(d);
      this.$store.dispatch('tags/setTagsItem',{name: 'inform-OperationsAnnouncementEdit', title: type=='add'?`新建${title}公告`:`编辑${title}公告`, path: '/inform-OperationsAnnouncementEdit?draftType='+this.params.type,type:'child'});
      this.$router.push({path: '/inform-OperationsAnnouncementEdit', query:{draftType:this.params.type}})
    }
  }
}
</script>
<style>
.announcement_content{width:100%}
.announcement_content img{max-width:100%}
.announcement_content table{border-collapse: collapse;}
.announcement_content table tr{height:30px;line-height:30px}
.announcement_content table,.announcement_content table th,.announcement_content table td,.announcement_content table tr{border:1px solid #ddd;text-align: center}
</style>

<template>
 <div class="content_box">
  <div class="search_bar">
    <el-button type="success" v-on:click="importProduct" size="small">批量更新品牌</el-button>
    <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
  </div>
  <div class="main-box">
    <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
      <template v-slot:operation="actionBtn">
        <el-button type="primary" plain size="small" @click="submitItem(actionBtn.data,'ck')" >查看</el-button>
        <el-button type="warning" plain size="small" @click="submitItem(actionBtn.data,'edit')" >编辑</el-button>
      </template>
    </mainTable>
  </div>
  <el-dialog v-model="showDetailPage" :title="modeType=='edit'?'商品基础资料修改':'商品基础资料查看'" width="700" style="padding-bottom:20px;">
    <el-form ref="editForm" :model="form" size="small" label-width="100px" :rules="rules" style="margin:auto;">
      <el-row>
        <el-col :span="24">
          <el-form-item label="产品名称">
            <el-input v-model="form.productName" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品编号">
            <el-input v-model="form.productCode" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="条码">
            <el-input v-model="form.barcode" :disabled="true" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="产地">
            <el-input v-model="form.origin"  />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计价单位">
            <el-input v-model="form.unit" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="包装单位">
            <el-input v-model="form.packUnit" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="包装数量">
            <el-input v-model="form.packNum" :disabled="true" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="保质期（天）"  prop="shelfLife">
            <el-input v-model="form.shelfLife"  />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="存储库型">
            <el-select  v-model="form.storageType"   placeholder="请选择" style="width:100%" :disabled="true">
              <el-option v-for="(item,i) in storageTypeInfo" :key="i" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="使用辅助单位">
            <el-select  v-model="form.useAssistantUnit"   placeholder="请选择" style="width:100%" :disabled="true" >
              <el-option  label="否" :value="0"></el-option>
              <el-option  label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="品牌">
            <el-select  v-model="form.brandCode"   placeholder="请选择" filterable style="width:100%">
              <el-option v-for="(item,i) in BrandInfo" :key="i" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="类型">
            <el-select  v-model="form.typeCode"   placeholder="请选择" filterable style="width:100%">
              <el-option v-for="(item,i) in ProductTypeInfo" :key="i" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="修改原因" prop="reason"  v-if="modeType=='edit'">
        <el-input v-model="form.reason" placeholder="请输入修改原因" type="textarea"/>
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="submitForm" :disabled="canEdit" v-if="modeType=='edit'">确定</el-button>
          <el-button v-on:click="showDetailPage=false" v-if="modeType=='edit'">关闭</el-button>
        </span>
    </template>
  </el-dialog>
  <el-dialog v-model="importDialog" title="批量导入商品品牌" width="600px">
    <uploader :url="uploadOpt.url"
              filename="import_file"
              :templateUrl="uploadOpt.templateUrl"
              :postData="uploadOpt.data"
              :tips="uploadOpt.tips"
              :accept="uploadOpt.accept"
              v-on:complete="uploadFinish"></uploader>
  </el-dialog>
</div>
</template>
<script>
import {cloneObject,compareObjects,doFormValidate} from '@/utils/common'
import { ElNotification } from 'element-plus'
export default {
  data() {
    return {
      baseApiUrl: '/product',
      searchData: {},
      showDetailPage:false,
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"130", fixed:'left'},
          { label: "商品编码", width: 120, prop: "productCode" },
          { label: "商品名称", width: 150, prop: "productName" },
          { label: "条码", width: 120, prop: "barcode" },
          { label: "品牌", width: 100, prop: "brandName" },
          { label: "类型", width: 100, prop: "typeName" },
          { label: "计价单位", width: 80, prop: "unit" },
          { label: "包装单位", width: 80, prop: "packUnit" },
          { label: "规格", width: 80, prop: "spec" },
          { label: "保质期", width: 80, prop: "shelfLife" },
          { label: "产地", width: 100, prop: "origin" },
          { label: "存储库型", width: 100,callBack:(item)=>{  return item.storageType},getColor:(item)=>{return ['textsuccess','textwarning','textdanger','textdanger'][['常温','恒温','冷冻','冷藏'].indexOf(item.storageType)]}},
          { label: "使用辅助单位", minWidth: 100,callBack:(item)=>{  return item.useAssistantUnit == 1 ? "是" : "否";},getColor:(item)=>{return item.useAssistantUnit==1?'textsuccess':'textdanger'}},
        ]
      },
      storageTypeInfo:['常温','恒温','冷藏','冷冻'],
      ProductTypeInfo:[],
      BrandInfo:[],
      oldData:{},
      form:{},
      modeType:'',
      canEdit:false,
      importDialog:false,
      uploadOpt:{
        url: '/product/ImportProductBrand',
        templateUrl:'./templates/product_brand.xlsx',// 模板路径
        tips: '提示：请选择上传表格，仅支持.xlsx后缀的文件',
        accept:'.xls,.xlsx',
        data: {}
      },
      rules: {
        reason: [{ required: true, message: "修改原因必填", trigger: "blur" }],
        shelfLife: [
          { required: true, message: "请输入保质期", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: '保质期是正整数', trigger: 'blur' },
        ]
      },
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "商品类型", type: "select", value: "typeCode", selectOptions: this.ProductTypeInfo, multiple: false, keyValue:['Code','Name'],width:'130'},
        {label: "品牌", type: "select", value: "brandCode",width:230, selectOptions: this.BrandInfo, multiple: true, keyValue:['Code','Name']},
        {type: "input", value: "kw",placeholder:'查询商品名/编码'}
      ];
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ProductTypeInfo).then((res) => {
        this.ProductTypeInfo=res;
      });
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.BrandInfo).then((res) => {
        this.BrandInfo=res;
      });
    },
    getListData(){
      this.$http.post(this.baseApiUrl+'/GetProductBase', {...this.searchData,...this.pageinfo}).then((res)=>{
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    CheckData(rowData) {
       this.oldData= {
          id: rowData.id,
          productCode: rowData.productCode,
          productName: rowData.productName,
          barcode: rowData.barcode,
          origin: rowData.origin,
          brandCode: rowData.brandCode,
          typeCode: rowData.typeCode,
          unit: rowData.unit,
          packUnit: rowData.packUnit,
          packNum: rowData.packNum,
          shelfLife: rowData.shelfLife,
          storageType: rowData.storageType,
          useAssistantUnit: rowData.useAssistantUnit,
        }
      this.form=cloneObject(this.oldData);
    },
    submitItem(d,type){
      this.CheckData(d);
      this.modeType=type;
      this.showDetailPage=true;
    },
    submitForm() {
      doFormValidate(this, "editForm", () => this.saveItem());
    },
    saveItem(){
      let res = compareObjects( this.oldData, this.form);
      if(res.length==1&&res[0]=='reason'){
        this.showTipMessage('无修改内容!','warning');
        return;
      }
      res.splice(res.indexOf('reason'), 1);
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + '/EditProductBase' ,{...this.form,update:JSON.stringify([{"tableName":"ProductBase","cols":res}])}).then(()=>{
        this.showTipMessage('修改成功!','success');
        this.showDetailPage=false;
        this.canEdit=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
    importProduct(){
      this.importDialog=true;
    },
    uploadFinish(obj) {
     if (obj.resp.succ) {
       this.showTipMessage("批量更新成功", 'success');
       this.importDialog=false;
     } else {
       let msg='',str='';
       if(obj.resp.msg.indexOf('\r')!=-1){msg=obj.resp.msg.split("\r");
       }else if(obj.resp.msg.indexOf('\n')!=-1){msg = obj.resp.msg.split("\n");
       }else{msg=[obj.resp.msg]}
       msg.forEach((item)=>{
         str+='<p>'+item+'</p>';
       })
       ElNotification.error({
         message:`<div style="overflow-y: auto;max-height:calc(100vh - 100px);width:240px">${str}</div>`,
         dangerouslyUseHTMLString:true,
         duration: 0,
         type: 'error',
       })
     }
   }
  }
}
</script>

<template>
  <div class="content_box">
    <div class="search_bar" style="margin-bottom: 0;">
      <el-button-group>
        <el-button type="warning" size="small" @click="auditItem" v-if="params.moduleType == 1">审核</el-button>
        <el-button type="danger" size="small" @click="unauditItem" v-if="params.moduleType == 1">取消审核</el-button>
        <el-button type="warning" size="small" @click="payItem" v-if="params.moduleType == 2">结算</el-button>
        <el-button type="primary" @click="exportData" size="small">导出</el-button>
      </el-button-group>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <el-form :inline="true" :model="pageSizeForm" size="small" style="background: rgba(21,162,231,0.2);padding:4px 10px 4px;">
      <el-form-item label="每页行数:" style="margin: 0;">
        <el-select v-model="pageSizeForm.pageSize" style="width: 100px;">
          <el-option v-for="(item,index) in pageSizeList" :key="index"
                     :label="item.Name"
                     :value="item.Code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" style="margin: 0;">
        <span style="font-size: 14px;" class="textdanger textspan">合计金额：{{pageSizeForm.totalAmt}}</span>
      </el-form-item>
    </el-form>
    <div class="main-box">
      <mainTable ref="mainTable" :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" @selectChange="selectChange">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="detailItem(actionBtn.data)" >查看明细</el-button>
        </template>
      </mainTable>
    </div>
  </div>

  <el-dialog v-model="showAuditItemDialog" :title="auditItemForm.title" width="600px" >
    <el-form ref="auditItemForm" :model="auditItemForm" size="small" :rules="auditItemFormRules" label-width="80">
      <el-form-item label="运费金额:">
        <el-input v-model="auditItemForm.ShipPriceTotal" readonly/>
      </el-form-item>
      <el-form-item label="实际运费:" prop="auditedAmount">
        <el-input v-model="auditItemForm.auditedAmount" type="number"/>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input v-model="auditItemForm.remark"/>
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="auditItemSubmit">提交</el-button>
          <el-button @click="showAuditItemDialog = false">关闭</el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>


    export default {
        data() {
            return {
                baseApiUrl: '/finance',
                params:{},
                searchData: {},
                selectOptions:{},
                pageSizeForm:{
                    pageSize:15,
                    totalAmt:0
                },
                pageSizeList:[
                    { Code: 15, Name: 15 },
                    { Code: 50, Name: 50 },
                    { Code: 100, Name: 100 },
                    { Code: 500, Name: 500 },
                    { Code: 1000, Name: 1000 },
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    isMultiple:true,
                    rowClickSelect_kw:'Id',
                    labelList: [
                        { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "100", fixed: 'left' },
                        { label: '预计付款时间', width: 100,  callBack: (item)=>{
                                return this.$formatDate(item.ExpectPaidDate);
                            }
                        },
                        { prop: "Amount", label: '总金额', width: 100 },
                        { prop: "ShipPriceTotal", label: '总运费', width: 80 },
                        { prop: "OrderNo", label: '单号', width: 200 },
                        { prop: "SourceOrderTypeName", label: '源单类型', width: 120 },
                        { prop: "StageName", label: '驿站名称', width: 120 },
                        { prop: "AccountNameFull", label: '收款人', width: 150 },
                        { prop: "CardNumber", label: '收款账号', width: 160 },
                        { prop: "Bank", label: '收款开户银行', width: 130 },
                        { label: '入账日期', width: 100,  callBack:  (item)=>{
                                return this.$formatDate(item.TradedTime);
                            }
                        },
                        { prop: "StageFullName", label: '驿站全称', width: 200 },
                        { prop: "AuditedAmount", label: '实际运费', width: 70 },
                        { label: '状态', width: 70 ,callBack:  (item)=>{
                                return item.AuditStateName;
                            },getColor:(item)=>{
                                return item.AuditStateName == '已审核' ? "textsuccess" : "textdanger";
                            }
                        },
                        { prop: "AuditedBy", label: '审核人', width: 70 },
                        { prop: 'AuditedTime', label: '审核时间', width: 100 ,callBack: (item)=>{
                            return this.$formatDate(item.AuditedTime);
                        }},
                        { prop: "PaidBy", label: '结算人', width: 70 },
                        { prop: 'PaidTime', label: '结算时间', width: 100 ,callBack: (item)=>{
                            return this.$formatDate(item.PaidTime);
                        }},
                        { prop: 'Remark', label: '备注', minWidth: 150 },
                    ]
                },
                pageinfo:{
                    pg_idx: 1,
                    pg_size: 15,
                },
                selectedList:[],

                //auditItemDialog
                showAuditItemDialog:false,
                auditItemForm:{},
                auditItemFormRules:{
                    auditedAmount:[{ required: true, message: "实际运费不能为空", trigger: "blur" }]
                }
            }
        },
        computed:{
            searchItemList(){
                return [
                    { label: "入账日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                    { label: "驿站:", type: "select", value: "warehouseCode", selectOptions: this.selectOptions.warehouseList, multiple: false ,width:130,clearable:true,keyValue:['Code','Name']},
                    { label: "", type: "select", placeholder: '请选择状态', value: "auditedState", selectOptions: this.selectOptions.stateList,defaultValue:this.params.moduleType == 2 ? ['1','2']:['0','1'],multiple: true,width:160, keyValue: ['Code', 'Name'] },
                    { type: "input", value: "kw", placeholder: '查询单号',width:150}
                ]
            },
        },
        watch:{
            selectedList:{
                handler(){
                    this.pageSizeForm.totalAmt = 0
                    this.selectedList.forEach(item => {
                        this.pageSizeForm.totalAmt += item.ShipPriceTotal
                    })
                },
                deep:true
            }
        },
      created() {
        this.params.moduleType=this.$route.query.moduleType;
      },
        mounted() {
            this.getStageInfo()
            this.getStateInfo()
        },
        methods: {
            getStageInfo(){
              this.$store.dispatch(
                  'cache/loadCacheData',
                  {key:this.CacheKeys.StageInfo.key, url:this.CacheKeys.StageInfo.url, query:{...this.CacheKeys.StageInfo.query,state:-1}}).then((res) => {
                this.selectOptions.warehouseList = res;
              });
            },
            getStateInfo(){
                if(this.params.moduleType==2){
                    this.selectOptions.stateList = [{Code:"1",Name:"已审核"},{Code:"2",Name:"已结算"}];
                }else{
                    this.selectOptions.stateList = [{Code:"1",Name:"已审核"},{Code:"0",Name:"未审核"}];
                }
            },
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.pageinfo.pg_size = this.pageSizeForm.pageSize
                this.$http.post(this.baseApiUrl + '/GetStageFreightRevenueList', {...this.pageinfo,...this.searchData}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
            exportData(){
                this.postExportData({...this.searchData}, this.baseApiUrl+'/GetStageFreightRevenueList')
            },
            detailItem(row){
              this.$store.dispatch('tags/setTagsItem',{name: 'son-StageFreightRevenueDetail', title: '驿站运费分账单明细', path: '/son-StageFreightRevenueDetail?OrderNo='+row.OrderNo,type:'child'});
              this.$router.push({path: '/son-StageFreightRevenueDetail', query:{OrderNo:row.OrderNo}});
            },
            selectChange(data){
                this.selectedList = data
            },
            auditItem(){
                if(this.selectedList.length <= 0){
                    return this.showTipMessage("请选择记录！")
                }
                let errItem = this.selectedList.find(v=>v.AuditedState>0);
                if(errItem){
                    return this.showTipMessage(errItem.OrderNo+"-记录已审核")
                }
                if(this.selectedList.length>1){
                    const postData = {ids : this.selectedList.map(p=>p.Id).join(',')}
                    this.showConfirm('您正在进行【批量审核】操作，所选记录的实际金额均将填入运费金额，是否继续执行?',()=>{
                        this.$http.post(this.baseApiUrl + "/AuditStageFreightRevenueBulk", postData).then(()=>{
                            this.showTipMessage("提交成功","success")
                            this.getListData()
                        })
                    })
                    return;
                }

                this.auditItemForm = this.selectedList[0]
                this.auditItemForm.auditedAmount = this.auditItemForm.ShipPriceTotal
                this.auditItemForm.title = "驿站运费分帐单审核-" + this.auditItemForm.OrderNo
                this.showAuditItemDialog = true
            },
            auditItemSubmit(){
                this.$refs["auditItemForm"].validate((valid) => {
                    if (valid) {
                        let data = this.auditItemForm
                        const postData={
                            orderNo:data.OrderNo,
                            auditedAmount : data.auditedAmount,
                            remark:data.remark || ''
                        }
                        if(data.ShipPriceTotal != postData.auditedAmount && !postData.remark){
                            return this.showTipMessage("实际运费与账单不一致，请将原因写入备注")
                        }
                        this.showAuditItemDialog = false
                        this.showConfirm("您所填写的实际运费金额为：" + postData.auditedAmount + ",确认继续提交？",()=>{
                            this.$http.post(this.baseApiUrl + "/AuditStageFreightRevenue", postData).then(()=>{
                                this.showTipMessage("提交成功","success")
                                this.getListData()
                            })
                        })
                    }
                })
            },
            unauditItem(){
                if(this.selectedList.length <= 0){
                    return this.showTipMessage("请选择记录！")
                }
                let errItem = this.selectedList.find(v=>v.AuditedState!=1);
                if(errItem){
                    return this.showTipMessage(errItem.OrderNo+"-记录不可取消审核")
                }
                const postData = {ids : this.selectedList.map(p=>p.Id).join(',')}
                this.showConfirm('您正进行【取消审核】操作，所选记录的实际金额均将恢复为0，是否继续执行？',()=>{
                    this.$http.post(this.baseApiUrl + "/UnauditStageFreightRevenue", postData).then(()=>{
                        this.showTipMessage("提交成功","success")
                        this.getListData()
                    })
                })
            },
            payItem(){
                if(this.selectedList.length <= 0){
                    return this.showTipMessage("请选择记录！")
                }
                let errItem = this.selectedList.find(v=>v.AuditedState!=1);
                if(errItem){
                    return this.showTipMessage(errItem.OrderNo+"-记录不可结算")
                }
                const postData = {ids : this.selectedList.map(p=>p.Id).join(',')};
                this.showConfirm('您正进行【结算】操作，所选记录将变更为【已结算】状态，是否继续执行？',()=>{
                    this.$http.post(this.baseApiUrl + "/PayStageFreightRevenue", postData).then(()=>{
                        this.showTipMessage("提交成功","success")
                        this.getListData()
                    })
                })
            }
        }
    }
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success" @click="add" size="small">添加</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="editItem(actionBtn.data)" >编辑</el-button>
          <el-button :type="actionBtn.data.typeState==1?'warning':'success'"  plain size="small" @click="updateItem(actionBtn.data)" >{{actionBtn.data.typeState==1?'禁用':'启用'}}</el-button>
        </template>
      </mainTable>
    </div>
  </div>

  <el-dialog v-model="showEditDialog" :title="modeType == 'Add' ? '添加类型信息' : '编辑类型信息'" width="700">
    <Vform ref="vform" :formItemList="formItemList" :setFormInfo="{inline:true,labelWidth:'80px',formRules:rules}" @changeform="changeform" >
      <template v-slot:formButton="formBtn">
        <el-form-item style="margin-left: 80px;">
          <el-button type="primary"  @click="onSubmit(formBtn.validateForm)" :disabled="canEdit">确定提交</el-button>
          <el-button @click="showEditDialog=false">重置</el-button>
        </el-form-item>
      </template>
    </Vform>
  </el-dialog>

</template>
<script>
    export default {
        data() {
            const TYPE = [
                {Code:'PAY_OFF_TYPE',Name:'结算方式'},
                {Code:'CLIENT_TYPE',Name:'客户类型'},
                {Code:'SUPPLIER_TYPE',Name:'供应商类型'},
                {Code:'TRANSACTION_TYPE',Name:'业务类型（财务）'},
                {Code:'TAX_RATE_TYPE',Name:'税率类型'},
                {Code:'CLIENT_CHANNEL',Name:'客户渠道'},
                {Code:'COUPON_GROUP_TYPE',Name:'优惠券组类型'},
            ]
            return {
                baseApiUrl: '/system',
                searchData: {},
                searchItemList:[
                    { label: "类型：", type: "select", value: "groupKey", selectOptions: TYPE, defaultValue:'PAY_OFF_TYPE', multiple: false ,keyValue:['Code','Name']},
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "140", fixed: 'left' },
                        { label: "类型编码", width: 150, prop: "code" },
                        { label: "类型名", width: 150, prop: "typeName" },
                        { label: "状态", width: 150, prop: "typeStateName", callBack: (item)=>{
                                return item.typeStateName
                            },getColor:(item)=>{
                                return  item.typeStateName == "启用"? 'textsuccess' : 'textdanger'
                            }
                        },
                        { label: "备注", minWidth: 150, prop: "remark" }
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },

                // showEditDialog
                showEditDialog:false,
                FormItems: [
                    {label:"类型名：",type: "input", value: "typeName", span:1},
                    {label:"备注：",type: "input", value: "remark", span:1},
                    {label:"类型：",type: "select", value: "groupKey", defaultValue:'PAY_OFF_TYPE',selectOptions:TYPE,isDisabled:false, keyValue:['Code','Name'],span:3},
                    {label:"天数：",type: "inputNum", value: "delayDays",visible: true, span:3},
                    {label:"",type: "checkbox",value: "isMonthlyPay",defaultValue:[], selectOptions:[{Id:'on',Name:'是否月结'}],visible: true, span:3},
                    {label:"税率：",type: "inputNum", value: "rateNum",min:0,max:100, visible: false, span:3},
                    {label:"状态：",type: "select", value: "typeState",defaultValue:1,selectOptions:[{Code:1,Name:'启用'},{Code:0,Name:'禁用'}],multiple:false, keyValue:['Code','Name'],span:1},
                ],
                rules: {
                    typeName: [{ required: true, message: "请输入类型名", trigger: "manual"}],
                    delayDays: [{ required: true, message: "请输入天数", trigger: "manual"}],
                    rateNum: [{ required: false, message: "请输入税率", trigger: "manual"}],
                },
                curItem:{},
                oldCurItem:{},
                canEdit:false,
                modeType:'Add',
            }
        },
        computed: {
            formItemList() {
                return this.FormItems.map(item => ({
                    ...item,
                    defaultValue:this.curItem[item.value]??item.defaultValue??'',
                    isDisabled:item.value == 'groupKey' && this.modeType == 'Edit' ? true : item.isDisabled??false,
                }))
            },
        },
        methods: {
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.$http.post(this.baseApiUrl + '/GetTypeData', { ...this.searchData, ...this.pageinfo}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
            changeform(d){
                this.curItem = d
                if(d.groupKey == "PAY_OFF_TYPE"){
                    this.rules.delayDays[0].required = true
                    this.rules.rateNum[0].required = false
                    this.FormItems.forEach(item => {
                        if(item.value == 'delayDays' || item.value == 'isMonthlyPay'){
                            item.visible = true
                        }
                        else if(item.value == 'rateNum'){
                            item.visible = false
                        }
                    })
                }else if(d.groupKey == "TAX_RATE_TYPE"){
                    this.rules.delayDays[0].required = false
                    this.rules.rateNum[0].required = true
                    this.FormItems.forEach(item => {
                        if(item.value == 'rateNum'){
                            item.visible = true
                        }
                        else if(item.value == 'delayDays' || item.value == 'isMonthlyPay'){
                            item.visible = false
                        }
                    })
                }else{
                    this.rules.delayDays[0].required = false
                    this.rules.rateNum[0].required = false
                    this.FormItems.forEach(item => {
                        if(item.value == 'delayDays' || item.value == 'isMonthlyPay' || item.value == 'rateNum'){
                            item.visible = false
                        }
                    })
                }
            },
            add(){
                this.modeType = 'Add'
                this.curItem = {};
                this.oldCurItem={};
                this.FormItems.forEach(item => {
                  if(item.value == 'typeState'){
                    item.visible = true
                  }
                })
                this.changeform({groupKey:this.searchData.groupKey});
                this.showEditDialog = true
            },
            async onSubmit(validate) {
                const formData = await validate()
                let postData = formData
                if(postData.groupKey === "PAY_OFF_TYPE"){
                    postData.flag = postData.delayDays
                    if(postData.isMonthlyPay.length !== 0){
                        postData.flag = 1000 + postData.delayDays
                    }
                }
                if(postData.groupKey === "TAX_RATE_TYPE"){
                    postData.flag = postData.rateNum
                }
                let url = this.modeType=='Edit'?'/TypeInfoEdit':'/TypeInfoAdd'
                this.canEdit = true
                this.$http.post('/System'+ url, {...postData,editable:1,code:this.oldCurItem.code || '',id:this.oldCurItem.id || 0,typeState:this.modeType=='Edit'?this.oldCurItem.typeState:postData.typeState}).then((res) => {
                this.showTipMessage('操作成功!','success')
                    this.canEdit = false
                    this.showEditDialog = false
                    this.getListData()
                },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')})
            },
            editItem(row){
                this.modeType = 'Edit'
                this.oldCurItem = row;
                this.FormItems.forEach(item => {
                  if(item.value == 'typeState'){
                    item.visible = false;
                  }
                })
                this.changeform(row)
                if (row.groupKey === "PAY_OFF_TYPE") {
                    this.curItem.delayDays = parseInt(row.flag)
                    if (parseInt(row.flag) > 1000) {
                        this.curItem.isMonthlyPay = ['on']
                        this.curItem.delayDays = parseInt(row.flag) - 1000
                    }
                }
                if (row.groupKey === "TAX_RATE_TYPE") {
                    this.curItem.rateNum = parseInt(row.flag)
                }
                this.showEditDialog = true
            },
            updateItem(row){
                let typeStateName = row.typeState==1?"禁用":"启用";
                this.showConfirm(`确认${typeStateName}该记录吗？`,()=>{
                    this.$http.post(this.baseApiUrl + "/TypeInfoDelete", { id: row.id }).then(()=>{
                        this.showTipMessage(`${typeStateName}成功！`,"success")
                        this.getListData()
                    })
                })
            },
        }
    }
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="success"  size="small" @click="addItem">新增</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary" plain size="small" @click="editItem(actionBtn.data)">编辑</el-button>
          <el-button :type="actionBtn.data.roleCode=='RE00001'?'info':'danger'" plain size="small" @click="delItem(actionBtn.data)" :disabled="actionBtn.data.roleCode=='RE00001'||canEdit" >删除</el-button>
          <el-button type="warning" plain size="small" @click="openAuth(actionBtn.data)" >权限</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showEdit" :title="title" width="40%" style="min-width:350px">
    <el-form ref="editForm" :model="form" size="small" label-width="80px" :rules="rules">
      <el-form-item label="角色编码" prop="code" v-if="form.id>0">
        <el-input v-model="form.roleCode" :disabled="true" />
      </el-form-item>
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="form.roleName" placeholder="请输入角色名称" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" placeholder="请输入备注信息" />
      </el-form-item>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
            <el-button type="primary" v-on:click="submitForm">提交</el-button>
            <el-button v-on:click="showEdit = false">关闭</el-button>
        </span>
    </template>
  </el-dialog>
</template>
<script>
import {cloneObject} from '@/utils/common'
export default{
    data() {
			return {
				baseApiUrl: '/system',
        mainData: {
          DataList:[],
          Total:-1,
          labelList: [
            {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"200", fixed:'left'},
            { label: "编码", width: 120, prop: "roleCode" },
            { label: "名称", prop: "roleName" },
            { label: "备注", minWidth: 300, prop: "remark" }
          ]
        },
        searchItemList: [{type: "input", value: "kw",placeholder:'查询关键字'}],
				title: "",
				form: {},
				showEdit: false,
        canEdit:false,
        pageinfo:{
          pg_idx: 1,
          pg_size: 15
        },
				rules: {
            roleName: [{ required: true, message: "角色名称不能为空", trigger: "blur" }]
				}
			}
		},
		methods: {
      getListData(){
        this.$http.post(this.baseApiUrl+'/roledata', {...this.searchData,...this.pageinfo}).then((res)=>{
          this.mainData.DataList = res.data.rows;
          this.mainData.Total= res.data.total||-1;
        });
      },
      getPageCurrent(val){
        this.pageinfo.pg_idx = val;
        this.getListData();
      },
      search(postData){
        this.pageinfo.pg_idx = 1;
        this.searchData=postData||this.searchData;
        this.getListData();
      },
      resetForm(){
        this.form = {id: 0};
      },
			addItem(){
        this.resetForm();
        this.title = '添加用户角色';
        this.showEdit = true;
      },
      editItem(d) {
        this.form = cloneObject(d);
        this.title = '修改用户角色';
        this.showEdit = true;
      },
      submitForm() {
          this.$refs["editForm"].validate((valid) => {
              if (valid) {
                  this.saveItem();
              } else {
                  return false
              }
          });
      },
      saveItem() {
          this.$http.post(this.baseApiUrl + '/saverole', this.form).then(()=> {
              this.search();
              this.showEdit = false;
              this.showTipMessage("保存成功.", "success");
          });
      },
      delItem(d){
        this.showConfirm(`确认删除【${d.roleName}】角色吗`,()=>{
          this.canEdit=true;
          this.$http.post(this.baseApiUrl + '/deleterole',{id:d.id}).then(()=>{
            this.showTipMessage('删除成功','success');
            this.canEdit=false;
            this.search();
          },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
        })
      },
      openAuth(d){
        this.$store.dispatch('tags/setTagsItem',{
          name: 'system-AuthManager',
          title: '角色权限设置',
          path: '/system-AuthManager?roleCode='+d.roleCode,type:'child'
        });
        this.$router.push({path: '/system-AuthManager', query:{roleCode:d.roleCode}});
      }
		}
	}
</script>

<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data)" >审核</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" :title="title" width="70%" >
    <storage-OperatorAmendAudit ref="editPage" :params="editParams"  @closeDialog="handleClose" v-if="['operator','stored_compact'].indexOf(this.params.type)>-1"></storage-OperatorAmendAudit>
    <storage-PropuctAudit ref="editPage" :params="editParams"  @closeDialog="handleClose" v-if="['operator','stored_compact'].indexOf(this.params.type)==-1"></storage-PropuctAudit>
  </el-dialog>
</template>
<script>
import storageOperatorAmendAudit from './storage-OperatorAmendAudit.vue'
import storagePropuctAudit from './storage-PropuctAudit'
export default {
  components: {
    storageOperatorAmendAudit,
    storagePropuctAudit
  },
  data() {
    return {
      baseApiUrl:'/info',
      title: "",
      params:{type:''},
      form: {},
      showDetailPage:false,
      searchData: {},
      searchItemList:[
        {type: "input", value: "kw"}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: []
      },
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  computed: {
    labelList() {
      return [
        {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
        {label: "运营商编码",width: 100, prop: "operatorCode",isShow:this.params.type=='operator'},
        {label: "运营商名", 'min-width': 150, prop: "name"},
        {label: "商品编码",width: 120, prop: "code",isShow:this.params.type!='operator'},
        {label: "商品名称",'min-width': 160, prop:"name", isShow:this.params.type!='operator'},
        {label: "状态", width: 80, prop: "amendState" },
        {label: "申请时间", width: 150, prop: "createdTime" },
        {label: '审核人', width: 85, prop: "auditedBy" },
        {label: "审核时间", 'min-width': 150, prop: "auditedTime" },
      ];
    }
  },
  created() {
    this.params.type=this.$route.query.type;
    this.mainData.labelList = this.labelList;
  },
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/SwitchAmendLogList', {...this.searchData,type:this.params.type,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    handleClose() {
      this.showDetailPage = false;
      this.search();
    },
    showDetail(d){
      this.showDetailPage = true;
      let data;
      if(['operator','stored_compact'].indexOf(this.params.type)>-1){
        data={type:this.params.type,operatorCode:d.operatorCode,};
        this.title="运营商准入库审核";
      }else{
        data={type:this.params.type,code:d.code,operatorCode:d.operatorCode,id:d.recordId,extendData:d.extendData};
        this.title="审核商品入驻详情";
      }
      this.editParams = data;
    }
  }
}
</script>
<style>
.refund-summary{
  color: red; padding: 10px 0px;
}
</style>

<template>
 <div class="content_box" style="padding-top: 10px;">
      <el-row>
          <el-col :span="8" style="min-height: 110px;">
              <el-image :src="initParams.transReceipt"
                  :zoom-rate="1.2"
                  :preview-src-list="[initParams.transReceipt]"
              />
          </el-col>
          <el-col :span="16">
              <el-descriptions :column="2" size="small" border>
                  <el-descriptions-item label="单号">{{initParams.applyNo}}</el-descriptions-item>
                  <el-descriptions-item label="运营商名称">{{initParams.operatorName}}</el-descriptions-item>
                  <el-descriptions-item label="充值银行账号">{{initParams.bankNumber}}</el-descriptions-item>
                  <el-descriptions-item label="充值银行">{{initParams.bankName}}</el-descriptions-item>
                  <el-descriptions-item label="充值金额">{{initParams.amount}}</el-descriptions-item>
                  <el-descriptions-item label="充值时间">{{initParams.rechargeTime}}</el-descriptions-item>
              </el-descriptions>
          </el-col>
      </el-row>
      <div><el-button type="danger"  @click="disagree">拒绝</el-button></div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
              <template v-slot:operation="actionBtn">
                  <el-button type="success" plain size="small" @click="agree(actionBtn.data)">选中</el-button>
              </template>
          </mainTable>
      </div>
  </div>

  <el-dialog v-model="showAgree" title="通过调账" width="680">
      <el-descriptions :column="2" size="small" border>
          <el-descriptions-item label="充值银行账号">{{initParams.bankNumber}}</el-descriptions-item>
          <el-descriptions-item label="对方账号">{{agreeRow.oppAccNo}}</el-descriptions-item>
          <el-descriptions-item label="充值银行">{{initParams.bankName}}</el-descriptions-item>
          <el-descriptions-item label="对方开户行名称">{{agreeRow.oppBranchName}}</el-descriptions-item>
          <el-descriptions-item label="充值金额">{{initParams.amount}}</el-descriptions-item>
          <el-descriptions-item label="交易金额">{{agreeRow.tranAmt}}</el-descriptions-item>
          <el-descriptions-item label="充值时间">{{initParams.rechargeTime}}</el-descriptions-item>
          <el-descriptions-item label="交易时间">{{agreeRow.tranDate}}</el-descriptions-item>
      </el-descriptions>
      <div style="text-align: right;padding: 10px 0;">
          <el-button type="primary"  @click="submit">提交</el-button>
      </div>
  </el-dialog>

  <rejectPopup v-if="disagreeDialog" :rejectInfo="rejectInfo" @closedialog="disagreeDialog=false" @submitform="submitForm"></rejectPopup>
</template>
<script>
    export default {
        data() {
            return {
                baseApiUrl: '/finance',
                initParams:{},
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "70", fixed: 'left' },
                        { label: "对方账号", width: 180, prop: "oppAccNo" },
                        { label: "对方账户名称", width: 200, prop: "oppAccName" },
                        { label: "对方开户行名称", width: 180, prop: "oppBranchName" },
                        { label: "柜员机交易号", width: 130, prop: "hostFlw" },
                        { label: "交易序号", width: 80, prop: "hostSeq" },
                        { label: "交易日期", width: 80, prop: "tranDate" },
                        { label: "交易时间", width: 80, prop: "tranTime" },
                        { label: "交易金额", width: 80, prop: "tranAmt" },
                        { label: "对方支付联行号", width: 120, prop: "oppBankNo" },
                        { label: "附言", minWidth: 180, prop: "resume" },
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },
                //showAgree_dialog
                showAgree:false,
                agreeRow:{},
                // disagree_dialog
                disagreeDialog:false,
                rejectInfo:{title:'拒绝调账',label:'备注',value:'remark'},
            }
        },
        mounted() {
          this.$http.post(this.baseApiUrl + '/fundAdjustApply', { kw:this.$route.query.applyNo,pg_idx: 1,pg_size: 15}).then((res)=>{
            this.initParams = res.data.rows[0];
            this.getListData()
          });

        },
        methods: {
            getListData() {
                this.$http.post(this.baseApiUrl + '/PendingAdjustingList', {...this.pageinfo }).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
            disagree(){
                this.disagreeDialog = true
            },
            submitForm(reasonObj){
                let postData={
                    fundId:this.initParams.id,
                    remark:reasonObj.remark,
                }
                this.$http.post(this.baseApiUrl + "/fundAdjustingDisAgree", postData).then(()=>{
                    this.showTipMessage("处理调帐单成功,页面即将关闭", 'success');
                    this.disagreeDialog = false
                    setTimeout( ()=>{
                      this.$store.dispatch('tags/closeCurrentTag',{$router: this.$router, $route: this.$route});
                      this.$router.push({path:'/operate_FundAdjustApply'})
                    }, 2000)
                })
            },
            agree(row){
                this.agreeRow = row
                this.showAgree = true
            },
            submit(){
                let postData={
                    fundId:this.initParams.id,
                    penId:this.agreeRow.id,
                }
                this.$http.post(this.baseApiUrl + "/fundAdjustingAgree", postData).then(()=>{
                    this.showTipMessage("添加调帐单成功,页面即将关闭", 'success');
                    this.showAgree = false
                    setTimeout(()=>{
                        this.$store.dispatch('tags/closeCurrentTag',{$router: this.$router, $route: this.$route});
                        this.$router.push({path:'/operate_FundAdjustApply'})
                    }, 2000)
                },(res) => {
                    this.showTipMessage(res.msg || "未知错误");
                })
            }
        }
    }
</script>

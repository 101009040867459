<template>
  <div>
    <div id="qrcode"></div>
  </div>
</template>

<script>
// 二维码
import QRCode from 'qrcodejs2'
export default {
  props: {
    width: {
      type: Number,
      default () {return 200}
    },
    height: {
      type: Number,
      default () {return 200}
    },
    // 二维码地址
    url: {
      type: String,
      default () {return ''}
    }
  },
  mounted(){
    this.qrcode();
  },
  methods: {
    clearQrcode(){
      document.getElementById('qrcode').innerHTML = ''
    },
    qrcode() {
      this.clearQrcode();
      let qrcode = new QRCode('qrcode', {
        width: this.width,
        height: this.height,
        text: this.url,
        colorDark: "#000",
        colorLight: "#fff",
      })
    },
  }
}
</script>
<style scoped>
#qrcode {
  display: inline-block;
}
#qrcode img {
  width: 132px;
  height: 132px;
  background-color: #fff;
  padding: 6px;
}
</style>

<template>
  <div class="content_box">
      <div class="search_bar">
          <el-button type="warning" @click="exportData" size="small">导出</el-button>
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
      </div>
      <div class="main-box">
          <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
      </div>
  </div>
</template>
<script>

export default {
    data() {
        return {
            baseApiUrl: '/promotion',
            searchData: {},
            selectOptions:[],
            ownerType:[
                { Code: "WH", Name: "驿站主" },
                { Code: "OP", Name: "运营商" }
            ],
            mainData: {
                DataList: [],
                Total: -1,
                labelList: [
                    { prop: "orderNo", label: '单号', width: 200 },
                    { prop: 'createdTime', label: '下单日期', width: 150, },
                    { prop: "traderName", label: '客户', width: 200 },
                    { prop: 'orderAmount', label: '订单金额', width: 80 },
                    { prop: "operatorName", label: '货主', width: 180 },
                    { prop: "productName", label: '商品名称',width:180},
                    { prop: "unitQty", label: '下单数量', width: 70 },
                    { prop: "unit", label: '单位', width: 50 },
                    { prop: "unitPrice", label: '单价', width: 70 },
                    { prop: 'amount', label: '明细金额', width: 80 },
                    { prop: "shipPrice", label: '明细总运费', minWidth: 80 },
                ]
            },
            pageinfo: {
                pg_idx: 1,
                pg_size: 15
            },
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "下单日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                { label: "货主类型:", type: "select", value: "ownerType",clearable:true,selectOptions: this.ownerType,defaultValue:'WH', multiple: false, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询单号' }
            ]
        }
    },
    methods: {
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.$http.post(this.baseApiUrl + '/WholesalePlannedOrderDetail', { ...this.searchData, ...this.pageinfo}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            this.postExportData({...this.searchData}, this.baseApiUrl+'/WholesalePlannedOrderDetail');
        },
    }
}
</script>

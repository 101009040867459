<template>
 <div class="content_box">
    <div class="search_bar">
      <el-button type="primary" v-on:click="exportData" size="small">导出</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="primary"  plain size="small" @click="submitItem(actionBtn.data)" >查看明细</el-button>
        </template>
      </mainTable>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    const ARR_differType = [
      {Id: "0", Name: "下单"},
      {Id: "1", Name: "临时退货"},
      {Id: "2", Name: "分拣"},
    ];
    const ARR_DateType = [
      {Id: "created_time", Name: "制单时间"},
      {Id: "audited_time", Name: "入账时间"},
    ];
    const dateBegin = this.$formatDate(0, -7);
    const dateEnd = this.$formatDate(0);
    return {
      baseApiUrl: '/promotion',
      searchData: {},
      showDetailPage: false,
      searchItemList: [
        {
          type: "select",
          value: "dateType",
          selectOptions: ARR_DateType,
          defaultValue: 'created_time',
          multiple: false,
          width: '100'
        },
        {type: "daterange", value: "createtime", defaultValue: [dateBegin, dateEnd]},
        {label: "差异类型", type: "select", value: "differType", selectOptions: ARR_differType, multiple: true},
        {type: "input", value: "kw", placeholder: '单号'}
      ],
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          // { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"100", fixed:'left'},
          {label: "单号", width: 135, prop: "orderNo"},
          {label: "商品名称", width: 120, prop: "productName"},
          {label: "货主", width: 180, prop: "operatorName"},
          {label: "差异类型", width: 70, prop: "differTypeName"},
          {label: "差异数量", width: 70, prop: "differUnitQty"},
          {label: "差异金额", width: 80, prop: "differAmount"},
          {label: "差异运费", width: 80, prop: "differFreight"},
          {label: "运费单价", width: 70, prop: "freightPerUnit"},
          {label: "差异运费补差", width: 90, prop: "differShipPriceBalance"},
          {label: "备注", width: 150, prop: "remark"},
          {label: "批次", width: 60, prop: "batch"},
          {label: "制单时间", width: 150, prop: "createdTime"},
          {label: "入账时间", minWidth: 150, prop: "auditedTime"},
        ]
      },
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData() {
      this.$http.post(this.baseApiUrl + '/DetailDifferences', {...this.searchData, ...this.pageinfo, isExcel: false}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    exportData(){
      this.postExportData({...this.searchData,...this.pageinfo}, this.baseApiUrl+'/DetailDifferences');
    },
  }
}
</script>

<template>
  <div class="content_box">
    <div style="display: flex;justify-content: space-between;margin:30px 0 10px">
      <el-input v-model="title" placeholder="公告标题" />
      <el-button type="success" style="margin-left: 150px;" @click="submitForm">确定保存</el-button>
    </div>
    <v-ueditor :content="editorContent" :config="editorConfig" ref="VUEditor" @send="getContent" :ImgOption="ImgOption"/>
    <p style="color:#999;text-align:right;font-size:14px">当前输入{{content.length}}字符/最多可输入{{maxLength}}字符</p>
  </div>
</template>
<script>
import VUeditor from '@/components/vUedtior.vue'
export default {
  data() {
    return {
      params:{},
      editorContent:'',
      editorConfig:{},
      maxLength:100000,
      draftType:'',
      title:'',
      contentTxt:'',
      content:'',
      curItem:{},
      ImgOption:{imgData:{}},
      baseApiUrl: '/Inform',
    }
  },
  components: {VUeditor},
  inject: ['$hasPermission'],
  created() {
    this.draftType=this.$route.query.draftType;
  },
  activated() {
    this.params=JSON.parse(sessionStorage.inform);
    this.title=this.params.title;
    this.curItem=this.params;
    this.editorContent=this.params.content||'';
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      // 图片上传凭证
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ImgProof).then((d) => {
        this.ImgOption.imgData.policy = d.policy;
        this.ImgOption.imgData.authorization = d.auth;
        this.ImgOption.imageUpUrl = d.host;
        this.ImgOption.imageUrl = d.domain;
      });
    },
    getContent(val){
     this.content= val.textHtml;
     this.contentTxt= val.textValue;
      if(this.content.length>this.maxLength){
        this.showTipMessage('已超过最大输入字符,请调整公告内容');
      }
    },
    // 保存公告
     submitForm(){
       let postData={};
       if(!this.content){
         this.showTipMessage('请输入公告内容','warning');
         return;
       }
       if(!this.title){
         this.showTipMessage('请输入公告标题','warning');
         return;
       }
       if(this.content.length>this.maxLength){
         this.showTipMessage('已超过最大输入字符,请调整公告内容');
         return;
       }
       if(this.curItem.id){
         postData={subType:0,draftType:this.draftType,title:this.title,content:this.content,id:this.curItem.id};
       }else{
         postData={subType:0,draftType:this.draftType,title:this.title,content:this.content};
       }
      this.$http.post(this.baseApiUrl +'/DraftSave',postData).then(() => {
        this.showTipMessage(this.curItem.id?'修改公告成功':'新建公告成功','success');
        this.canEdit=false;
        setTimeout(()=>{
          this.$store.dispatch('tags/closeCurrentTag',{$router: this.$router, $route: this.$route});
          this.$router.push({path:'/inform-OperationsAnnouncement',query:{type:this.draftType}})
        },3000)

      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

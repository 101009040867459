<template>
  <div class="title" :style="iscollapse?'padding: 0 20px':'padding:0 10px'">
    <span v-if="!iscollapse">云食城平台管理</span>
    <el-icon size="25" @click="changeCollapse" style="cursor: pointer" v-if="!iscollapse" class="pcMenu"><Fold /></el-icon>
    <el-icon size="25" @click="changeCollapse" style="cursor: pointer" v-if="iscollapse" class="pcMenu"><Expand /></el-icon>
  </div>
  <div class="body" :style="iscollapse?'margin-left:64px':'margin-left:230px'">
    <el-dropdown class="nav-drop" v-on:command="handleNavDropMenu">
      <el-badge  style="outline: none">
          <span class="el-dropdown-link" style="padding-right: 8px;display: flex;align-items: center">
           <el-icon size="18"><Avatar/></el-icon> <span v-if="!iscollapse">{{userData.realname}}</span> <el-icon size="12"><ArrowDownBold /></el-icon>
          </span>
      </el-badge>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="info" v-if="!isMobile">账号信息</el-dropdown-item>
          <el-dropdown-item command="change_pwd"  v-if="!isMobile">修改密码</el-dropdown-item>
          <el-dropdown-item divided=divided command="exit">注销</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-menu :default-active="activeIndex+''"
             class="nav-menu"
             mode="horizontal"
             v-on:select="handleMenuSelect">
      <el-menu-item v-for="(item,i) in menus" :key="item" :index="i+''">{{item.title}}</el-menu-item>
    </el-menu>
  </div>
  <!--    修改密码-->
  <el-dialog v-model="showChangePwd" title="修改密码" width="550px">
    <Vform :formItemList="formItemList"  @search="search" ref="vform" :setFormInfo="{inline:false,labelWidth:'130px',formRules:rules}"></Vform>
    <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" v-on:click="submitForm">提交</el-button>
                <el-button v-on:click="showEdit = false">关闭</el-button>
            </span>
    </template>
  </el-dialog>
  <!--账号信息-->
  <el-dialog v-model="showInfo" title="我的账号信息" width="400px" style="padding-bottom:20px;">
    <el-form ref="infoForm" size="small" label-width="100px">
      <el-form-item label="姓名：">
        <span class="textspan">{{userData.realname}}</span>
      </el-form-item>
      <el-form-item label="登录账号：">
        <span class="textspan">{{userData.loginName}}</span>
      </el-form-item>
      <el-form-item label="系统角色：" >
        <span class="textspan">{{roles.join(',')}}</span>
      </el-form-item>
      <el-form-item label="Email：">
        <span class="textspan">{{userData.email}}</span>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent,toRefs,ref,reactive,onMounted,computed} from 'vue'
import { useStore } from 'vuex'
import {showConfirm,showTipMessage} from '@/utils/common'
import $http from '@/utils/http'
import {ElMessageBox} from "element-plus";
export default defineComponent({
  setup() {
    const store = useStore()
    interface VFormInstance {
      form: { [key: string]: any };
      validate: () => void;
      validateForm: Function;
    }
    interface State {
      showInfo: boolean;
      showChangePwd: boolean;
      form: Record<string, unknown>;
      heartbeatTimer:any;
      HREAR_BEAT_TICK_SEC: number;
      HEARTBEAT_MAX_ERROR: number;
      heartbeatErrTimes: number;
      inform: unknown[];
      heardbeatUrl: string;
      rules: Record<string, any>;
    }
    const vform =ref<VFormInstance | null>(null); // 提供具体的类型
    const state:State= reactive({
      showInfo:false,
      showChangePwd: false,
      form: {},
      heartbeatTimer: null,
      HREAR_BEAT_TICK_SEC:60,
      HEARTBEAT_MAX_ERROR:5,
      heartbeatErrTimes: 0,
      inform: [],
      heardbeatUrl:'/user/heartbeat',
      rules: {
        oldPwd: [{ required: true, message: "旧密码不能为空", trigger: "blur" }],
        newPwd: [{ required: true, min: 6, max: 12, message: "请输入长度6-12位的密码", trigger: "blur" }],
        conPwd:[{required: true, message: '请再一次输入密码', trigger: 'blur'}],
      }
    })
    const isMobile =computed(()=> {
      const userAgent = navigator.userAgent;
      return /iPhone|iPod|Android/i.test(userAgent);
    })
    const iscollapse = computed(() => store.state.menu.iscollapse);
    const roles = computed(() => store.state.user.roles);
    const userData:{ [key: string]: any } = computed(() => store.state.user.userInfo)
    const menus:Record<string, any> = computed(() => store.state.menu.topMenu)
    const activeIndex = computed(() =>{
      let index=store.state.menu.topMenu.findIndex((v:any) => v.code ==store.state.menu.topId);
      return index+'';
    })
    const formItemList = computed(() => {
      return [
            {label: "登录账号",type: "input", value: "loginName",defaultValue:userData.value.loginName,isDisabled:true},
            {label: "旧密码",type: "password", value: "oldPwd",defaultValue:'',placeholder:'请输入旧密码'},
            {label: "新密码",type: "password", value: "newPwd",defaultValue:'',placeholder:'新密码'},
            {label: "确认新密码",type: "password", value: "conPwd",defaultValue:'',placeholder:'请再一次输入密码'},
          ]
    })
    onMounted(() => {
      setDefaultValue();
      window.addEventListener('resize', ()=>{
        setDefaultValue();
      });

      if (userData.value) {
        setHeartBeatTimer();
      }
    });
    const setDefaultValue=()=>{
      if(window.innerWidth<1000||isMobile.value){
        store.commit('menu/changeCollapse',true);
      }else{
        store.commit('menu/changeCollapse',false);
      }
    }
    const handleMenuSelect = (i:number) => {
        store.commit('menu/changeTopId',menus.value[i].code);
    }
    const handleNavDropMenu=(cmd:any)=>{
        switch (cmd) {
          case 'info':
            state.showInfo = true;
            break;
          case 'change_pwd':
            changePwd();
            break;
          case 'exit':
            showConfirm('确认退出登录？', () => {
              logout();
            });
            break;
        }
    }
    const setHeartBeatTimer=()=> {
      state.heartbeatTimer = setTimeout(() => {runHeartBeat(); }, state.HREAR_BEAT_TICK_SEC * 1000);
    }
    const runHeartBeat=()=> {
      $http.post(state.heardbeatUrl, "t=" + new Date().getTime()).then((res) => {
            let userId = res.data.loginName;
            if (userId !=userData.value.loginName) {
              exitPage();
            } else {
              state.heartbeatErrTimes = 0;
              setHeartBeatTimer();
              if (res.data.cacheKey) {
                var changedArr = res.data.cacheKey.split(',');
                for (let i = 0; i < changedArr.length; i++) {
                 store.commit("cache/clearCache",changedArr[i]);
                }
              }
            }
          },() => {
           state.heartbeatErrTimes++;
            if (state.heartbeatErrTimes > state.HEARTBEAT_MAX_ERROR) {
              exitPage();
            } else {
             setHeartBeatTimer();
            }
          }
      );
    }
    const clearTimer=()=>{
       if (state.heartbeatTimer) clearTimeout(state.heartbeatTimer);
    }
    const logout=()=>{
      sessionStorage.clear()
      store.commit('user/SET_TOKEN', '')
      store.commit('user/SET_ROLES', [])
      location.reload();
    }
    const exitPage=()=> {
      clearTimer();
      ElMessageBox.alert('登录已超时，请重新登录', '提示信息', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: () => {
          logout();
        },
      });
    }
    const changePwd=()=>{
      state.showChangePwd = true;
      state.form = {
        loginName: userData.loginName
      }
    }
    const submitForm=async() =>{
      if (vform.value) {
        state.rules.conPwd.push({
          validator: (rule:any, value:string|number, callback:Function) => {
            if (value !==vform.value?.form.newPwd) {
              callback(new Error('两次密码输入不一致'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        });
      }
      let formData = await vform.value?.validateForm();
      let postData={oldPwd:formData.oldPwd,newPwd: formData.newPwd,conPwd: formData.conPwd}
           $http.post('/user/changepwd', postData).then(() => {
            state.showChangePwd = false;
             showTipMessage("密码修改成功请记住新密码.", "success");
            setTimeout(()=>{
              logout();
            },3000)
          });
    }
    const changeCollapse=()=>{
      if(isMobile.value||window.innerWidth<1000){
        store.commit('menu/changeCollapse');
      }
    }
    return {
      ...toRefs(state),
      isMobile,
      iscollapse,
      roles,
      userData,
      menus,
      activeIndex,
      formItemList,
      vform,
      setDefaultValue,
      changeCollapse,
      handleMenuSelect,
      handleNavDropMenu,
      setHeartBeatTimer,
      runHeartBeat,
      clearTimer,
      changePwd,
      logout,
      exitPage,
      submitForm,
    }
  }
})
</script>

<template>
  <div class="content_box">
      <div class="search_bar" style="margin-bottom: 0;">
          <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="false"></baseSearch>
      </div>
      <el-form :inline="true" :model="pageSizeForm" size="small" style="background: rgba(21,162,231,0.2);padding:4px 10px 4px;">
          <el-button-group>
              <el-button type="warning" size="small" @click="auditItem" v-if="params.moduleType == 1">财务审核</el-button>
              <el-button type="warning" size="small" @click="payItem" v-if="params.moduleType == 2">结算审核</el-button>
              <el-button type="primary" @click="exportData" size="small">导出</el-button>
          </el-button-group>
          <el-form-item label="每页行数:" style="margin:0 0 0 20px;">
              <el-select v-model="pageSizeForm.pageSize" style="width: 100px;">
                  <el-option v-for="(item,index) in pageSizeList" :key="index"
                              :label="item.Name"
                              :value="item.Code">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="" style="margin: 0;">
              <span style="font-size: 14px;" class="textdanger textspan">合计金额：{{pageSizeForm.totalAmt}}</span>
          </el-form-item>
      </el-form>
      <div class="main-box">
          <mainTable ref="mainTable" :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" @selectChange="selectChange">
              <template v-slot:operation="actionBtn">
                  <el-button type="primary" plain size="small" @click="detailItem(actionBtn.data)" >查看明细</el-button>
              </template>
          </mainTable>
      </div>
  </div>
  <el-dialog v-model="showAuditItemDialog" :title="auditItemForm.title" width="600px" >
      <el-form ref="auditItemForm" :model="auditItemForm" size="small" :rules="auditItemFormRules" label-width="80">
          <el-form-item label="贷款金额:">
            <el-input v-model="auditItemForm.amount" readonly/>
          </el-form-item>
          <el-form-item label="实际贷款:" prop="auditedAmount">
            <el-input v-model="auditItemForm.auditedAmount" type="number"/>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="auditItemForm.remark"/>
          </el-form-item>
        </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="auditItemSubmit">提交</el-button>
            <el-button @click="showAuditItemDialog = false">关闭</el-button>
          </span>
      </template>
    </el-dialog>
</template>
<script>
export default {
    data() {

        return {
            baseApiUrl: '/finance',
            searchData: {},
            params:{},
            sourceOrderTypeList:[
                { Code: "31", Name: "城批销售采销单" },
                { Code: "32", Name: "城批销退采销单" },
            ],
            stateList:[
              [{Code:"1",Name:"已审核"},{Code:"2",Name:"已结算"}],
              [{Code:"0",Name:"未审核"},{Code:"1",Name:"已审核"},{Code:"2",Name:"已结算"}],
            ],
            selectOptions:{},
            pageSizeForm:{
                pageSize:15,
                totalAmt:0
            },
            pageSizeList:[
                { Code: 15, Name: 15 },
                { Code: 50, Name: 50 },
                { Code: 100, Name: 100 },
                { Code: 500, Name: 500 },
                { Code: 1000, Name: 1000 },
            ],
            mainData: {
                DataList: [],
                Total: -1,
                isMultiple:true,
                rowClickSelect_kw:'id',
                labelList: [
                    { label: "操作", prop: "operation", slot_name: 'operation', type: 'slot', width: "100", fixed: 'left' },
                    { prop: "orderNo", label: '分账单号', width: 200 },
                    { prop: "sourceOrderTypeName", label: '源单类型', width: 120 },
                    { prop: "operatorName", label: '运营商名称', width: 200 },
                    {
                        prop: 'tradedTime', label: '入账时间', width: 100,  callBack: (item)=>{
                            return this.$formatDate(item.tradedTime);
                        }
                    },
                    { prop: "accountNameFull", label: '收款人', width: 100 },
                    { prop: "bankCardNumber", label: '收款账号', width: 150 },
                    { prop: "bank", label: '收款开户银行', width: 100 },
                    { prop: "amount", label: '单据金额', width: 80 },
                    { prop: "auditedAmount", label: '审核金额', width: 80 },
                    { prop: "auditedStateName", label: '状态', width: 70 ,callBack: (item)=>{
                            return item.auditedStateName
                        },getColor:(item)=>{
                            return item.AuditStateName == '已审核' ? "textsuccess":item.AuditStateName == '未审核'? "textdanger" : "textwarning";
                        }
                    },
                    { prop: "auditedBy", label: '财务审核人', width: 100 },
                    { prop: 'auditedTime', label: '财务审核时间', width: 100 ,callBack: (item)=>{
                        return this.$formatDate(item.auditedTime);
                    }},
                    { prop: "bankCardNumber", label: '银行卡号', width: 150 },
                    { prop: "settledBy", label: '结算人', width: 100 },
                    { prop: 'settledTime', label: '结算确认时间', width: 100 ,callBack: (item)=>{
                        return this.$formatDate(item.settledTime);
                    }},
                    { prop: 'remark', label: '备注', mimWidth: 150 },
                ]
            },
            pageinfo:{
                pg_idx: 1,
                pg_size: 15,
            },
            selectedList:[],

            //auditItemDialog
            showAuditItemDialog:false,
            auditItemForm:{},
            auditItemFormRules:{
                auditedAmount:[{ required: true, message: "实际运费不能为空", trigger: "blur" }]
            }
        }
    },
    computed:{
        searchItemList(){
            return [
                { label: "入账日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                { label: "", placeholder: '运营商',type: "select", value: "operatorCode", selectOptions: this.selectOptions.operatorList, multiple: false ,clearable:true,width:180, keyValue:['Code','Name']},
                { label: "",placeholder: '源单类型', type: "select", value: "sourceOrderType", selectOptions: this.sourceOrderTypeList, multiple: true,filterable:false,clearable:true,keyValue:['Code','Name']},
                { label: "",placeholder: '状态',type: "select", value: "auditedState", selectOptions: this.selectOptions.stateList,multiple: true,filterable:false,clearable:true,width:150, keyValue: ['Code', 'Name'] },
                { type: "input", value: "kw", placeholder: '查询单号',width:150}
            ]
        },
    },
    watch:{
        selectedList:{
            handler(){
                this.pageSizeForm.totalAmt = 0
                this.selectedList.forEach(item => {
                    this.pageSizeForm.totalAmt += item.amount
                })
            },
            deep:true
        }
    },
    created() {
     this.params.moduleType=this.$route.query.moduleType;
    },
    mounted() {
        this.getOperatorInfo();
        this.getStateInfo();
        this.getListData();
    },
    methods: {
        getOperatorInfo(){
          this.$store.dispatch('cache/loadCacheData',this.CacheKeys.OperatorInfo).then((res) => {
            this.selectOptions.operatorList = res
          });
        },
        getStateInfo(){
          if(this.params.moduleType==2){
            this.selectOptions.stateList = this.stateList[0];
          }else{
            this.selectOptions.stateList = this.stateList[1];
          }
        },
        search(postData) {
            this.pageinfo.pg_idx = 1;
            this.searchData = postData || this.searchData;
            this.getListData();
        },
        getListData() {
            this.pageinfo.pg_size = this.pageSizeForm.pageSize;
            let auditedState=this.formatData();
            this.$http.post(this.baseApiUrl + '/GetWholesaleRevenueList', {...this.pageinfo,...this.searchData,auditedState:this.params.moduleType==2?auditedState.join(','):this.searchData.auditedState}).then((res)=>{
                this.mainData.DataList = res.data.rows;
                this.mainData.Total = res.data.total || -1;
            });
        },
        formatData(){
          let auditedState=[];
          if((!this.searchData.auditedState||this.searchData.auditedState.length==0)&&this.params.moduleType==2){
            this.stateList[0].map((item)=>{auditedState.push(item.Code)})
          }else{
            auditedState=this.searchData.auditedState;
          }
          return auditedState;
        },
        getPageCurrent(val) {
            this.pageinfo.pg_idx = val;
            this.getListData();
        },
        exportData(){
            let auditedState=this.formatData();
            this.postExportData({...this.searchData,auditedState:this.params.moduleType==2?auditedState.join(','):this.searchData.auditedState}, this.baseApiUrl+'/GetWholesaleRevenueList')
        },
        detailItem(d){
          this.$store.dispatch('tags/setTagsItem',{name: 'son-WholesaleRevenueDetail', title: '贷款分账明细', path: '/son-WholesaleRevenueDetail?orderNo='+d.orderNo,type:'child'});
          this.$router.push({path: '/son-WholesaleRevenueDetail', query:{orderNo:d.orderNo}})
        },
        selectChange(data){
            this.selectedList = data
        },
        auditItem(){
            if(this.selectedList.length <= 0){
                return this.showTipMessage("请选择记录！")
            }
            let errItem = this.selectedList.find(v=>v.auditedState>0);
            if(errItem){
                return this.showTipMessage("存在已审核记录！")
            }
            if(this.selectedList.length>1){
                const postData = {ids : this.selectedList.map(p=>p.id).join(',')}
                this.showConfirm('您现在进行【批量审核】操作，所选记录中的实际金额均填入单据金额，是否继续执行？',()=>{
                    this.$http.post(this.baseApiUrl + "/BatchAuditWholesaleRevenue", postData).then(()=>{
                        this.showTipMessage("提交成功","success")
                        this.getListData()
                    })
                })
                return;
            }

            this.auditItemForm = this.selectedList[0]
            this.auditItemForm.auditedAmount = this.auditItemForm.amount
            this.auditItemForm.title = "分帐单审核-" + this.auditItemForm.orderNo
            this.showAuditItemDialog = true
        },
        auditItemSubmit(){
            this.$refs["auditItemForm"].validate((valid) => {
                if (valid) {
                    let data = this.auditItemForm
                    const postData={
                        orderNo:data.orderNo,
                        auditedAmount : data.auditedAmount,
                        remark:data.remark || ''
                    }
                    if(data.amount != postData.auditedAmount && !postData.remark){
                        return this.showTipMessage("实际货款与账单货款不一致，请在备注写明缘由")
                    }
                    this.showAuditItemDialog = false
                    this.showConfirm("您所填写的实际金额为：" + postData.auditedAmount + ",确认继续提交？",()=>{
                        this.$http.post(this.baseApiUrl + "/AuditWholesaleRevenue", postData).then(()=>{
                            this.showTipMessage("提交成功","success")
                            this.getListData()
                        })
                    })
                }
            })
        },
        payItem(){
            if(this.selectedList.length <= 0){
                return this.showTipMessage("请选择记录！")
            }
            let errItem = this.selectedList.find(v=>v.auditedState!=1);
            if(errItem){
                return this.showTipMessage("非法操作")
            }
            var row = this.selectedList[0];
            let s = this.selectedList.filter(p=>p.operatorCode != row.operatorCode);
            if(s && s.length>0){
                return this.showTipMessage("运营商不一致")
            }
            let url = "",tips="",postData={};

            if(this.selectedList.length == 1){
                tips = "您所填选单号为：" + row.orderNo+",确认继续提交？";
                url = "/SettlementAuditWholesaleRevenue";
                postData["orderNo"] = row.orderNo;
            }
            else{
                tips = "批量结算审核操作，单号："+this.selectedList.map(p=>p.orderNo).join(',')+"，是否继续提交？";
                url = "/BatchSettlementAuditWholesaleRevenue";
                postData["ids"] = this.selectedList.map(p=>p.id).join(',');
            }

            this.showConfirm(tips,()=>{
                this.$http.post(this.baseApiUrl + url, postData).then(()=>{
                    this.showTipMessage("提交成功","success")
                    this.getListData()
                })
            })
        }
    }
}
</script>

<template>
 <div>
  <div style="margin:20px 0">
    运营商编码: <span class="textprimary textspan mr30">{{operatorInfo.operatorCode}}</span>
    运营商名称: <span class="textprimary textspan mr30">{{operatorInfo.operatorName}}</span>
  </div>
  <h3>相关附件：</h3>
  <mainTable :mainOptions="mainData" ref="mainTable"></mainTable>
  <div style="text-align:center;padding:30px;">
    <el-button type="success" @click="saveItem(true)" :disabled="canEdit">准入</el-button>
    <el-button type="danger"  @click="disagreeDialog=true;form={}" :disabled="canEdit">拒绝</el-button>
  </div>
  </div>
  <reject-Popup v-if="disagreeDialog" :rejectInfo="rejectInfo" :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></reject-Popup>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    }
  },
  emits:['closeDialog'],
  data() {
    return {
      baseApiUrl: '/info',
      operatorCode:'',
      type:'',
      operatorInfo:{},
      amendColObj:{},
      disagreeDialog: false,
      canEdit: false,
      rejectInfo:{title:'未通过的原因',value:'remark'},
      order: {},
      id:'',
      details: [],
      mainData: {
        DataList:[],
        tableHeight:'150',
        Total:0,
        labelList: [
          { label: "文件", width: 70,callBack:(item)=>{return item.url}, isHtml:true},
          { label: "文件名", width: 190, prop: "imgName" },
          { label: "大小(kb)", minWidth: 150,callBack:(item)=>{return (item.size/1024).toFixed(2)}},
        ]
      }
    }
  },
  created(){
    this.operatorCode=this.params.operatorCode;
    this.type=this.params.type=='stored_compact'? "is_stored_compact" : "is_leave_with";
    this.loadOrder();
    this.loadAmendDetail();
  },
  watch:{
    params(v){
      let isChange = this.operatorCode != v.operatorCode;
      if (isChange)  this.operatorCode=this.params.operatorCode; setTimeout(() => { this.loadOrder();this.loadAmendDetail(); }, 300);
    }
  },
  methods: {
    loadOrder() {
      let postData={operatorCode:this.operatorCode,code:this.operatorCode,type:this.type}
      this.$http.post(this.baseApiUrl + "/LoadAttachments" ,postData).then((res)=>{
        this.mainData.DataList=res.data||[];
      });
    },
    loadAmendDetail(){
      let postData={operatorCode:this.operatorCode,code:this.operatorCode,type:this.type};
      this.amendColObj={};
      this.$http.post(this.baseApiUrl+'/AmendDetail' ,postData).then((res)=>{
        var data = res.data;
        for (var i = 0; i < data.length; i++) {
          if (data[i].colViewId == "operatorName") {
            this.operatorInfo.operatorName=data[i].value;
          }
          if (data[i].colViewId == "operatorCode") {
            this.operatorInfo.operatorCode=data[i].value;
          }
          if (data[i].isAmendCol === 1) {
            this.amendColObj = data[i];
          }
        }
      });
    },
    submitForm(value) {
      this.saveItem(false,value);
    },
    saveItem(isAgree,value) {
      let postData,type=this.params.type=='stored_compact'? "operator-StoredCompact" : "operator-AllowInStock";
      if (isAgree) {
        postData = {
          logs: JSON.stringify([{...this.amendColObj,pass:1}]),
          type:type
        };
      } else {
        postData = {
          logs: JSON.stringify([{...this.amendColObj,pass:0,...value}]),
          type: type
        }
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/AuditAmendLog" ,postData).then(()=>{
        this.showTipMessage('审核结果提交成功!','success');
        this.disagreeDialog=false;
        this.canEdit=false;
        this.$emit('closeDialog');
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

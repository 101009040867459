<template>
  <div class="content_box">
    <div class="search_bar">
      <el-button type="warning" @click="exportData" size="small">导出</el-button>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent"></mainTable>
    </div>
  </div>
</template>
<script>
    export default {
        data() {
            return {
                baseApiUrl: '/operator',
                searchData: {},
                selectOptions:[],
                logtypeArr:[
                    { Code: "运费", Name: "运费" },
                    { Code: "提现", Name: "提现" },
                    { Code: "货款", Name: "货款" }
                ],
                mainData: {
                    DataList: [],
                    Total: -1,
                    labelList: [
                        { prop: "linkNo", label: '关联单号', width: 210 },
                        { prop: "stageName", label: '驿站名称', width: 150 },
                        { prop: "amount", label: '金额', width: 100 },
                        { prop: "balance", label: '结余', width: 100 },
                        { prop: "logType", label: '日志类型', width: 100 },
                        { prop: "createdTime", label: '时间', width: 150 },
                        { prop: 'remark', label: '备注', minWidth: 150 },
                    ]
                },
                pageinfo: {
                    pg_idx: 1,
                    pg_size: 15
                },
            }
        },
        computed:{
            searchItemList(){
                return [
                    { label: "日期:", type: "daterange", value: "createtime",defaultValue:[this.$formatDate(0, -7),this.$formatDate(0)]},
                    { label: "驿站:", type: "select", value: "code", selectOptions: this.selectOptions, multiple: false ,clearable:true,keyValue:['Code','Name']},
                    { label: "类型:", type: "select", value: "logtype", selectOptions: this.logtypeArr, clearable:true, multiple: true,width:150, keyValue: ['Code', 'Name'] },
                    { type: "input", value: "kw", placeholder: '关联单号' }
                ]
            }
        },
        mounted() {
            this.getStageInfo()
        },
        methods: {
            getStageInfo(){
              this.$store.dispatch('cache/loadCacheData',{...this.CacheKeys.StageInfo,query:{...this.CacheKeys.StageInfo.query,state:-1}}).then((res) => {
                 this.selectOptions = res;
              });
            },
            search(postData) {
                this.pageinfo.pg_idx = 1;
                this.searchData = postData || this.searchData;
                this.getListData();
            },
            getListData() {
                this.$http.post(this.baseApiUrl + '/StageFundLogsList', { ...this.searchData, ...this.pageinfo,...{isExcel: false}}).then((res)=>{
                    this.mainData.DataList = res.data.rows;
                    this.mainData.Total = res.data.total || -1;
                });
            },
            getPageCurrent(val) {
                this.pageinfo.pg_idx = val;
                this.getListData();
            },
            exportData(){
                this.postExportData({...this.searchData,...{isExcel:true}}, this.baseApiUrl+'/StageFundLogsList');
            },
        }
    }
</script>

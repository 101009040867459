<template>
  <div class="content_box">
    <div class="search_bar">
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="warning" plain size="small" @click="showDetail(actionBtn.data)" >审核</el-button>
        </template>
      </mainTable>
    </div>
  </div>
  <el-dialog v-model="showDetailPage" title="查看商品明细" width="780px">
    <el-row :gutter="24">
      <el-col :span="8">
        <div style="display: flex;justify-content: center;align-items: center;width:100%;height:100%">
          <span v-if="!curItem.imgUrl">暂无图片</span>
          <img :src="curItem.imgUrl" style="max-height: 290px; width: 100%; object-fit: contain;" v-else/>
        </div>
      </el-col>
      <el-col :span="16">
        <el-descriptions size="small" :border="true"  :column="1" class="uniform-description">
          <el-descriptions-item label="商品名称"  label-class-name="labelName">
            <el-tag size="small">{{curItem.productNameMarket}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="商城">{{curItem.areaName}}</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="商城价" :span="2">{{curItem.marketPrice}}&nbsp;</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="运营商">{{curItem.operatorName}}</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="条形码">{{curItem.barcode}}</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="规格">{{curItem.spec}}</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="有效期（天）">{{curItem.shelfLife}}</el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="批次策略">
            <span class="textdanger">{{curItem.batch}}</span>
            <span class="textwarning" v-if="curItem.batchDate">&nbsp;（{{curItem.batchDate}}）</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="labelName" label="备注">{{curItem.remark}}</el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <div style="text-align:center;padding:30px;">
      <el-button type="primary" size="small" @click="NextItem" :disabled="canEdit">下一个</el-button>
      <el-button type="success" size="small" @click="saveItem(true)" :disabled="canEdit">审核同意</el-button>
      <el-button type="danger"  size="small" @click="disagreeDialog=true;" :disabled="canEdit">拒绝通过</el-button>
    </div>
  </el-dialog>
  <reject-Popup v-if="disagreeDialog" :rejectInfo="rejectInfo"  :iscanEdit="canEdit" @closedialog="disagreeDialog=false" @submitform="submitForm"></reject-Popup>
</template>
<script>
import {formatBatchDate,formatBatch} from '@/utils/common'
export default {
  data() {
    return {
      baseApiUrl: '/mall',
      searchItemList:[
        {type: "input", value: "kw",placeholder:'查询商品名/编码/条码'}
      ],
      mainData: {
        DataList:[],
        Total:-1,
        labelList: [
          {label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "条目编码", width: 100, prop: "itemSerialCode" },
          { label: "产品商城名称", width: 150, prop: "productNameMarket" },
          { label: "状态", width: 100, prop: "isOnShelfName" },
          { label: "商城", width: 150, prop: "areaName" },
          { label: "备注", minWidth: 200, prop: "remark" }
        ]
      },
      rejectInfo:{title:'拒绝原因',value:'auditRemark'},
      showDetailPage:false,
      disagreeDialog:false,
      form:{},
      pageinfo:{
        pg_idx: 1,
        pg_size: 15
      },
      canEdit:false,
      curItem:{},
    }
  },
  inject: ['$hasPermission'],
  methods: {
    getListData(){
      this.$http.post(this.baseApiUrl+'/ProOperatorMarketAudit', {...this.searchData,...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total= res.data.total||-1;
      });
    },
    getPageCurrent(val){
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData){
      this.pageinfo.pg_idx = 1;
      this.searchData=postData||this.searchData;
      this.getListData();
    },
    NextItem(){
      this.showDetail('NextItem')
    },
    showDetail(d){
      let url=d == 'NextItem'?'/GetNextProMarketInfo':'/GetProMarketInfo';
      this.canEdit=true;
      this.$http.post(this.baseApiUrl+url, {id:d == 'NextItem'?this.curItem.id:d.id}).then((res) => {
        this.canEdit=false;
        if(!res.ext&&!res.data){
          this.showTipMessage('暂无记录','warning');
          return;
        }
        let data=res.ext.ProMarketData;
        this.curItem={
          id:data.proMarketData.id,
          productNameMarket:data.proMarketData.productNameMarket,
          areaName:data.proMarketData.areaName,
          operatorName:data.operatorName,
          barcode:data.proBase.barcode,
          spec:data.proBase.spec,
          shelfLife:data.proBase.shelfLife,
          productCode:data.proMarketData.productCode,
          marketPrice:data.proMarketData.marketPrice,
          imgUrl:data.imgData.length==0?'':data.imgData[0].url,
          remark:data.proMarketData.remark,
          batch:formatBatch(data.proMarketData.produceCondition),
          batchDate:formatBatchDate(data.proMarketData.produceCondition, data.proMarketData.produceDate1,data.proMarketData.produceDate2)
        }
        this.showDetailPage=true;
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');})
    },
    submitForm(value) {
      this.saveItem(false,value);
    },
    saveItem(isAgree,value) {
      let postData = isAgree?{id:this.curItem.id}:{id:this.curItem.id,...value}
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + "/ProOperatorMarketListUpState" ,postData).then(() => {
        this.showTipMessage('提交成功!','success');
        this.canEdit=false;
        this.showDetailPage = false;
        this.disagreeDialog=false;
        this.search();
      },(res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error');});
    }
  }
}
</script>

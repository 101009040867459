<template>
  <div class="content_box">
    <div class="search_bar">
      <el-row class="search_bar-bg-box">
        <span>角色权限编辑-----【{{roleInfo.roleName}}】</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button type="success" size="small" @click="saveItems()">保存</el-button>
      </el-row>
    </div>
    <div id="datagrid" style="height:calc(100vh - 50px);overflow-y: scroll">
      <div id="main" class="dataTable">
        <table style="width: 100%;font-size:12px;">
          <div v-for="(item,index) in MenuList"  style="border:1px solid #eee;margin-bottom: 20px">
            <div class="td0-l" style="background: #eee">
              <label>
                <el-checkbox  v-model="item.isCheck" true-value="checked" false-label="" @change="parentCheck(item,1)">{{item.name}}</el-checkbox>
              </label>
            </div>
            <tr><td class="td0-r">
              <table>
                <tr v-for="child in item.details">
                  <td class="td1-l">
                    <label>
                      <el-checkbox  v-model="child.isCheck" true-value="checked" false-label="" @change="parentCheck(child,2)">{{child.name}}</el-checkbox>
                    </label>
                  </td>
                  <td class="td1-r">
                    <table>

                      <tr v-for="son in child.details">
                        <td class="td2">
                          <label>
                            <el-checkbox  v-model="son.isCheck" true-value="checked" false-label="" @change="parentCheck(son,3)">{{son.name}}</el-checkbox>
                          </label>
                        </td>
                        <td>
                          <div>
                            <label v-for="sonChild in son.details">
                                  <span>
                                      <span>
                                         <el-checkbox  v-model="sonChild.isCheck" true-value="checked" false-label="" @change="parentCheck(sonChild,4)">{{sonChild.name}}</el-checkbox>
                                      </span>
                                  </span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td></tr>
          </div>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baseApiUrl: '/system',
      MenuList:[],
      roleInfo:{},
      roleCode:'',
      roleName:'',
    }
  },
  inject: ['$hasPermission'],
  created() {
    this.roleCode=this.$route.query.roleCode
  },
  mounted(){
    this.getListData();
  },
  methods: {
    getListData() {
      this.$http.post(this.baseApiUrl+'/getrolepermission', {roleCode: this.roleCode}).then((res)=>{
        let moduleTrees = res.ext.moduleTrees, rolePermissions = res.ext.rolePermissions;
        this.roleInfo=res.ext.role;
        moduleTrees.forEach(item => this.checkAndUpdate(item, rolePermissions));
        this.MenuList = moduleTrees;
      })
    },
    checkAndUpdate(item, PermissionArray) {
      const isPermission = PermissionArray.some(PItem =>
          item.code === PItem.moduleCode || (PItem.uaCodes && PItem.uaCodes.includes(item.code))
      );
      if (isPermission) {
        item.isCheck = 'checked';
      } else {
        item.isCheck = '';
      }
      if (item.details && item.details.length > 0) {
        item.details.forEach(detailItem => this.checkAndUpdate(detailItem, PermissionArray));
      }
     },
    getChildValue(item, checkValue) {
      if (item.details && item.details.length > 0) {
        item.details.map((detailItem) => {
          detailItem.isCheck = checkValue
          if (detailItem.details) {
            this.getChildValue(detailItem, checkValue)
          }
        })
      }
    },
    getParentValue(list) {
      // 改变上一级
      list.map((item) => {
        if (item.details && item.details.length > 0) {
          var index = item.details.findIndex((detailItem) => {
            return detailItem.isCheck == "checked";
          });
          item.isCheck = index == -1 ? '' : "checked";
          this.getParentValue(item.details)
        }
      })
    },
    parentCheck(curItem, leng) {
      var checkValue = curItem.isCheck == 'checked' ? 'checked' : '';
      this.getChildValue(curItem, checkValue);
      setTimeout(() => {
        for (var i = 0; i < leng; i++) {
          this.getParentValue(this.MenuList)
        }
      }, 200)
    },
    formatData(data, common, operations, pitem) {
      data.forEach(item => {
        if (item.isCheck === 'checked' && item.code.indexOf('UA') == -1) {
          common.push(item.code);
        } else if (item.isCheck === 'checked' && item.code.indexOf('UA') > -1) {
          operations.push(item.code + "|" + pitem.code);
        }
        if (item.details) {
          this.formatData(item.details, common, operations, item);
        }
      });
    },
    saveItems() {
      const common = [], operations = [];
      this.formatData(this.MenuList, common, operations);
      const loading = this.AjaxLoading();
      this.$http.post(this.baseApiUrl+'/SaveRolePermission', {
        "common": common.join(),
        "operations": operations.join(),
        "roleCode": this.roleCode
      }).then((res)=>{
        if (!res.succ) {
          this.showTipMessage(res.msg, "error");
          this.AjaxLoadEnd(loading);
          return;
        } else {
          this.showTipMessage("保存成功，即将关闭子页面……", "success");
          setTimeout(()=>{
            this.AjaxLoadEnd(loading);
            this.$store.dispatch('tags/closeCurrentTag',{$router: this.$router, $route: this.$route});
            this.$router.push({path:'/system-rolemanage'})
          },3000)
        }
      });
    }
  }
}
</script>
<style>
.dataTable .el-checkbox__label{font-size:12px!important;}
.dataTable td.td0-l, td.td2 {
  /* display:none*/
  background-color: #ffe7bc;
  width: 160px;
}

.dataTable td.td1-l {
  padding:20px;
  border:solid 1px #eee;
  width:130px;
  text-align:center
}

.dataTable td.td1-r {
  border:solid 1px #eee;
  width:100vw;
}

.dataTable div > label {
  padding: 5px 20px 5px 5px;
  display: inline-grid;
  width: 130px
}
</style>

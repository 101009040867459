<template>
  <div class="content_box">
    <div class="search_bar">
      <div>
        <el-button type="primary" v-on:click="exportData" size="small">导出</el-button>
        <el-button type="success" size="small" @click="importProduct">导入</el-button>
      </div>
      <baseSearch :formItemList="searchItemList" @search="search" :emitSearch="true"></baseSearch>
    </div>
    <div class="main-box">
      <mainTable :mainOptions="mainData" :mainPage="pageinfo" @getPageCurrent="getPageCurrent" ref="mainTable">
        <template v-slot:operation="actionBtn">
          <el-button type="warning"  plain size="small" @click="submitItem(actionBtn.data)" >编辑</el-button>
        </template>
      </mainTable>
    </div>
    <el-dialog v-model="showDetailPage" title="运费设置" width="680">
      <div style="margin:20px 15px">
        运营商: <span class="textprimary textspan mr30">{{curItem.operatorName}}</span>
        商品: <span class="textprimary textspan mr30">{{curItem.productName}}</span>
      </div>
      <el-form ref="editForm" :model="form" size="small" label-width="80px" :rules="rules" style="margin:auto;">
        <el-form-item label="运费单位" prop="freightUnit" >
          <el-select v-model="form.freightUnit"  placeholder="运费单位" :clearable="true" style="width: 100%;">
            <el-option key="件" label="件" value="件" />
            <el-option key="公斤" label="公斤" value="公斤" />
            <el-option key="立方米" label="立方米" value="立方米" />
          </el-select>
        </el-form-item>
        <el-form-item label="运费单价" prop="freightPrice">
          <el-input v-model="form.freightPrice" placeholder="请输入运费单价"  type="number"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" v-on:click="submitForm" :disabled="canEdit">确定</el-button>
          <el-button v-on:click="showDetailPage=false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="importDialog" title="批量修改运费" width="600px">
      <uploader :url="uploadOpt.url"
                filename="import_file"
                :templateUrl="uploadOpt.templateUrl"
                :postData="uploadOpt.data"
                :tips="uploadOpt.tips"
                :accept="uploadOpt.accept"
                v-on:complete="uploadFinish"></uploader>
    </el-dialog>
  </div>
</template>
<script>
import {ElNotification} from "element-plus";
export default {
  data() {
    return {
      baseApiUrl: '/product',
      searchData: {},
      showDetailPage: false,
      mainData: {
        DataList: [],
        Total: -1,
        labelList: [
          { label:"操作",prop:"operation",slot_name:'operation',type:'slot',width:"70", fixed:'left'},
          { label: "运营商", width: 160, prop: "operatorName" },
          { label: "商品编码", width: 100, prop: "productCode" },
          { label: "商品名称", width: 180, prop: "productName" },
          { label: "商品类型", width: 100, prop: "typeName" },
          { label: "品牌", width: 100, prop: "brandName" },
          { label: "运费单价", width: 80,callBack:(item)=>{ return item.freightPrice.toFixed(2)},getColor:()=>{return 'textdanger'}},
          { label: "运费单位", minWidth: 60, prop: "freightUnit" }
        ]
      },
      BrandInfo:[],
      ProductTypeInfo:[],
      form:{},
      curItem:{},
      canEdit:false,
      importDialog:false,
      uploadOpt:{
        url: '/product/ImportPromotionPrice',
        templateUrl:'./templates/promotion_price.xlsx',// 模板路径
        tips: '提示：请选择上传表格，仅支持.xlsx后缀的文件',
        accept:'.xls,.xlsx',
        data: {}
      },
      rules: {
        freightUnit: [{ required: true, message: "运费单位不能为空", trigger: "blur" }],
        freightPrice: [
          { required: true, message: "请输入运费单价", trigger: "blur" },
          { pattern: /^\d+(\.\d+)?$/, message: '运费只能输入数字', trigger: 'blur' },
        ]
      },
      pageinfo: {
        pg_idx: 1,
        pg_size: 15
      },
    }
  },
  inject: ['$hasPermission'],
  computed: {
    searchItemList() {
      return [
        {label: "商品类型", type: "select", value: "typeCode", selectOptions: this.ProductTypeInfo, multiple: false, keyValue:['Code','Name'],width:'130'},
        {label: "品牌", type: "select", value: "brandCode",width:220,selectOptions: this.BrandInfo, multiple: true, keyValue:['Code','Name']},
        {type: "input", value: "kw", placeholder: '商品名'}
      ];
    }
  },
  mounted(){
    this.commonAjax();
  },
  methods: {
    commonAjax(){
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.ProductTypeInfo).then((res) => {
        this.ProductTypeInfo=res;
      });
      this.$store.dispatch('cache/loadCacheData',this.CacheKeys.BrandInfo).then((res) => {
        this.BrandInfo=res;
      });
    },
    getListData() {
      this.$http.post(this.baseApiUrl + '/SetUpPromotionPrice', {...this.searchData, ...this.pageinfo}).then((res) => {
        this.mainData.DataList = res.data.rows;
        this.mainData.Total = res.data.total || -1;
      });
    },
    getPageCurrent(val) {
      this.pageinfo.pg_idx = val;
      this.getListData();
    },
    search(postData) {
      if (postData) this.pageinfo.pg_idx = 1;
      this.searchData = postData || this.searchData;
      this.getListData();
    },
    submitItem(d){
      this.curItem=d;
     this.form = {
        id: d.id,
        freightPrice:d.freightPrice,
        freightUnit: d.freightUnit
      }
      this.showDetailPage=true;
    },
    submitForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.saveItem();
        } else {
          return false
        }
      });
    },
    saveItem() {
      if(this.form.freightPrice==this.curItem.freightPrice&&this.form.freightUnit==this.curItem.freightUnit){
        this.showTipMessage('无修改内容!','warning');
        return;
      }
      this.canEdit=true;
      this.$http.post(this.baseApiUrl + '/EditPromotionPrice' ,this.form).then(() => {
        this.showTipMessage('修改成功!','success');
        this.showDetailPage=false;
        this.canEdit=false;
        this.search();
      }).then((res)=>{this.canEdit=false;this.showTipMessage(res.msg||'未知错误','error')});
    },
    importProduct(){
      this.importDialog=true;
    },
    uploadFinish(obj) {
      if (obj.resp.succ) {
        this.showTipMessage("批量修改运费成功", 'success');
        this.importDialog=false;
      } else {
        let msg='',str='';
        if(obj.resp.msg.indexOf('\r')!=-1){msg=obj.resp.msg.split("\r");
        }else if(obj.resp.msg.indexOf('\n')!=-1){msg = obj.resp.msg.split("\n");
        }else{msg=[obj.resp.msg]}
        msg.forEach((item)=>{
          str+='<p>'+item+'</p>';
        })
        ElNotification.error({
          message:`<div style="overflow-y: auto;max-height:calc(100vh - 100px);width:240px">${str}</div>`,
          dangerouslyUseHTMLString:true,
          duration: 0,
          type: 'error',
        })
      }
    },
    exportData(){
      this.postExportData({...this.searchData,...this.pageinfo}, this.baseApiUrl+'/SetUpPromotionPrice');
    },
  }
}
</script>

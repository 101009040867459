<template>
  <div v-if="curUrl.length>0" class="breadcrumb-box">
<!--    <el-icon size="18" color="#409eff"><HomeFilled /></el-icon>-->
    <span style="color:#606266;font-size:12px">当前位置：</span>
    <el-breadcrumb >
     <el-breadcrumb-item v-for="(item,index) in curUrl"><span :style="index==curUrl.length-1?'color:#e6a23c':'color:#606266'">{{item}}</span></el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, toRefs, watch} from "vue";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
export default defineComponent({
  setup() {
    const store = useStore();
    const curUrl = computed(() => store.state.menu.breadcrumb);
    return {
      curUrl
    }
  }
})
</script>
<style scoped>
.breadcrumb-box{display: flex;background:#fefefe;  box-shadow: 0 1px 2px #eee;padding-left:12px!important;align-items: center}
.breadcrumb-box .el-breadcrumb{margin:0 0 !important;border:none!important;padding-left:0px!important;}
</style>
